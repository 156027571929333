import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextArea,
  YesNoButtons,
  OkNotOkButtons,
} from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";
import { normalizeCommunicationData } from "./lib";

const CommunicationCircuitSchema = Yup.object().shape({
  communicationIsHashboardDetected: Yup.boolean().required(),
  communicationEEPROMOk: Yup.boolean().required(),
  communicationPICOk: Yup.boolean().when("communicationEEPROMOk", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  communicationGotCorrosion: Yup.boolean().when("communicationPICOk", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  communicationNotes: Yup.string(),
});

export const CommunicationCircuitForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        communicationIsHashboardDetected:
          typeof report.communicationIsHashboardDetected !== "undefined"
            ? report.communicationIsHashboardDetected
            : true,
        communicationEEPROMOk:
          typeof report.communicationEEPROMOk !== "undefined"
            ? report.communicationEEPROMOk
            : true,
        communicationPICOk:
          typeof report.communicationPICOk !== "undefined"
            ? report.communicationPICOk
            : true,
        communicationGotCorrosion:
          typeof report.communicationGotCorrosion !== "undefined"
            ? report.communicationGotCorrosion
            : false,
        communicationNotes: report.communicationNotes,
      }}
      validationSchema={CommunicationCircuitSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.communicationIsHashboardDetected !== "undefined" &&
            isValid
          }
          canGoToPrevStep
          title="Communication Circuit"
          values={normalizeCommunicationData(values)}
        >
          <YesNoButtons
            label="Hashboard detected?"
            name="communicationIsHashboardDetected"
          />
          {typeof values.communicationIsHashboardDetected !== "undefined" ? (
            <OkNotOkButtons label="EEPROM" name="communicationEEPROMOk" />
          ) : null}

          {values.communicationEEPROMOk ? (
            <OkNotOkButtons label="PIC" name="communicationPICOk" />
          ) : null}

          {values.communicationPICOk ? (
            <YesNoButtons label="Corrosion" name="communicationGotCorrosion" />
          ) : null}

          <TextArea label="Notes (Optional)" name="communicationNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
