import { Link } from "react-router-dom";
import { Text, Icon, Stack, HStack } from "@chakra-ui/react";
import { SpreadsheetIcon } from "components/Icons";
import { ActionButton } from "components/utils/ActionButton";
import {
  InspectionsProvider,
  useInspections,
} from "features/inspections/context/Inspections";
import { Page } from "components/layout/Page";
import { DataListWithFiltersAndPagination } from "components/DataList/index";
import { useInspectionReport } from "features/inspections/data/hooks/inspection-reports";

const ConnectedInspectionsPage = () => {
  const { loading, data, setParams, params } = useInspections();
  const { generateReport } = useInspectionReport();
  return (
    <Page
      loading={loading}
      title="Inspections"
      subTitle="See and manage all the inspections in the system"
    >
      <DataListWithFiltersAndPagination
        loading={loading}
        data={data?.inspections?.data || []}
        pagination={data?.inspections?.meta.pagination}
        onPageChange={(page) => {
          setParams(Object.assign({}, params, { page }));
        }}
        renderItem={({ item: inspection }) => (
          <Stack>
            <Link to={`/inspections/${inspection.id}`}>
              <Text fontWeight="bold">{inspection.attributes?.name}</Text>
            </Link>
            <HStack>
              <ActionButton
                size="xs"
                variant="outline"
                action={async () => {
                  if (inspection.id) {
                    await generateReport(inspection.id);
                  }
                }}
              >
                <Icon mr="2" as={SpreadsheetIcon} />
                Download Report
              </ActionButton>
            </HStack>
          </Stack>
        )}
      />
    </Page>
  );
};

export const InspectionsPage = () => (
  <InspectionsProvider>
    <ConnectedInspectionsPage />
  </InspectionsProvider>
);
