import {
  Table as BaseTable,
  TableProps as BaseTableProps,
  Th as BaseTh,
  TableColumnHeaderProps as BaseTableColumnHeaderProps,
} from "@chakra-ui/react";

export { TableContainer, Thead, Tbody, Tr, Td } from "@chakra-ui/react";

interface TableColumnHeaderProps extends BaseTableColumnHeaderProps {}
interface TableProps extends BaseTableProps {}

export const Table = (props: TableProps) => <BaseTable size="md" {...props} />;
export const SimpleTable = (props: TableProps) => (
  <BaseTable variant="simple" size="md" {...props} />
);
export const Th = (props: TableColumnHeaderProps) => (
  <BaseTh fontWeight="bold" textTransform="uppercase" {...props} />
);
