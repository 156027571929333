import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanels, TabPanel, Tab } from "components/utils/Tabs";
import { useParams } from "react-router-dom";
import { useNotifications } from "context/Notifications";
import { DataListWithFiltersAndPagination } from "components/DataList";
import { Page } from "components/layout/Page";
import { useCustomerDetails } from "features/customers/data/hooks/customers";
import { OrderTableEntry } from "features/orders/components/OrdersTable";
import { OrderPackagesTable } from "features/order-packages/components/OrderPackagesTable";
import { CreateOrderPackageFormContainer as CreateOrderPackageForm } from "features/order-packages/containers/CreateOrderPackageForm";
import type { CreateOrderPackageFormData } from "features/order-packages/containers/CreateOrderPackageForm";
import { useCreateOrderPackage } from "features/order-packages/data/hooks/order-packages";

interface ConnectedCustomerPageProps {
  customerId: string;
}

export const ConnectedCustomerPage = (props: ConnectedCustomerPageProps) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const [newOrderPackageData, setNewOrderPackageData] =
    useState<CreateOrderPackageFormData | null>(null);
  const { loading, customer, orderPackages, orders, refetch } =
    useCustomerDetails({
      customerId: props.customerId,
    });
  const { createOrderPackage } = useCreateOrderPackage();

  return (
    <Page
      loading={loading}
      title={`${customer?.attributes?.accountName}`}
      subTitle={null}
      primaryActionButtonText="Create Package"
      actionPanel={{
        title: "Create Package",
        children: customer ? (
          <CreateOrderPackageForm
            onChange={(formData) => {
              setNewOrderPackageData(formData);
            }}
            customer={customer}
          />
        ) : null,
        onAction: async () => {
          try {
            if (customer && newOrderPackageData) {
              const orderPackage = await createOrderPackage({
                customer,
                name: newOrderPackageData.name,
              });
              await refetch();
              showSuccessMessage("Package created");

              if (orderPackage?.id) {
                navigate(`/packages/${orderPackage.id}`);
              }
            }
          } catch (e) {
            showErrorMessage(
              "Failed to create package: make sure package name is unique!"
            );
          } finally {
            setNewOrderPackageData(null);
          }
        },
        onClose: async () => {},
        enablePrimaryAction: newOrderPackageData !== null,
      }}
    >
      <Tabs>
        <TabList>
          <Tab>Orders</Tab>
          <Tab>Packages</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <DataListWithFiltersAndPagination
              loading={loading}
              data={orders || []}
              onPageChange={() => {}}
              renderItem={({ item: order }) => (
                <OrderTableEntry order={order} />
              )}
            />
          </TabPanel>
          <TabPanel>
            <OrderPackagesTable orderPackages={orderPackages || []} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export const CustomerPage = () => {
  const { customerId } = useParams();
  return customerId ? <ConnectedCustomerPage customerId={customerId} /> : null;
};
