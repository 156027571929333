import { TableContainer, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import {
  PsuRepairInput,
  PsuRepair,
  OrderItemDataFragment,
  Enum_Device_Make,
} from "data";
import { YesLabel, NoLabel } from "components/Labels";
import { ShowForAllTechs } from "features/accounts/components/ShowForAllTechs";
import { ShowForMake } from "features/devices/components/ShowForMake";

interface PSURepairReportPreviewProps {
  report: PsuRepairInput | PsuRepair;
  orderItem: OrderItemDataFragment;
}

export const PSURepairReportPreview = (props: PSURepairReportPreviewProps) => {
  const { report, orderItem } = props;
  return (
    <TableContainer>
      <Table variant="simple">
        <Tbody>
          <ShowForMake
            device={orderItem.attributes?.device?.data}
            make={Enum_Device_Make.Antminer}
          >
            <>
              {report.quantityInputFusesReplaced ? (
                <Tr>
                  <Td>Input fuses replaced</Td>
                  <Td>{report.quantityInputFusesReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityInrushCurrentLimitersReplaced ? (
                <Tr>
                  <Td>Inrush current limiters replaced</Td>
                  <Td>{report.quantityInrushCurrentLimitersReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityMOSFETsReplaced ? (
                <Tr>
                  <Td>MOSFETs replaced</Td>
                  <Td>{report.quantityMOSFETsReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityPowerDiodesReplaced ? (
                <Tr>
                  <Td>Power diodes replaced</Td>
                  <Td>{report.quantityPowerDiodesReplaced}</Td>
                </Tr>
              ) : null}
              <Tr>
                <Td>U25 replaced</Td>
                <Td>{report.u25Replaced ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
            </>
          </ShowForMake>
          <ShowForMake
            device={orderItem.attributes?.device?.data}
            make={Enum_Device_Make.Whatsminer}
          >
            <>
              {report.quantityInputFusesReplaced ? (
                <Tr>
                  <Td>Input fuses replaced</Td>
                  <Td>{report.quantityInputFusesReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityInrushCurrentLimitersReplaced ? (
                <Tr>
                  <Td>Inrush current limiters replaced</Td>
                  <Td>{report.quantityInrushCurrentLimitersReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityMOSFETsReplaced ? (
                <Tr>
                  <Td>MOSFETs replaced</Td>
                  <Td>{report.quantityMOSFETsReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityBridgesReplaced ? (
                <Tr>
                  <Td>Bridges replaced</Td>
                  <Td>{report.quantityBridgesReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityRelaysReplaced ? (
                <Tr>
                  <Td>Relays replaced</Td>
                  <Td>{report.quantityRelaysReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityCapacitorsReplaced ? (
                <Tr>
                  <Td>Capacitors replaced</Td>
                  <Td>{report.quantityCapacitorsReplaced}</Td>
                </Tr>
              ) : null}
              {report.quantityICsReplaced ? (
                <Tr>
                  <Td>ICs replaced</Td>
                  <Td>{report.quantityICsReplaced}</Td>
                </Tr>
              ) : null}
              <Tr>
                <Td>Other Components Damaged</Td>
                <Td>
                  {report.otherComponentsDamaged ? <YesLabel /> : <NoLabel />}
                </Td>
              </Tr>
            </>
          </ShowForMake>
          {typeof report.isSuccessful !== "undefined" && (
            <Tr>
              <Td>Success</Td>
              <Td>{report.isSuccessful ? <YesLabel /> : <NoLabel />}</Td>
            </Tr>
          )}
          {report.repairTimeMinutes ? (
            <ShowForAllTechs>
              <Tr>
                <Td>Repair time</Td>
                <Td>{`${report.repairTimeMinutes} minutes`}</Td>
              </Tr>
            </ShowForAllTechs>
          ) : null}
          {report.notes ? (
            <Tr>
              <Td>Notes</Td>
              <Td>{report.notes}</Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
