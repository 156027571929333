import {
  Avatar,
  Box,
  HStack,
  Text,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { truncateText } from "lib";
import { useAuthentication } from "features/accounts/context/Authentication";

export const UserProfile = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { userProfile, logout } = useAuthentication();
  return !userProfile ? null : (
    <Menu>
      <MenuButton data-cy="user-profile-menu-button">
        <HStack spacing="3" ps="2">
          {userProfile.attributes?.images?.data.length !== 0 ? (
            <Avatar
              name={userProfile.attributes?.name}
              src={userProfile.attributes?.images?.data[0].attributes?.url}
              boxSize="10"
            />
          ) : null}
          <Box>
            <Text textAlign="left" fontWeight="medium" fontSize="sm">
              {userProfile.attributes?.name}
            </Text>
            <Text color="muted" fontSize="sm">
              {truncateText(
                userProfile.attributes?.user?.data?.attributes?.email,
                isDesktop ? 20 : 25
              )}
            </Text>
          </Box>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem
          data-cy="user-profile-menu-logout"
          onClick={async () => {
            await logout();
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
