import {
  Stack,
  Text,
  Icon,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Radio,
  RadioGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import { SiSpeedtest } from "react-icons/si";
import { BiChip } from "react-icons/bi";
import { TemperatureIcon, HashboardIcon } from "components/Icons";
import { parseIntOrUndefined } from "lib";
import { OrderItemDataFragment, Enum_Device_Make } from "data";
import {
  HashboardSpec,
  MinerDiagnosticsReport,
} from "features/diagnostics/data";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";

interface HashboardProps {
  orderItem: OrderItemDataFragment;
  hashboard: HashboardSpec;
  onChange: (hashboard: HashboardSpec) => void;
}

const Hashboard = (props: HashboardProps) => {
  const { hashboard, orderItem, onChange } = props;
  return (
    <Box
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      p="6"
    >
      <Stack alignItems={"center"}>
        <Icon as={HashboardIcon} w={6} h={6} />
        <Text fontWeight="bold" fontSize="larger">{`Hashboard ${
          hashboard.id + 1
        }`}</Text>
        <Box pt="4">
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Hashrate</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SiSpeedtest} color="gray.300" />}
                />
                <Input
                  onChange={(e) => {
                    onChange(
                      Object.assign({}, hashboard, {
                        hashrate: parseIntOrUndefined(e.currentTarget.value),
                      })
                    );
                  }}
                  type="number"
                  placeholder="Hashrate"
                  value={hashboard.hashrate}
                />
              </InputGroup>
            </FormControl>
            {/* XX: Avalons do not report number of ASICs */}
            {orderItem.attributes?.device?.data?.attributes?.make !==
            Enum_Device_Make.Avalon ? (
              <FormControl>
                <FormLabel>Number of ASICs</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={BiChip} color="gray.300" />}
                  />
                  <Input
                    onChange={(e) => {
                      onChange(
                        Object.assign({}, hashboard, {
                          numberOfASICs: parseIntOrUndefined(
                            e.currentTarget.value
                          ),
                        })
                      );
                    }}
                    type="number"
                    placeholder="Number of asics"
                    value={hashboard.numberOfASICs}
                  />
                </InputGroup>
              </FormControl>
            ) : null}
            <FormControl>
              <FormLabel>Temperature</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={TemperatureIcon} color="gray.300" />}
                />
                <Input
                  onChange={(e) => {
                    onChange(
                      Object.assign({}, hashboard, {
                        temperature: parseIntOrUndefined(e.currentTarget.value),
                      })
                    );
                  }}
                  type="number"
                  placeholder="Temperature"
                  value={hashboard.temperature}
                />
              </InputGroup>
            </FormControl>

            <RadioGroup
              value={hashboard.isGood ? "1" : "0"}
              onChange={(v) => {
                onChange(
                  Object.assign({}, hashboard, {
                    isGood: v !== "0",
                  })
                );
              }}
              pt="4"
            >
              <Stack direction="row">
                <Radio value="1">Good</Radio>
                <Radio value="0">Bad</Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

interface HashboardsDiagnosticsProps {
  orderItem: OrderItemDataFragment;
}

export const HashboardsDiagnostics = (props: HashboardsDiagnosticsProps) => {
  const { orderItem } = props;
  const { data, updateData } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      {minerDiagnosticsReport?.hashboards.map((hashboard) => (
        <GridItem key={hashboard.id}>
          <Hashboard
            orderItem={orderItem}
            onChange={(board) => {
              const hashboards = minerDiagnosticsReport.hashboards;
              updateData(
                Object.assign({}, minerDiagnosticsReport, {
                  hashboards: hashboards.map((hb) => {
                    return hb.id !== board.id ? hb : board;
                  }),
                })
              );
            }}
            hashboard={hashboard}
          />
        </GridItem>
      ))}
    </Grid>
  );
};
