import { createContext, ReactNode, useContext } from "react";
import {
  InspectionDataFragment,
  DeviceDataFragment,
  useUpdateInspectionMutation,
} from "data";
import { useDevicesData } from "features/devices/data/hooks/devices";

interface InspectionSettingsProviderProps {
  inspection: InspectionDataFragment;
  children: ReactNode;
}

interface InspectionRackLayout {
  cols: number;
  rows: number;
  start: number;
  stepCol: number;
  stepRow: number;
}

interface PoolSettings {
  groupName: string;
  url: string;
  username: string;
  password: string;
}

interface InspectionSettingsData {
  targetDevice: DeviceDataFragment;
  scannerURL: string;
  poolSettings?: PoolSettings | null;
  mode: "scan" | "ping";
  // min value for expected hashrate percentage to be considered as "hashing"
  // e.g. 95 indicates that anything below 95% of the expected hashrate is considered as "partially hashing"
  expectedHashratePercentageCutoff: number;
  layout: InspectionRackLayout;
  updateSettings: ({ scannerURL }: { scannerURL: string }) => Promise<void>;
}

const InspectionSettingsContext = createContext<InspectionSettingsData>(
  {} as InspectionSettingsData
);

export const useInspectionSettings = () => {
  return useContext(InspectionSettingsContext);
};

export const InspectionSettingsProvider = (
  props: InspectionSettingsProviderProps
) => {
  const { inspection } = props;
  const { devices } = useDevicesData();
  const [updateInspection] = useUpdateInspectionMutation();

  return (
    <InspectionSettingsContext.Provider
      value={{
        targetDevice: devices.find(
          (d) => d.id === inspection.attributes?.configuration.device
        )!,
        scannerURL: inspection.attributes?.configuration.scannerURL,
        poolSettings: inspection.attributes?.configuration.poolSettings,
        mode: inspection.attributes?.configuration.mode,
        layout: inspection.attributes?.configuration.layout,
        expectedHashratePercentageCutoff:
          inspection.attributes?.configuration
            .expectedHashratePercentageCutoff || 95,
        async updateSettings({ scannerURL }) {
          await updateInspection({
            variables: {
              id: inspection.id!,
              data: {
                configuration: Object.assign(
                  {},
                  inspection.attributes?.configuration,
                  {
                    scannerURL,
                  }
                ),
              },
            },
          });
        },
      }}
    >
      <>{props.children}</>
    </InspectionSettingsContext.Provider>
  );
};
