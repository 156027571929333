import { useState } from "react";
import { OrderItemDataFragment, PsuInspectionReportInput } from "data";
import { PSUDiagnosticsForm } from "features/diagnostics/components/psus/PSUDiagnosticsForm";
import { PSUDiagnosticsSummaryForm } from "features/diagnostics/components/psus/PSUDiagnosticsSummaryForm";
import { useCreatePSUInspectionReport } from "features/diagnostics/data/hooks/diagnostics";

interface PSUDiagnosticsProps {
  orderItem: OrderItemDataFragment;
  onDone: () => void;
}

type DiagnosticsStep = "details" | "summary";

export const PSUDiagnostics = (props: PSUDiagnosticsProps) => {
  const { onDone, orderItem } = props;
  const { createReport } = useCreatePSUInspectionReport();
  const [inspectionReport, setInspectionReport] =
    useState<PsuInspectionReportInput | null>(null);
  const [diagnosticsStep, setDiagnosticsStep] =
    useState<DiagnosticsStep>("details");

  if (diagnosticsStep === "details") {
    return (
      <PSUDiagnosticsForm
        report={inspectionReport}
        onSubmit={(report) => {
          setInspectionReport(Object.assign({}, inspectionReport, report));
          setDiagnosticsStep("summary");
        }}
        orderItem={props.orderItem}
      />
    );
  }

  return (
    inspectionReport && (
      <PSUDiagnosticsSummaryForm
        orderItem={orderItem}
        report={inspectionReport}
        onSubmit={async (report) => {
          const completeReport = Object.assign(inspectionReport, report);
          await createReport({
            orderItem,
            report: completeReport,
            isOk: completeReport.isOk!,
          });
          onDone();
        }}
        onPrevious={() => {
          setDiagnosticsStep("details");
        }}
      />
    )
  );
};
