import {
  OrderItemDataFragment,
  Enum_Device_Type,
  useCreateControlBoardQaReportMutation,
  useCreateHashboardQaReportMutation,
  useCreateMinerQaReportMutation,
  useCreatePsuQaReportMutation,
  useUpdateOrderItemMutation,
  Enum_Orderitem_Status,
} from "data";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";
import { QAReportInput } from "features/qa/data";

interface UseCreateQAReportHookData {
  createReport({
    orderItem,
    orderItemStatus,
    report,
  }: {
    orderItem?: OrderItemDataFragment | null;
    orderItemStatus: Enum_Orderitem_Status;
    report: QAReportInput;
  }): Promise<void>;
}

export const useCreateQAReport = (): UseCreateQAReportHookData => {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { createAction } = useCreateAction();
  const [createControlBoardQaReport] = useCreateControlBoardQaReportMutation();
  const [createHashboardQaReport] = useCreateHashboardQaReportMutation();
  const [createMinerQaReport] = useCreateMinerQaReportMutation();
  const [createPsuQaReport] = useCreatePsuQaReportMutation();
  const [updateOrderItem] = useUpdateOrderItemMutation();

  return {
    async createReport({ orderItem, orderItemStatus, report }) {
      let controlBoardQaReport, hashboardQaReport, minerQaReport, psuQaReport;

      if (!orderItem?.id) {
        return;
      }

      const reportWithOrderItem = Object.assign({}, report, {
        order_item: orderItem?.id,
      });

      try {
        switch (orderItem?.attributes?.device?.data?.attributes?.type) {
          case Enum_Device_Type.ControlBoard:
            const { data: d1 } = await createControlBoardQaReport({
              variables: {
                data: reportWithOrderItem,
              },
            });
            controlBoardQaReport = d1?.createControlBoardQaReport?.data?.id;
            break;
          case Enum_Device_Type.HashBoard:
            const { data: d2 } = await createHashboardQaReport({
              variables: {
                data: reportWithOrderItem,
              },
            });
            hashboardQaReport = d2?.createHashboardQaReport?.data?.id;
            break;
          case Enum_Device_Type.Miner:
            const { data: d3 } = await createMinerQaReport({
              variables: {
                data: reportWithOrderItem,
              },
            });
            minerQaReport = d3?.createMinerQaReport?.data?.id;
            break;
          case Enum_Device_Type.Psu:
            const { data: d4 } = await createPsuQaReport({
              variables: {
                data: reportWithOrderItem,
              },
            });
            psuQaReport = d4?.createPsuQaReport?.data?.id;
            break;
        }

        await updateOrderItem({
          variables: {
            id: orderItem?.id,
            data: {
              status: orderItemStatus,
            },
          },
        });

        await createAction({
          type: Enum_Action_Type.QaOrderItem,
          scope: Enum_Action_Scope.OrderItem,
          data: {
            ...expandActionDataPiece(orderItem),
            control_board_qa_report: controlBoardQaReport,
            hashboard_qa_report: hashboardQaReport,
            miner_qa_report: minerQaReport,
            psu_qa_report: psuQaReport,
            metadata: {
              status: orderItemStatus,
            },
          },
          context: {
            order: orderItem.attributes?.order?.data,
            device: orderItem.attributes?.device?.data,
            customerDevice: orderItem.attributes?.customer_device?.data,
          },
        });

        showSuccessMessage("QA report submitted");
      } catch (err) {
        showErrorMessage("Failed to create QA report");
      }
    },
  };
};
