import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { OrderItemDataFragment } from "data";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { ShowForHashboard } from "features/devices/components/ShowForHashboard";
import { ShowForPSU } from "features/devices/components/ShowForPSU";
import { OrderItemActionPanelHeader } from "features/order-items/components/OrderItemActionPanelHeader";
import { DiagnosticsWizardProvider } from "features/diagnostics/context/DiagnosticsWizard";
import { AdvancedHashboardDiagnosticsWizardProvider } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { PSUDiagnostics } from "features/diagnostics/components/psus/PSUDiagnostics";
import { DiagnosticsWizard } from "features/diagnostics/components/DiagnosticsWizard";
import { DiagnosticsReportSummary } from "features/diagnostics/components/DiagnosticsReportSummary";
import {
  ExistingReport,
  getInspectionStepsForOrderItem,
  getInitialDiagnosticsReport,
  canGoToNextStepWithCurrentReport,
} from "features/diagnostics/data";

interface DiagnosticsContextMenuProviderProps {
  children: ReactNode;
}

interface DiagnosticsContextMenuData {
  openDiagnosticsMenu: (
    orderItem: OrderItemDataFragment,
    existingReport?: ExistingReport
  ) => void;
  orderItem: OrderItemDataFragment | null;
}

const DiagnosticsContextMenuContext = createContext<DiagnosticsContextMenuData>(
  {} as DiagnosticsContextMenuData
);

export const useDiagnosticsContextMenu = () => {
  return useContext(DiagnosticsContextMenuContext);
};

export const DiagnosticsContextMenuProvider = (
  props: DiagnosticsContextMenuProviderProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderItem, setOrderItem] = useState<OrderItemDataFragment | null>(
    null
  );
  const [existingReport, setExistingReport] = useState<
    ExistingReport | undefined
  >();

  const [showReport, setShowReport] = useState<boolean>(false);

  const closeDrawer = () => {
    onClose();
    setOrderItem(null);
  };

  return (
    <DiagnosticsContextMenuContext.Provider
      value={{
        orderItem,
        openDiagnosticsMenu: useCallback((oi, existingReport) => {
          setOrderItem(oi);
          setExistingReport(existingReport);
          setShowReport(false);
          onOpen();
        }, []),
      }}
    >
      {props.children}
      <Drawer
        isOpen={isOpen && orderItem !== null}
        placement="right"
        size="xl"
        onClose={() => {
          closeDrawer();
        }}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important">
          <DrawerCloseButton />
          <DrawerHeader>
            <OrderItemActionPanelHeader orderItem={orderItem} />
          </DrawerHeader>
          <DrawerBody>
            {orderItem ? (
              <>
                <ShowForPSU device={orderItem.attributes?.device?.data}>
                  <PSUDiagnostics
                    orderItem={orderItem}
                    onDone={() => {
                      closeDrawer();
                    }}
                  />
                </ShowForPSU>
                <ShowForHashboard device={orderItem.attributes?.device?.data}>
                  <AdvancedHashboardDiagnosticsWizardProvider
                    orderItem={orderItem}
                    onComplete={() => {
                      closeDrawer();
                    }}
                  />
                </ShowForHashboard>
                <ShowForMiner device={orderItem.attributes?.device?.data}>
                  <DiagnosticsWizardProvider
                    steps={getInspectionStepsForOrderItem(orderItem)}
                    initialWizardData={getInitialDiagnosticsReport(
                      orderItem,
                      existingReport
                    )}
                    validateCurrentWizardData={(currentStep, data) => {
                      return canGoToNextStepWithCurrentReport(
                        currentStep,
                        data,
                        orderItem
                      );
                    }}
                  >
                    {!showReport ? (
                      <DiagnosticsWizard
                        orderItem={orderItem}
                        onExit={() => {
                          setShowReport(true);
                        }}
                      />
                    ) : (
                      <DiagnosticsReportSummary
                        orderItem={orderItem}
                        onAfterSubmitInspection={() => {
                          closeDrawer();
                        }}
                        onPrevious={() => {
                          setShowReport(false);
                        }}
                      />
                    )}
                  </DiagnosticsWizardProvider>
                </ShowForMiner>
              </>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </DiagnosticsContextMenuContext.Provider>
  );
};
