import { Text } from "@chakra-ui/react";
import { UploadFileDataFragment } from "data";

interface FileSizeLabelProps {
  file: UploadFileDataFragment;
}

const buildLabel = (sizeInKb: number): string => {
  if (sizeInKb > 1000) {
    return `${Math.round(sizeInKb / 1000)} MB`;
  }

  return `${Math.round(sizeInKb)} KB`;
};

export const FileSizeLabel = (props: FileSizeLabelProps) => {
  const { file } = props;
  return !file.attributes?.size ? null : (
    <Text color="muted">{buildLabel(file.attributes?.size)}</Text>
  );
};
