import { HStack, Text, TextProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { OrderItemDataFragment } from "data";
import { ShowForAllTechs } from "features/accounts";
import { useDevicesData } from "features/devices/data/hooks/devices";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";

interface OrderItemParentLinkProps extends TextProps {
  orderItem?: OrderItemDataFragment | null;
  prefix?: string;
}

export const OrderItemParentLink = (props: OrderItemParentLinkProps) => {
  const { orderItem, prefix, ...rest } = props;
  const parent = orderItem?.attributes?.parent?.data;
  const { getParentDevice } = useDevicesData();

  if (!parent) {
    return null;
  }

  const parentDevice = getParentDevice(orderItem.attributes?.device?.data);
  const content = (
    <HStack>
      {prefix ? <Text {...rest}>{prefix}</Text> : null}
      <HStack>
        <DeviceCompleteNameLabel
          fontWeight="bold"
          {...rest}
          device={parentDevice}
        />
      </HStack>
    </HStack>
  );

  return (
    <>
      <ShowForAllTechs>
        <Link to={`/orders/items/${parent.id}`}>{content}</Link>
      </ShowForAllTechs>
      <ShowForAllTechs inverse>{content}</ShowForAllTechs>
    </>
  );
};
