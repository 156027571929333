import { Badge, TextProps } from "@chakra-ui/react";
import { CustomerDeviceDataFragment } from "data";

interface CustomerDeviceShortIdLabelProps extends TextProps {
  customerDevice?: CustomerDeviceDataFragment | null;
}

export const CustomerDeviceShortIdLabel = (
  props: CustomerDeviceShortIdLabelProps
) => {
  const { customerDevice, ...rest } = props;
  return customerDevice?.attributes?.shortId ? (
    <Badge variant="outline" colorScheme="gray" textTransform="none" {...rest}>
      {customerDevice.attributes?.shortId}
    </Badge>
  ) : null;
};
