import { LayoutShell } from "features/accounts/components/LayoutShell";
import { ForgotPasswordFormContainer as ForgotPasswordForm } from "features/accounts/containers/ForgotPasswordForm";
import { RedirectIfLoggedIn } from "features/accounts/containers/RedirectIfLoggedIn";

export const ForgotPasswordPage = () => (
  <RedirectIfLoggedIn>
    <LayoutShell
      title="Forgot your password?"
      subtitle="Don't have an account? Please contact ACS team"
    >
      <ForgotPasswordForm />
    </LayoutShell>
  </RedirectIfLoggedIn>
);
