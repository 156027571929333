import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Button,
} from "@chakra-ui/react";
import { OrderDataFragment } from "data";
import { useUpdateOrder } from "features/orders/data/hooks/update-order";

interface OrderSettingsProps {
  order: OrderDataFragment;
}

export const OrderSettings = ({ order }: OrderSettingsProps) => {
  const { updateOrder } = useUpdateOrder();
  return (
    <TableContainer>
      <Table variant="simple">
        <Tbody>
          <Tr>
            <Td>
              <Text fontWeight="bold">Archive Status:</Text>
            </Td>
            <Td textAlign="right">
              {order.attributes?.isArchived ? (
                <Button
                  onClick={async () => {
                    await updateOrder({
                      orderId: order.id!,
                      data: {
                        isArchived: false,
                      },
                    });
                  }}
                  size="sm"
                  variant="outline"
                  color="green"
                >
                  Remove from Archive
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    await updateOrder({
                      orderId: order.id!,
                      data: {
                        isArchived: true,
                      },
                    });
                  }}
                  size="sm"
                  variant="outline"
                  color="red"
                >
                  Move to Archive
                </Button>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
