import {
  Alert,
  AlertIcon,
  FormControl,
  Stack,
  Text,
  Icon,
} from "@chakra-ui/react";
import {
  CheckboxGridCardGroup,
  CheckboxCard,
} from "components/forms/CheckboxCardGroup";
import { IconType } from "react-icons";
import { FanSpec } from "features/diagnostics/data";

interface FansDiagnosticsProps {
  icon: IconType;
  fans: FanSpec[];
  onChange: (fans: FanSpec[]) => void;
  prefix: string;
}

export const FansDiagnostics = (props: FansDiagnosticsProps) => {
  const { icon, fans, onChange, prefix } = props;
  const totalFans = fans.length;
  const workingFans = fans.filter((f) => f.isOn).length;

  return (
    <>
      <Alert status={totalFans === workingFans ? "success" : "warning"}>
        <AlertIcon />
        {`${workingFans}/${totalFans} ${
          workingFans === 1 ? "is" : "are"
        } working`}
      </Alert>
      <FormControl mt="8">
        <CheckboxGridCardGroup
          onChange={(value) => {
            const ids = value.map((v) => parseInt(v));
            onChange(
              fans.map((fan) => {
                return Object.assign({}, fan, {
                  isOn: ids.indexOf(fan.id) !== -1,
                });
              })
            );
          }}
          value={fans.filter((fan) => fan.isOn).map((fan) => `${fan.id}`)}
        >
          {fans.map((fan) => {
            return (
              <CheckboxCard key={fan.id} value={`${fan.id}`}>
                <Stack alignItems={"center"}>
                  <Icon as={icon} w={6} h={6} />
                  <Text>{`${prefix} ${fan.id + 1}`}</Text>
                </Stack>
              </CheckboxCard>
            );
          })}
        </CheckboxGridCardGroup>
      </FormControl>
    </>
  );
};
