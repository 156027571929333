import { ReactNode, useContext } from "react";
import {
  InspectionsQueryVariables,
  InspectionsQuery,
  InspectionsDocument,
} from "data";
import { generateContextAndProvider, DataSourceSpec } from "context/DataSource";

const { Context, Provider } = generateContextAndProvider<
  InspectionsQuery,
  InspectionsQueryVariables
>({
  parseFilters(searchParams) {
    const page = searchParams.find((sp) => sp[0] === "page");
    return {
      page: page ? parseInt(page[1] || "1") : 1,
    };
  },
  serializeFilters(filters) {
    const r: Array<[string, string]> = [];
    r.push(["page", filters.page ? `${filters.page}` : "1"]);
    return r;
  },
  QueryDocument: InspectionsDocument,
});

interface InspectionsProviderProps {
  children: ReactNode;
}

export const InspectionsProvider = (props: InspectionsProviderProps) => {
  const { children } = props;
  return <Provider>{children}</Provider>;
};

export const useInspections = (): DataSourceSpec<
  InspectionsQuery,
  InspectionsQueryVariables
> => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useInspections used outside of InspectionsProvider");
  }

  return context;
};
