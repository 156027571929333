import { Text, HStack, IconButton, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "components/utils/Table";
import { DeviceTypeLabel, DeviceCompleteNameLabel } from "features/devices/components";
import { OrderItemStatusLabel } from "features/order-items/components/OrderItemStatusLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import {
  CustomerDeviceDataFragment,
  DeviceDataFragment,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
} from "data";
import { OrderItemReplacementIndicator } from "features/order-items/components/OrderItemReplacementIndicator";
import { MinerIpAddressForm } from "features/order-items/components/MinerIpAddressForm";
import { ReceiveOrderItemAction } from "features/order-items/components/ReceiveOrderItemAction";
import { ReplaceSubcomponentAction } from "features/order-items/components/ReplaceSubcomponentAction";
import { ActionButton } from "components/utils/ActionButton";
import { DeleteIcon } from "@chakra-ui/icons";

// Define interface for non-miner order item summary props
interface NonMinerOrderItemSummaryProps {
  orderItem: OrderItemDataFragment;
}

// Define interface for miner order item summary props, extending the non-miner props
interface MinerOrderItemSummaryProps extends NonMinerOrderItemSummaryProps {
  orderItemSubcomponents: OrderItemSubcomponentDataFragment[];
  customerDeviceForSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => CustomerDeviceDataFragment | null;
  orderItemForSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => OrderItemDataFragment | null;
  onDeleteSubcomponent: (orderItemSubcomponent: OrderItemSubcomponentDataFragment) => Promise<void>;
}

// Component for non-miner order item summary (currently returns null)
export const NonMinerOrderItemSummary = (props: NonMinerOrderItemSummaryProps) => {
  return null;
};

// Component for miner order item summary
export const MinerOrderItemSummary = (props: MinerOrderItemSummaryProps) => {
  // Destructure props
  const {
    orderItemSubcomponents,
    customerDeviceForSubcomponent,
    orderItemForSubcomponent,
    onDeleteSubcomponent,
    orderItem,
  } = props;

  return (
    <>
      {/* Render MinerIpAddressForm component */}
      <MinerIpAddressForm orderItem={orderItem} />

      {/* Create a table to display order item subcomponents */}
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Device</Th>
              <Th>Status</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {/* Map through orderItemSubcomponents and render each as a table row */}
            {orderItemSubcomponents.map((ois) => {
              // Get the order item and customer device for the current subcomponent
              const orderItem = orderItemForSubcomponent(ois);
              const customerDevice = customerDeviceForSubcomponent(ois);
              const isDeviceCheckedIn = customerDevice !== null;

              return (
                <Tr
                  data-cy='order-item-subcomponent'
                  data-order-item-subcomponent-id={ois.id}
                  data-order-item-subcomponent-device-type={
                    ois.attributes?.device?.data?.attributes?.type
                  }
                  {...(orderItem
                    ? {
                        "data-order-item-id": orderItem.id,
                        "data-order-item-status": orderItem.attributes?.status,
                      }
                    : {})}
                  key={ois.id}
                >
                  {/* Display device type */}
                  <Td>
                    <DeviceTypeLabel
                      showTypeIcon
                      deviceType={ois.attributes?.device?.data?.attributes?.type}
                    />
                  </Td>

                  {/* Display device details */}
                  <Td>
                    {isDeviceCheckedIn ? (
                      // If device is checked in, display as a link
                      <Link to={`/orders/items/${ois.attributes?.order_item?.data?.id}`}>
                        <HStack fontWeight='bold'>
                          <OrderItemReplacementIndicator orderItem={orderItem} />
                          <DeviceCompleteNameLabel
                            showMakeIcon
                            device={ois.attributes?.device?.data as DeviceDataFragment}
                          />
                          {customerDevice ? (
                            <CustomerDeviceShortIdLabel customerDevice={customerDevice} />
                          ) : null}
                        </HStack>
                      </Link>
                    ) : (
                      // If device is not checked in, display without link
                      <DeviceCompleteNameLabel
                        showMakeIcon
                        device={ois.attributes?.device?.data as DeviceDataFragment}
                      />
                    )}
                    {/* Display serial number if available */}
                    {customerDevice?.attributes?.serialNumber ? (
                      <Text
                        pl={6}
                        color='gray.500'
                      >{`serial #: ${customerDevice?.attributes?.serialNumber}`}</Text>
                    ) : null}
                  </Td>

                  {/* Display order item status */}
                  <Td>
                    {orderItem ? <OrderItemStatusLabel orderItem={orderItem} /> : <Text>N/A</Text>}
                  </Td>

                  {/* Display action buttons */}
                  <Td>
                    <HStack justifyContent='flex-end' spacing={2}>
                      <ReceiveOrderItemAction variant='small-button' orderItemSubcomponent={ois} />
                      <ReplaceSubcomponentAction
                        variant='small-button'
                        orderItemSubcomponent={ois}
                        orderItem={orderItem}
                      />
                      <Box width='32px' height='32px'>
                        {!orderItem && (
                          <ActionButton
                            size='xs'
                            variant='ghost'
                            colorScheme='gray'
                            confirmationMessage='Are you sure you want to delete this component?'
                            action={async () => {
                              await onDeleteSubcomponent(ois);
                            }}
                          >
                            <IconButton
                              aria-label='Delete component'
                              icon={<DeleteIcon />}
                              size='xs'
                              variant='ghost'
                              colorScheme='gray'
                            />
                          </ActionButton>
                        )}
                      </Box>
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
