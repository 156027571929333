import { Stack, Spacer, Text } from "@chakra-ui/react";
import { useField, FieldAttributes } from "formik";
import { Label } from "./Label";
import {
  RadioCard,
  RadioCardGroupGrid as BaseRadioCardGroupGrid,
} from "components/forms/RadioCardGroup";
import { ErrorMessage } from "./ErrorMessage";

export const RadioCardGroupGrid = ({
  label,
  type,
  supportedValues,
  prettyValue,
  ...props
}: FieldAttributes<any> & {
  supportedValues: string[];
  prettyValue?: (v: string) => string;
}) => {
  const [field, meta, helpers] = useField(props);
  return (
    <Stack>
      <Label>{label}</Label>
      <Spacer />
      <BaseRadioCardGroupGrid
        onChange={(v: string) => {
          helpers.setValue(v);
        }}
        value={field.value}
      >
        {supportedValues.map((v: string) => (
          <RadioCard key={type} value={v}>
            <Text
              data-cy={`select-${field.name}-${v}`}
              color="emphasized"
              fontWeight="medium"
              fontSize="sm"
            >
              {prettyValue ? prettyValue(v) : v}
            </Text>
          </RadioCard>
        ))}
      </BaseRadioCardGroupGrid>
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </Stack>
  );
};
