import {
  useCallback,
  useRef,
  createContext,
  useContext,
  ReactNode,
  MutableRefObject,
} from "react";
import { useReactToPrint } from "react-to-print";

interface LabelPrinterContextData {
  printableRef: MutableRefObject<null>;
  print: (event?: any, content?: any) => void;
}

const LabelPrinterContext = createContext<LabelPrinterContextData>(
  {} as LabelPrinterContextData
);

interface LabelPrinterProviderProps {
  children: ReactNode;
}

export const LabelPrinterProvider = (props: LabelPrinterProviderProps) => {
  const printableRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return printableRef.current;
  }, [printableRef.current]);
  const print = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "",
    removeAfterPrint: true,
  });

  return (
    <LabelPrinterContext.Provider value={{ printableRef, print }}>
      {props.children}
    </LabelPrinterContext.Provider>
  );
};

export const useLabelPrinter = () => useContext(LabelPrinterContext);
