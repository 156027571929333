import { ReactNode, useContext } from "react";
import {
  CustomersQueryVariables,
  CustomersQuery,
  CustomersDocument,
} from "data";
import { generateContextAndProvider, DataSourceSpec } from "context/DataSource";

const { Context, Provider } = generateContextAndProvider<
  CustomersQuery,
  CustomersQueryVariables
>({
  parseFilters(searchParams) {
    const page = searchParams.find((sp) => sp[0] === "page");
    return {
      page: page ? parseInt(page[1] || "1") : 1,
    };
  },
  serializeFilters(filters) {
    const r: Array<[string, string]> = [];
    r.push(["page", filters.page ? `${filters.page}` : "1"]);
    return r;
  },
  QueryDocument: CustomersDocument,
});

interface CustomersProviderProps {
  children: ReactNode;
}

export const CustomersProvider = (props: CustomersProviderProps) => {
  const { children } = props;
  return <Provider>{children}</Provider>;
};

export const useCustomers = (): DataSourceSpec<
  CustomersQuery,
  CustomersQueryVariables
> => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useCustomers used outside of CustomersProvider");
  }

  return context;
};
