import {
  useUploadMutation,
  useMultipleUploadMutation,
  UploadFileDataFragment,
} from "data";

interface UseUploadHookData {
  uploadFile: ({
    file,
  }: {
    file: File;
  }) => Promise<UploadFileDataFragment | null>;
  uploadFiles: ({
    files,
  }: {
    files: File[];
  }) => Promise<UploadFileDataFragment[]>;
  uploadTextFromURL: ({
    url,
    fileName,
  }: {
    url: string;
    fileName: string;
  }) => Promise<UploadFileDataFragment | null>;
}

export const useUpload = (): UseUploadHookData => {
  const [doUpload] = useUploadMutation();
  const [doMultipleUpload] = useMultipleUploadMutation();
  return {
    async uploadFile({ file }) {
      const { data } = await doUpload({
        variables: {
          file,
        },
      });
      return data?.upload.data || null;
    },
    async uploadFiles({ files }) {
      const { data } = await doMultipleUpload({
        variables: {
          files,
        },
      });
      return (data?.multipleUpload || [])
        .map((d) => d?.data)
        .filter((d) => d) as UploadFileDataFragment[];
    },
    async uploadTextFromURL({ url, fileName }) {
      const r = await fetch(url);
      const result = await r.text();

      const { data } = await doUpload({
        variables: {
          file: new File([new Blob([result])], fileName),
        },
      });
      return data?.upload.data || null;
    },
  };
};
