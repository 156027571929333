import * as Yup from "yup";
import { Formik } from "formik";
import { TextArea, TextInput, YesNoButtons } from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";
import { normalizeTemperatureData } from "./lib";

const TemperatureSensorsSchema = Yup.object().shape({
  temperatureAllTempSensorsDetected: Yup.boolean().required(),
  temperatureBadTempSensors: Yup.string().when(
    "temperatureAllTempSensorsDetected",
    {
      is: false,
      then: () => Yup.string().required(),
    }
  ),
  temperatureGotBrokenTraces: Yup.boolean().when(
    "temperatureAllTempSensorsDetected",
    {
      is: false,
      then: () => Yup.boolean().required(),
    }
  ),
  temperatureGotCorrosion: Yup.boolean().when(
    "temperatureAllTempSensorsDetected",
    {
      is: false,
      then: () => Yup.boolean().required(),
    }
  ),
  temperatureNotes: Yup.string(),
});

export const TemperatureSensorsForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        temperatureAllTempSensorsDetected:
          report.temperatureAllTempSensorsDetected,
        temperatureBadTempSensors: report.temperatureBadTempSensors,
        temperatureGotBrokenTraces: report.temperatureGotBrokenTraces,
        temperatureGotCorrosion: report.temperatureGotCorrosion,
        temperatureNotes: report.temperatureNotes,
      }}
      validationSchema={TemperatureSensorsSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.temperatureAllTempSensorsDetected !== "undefined" &&
            isValid
          }
          canGoToPrevStep
          title="Temperature Sensors"
          values={normalizeTemperatureData(values)}
        >
          <YesNoButtons
            label="Are all temp sensors detected?"
            name="temperatureAllTempSensorsDetected"
          />
          {values.temperatureAllTempSensorsDetected === false ? (
            <>
              <TextInput
                name="temperatureBadTempSensors"
                label="Which temp sensors are bad?"
                type="text"
              />
              <YesNoButtons
                label="Broken Traces"
                name="temperatureGotBrokenTraces"
              />
              <YesNoButtons label="Corrosion" name="temperatureGotCorrosion" />
            </>
          ) : null}

          <TextArea label="Notes (Optional)" name="temperatureNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
