import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  IconButton,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SearchIcon } from "components/Icons";
import { ShowForAllTechs } from "features/accounts";
import { AutocompleteSearchModal } from "features/search/components/AutocompleteSearchModal";

export const SearchBox = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <ShowForAllTechs>
      <>
        {isDesktop ? (
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="muted" boxSize="5" />
            </InputLeftElement>
            <Input
              onFocus={() => {
                onOpen();
              }}
              placeholder="Search"
            />
          </InputGroup>
        ) : (
          <IconButton
            aria-label="Show search modal"
            position="relative"
            variant="unstyled"
            icon={<SearchIcon />}
            onClick={() => {
              onOpen();
            }}
          />
        )}

        <AutocompleteSearchModal isOpen={isOpen} onClose={onClose} />
      </>
    </ShowForAllTechs>
  );
};
