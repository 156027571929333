import { Box, HStack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { UploadFileDataFragment } from "data";
import { MimeImageIcon, MimeGenericFileIcon } from "components/Icons";

interface ListUploadsProps {
  uploads: UploadFileDataFragment[];
}

export const ListUploads = (props: ListUploadsProps) => {
  const { uploads } = props;
  return uploads.length !== 0 ? (
    <Wrap>
      {uploads.map((upload) => (
        <WrapItem key={upload.id}>
          <a href={upload.attributes?.url} target="_blank" rel="noreferrer">
            <Box>
              <HStack>
                {(upload.attributes?.mime || "").match(/image/gi) ? (
                  <MimeImageIcon />
                ) : (
                  <MimeGenericFileIcon />
                )}
                <Text>{upload.attributes?.name}</Text>
              </HStack>
            </Box>
          </a>
        </WrapItem>
      ))}
    </Wrap>
  ) : null;
};
