import { Box, HStack, Icon, Square, Stack, Text, Link } from "@chakra-ui/react";
import { FileIcon } from "components/Icons";
import { FileSizeLabel } from "features/uploads/components/FileSizeLabel";
import { UploadFileDataFragment } from "data";

interface ReportAttachmentsProps {
  attachments: UploadFileDataFragment[];
}

export const ReportAttachments = (props: ReportAttachmentsProps) => {
  const { attachments } = props;
  return attachments.length === 0 ? null : (
    <Box bg="bg-surface" py="4">
      <Text fontWeight="bold" my="4">
        Attachments
      </Text>
      <Stack spacing="4">
        {attachments.map((attachment) => (
          <Box
            key={attachment.id}
            borderWidth={{ base: "0", md: "1px" }}
            p={{ base: "0", md: "4" }}
            borderRadius="lg"
          >
            <Stack
              justify="space-between"
              direction={{ base: "column", md: "row" }}
              spacing="5"
            >
              <HStack spacing="3">
                <Square size="10" bg="bg-subtle" borderRadius="lg">
                  <Icon as={FileIcon} boxSize="5" />
                </Square>
                <Box fontSize="sm">
                  <Link href={attachment.attributes?.url} target="_blank">
                    <Text color="empahsized" fontWeight="medium">
                      {attachment.attributes?.name}
                    </Text>
                  </Link>
                  <FileSizeLabel file={attachment} />
                </Box>
              </HStack>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
