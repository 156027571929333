import {
  NoteDataFragment,
  NoteInput,
  OrderItemDataFragment,
  OrderDataFragment,
  useCreateNoteMutation,
  useNotesQuery,
  NoteFiltersInput,
  NotesDocument,
} from "data";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Type,
  Enum_Action_Scope,
} from "features/actions";
import { useAuthentication } from "features/accounts/context/Authentication";

interface UseCreateNoteHookData {
  createNote: ({
    note,
    attachedTo,
  }: {
    note: NoteInput;
    attachedTo: OrderItemDataFragment | OrderDataFragment;
  }) => Promise<NoteDataFragment | null>;
}

export const useCreateNote = (): UseCreateNoteHookData => {
  const [createNote] = useCreateNoteMutation();
  const { userProfile } = useAuthentication();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { createAction } = useCreateAction();

  return {
    async createNote({ note, attachedTo }) {
      try {
        const refetchQueries = [];

        if (attachedTo.__typename === "OrderEntity") {
          refetchQueries.push({
            query: NotesDocument,
            variables: {
              filters: {
                order: {
                  id: {
                    eq: attachedTo.id,
                  },
                },
              },
            },
          });
        } else if (attachedTo.__typename === "OrderItemEntity") {
          refetchQueries.push({
            query: NotesDocument,
            variables: {
              filters: {
                order_item: {
                  id: {
                    eq: attachedTo.id,
                  },
                },
              },
            },
          });
        }

        const { data } = await createNote({
          variables: {
            note: Object.assign(
              {
                author: userProfile?.id,
              },
              note,
              attachedTo.__typename === "OrderItemEntity"
                ? {
                    order_item: attachedTo.id,
                  }
                : {},
              attachedTo.__typename === "OrderEntity"
                ? {
                    order: attachedTo.id,
                  }
                : {}
            ),
          },
          refetchQueries,
        });

        const n = data?.createNote?.data || null;

        if (attachedTo.__typename === "OrderItemEntity") {
          await createAction({
            type: Enum_Action_Type.AddNote,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(attachedTo),
              note: n?.id,
            },
          });
        } else if (attachedTo.__typename === "OrderEntity") {
          await createAction({
            type: Enum_Action_Type.AddNote,
            scope: Enum_Action_Scope.Order,
            data: {
              ...expandActionDataPiece(attachedTo),
              note: n?.id,
            },
          });
        }

        showSuccessMessage("Note added!");

        return n || null;
      } catch (error) {
        showErrorMessage("Failed to add note");
      }

      return null;
    },
  };
};

interface UseNotesForOrderItemHookData {
  loading: boolean;
  notes: NoteDataFragment[];
}

export const useNotes = (
  filters: NoteFiltersInput
): UseNotesForOrderItemHookData => {
  const { loading, data } = useNotesQuery({
    variables: {
      filters,
    },
  });
  return {
    loading,
    notes: data?.notes?.data || [],
  };
};
