import { useEffect, useState } from "react";
import { DeviceDataFragment } from "data";
import { NumberPicker } from "components/forms/NumberPicker";
import { DeviceSelectorContainer as DeviceSelector } from "features/devices/containers/DeviceSelector";

export type BulkDeviceSelection = {
  device: DeviceDataFragment | null;
  quantity: number;
};

interface BulkDeviceSelectorContainerProps {
  onSelectionChange: (deviceSelection: BulkDeviceSelection) => void;
}

export const BulkDeviceSelectorContainer = (
  props: BulkDeviceSelectorContainerProps
) => {
  const { onSelectionChange } = props;
  const [quantity, setQuantity] = useState<number>(0);
  const [device, setDevice] = useState<DeviceDataFragment | null>(null);

  useEffect(() => {
    onSelectionChange({ device, quantity });
  }, [device, quantity]);

  return (
    <>
      <DeviceSelector
        onDeviceChange={(d) => {
          setDevice(d);
          setQuantity(0);
        }}
      />
      {device ? (
        <NumberPicker
          label="Quantity"
          value={quantity}
          onChange={(value) => {
            setQuantity(value);
          }}
          inputProps={{ "data-cy": "bulk-device-selector-quantity" }}
        />
      ) : null}
    </>
  );
};
