import {
  useFindCustomerDevicesQuery,
  useFindCustomersQuery,
  useFindOrdersQuery,
  CustomerDataFragment,
  CustomerDeviceDataFragment,
  OrderDataFragment,
} from "data";

interface SearchResults {
  customerDevices: CustomerDeviceDataFragment[];
  customers: CustomerDataFragment[];
  orders: OrderDataFragment[];
  hasResults: boolean;
}

interface UseSearchHookData {
  results: SearchResults;
  loading: boolean;
}

export const useSearch = (query: string): UseSearchHookData => {
  const { loading: loadingCustomerDevices, data: customerDevicesData } =
    useFindCustomerDevicesQuery({
      variables: {
        query,
      },
      fetchPolicy: "network-only",
    });
  const { loading: loadingCustomers, data: customersData } =
    useFindCustomersQuery({
      variables: {
        query,
      },
      fetchPolicy: "network-only",
    });
  const { loading: loadingOrders, data: ordersData } = useFindOrdersQuery({
    variables: {
      query,
    },
    fetchPolicy: "network-only",
  });
  const done = !(loadingCustomerDevices || loadingCustomers || loadingOrders);
  const customerDevices = customerDevicesData?.customerDevices?.data || [];
  const customers = customersData?.customers?.data || [];
  const orders = ordersData?.orders?.data || [];
  const hasResults =
    customerDevices.length + customers.length + orders.length > 0;

  return {
    loading: !done,
    results: {
      customerDevices,
      customers,
      orders,
      hasResults,
    },
  };
};
