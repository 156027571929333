import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CustomerDataFragment } from "data";

interface LinkToCustomerProps {
  customer?: CustomerDataFragment | null;
  onClick?: () => void;
  children: ReactNode;
}

export const LinkToCustomer = (props: LinkToCustomerProps) => {
  const { customer, children, onClick } = props;
  return customer ? (
    <Link
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      to={`/customers/${customer.id}`}
    >
      {children}
    </Link>
  ) : null;
};
