import {
  Stack,
  SimpleGrid,
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  HStack,
  Button,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { InspectionRackDataFragment } from "data";
import { Loader } from "components";
import { PrintIcon, SearchIcon, DoneIcon, WarningIcon } from "components/Icons";
import { useInspectionRack } from "features/inspections/context/InspectionRack";
import { useInspectionSettings } from "features/inspections/context/InspectionSettings";
import { CheckInDevices } from "features/inspections/components/CheckInDevices";
import { InspectedDevice } from "features/inspections/components/InspectedDevice";
import { MultiDevicesPrintableLabel } from "features/customer-devices/components/MultiDevicesPrintableLabel";
import { useLabelPrinter } from "context/LabelPrinter";

interface InspectionRackProps {
  rack: InspectionRackDataFragment;
}

export const InspectionRack = (props: InspectionRackProps) => {
  const { rack } = props;
  const { devices, canSubmit, scan, done, reset, updateDeviceStatus, loading } =
    useInspectionRack();
  const { printableRef, print } = useLabelPrinter();
  const { layout } = useInspectionSettings();

  if (loading) {
    return <Loader />;
  }

  return devices.length === 0 ? (
    <CheckInDevices rack={rack} />
  ) : (
    <>
      <Card>
        <CardHeader>
          <HStack>
            <Heading size="sm" flex={1}>
              {rack.attributes?.name} ({rack.attributes?.startIp}) -{" "}
              {layout.rows} rows x {layout.cols} columns
            </Heading>
            <Box>
              <Stack direction="row" spacing={10}>
                <Button
                  data-cy="launch-bulk-scan"
                  onClick={async () => {
                    await scan();
                  }}
                  leftIcon={<SearchIcon />}
                  variant="outline"
                >
                  Inspect
                </Button>
                <Button
                  onClick={() => {
                    print();
                  }}
                  rightIcon={<PrintIcon />}
                  variant="outline"
                >
                  Print
                </Button>
              </Stack>
            </Box>
          </HStack>
        </CardHeader>

        <CardBody>
          <Divider mb={8} />
          <SimpleGrid columns={layout.cols} spacing={10}>
            {devices.map((d) => (
              <InspectedDevice
                onClick={
                  d.device.attributes?.gotResponse === false
                    ? () => {
                        if (d.device) {
                          updateDeviceStatus({ device: d.device });
                        }
                      }
                    : undefined
                }
                device={d}
                key={d.device.id}
              />
            ))}
          </SimpleGrid>
          <Divider my={8} />

          <Flex direction="row-reverse">
            <Button
              data-cy="submit-rack"
              isDisabled={!canSubmit}
              ml={8}
              onClick={() => {
                // eslint-disable-next-line
                if (confirm("Are you sure you are done with this rack?")) {
                  done();
                }
              }}
              leftIcon={<DoneIcon />}
              variant="outline"
              color="green"
            >
              Done
            </Button>
            <Button
              onClick={async () => {
                if (
                  // eslint-disable-next-line
                  confirm(`
                Resetting rack:
                -- remove checked in devices
                -- remove inspected devices
                -- clear rack

                Are you sure? There is no undo
                `)
                ) {
                  await reset();
                }
              }}
              leftIcon={<WarningIcon />}
              variant="outline"
              color="red"
            >
              Reset Rack [DANGER ZONE]
            </Button>
          </Flex>
        </CardBody>
      </Card>
      {devices.length !== 0 ? (
        <MultiDevicesPrintableLabel
          devices={devices.map((d) => {
            const cd = d.device.attributes?.customer_device?.data!;
            const serial = cd?.attributes?.serialNumber;
            return {
              device: cd,
              meta: `${
                serial ? `${serial.substring(serial.length - 4)} - ` : ""
              } ${rack.attributes?.name}/${
                d.device.attributes?.rackPosition! + 1
              }`,
            };
          })}
          ref={printableRef}
        />
      ) : null}
    </>
  );
};
