import { useNavigate } from "react-router-dom";
import { Page } from "components/layout/Page";
import { useOrders, OrdersProvider } from "features/orders/context/Orders";
import { useNotifications } from "context/Notifications";
import { ReceiveOrderContainer as ReceiveOrder } from "features/orders/containers/ReceiveOrder";
import { OrdersTable } from "features/orders/components/OrdersTable";
import { OrdersListProvider } from "features/orders/context/ListOrders";

const ConnectedOrdersPage = () => {
  const navigate = useNavigate();
  const { receiveOrderPayload, setOrderPayload, receiveOrder } = useOrders();
  const { showSuccessMessage } = useNotifications();
  return (
    <Page
      title="Orders"
      subTitle="See and manage all the orders in the system"
      primaryActionButtonText="Receive Order"
      actionPanel={{
        title: "Receive Order",
        children: (
          <ReceiveOrder
            onChangeSelection={(selection) => {
              setOrderPayload(selection);
            }}
          />
        ),
        onAction: async () => {
          const order = await receiveOrder();
          if (order) {
            showSuccessMessage("Order Received");
            navigate(
              `/orders/${order.attributes?.zoho_sales_order?.data?.attributes?.salesOrderNumber}`
            );
          }
        },
        onClose: async () => {
          setOrderPayload({ zohoSalesOrder: null, location: null });
        },
        enablePrimaryAction:
          receiveOrderPayload.location !== null &&
          receiveOrderPayload.zohoSalesOrder !== null,
      }}
    >
      <OrdersTable />
    </Page>
  );
};

export const OrdersPage = () => (
  <OrdersProvider>
    <OrdersListProvider>
      <ConnectedOrdersPage />
    </OrdersListProvider>
  </OrdersProvider>
);
