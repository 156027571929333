import { Text, HStack, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ActionComponent } from "components/ActionComponent";
import { RemoveIcon } from "components/Icons";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "components/utils/Table";
import { DeviceDataFragment, OrderItemDataFragment } from "data";
import {
  DeviceTypeLabel,
  DeviceCompleteNameLabel,
} from "features/devices/components";
import { useOrderPackage } from "features/order-packages/context/OrderPackage";
import { OrderItemCompleteBage } from "features/order-items/components/OrderItemCompleteBadge";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderNameLabel } from "features/orders/components/OrderNameLabel";
import { LinkToOrder } from "features/orders/components/LinkToOrder";
import { OrderItemParentLink } from "features/order-items/components/OrderItemParentLink";

interface PackagedOrderItemsProps {
  orderItems: OrderItemDataFragment[];
}

export const PackagedOrderItems = (props: PackagedOrderItemsProps) => {
  const { orderItems } = props;
  const { removeOrderItemFromPackage } = useOrderPackage();
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th />
            <Th>Type</Th>
            <Th>Device</Th>
            <Th>Order</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {orderItems.map((orderItem, idx) => {
            const customerDevice = orderItem.attributes?.customer_device?.data;
            return (
              <Tr
                data-cy="packaged-order-item"
                data-order-item-id={orderItem.id}
                key={orderItem.id}
              >
                <Td>
                  <Text color="gray.600">{idx + 1}</Text>
                </Td>
                <Td>
                  <HStack>
                    <DeviceTypeLabel
                      showTypeIcon
                      deviceType={
                        orderItem.attributes?.device?.data?.attributes?.type
                      }
                    />
                    <OrderItemCompleteBage orderItem={orderItem} />
                  </HStack>
                </Td>
                <Td>
                  <Stack>
                    <Link to={`/orders/items/${orderItem.id}`}>
                      <HStack fontWeight="bold">
                        <DeviceCompleteNameLabel
                          showMakeIcon
                          device={
                            orderItem.attributes?.device
                              ?.data as DeviceDataFragment
                          }
                        />
                        {orderItem.attributes?.customer_device?.data ? (
                          <CustomerDeviceShortIdLabel
                            customerDevice={
                              orderItem.attributes?.customer_device?.data
                            }
                          />
                        ) : null}
                      </HStack>
                    </Link>
                    <OrderItemParentLink
                      prefix="part of"
                      color="muted"
                      fontSize="small"
                      orderItem={orderItem}
                    />
                  </Stack>
                </Td>
                <Td>
                  <LinkToOrder order={orderItem.attributes?.order?.data}>
                    <OrderNameLabel
                      orderData={orderItem.attributes?.order?.data}
                    />
                  </LinkToOrder>
                </Td>
                <Td>
                  <ActionComponent
                    action={async () => {
                      await removeOrderItemFromPackage(orderItem);
                    }}
                    variant="small-button"
                    actionIcon={<RemoveIcon />}
                    actionLabel="Remove"
                    data-order-item-id={orderItem.id}
                    data-cy="remove-order-item-from-package"
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
