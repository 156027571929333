import { OrderItemDataFragment } from "data";
import { useUpdateOrderItemHostedStatus } from "features/order-items/data/hooks/order-items";
import { OrderItemHostedStatusBadge } from "features/order-items/components/OrderItemHostedStatusBadge";

interface OrderItemHostedStatusBadgeContainerProps {
  orderItem: OrderItemDataFragment;
  editable?: boolean;
}

export const OrderItemHostedStatusBadgeContainer = (
  props: OrderItemHostedStatusBadgeContainerProps
) => {
  const { orderItem } = props;
  const { updateOrderItemHostedStatus } = useUpdateOrderItemHostedStatus();
  return (
    <OrderItemHostedStatusBadge
      {...props}
      onUpdateHostedStatus={async (isHosted) => {
        if (orderItem.id) {
          await updateOrderItemHostedStatus(orderItem.id, isHosted);
        }
      }}
    />
  );
};
