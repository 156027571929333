import {
  InspectionRackDataFragment,
  InspectedDeviceDataFragment,
  useUpdateInspectionRackMutation,
  useDeleteInspectedDeviceMutation,
  useDeleteCustomerDeviceMutation,
} from "data";

interface ResetRackParams {
  rack: InspectionRackDataFragment;
  devices: InspectedDeviceDataFragment[];
  refetchDevices: () => Promise<void>;
}

interface UseResetRackHookData {
  resetRack: (params: ResetRackParams) => Promise<void>;
}

export const useResetRack = (): UseResetRackHookData => {
  const [updateRack] = useUpdateInspectionRackMutation();
  const [deleteInspectedDevice] = useDeleteInspectedDeviceMutation();
  const [deleteCustomerDevice] = useDeleteCustomerDeviceMutation();
  return {
    async resetRack(params) {
      const { devices, rack, refetchDevices } = params;

      // nuke all customer devices
      // nuke all inspected devices
      // clear rack

      const customerDeviceIds = devices.map(
        (d) => d.attributes?.customer_device?.data?.id
      );
      const inspectedDeviceIds = devices.map((d) => d.id);

      await updateRack({
        variables: {
          id: rack.id!,
          data: {
            devices: [],
          },
        },
      });

      await refetchDevices();

      if (customerDeviceIds) {
        for (let i = 0; i < customerDeviceIds.length; i++) {
          await deleteCustomerDevice({
            variables: {
              id: customerDeviceIds[i]!,
            },
          });
        }
      }

      if (inspectedDeviceIds) {
        for (let i = 0; i < inspectedDeviceIds.length; i++) {
          await deleteInspectedDevice({
            variables: {
              id: inspectedDeviceIds[i]!,
            },
          });
        }
      }
    },
  };
};
