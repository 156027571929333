import { ReactNode, useContext } from "react";
import {
  OrdersForCustomerQueryVariables,
  OrdersForCustomerQuery,
  OrdersForCustomerDocument,
  Enum_Order_Status,
} from "data";
import { generateContextAndProvider, DataSourceSpec } from "context/DataSource";

const { Context, Provider } = generateContextAndProvider<
  OrdersForCustomerQuery,
  OrdersForCustomerQueryVariables
>({
  parseFilters(searchParams) {
    const page = searchParams.find((sp) => sp[0] === "page");
    const orderStatus = searchParams.find((sp) => sp[0] === "status");

    return {
      page: page ? parseInt(page[1] || "1") : 1,
      // XX: customer passed down in processParams
      customer: "",
      orderStatus: orderStatus
        ? ([orderStatus[0]] as Enum_Order_Status[])
        : [
            Enum_Order_Status.Complete,
            Enum_Order_Status.Receiving,
            Enum_Order_Status.Wip,
          ],
    };
  },
  serializeFilters(filters) {
    const r: Array<[string, string]> = [];
    r.push(["page", filters.page ? `${filters.page}` : "1"]);

    if (filters.orderStatus && filters.orderStatus.length) {
      r.push(["status", filters.orderStatus[0]]);
    }

    return r;
  },
  QueryDocument: OrdersForCustomerDocument,
});

interface CustomersProviderProps {
  children: ReactNode;
  customer: string;
}

export const OrdersListProvider = (props: CustomersProviderProps) => {
  const { children, customer } = props;
  return (
    <Provider
      processParams={(params) => Object.assign({}, params, { customer })}
    >
      {children}
    </Provider>
  );
};

export const useOrdersList = (): DataSourceSpec<
  OrdersForCustomerQuery,
  OrdersForCustomerQueryVariables
> => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useOrdersList used outside of OrdersListProvider");
  }

  return context;
};
