import Package from "../../package.json";
import omit from "lodash.omit";

export const truncateText = (
  text: string | null | undefined,
  maxLength: number
): string | null | undefined => {
  if (!text) {
    return text;
  }

  return text.length <= maxLength ? text : `${text.slice(0, maxLength - 3)}...`;
};

export const parseIntOrUndefined = (str: string): number | undefined => {
  if (!str.match(/^[0-9]+$/gi)) {
    return undefined;
  }
  return parseInt(str);
};

export const parseFloatOrUndefined = (str: string): number | undefined => {
  const result = parseFloat(str);
  return !Number.isNaN(result) ? result : undefined;
};

export const validateIPAddress = (ipAddress: string) => {
  return /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$$/.test(
    ipAddress
  )
    ? true
    : false;
};

export const extractIPAddress = (str: string): string | null => {
  const m = str
    .replaceAll(/[^0-9\.]/gi, "")
    .match(/(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$$/);
  return m && m.length !== 0 ? m[0] : null;
};

export const getUserAgent = (): string => window.navigator.userAgent;
export const getAppVersion = (): string => Package.version;

export const shallowDiff = (o1: object, o2: object): object => {
  // @ts-ignore
  return omit(o1, function (v, k) {
    // @ts-ignore
    return o2[k] === v;
  });
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

/**
 * Retries a function n number of times before giving up
 */
export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  sleepMs = 0,
  retryCount = 1
): Promise<Awaited<ReturnType<T>>> {
  const currRetry = typeof retryCount === "number" ? retryCount : 1;
  try {
    const result = await fn(...args);
    return result;
  } catch (e) {
    console.log(`Retry ${currRetry} failed with ${e}`);
    if (currRetry > maxTry) {
      console.log(`All ${maxTry} retry attempts exhausted`);
      throw e;
    }

    if (sleepMs !== 0) {
      await sleep(sleepMs);
    }

    return retry(fn, args, maxTry, sleepMs, currRetry + 1);
  }
}
