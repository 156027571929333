import { ReactNode, useContext } from "react";
import {
  Enum_Device_Type,
  OrderItemsForOrderQueryVariables,
  OrderItemsForOrderQuery,
  OrderItemsForOrderDocument,
  ALL_ORDER_ITEM_STATUS,
} from "data";
import { generateContextAndProvider, DataSourceSpec } from "context/DataSource";

const { Context, Provider } = generateContextAndProvider<
  OrderItemsForOrderQuery,
  OrderItemsForOrderQueryVariables
>({
  parseFilters(searchParams) {
    const status = searchParams.find((sp) => sp[0] === "status");
    const device = searchParams.find((sp) => sp[0] === "device");
    const page = searchParams.find((sp) => sp[0] === "page");

    return {
      // XX: processParams below takes care of this
      orderId: "",
      status: status ? status[1]?.split(",") : null,
      deviceTypes: device
        ? ([device[1]] as Enum_Device_Type[])
        : [
            Enum_Device_Type.ControlBoard,
            Enum_Device_Type.HashBoard,
            Enum_Device_Type.Miner,
            Enum_Device_Type.Psu,
          ],
      page: page ? parseInt(page[1] || "1") : 1,
    };
  },
  serializeFilters(filters) {
    const r: Array<[string, string?]> = [];

    if (filters.status) {
      r.push(["status", (filters.status as string[]).join(",")]);
    } else {
      r.push(["status", undefined]);
    }

    r.push(["page", filters.page ? `${filters.page}` : "1"]);

    if (filters.deviceTypes?.length === 1) {
      r.push(["device", filters.deviceTypes[0]]);
    } else {
      r.push(["device", undefined]);
    }

    return r;
  },
  QueryDocument: OrderItemsForOrderDocument,
  fetchPolicy: "network-only",
});

interface OrderItemsForOrderProviderProps {
  orderId: string;
  children: ReactNode;
}

export const OrderItemsForOrderProvider = (
  props: OrderItemsForOrderProviderProps
) => {
  const { children, orderId } = props;
  return (
    <Provider
      processParams={(params) => {
        return Object.assign(
          {},
          params,
          { orderId },
          params.status === null
            ? {
                status: ALL_ORDER_ITEM_STATUS,
              }
            : {}
        );
      }}
    >
      {children}
    </Provider>
  );
};

export const useOrderItemsForOrder = (): DataSourceSpec<
  OrderItemsForOrderQuery,
  OrderItemsForOrderQueryVariables
> | null => {
  return useContext(Context);
};
