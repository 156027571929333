import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Square,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";
import { useDropzone } from "react-dropzone";

export type DropzoneVariant = "normal" | "small";

interface DropzoneProps {
  onDrop: (files: File[]) => Promise<void>;
  promptText: string;
  variant: DropzoneVariant;
}

export const Dropzone = (props: DropzoneProps) => {
  const { onDrop, promptText, variant } = props;
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const bg = useColorModeValue("white", "gray.800");

  if (variant === "normal") {
    return (
      <Center
        borderWidth="1px"
        borderRadius="lg"
        px="6"
        py="4"
        bg={bg}
        {...getRootProps()}
      >
        <input data-cy="upload-file-input" {...getInputProps()} />
        <VStack spacing="3">
          <Square size="10" bg="bg-subtle" borderRadius="lg">
            <Icon as={FiUploadCloud} boxSize="5" color="muted" />
          </Square>
          <VStack spacing="1">
            <HStack spacing="1" whiteSpace="nowrap">
              <Button variant="link" colorScheme="blue" size="sm">
                Click to upload
              </Button>
              <Text fontSize="sm" color="muted">
                or drag and drop
              </Text>
            </HStack>
            <Text fontSize="xs" color="muted">
              {promptText}
            </Text>
          </VStack>
        </VStack>
      </Center>
    );
  }

  return (
    <Box {...getRootProps()}>
      <input data-cy="upload-file-input" {...getInputProps()} />
      <HStack>
        <Square size="10" bg="bg-subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="muted" />
        </Square>
        <Button variant="link" colorScheme="blue" size="sm">
          Upload files
        </Button>
      </HStack>
    </Box>
  );
};
