import { ReactElement } from "react";
import {
  Box,
  Center,
  Stack,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { PaginationDataFragment } from "data";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
} from "components/utils/Table";
import { Loader } from "components/Loader";
import { Pagination } from "components/Pagination";

interface DataListWithFiltersAndPaginationProps<T> {
  loading: boolean;
  data: T[];
  renderFilters?: () => ReactElement;
  renderItem: ({ item, index }: { item: T; index: number }) => ReactElement;
  onPageChange?: (page: number) => void;
  pagination?: PaginationDataFragment;
}

export function DataListWithFiltersAndPagination<T>(
  props: DataListWithFiltersAndPaginationProps<T>
) {
  const { data, loading, renderItem, renderFilters, pagination, onPageChange } =
    props;
  const boxShadow = useColorModeValue("sm", "sm-dark");
  return (
    <Box boxShadow={boxShadow} bg="bg-surface" borderRadius="lg">
      {renderFilters ? (
        <>
          <Box pl="4" pt="4">
            {renderFilters()}
          </Box>
          <Divider mt="2" mb="4" />
        </>
      ) : null}

      <Stack spacing="4">
        {loading ? (
          <Center py="4">
            <Loader />
          </Center>
        ) : (
          <>
            {data.map((item, index) => (
              <>
                <Box px={{ base: "4", md: "6" }} py={{ base: "2", md: "3" }}>
                  {renderItem({ item, index })}
                </Box>
                <Divider />
              </>
            ))}
          </>
        )}
      </Stack>
      {pagination && onPageChange ? (
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      ) : null}
    </Box>
  );
}

interface DataTableWithFiltersAndPaginationProps<T> {
  headers: string[];
  pagination?: PaginationDataFragment;
  onPageChange: (page: number) => void;
  renderFilters?: () => ReactElement;
  loading: boolean;
  data: T[];
  renderItem: (item: T, idx: number) => ReactElement;
}

export function DataTableWithFiltersAndPagination<T>(
  props: DataTableWithFiltersAndPaginationProps<T>
) {
  const {
    headers,
    data,
    loading,
    pagination,
    onPageChange,
    renderFilters,
    renderItem,
  } = props;
  return (
    <>
      {typeof renderFilters !== "undefined" ? renderFilters() : null}
      {loading ? (
        <Center py="4">
          <Loader />
        </Center>
      ) : (
        <>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  {headers.map((header, idx) => (
                    <Th key={`header-${header}-${idx}`}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>{data.map(renderItem)}</Tbody>
            </Table>
          </TableContainer>
          <Pagination onPageChange={onPageChange} pagination={pagination} />
        </>
      )}
    </>
  );
}
