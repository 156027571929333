import { TableContainer, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import {
  PsuInspectionReport,
  PsuInspectionReportInput,
  OrderItemDataFragment,
  Enum_Device_Make,
} from "data";
import { YesLabel, NoLabel } from "components/Labels";
import { ShowForAllTechs } from "features/accounts/components/ShowForAllTechs";
import { ShowForMake } from "features/devices/components/ShowForMake";

interface PSUInspectionReportPreviewProps {
  report: PsuInspectionReport | PsuInspectionReportInput;
  orderItem: OrderItemDataFragment;
}

export const PSUInspectionReportPreview = (
  props: PSUInspectionReportPreviewProps
) => {
  const { report, orderItem } = props;
  return (
    <TableContainer>
      <Table variant="simple">
        <Tbody>
          <ShowForMake
            device={orderItem.attributes?.device?.data}
            make={Enum_Device_Make.Antminer}
          >
            <>
              <Tr>
                <Td>Are both input fuses OK?</Td>
                <Td>{report.inputFusesOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are 4 inrush current limiters OK?</Td>
                <Td>
                  {report.inrushCurrentLimitersOk ? <YesLabel /> : <NoLabel />}
                </Td>
              </Tr>
              <Tr>
                <Td>Are 6 MOSFETS OK?</Td>
                <Td>{report.mosfetsOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are 4 power diodes OK?</Td>
                <Td>{report.powerDiodesOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Is U25 OK?</Td>
                <Td>{report.u25Ok ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
            </>
          </ShowForMake>
          <ShowForMake
            device={orderItem.attributes?.device?.data}
            make={Enum_Device_Make.Whatsminer}
          >
            <>
              <Tr>
                <Td>Are both input fuses OK?</Td>
                <Td>{report.inputFusesOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are both inrush current limiters OK?</Td>
                <Td>
                  {report.inrushCurrentLimitersOk ? <YesLabel /> : <NoLabel />}
                </Td>
              </Tr>
              <Tr>
                <Td>Are all 8 MOSFETS OK?</Td>
                <Td>{report.mosfetsOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are both bridges OK?</Td>
                <Td>{report.bridgesOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Is relay OK?</Td>
                <Td>{report.relaysOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are all 8 capacitors OK?</Td>
                <Td>{report.capacitorsOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
              <Tr>
                <Td>Are all 4 ICs OK?</Td>
                <Td>{report.icsOk ? <YesLabel /> : <NoLabel />}</Td>
              </Tr>
            </>
          </ShowForMake>
          {report.timeOfDiagnosisMinutes ? (
            <ShowForAllTechs>
              <Tr>
                <Td>Inspection time</Td>
                <Td>{`${report.timeOfDiagnosisMinutes} minutes`}</Td>
              </Tr>
            </ShowForAllTechs>
          ) : null}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
