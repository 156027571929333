import {
  Tabs as BaseTabs,
  TabsProps as BaseTabsProps,
  TabList as BaseTabList,
  TabListProps as BaseTabListProps,
  TabPanels as BaseTabPanels,
  TabPanelsProps as BaseTabPanelsProps,
  TabPanel as BaseTabPanel,
  TabPanelProps as BaseTabPanelProps,
  Tab as BaseTab,
  TabProps as BaseTabProps,
} from "@chakra-ui/react";

interface TabsProps extends Omit<BaseTabsProps, "orientation"> {}
interface TabListProps extends BaseTabListProps {}
interface TabProps extends BaseTabProps {}
interface TabPanelsProps extends BaseTabPanelsProps {}
interface TabPanelProps extends BaseTabPanelProps {}

export const Tabs = (props: TabsProps) => <BaseTabs {...props} />;
export const TabList = (props: TabListProps) => (
  <BaseTabList mb={6} alignItems="self-start" {...props} />
);
export const Tab = (props: TabProps) => <BaseTab {...props} />;
export const TabPanels = (props: TabPanelsProps) => (
  <BaseTabPanels {...props} />
);
export const TabPanel = (props: TabPanelProps) => (
  <BaseTabPanel p={0} pt={2} {...props} />
);
