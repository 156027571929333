import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Heading,
  Button,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";
import { Logo } from "components/Logo";
import { CustomerIntakeFormDataFragment } from "data";

interface IntakeFormSuccessMessageProps {
  customerIntakeData: CustomerIntakeFormDataFragment;
}

export const IntakeFormSuccessMessage = ({
  customerIntakeData,
}: IntakeFormSuccessMessageProps) => {
  const {
    zohoSalesOrderNumber,
    typesOfEquipment,
    manufacturersOfEquipment,
    totalPieces,
    referenceNumber,
    notes,
  } = customerIntakeData.attributes || {};
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: "padding:10px;",
    content: () => componentRef.current,
  });

  return (
    <Stack>
      <p ref={componentRef}>
        <Stack>
          <HStack>
            <Box pt={2}>
              <Logo />
            </Box>
            <Heading
              width="100%"
              textAlign="right"
              style={{ fontSize: "1.5em" }}
            >
              Sales order # {zohoSalesOrderNumber}
            </Heading>
          </HStack>

          <Text pl={3}>
            <strong>Attn: Receiving - {zohoSalesOrderNumber}</strong>
            <br />
            Advanced Crypto Services, Inc.
            <br />
            7546 Pebble Dr.
            <br />
            Bldg 29
            <br />
            Fort Worth, Texas 76118
            <br />
            United States
          </Text>
          <Text my={10} pl={3}>
            <strong>Equipment: </strong>
            {typesOfEquipment.join(", ")} {manufacturersOfEquipment.join(", ")}{" "}
            (x {totalPieces})
            <br />
            <strong>Reference number: </strong>
            {referenceNumber || "N/A"}
            <br />
            <strong>Notes: </strong>
            {notes || "N/A"}
          </Text>
        </Stack>
      </p>
      <Button my={10} onClick={handlePrint}>
        Print
      </Button>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Thank you for your order!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Please print a copy of this message and include it with your shipment.
          Failure to do so will delay receiving of your order! When your
          equipment arrives at our facility we will update the status of your
          sales order and send you another email.
        </AlertDescription>
      </Alert>
    </Stack>
  );
};
