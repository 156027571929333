import {
  RadioGroup,
  Radio,
  Stack,
  HStack,
  FormLabel,
  Divider,
} from "@chakra-ui/react";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { PSUDiagnosticsReport } from "features/diagnostics/data";

export const BasicPSUInputs = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const psuDiagnosticsReport = data as PSUDiagnosticsReport;
  return (
    <Stack spacing="4">
      <HStack>
        <FormLabel pt="2" flex="1">
          Are both input fuses OK?
        </FormLabel>
        <RadioGroup
          value={psuDiagnosticsReport.inputFusesOk ? "1" : "0"}
          onChange={(v) => {
            updateData(
              Object.assign({}, psuDiagnosticsReport, {
                inputFusesOk: v !== "0",
              })
            );
          }}
        >
          <Stack direction="row">
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Divider />
      <HStack>
        <FormLabel pt="2" flex="1">
          Are 4 inrush current limiters OK?
        </FormLabel>
        <RadioGroup
          value={psuDiagnosticsReport.inrushCurrentLimitersOk ? "1" : "0"}
          onChange={(v) => {
            updateData(
              Object.assign({}, psuDiagnosticsReport, {
                inrushCurrentLimitersOk: v !== "0",
              })
            );
          }}
        >
          <Stack direction="row">
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Divider />
      <HStack>
        <FormLabel pt="2" flex="1">
          Are 6 MOSFETS OK?
        </FormLabel>
        <RadioGroup
          value={psuDiagnosticsReport.mosfetsOk ? "1" : "0"}
          onChange={(v) => {
            updateData(
              Object.assign({}, psuDiagnosticsReport, {
                mosfetsOk: v !== "0",
              })
            );
          }}
        >
          <Stack direction="row">
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Divider />
      <HStack>
        <FormLabel pt="2" flex="1">
          Are 4 power diodes OK?
        </FormLabel>
        <RadioGroup
          value={psuDiagnosticsReport.powerDiodesOk ? "1" : "0"}
          onChange={(v) => {
            updateData(
              Object.assign({}, psuDiagnosticsReport, {
                powerDiodesOk: v !== "0",
              })
            );
          }}
        >
          <Stack direction="row">
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Divider />
      <HStack>
        <FormLabel pt="2" flex="1">
          Is U25 OK?
        </FormLabel>
        <RadioGroup
          value={psuDiagnosticsReport.u25Ok ? "1" : "0"}
          onChange={(v) => {
            updateData(
              Object.assign({}, psuDiagnosticsReport, {
                u25Ok: v !== "0",
              })
            );
          }}
        >
          <Stack direction="row">
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
    </Stack>
  );
};
