import {
  OrderDataFragment,
  DeviceDataFragment,
  CustomerDeviceDataFragment,
  CustomerDeviceInput,
  OrderItemDataFragment,
  OrderItemInput,
  InspectionDataFragment,
  Enum_Device_Make,
  Enum_Device_Model,
  Enum_Device_Type,
  Enum_Orderitem_Status,
} from "data";

export const validateEmail = (email: string): boolean => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

export type TestHelpers = {
  getOrderByZohoSalesOrderId: (
    zohoSalesOrderId: string
  ) => Promise<OrderDataFragment | null>;
  getDevice: ({
    type,
    make,
    model,
  }: {
    type: Enum_Device_Type;
    make: Enum_Device_Make;
    model: Enum_Device_Model;
  }) => Promise<DeviceDataFragment | null>;
  createCustomerDevice: (
    input: CustomerDeviceInput
  ) => Promise<CustomerDeviceDataFragment>;
  createOrderItem: (input: OrderItemInput) => Promise<OrderItemDataFragment>;
  createOrderItemFromSpec: (spec: {
    order: OrderDataFragment;
    device: DeviceDataFragment;
    status: Enum_Orderitem_Status;
    serialNumber?: string;
    parent?: string;
  }) => Promise<OrderItemDataFragment>;
  createInspection: (spec: {
    customer: string;
    name: string;
    configuration: object;
    numberOfRacks: number;
  }) => Promise<InspectionDataFragment>;
};
