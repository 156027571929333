import {
  HashboardBasicRepairInput,
  PsuRepairInput,
  useCreateHashboardRepairMutation,
  useCreatePsuRepairMutation,
  useHashboardRepairsForOrderItemQuery,
  usePsuRepairsForOrderItemQuery,
  useRegisterAdvancedRepairMutation,
  useDeleteAdvancedRepairMutation,
  HashboardRepairDataFragment,
  OrderItemDataFragment,
  Enum_Orderitem_Status,
  PsuRepairDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
} from "data";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";

interface UseRegisterPSURepairHookData {
  registerRepair: ({
    orderItem,
    repair,
  }: {
    orderItem: OrderItemDataFragment;
    repair: PsuRepairInput;
  }) => Promise<void>;
}

export const useRegisterPSURepair = (): UseRegisterPSURepairHookData => {
  const { createAction } = useCreateAction();
  const { updateOrderItemStatus } = useUpdateOrderItemStatus();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const [createPsuRepair] = useCreatePsuRepairMutation();
  const [registerAdvancedRepair] = useRegisterAdvancedRepairMutation();

  return {
    async registerRepair({ orderItem, repair }) {
      if (!repair.order_item) {
        return;
      }
      try {
        const { data } = await createPsuRepair({
          variables: {
            data: repair,
          },
        });
        // register advanced repair if item was in need of one
        if (
          orderItem.attributes?.status &&
          [
            Enum_Orderitem_Status.RepairedFailed,
            Enum_Orderitem_Status.QaFail,
          ].indexOf(orderItem.attributes?.status) !== -1
        ) {
          await registerAdvancedRepair({
            variables: {
              orderItemId: orderItem.id!,
            },
          });
        }

        await updateOrderItemStatus(
          orderItem,
          repair.isSuccessful
            ? Enum_Orderitem_Status.RepairedNeedsQa
            : Enum_Orderitem_Status.RepairedFailed,
          false
        );

        await createAction({
          type: Enum_Action_Type.RepairOrderItem,
          scope: Enum_Action_Scope.OrderItem,
          data: Object.assign(
            {
              ...expandActionDataPiece(orderItem),
            },
            {
              psu_repair: data?.createPsuRepair?.data?.id,
            }
          ),
          context: {
            order: orderItem.attributes?.order?.data,
            device: orderItem.attributes?.device?.data,
            customerDevice: orderItem.attributes?.customer_device?.data,
            psuRepair: data?.createPsuRepair?.data,
          },
        });

        showSuccessMessage("Repair Registered");
      } catch (error) {
        showErrorMessage("Failed to register repair");
      }
    },
  };
};

interface UseRegisterHashboardRepairHookData {
  registerRepair: ({
    orderItem,
    repair,
    associatedDiagnostics,
  }: {
    orderItem: OrderItemDataFragment;
    repair: HashboardBasicRepairInput;
    associatedDiagnostics?: HashboardAdvancedInspectionReportDataFragment | null;
  }) => Promise<void>;
}

export const useRegisterHashboardRepair =
  (): UseRegisterHashboardRepairHookData => {
    const { createAction } = useCreateAction();
    const { registerAdvancedRepair } = useAdvancedRepair();
    const { updateOrderItemStatus } = useUpdateOrderItemStatus();
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const [createHashboardRepair] = useCreateHashboardRepairMutation();

    return {
      async registerRepair({ orderItem, repair, associatedDiagnostics }) {
        try {
          const { data } = await createHashboardRepair({
            variables: {
              data: Object.assign({}, repair, {
                order_item: orderItem.id,
                inspection_report: associatedDiagnostics?.id,
              }),
            },
          });

          await createAction({
            type: Enum_Action_Type.RepairOrderItem,
            scope: Enum_Action_Scope.OrderItem,
            data: Object.assign({}, expandActionDataPiece(orderItem), {
              hashboard_basic_repair:
                data?.createHashboardBasicRepair?.data?.id,
            }),
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
              hashboardRepair: data?.createHashboardBasicRepair?.data,
            },
          });

          if (repair.isAdvancedRepair) {
            await registerAdvancedRepair({ orderItem });
          }

          await updateOrderItemStatus(
            orderItem,
            !repair.gotFurtherIssues
              ? Enum_Orderitem_Status.RepairedNeedsQa
              : Enum_Orderitem_Status.RepairedFailed,
            false
          );

          showSuccessMessage("Repair Registered");
        } catch (error) {
          showErrorMessage("Failed to register repair");
        }
      },
    };
  };

interface UseRepairsForOrderItemHookData<T> {
  loading: boolean;
  repairs: T[];
}

export const useHashboardRepairsForOrderItem = (
  orderItem: OrderItemDataFragment
): UseRepairsForOrderItemHookData<HashboardRepairDataFragment> => {
  const { data, loading } = useHashboardRepairsForOrderItemQuery({
    variables: {
      orderItemId: orderItem.id || "",
    },
  });
  return {
    loading,
    repairs: data?.hashboardRepairs?.data || [],
  };
};

export const usePSURepairsForOrderItem = (
  orderItem: OrderItemDataFragment
): UseRepairsForOrderItemHookData<PsuRepairDataFragment> => {
  const { data, loading } = usePsuRepairsForOrderItemQuery({
    variables: {
      orderItemId: orderItem.id || "",
    },
  });
  return {
    loading,
    repairs: data?.psuRepairs?.data || [],
  };
};

interface UseAdvancedRepairHookData {
  registerAdvancedRepair: ({
    orderItem,
  }: {
    orderItem: OrderItemDataFragment;
  }) => Promise<void>;
  deleteAdvancedRepair: ({
    orderItem,
  }: {
    orderItem: OrderItemDataFragment;
  }) => Promise<void>;
}

export const useAdvancedRepair = (): UseAdvancedRepairHookData => {
  const { pushToDataLayer } = useCreateAction();
  const [registerAdvancedRepair] = useRegisterAdvancedRepairMutation();
  const [deleteAdvancedRepair] = useDeleteAdvancedRepairMutation();

  return {
    async registerAdvancedRepair({ orderItem }) {
      await registerAdvancedRepair({
        variables: {
          orderItemId: orderItem?.id!,
        },
      });

      pushToDataLayer({
        event: "register_advanced_repair",
        context: {
          order: orderItem.attributes?.order?.data,
          device: orderItem.attributes?.device?.data,
          customerDevice: orderItem.attributes?.customer_device?.data,
        },
      });
    },
    async deleteAdvancedRepair({ orderItem }) {
      const advancedRepairs =
        orderItem.attributes?.advanced_repairs?.data || [];
      for (let i = 0; i < advancedRepairs.length; i++) {
        await deleteAdvancedRepair({
          variables: {
            advancedRepairId: advancedRepairs[i].id!,
          },
        });
      }
    },
  };
};
