import { Text, TextProps } from "@chakra-ui/react";
import { Enum_Orderitemreplacement_Status } from "data";
import { replacementStatusToString } from "features/order-items/data";

interface ReplacementStatusLabelProps extends TextProps {
  status: Enum_Orderitemreplacement_Status;
}

export const ReplacementStatusLabel = (props: ReplacementStatusLabelProps) => {
  const { status, ...rest } = props;
  return <Text {...rest}>{replacementStatusToString(status)}</Text>;
};
