import {
  HStack,
  Spacer,
  useDisclosure,
  Collapse,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { HashboardAdvancedInspectionReportDataFragment } from "data";
import { ShowForCustomers } from "features/accounts/components/ShowForCustomers";
import { ShowForAllTechs } from "features/accounts/components/ShowForAllTechs";
import { HashboardDiagnosisLabel } from "features/diagnostics/components/hashboards/DiagnosisLabel";
import { parseHashboardDiagnosis } from "features/diagnostics/data/helpers";
import { MultilineText } from "components/Labels";
import { ChevronDown, ChevronRight } from "components/Icons";

interface AdvancedDiagnosticReportProps {
  report: HashboardAdvancedInspectionReportDataFragment;
}

const NoteEntry = ({
  label,
  value,
}: {
  label?: string | null;
  value?: string | null;
}) => {
  return !value ? null : (
    <Tr>
      <Td>
        <Text fontWeight="bold">{label}</Text>
      </Td>
      <Td>
        <MultilineText>{value}</MultilineText>
      </Td>
    </Tr>
  );
};

export const AdvancedDiagnosticReport = ({
  report,
}: AdvancedDiagnosticReportProps) => {
  const { category } = parseHashboardDiagnosis(report.attributes?.diagnosis);
  const { isOpen, onToggle } = useDisclosure();
  const notes = [
    { value: report.attributes?.visualNotes, label: "Visuals" },

    {
      value: report.attributes?.powerNotes,
      label: "Power",
    },

    {
      value: report.attributes?.communicationNotes,
      label: "Comm",
    },
    {
      value: report.attributes?.asicNotes,
      label: "ASICs",
    },
    {
      value: report.attributes?.temperatureNotes,
      label: "Temperature",
    },
    {
      value: report.attributes?.thermalsNotes,
      label: "Thermals",
    },
  ];
  const noteCount = notes.filter((n) => n.value).length;

  return (
    <>
      <ShowForAllTechs>
        <>
          <HStack>
            <HashboardDiagnosisLabel
              fontWeight="bold"
              diagnosis={report.attributes?.diagnosis}
            />
            <Spacer />
            {noteCount !== 0 ? (
              <IconButton
                variant="ghost"
                aria-label="Toggle report details"
                onClick={onToggle}
                icon={isOpen ? <ChevronDown /> : <ChevronRight />}
              />
            ) : null}
          </HStack>
          <Collapse in={isOpen} animateOpacity>
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <NoteEntry
                    value={report.attributes?.visualNotes}
                    label="Visuals"
                  />
                  <NoteEntry
                    value={report.attributes?.powerNotes}
                    label="Power"
                  />
                  <NoteEntry
                    value={report.attributes?.communicationNotes}
                    label="Comm"
                  />
                  <NoteEntry
                    value={report.attributes?.asicNotes}
                    label="ASICs"
                  />
                  <NoteEntry
                    value={report.attributes?.temperatureNotes}
                    label="Temperature"
                  />
                  <NoteEntry
                    value={report.attributes?.thermalsNotes}
                    label="Thermals"
                  />
                  <NoteEntry value={report.attributes?.notes} label="General" />
                </Tbody>
              </Table>
            </TableContainer>
          </Collapse>
        </>
      </ShowForAllTechs>
      <ShowForCustomers>
        <strong>{category} issue</strong>
      </ShowForCustomers>
    </>
  );
};
