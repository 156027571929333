import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Type } from "data";

interface ShowForPSUProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
}

export const ShowForPSU = (props: ShowForPSUProps) => {
  const { device, children } = props;

  if (device?.attributes?.type !== Enum_Device_Type.Psu) {
    return null;
  }

  return children;
};
