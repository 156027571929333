import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider as ApolloProvider } from "./apollo";
import { TestHelpers } from "components/utils/TestHelpers";
import { HomePage } from "pages/Home";
import { ChangeLogsPage } from "pages/ChangeLogs";
import { ChangeLogPage } from "pages/ChangeLog";
import { Layout } from "components/layout/DefaultLayout";
import { AuthLayout } from "components/layout/AuthLayout";
import { ChangeLogLayout } from "components/layout/ChangeLogLayout";
import { OrdersPage } from "features/orders/pages/Orders";
import { OrderPage } from "features/orders/pages/Order";
import { PrintOrderPage } from "features/orders/pages/PrintOrder";
import { OrderItemPage } from "features/order-items/pages/OrderItem";
import { LoginPage } from "features/accounts/pages/Login";
import { RegistrationPage } from "features/accounts/pages/Register";
import { ConfirmEmailPage } from "features/accounts/pages/ConfirmEmail";
import { ForgotPasswordPage } from "features/accounts/pages/ForgotPassword";
import { ResetPasswordPage } from "features/accounts/pages/ResetPassword";
import { SetupCustomerAccountPage } from "features/accounts/pages/SetupCustomerAccount";
import { PasswordResetRequestedPage } from "features/accounts/pages/PasswordResetRequested";
import { CustomerDevicePage } from "features/customer-devices/pages/CustomerDevice";
import { CustomersPage } from "features/customers/pages/Customers";
import { CustomerPage } from "features/customers/pages/Customer";
import { OrderPackagePage } from "features/order-packages/pages/OrderPackage";
import { InspectionsPage } from "features/inspections/pages/Inspections";
import { InspectionPage } from "features/inspections/pages/Inspection";
import { FeedPage } from "features/actions/pages/Feed";
import { NotificationsProvider } from "context/Notifications";
import { ApplicationConfigurationProvider } from "context/ApplicationConfiguration";
import { AuthenticationProvider } from "features/accounts";

import appTheme from "./theme";

export const App = () => {
  return (
    <ChakraProvider theme={appTheme}>
      <ApolloProvider>
        <ApplicationConfigurationProvider>
          <TestHelpers>
            <NotificationsProvider>
              <BrowserRouter>
                <AuthenticationProvider>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/unauthorized" element={<HomePage />} />
                    <Route
                      path="/orders/print/:orderId"
                      element={<PrintOrderPage />}
                    />
                    <Route path="/orders" element={<Layout />}>
                      <Route index element={<OrdersPage />} />
                      <Route path=":orderId" element={<OrderPage />} />
                      <Route
                        path="items/:orderItemId"
                        element={<OrderItemPage />}
                      />
                    </Route>
                    <Route path="/inspections" element={<Layout />}>
                      <Route index element={<InspectionsPage />} />
                      <Route
                        path=":inspectionId"
                        element={<InspectionPage />}
                      />
                    </Route>
                    <Route path="/devices" element={<Layout />}>
                      <Route path=":shortId" element={<CustomerDevicePage />} />
                    </Route>
                    <Route path="/customers" element={<Layout />}>
                      <Route index element={<CustomersPage />} />
                      <Route path=":customerId" element={<CustomerPage />} />
                    </Route>
                    <Route path="/packages" element={<Layout />}>
                      <Route
                        path=":orderPackageId"
                        element={<OrderPackagePage />}
                      />
                    </Route>
                    <Route path="/feed" element={<Layout />}>
                      <Route index element={<FeedPage />} />
                    </Route>
                    <Route path="/account" element={<AuthLayout />}>
                      <Route path="login" element={<LoginPage />} />
                      <Route path="register" element={<RegistrationPage />} />
                      <Route
                        path="setup-customer-account"
                        element={<SetupCustomerAccountPage />}
                      />
                      <Route
                        path="confirm-email"
                        element={<ConfirmEmailPage />}
                      />
                      <Route
                        path="forgot-password"
                        element={<ForgotPasswordPage />}
                      />
                      <Route
                        path="password-reset-requested"
                        element={<PasswordResetRequestedPage />}
                      />
                      <Route
                        path="reset-password"
                        element={<ResetPasswordPage />}
                      />
                    </Route>
                    <Route path="/changelogs" element={<ChangeLogLayout />}>
                      <Route index element={<ChangeLogsPage />} />
                      <Route path=":changelogId" element={<ChangeLogPage />} />
                    </Route>
                  </Routes>
                </AuthenticationProvider>
              </BrowserRouter>
            </NotificationsProvider>
          </TestHelpers>
        </ApplicationConfigurationProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
};
