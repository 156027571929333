import { ReactElement } from "react";
import { useAuthentication } from "features/accounts/context/Authentication";

type SupportedRoles = "CheckInTech" | "AdminTech" | "Customer";

interface ShowForRolesProps {
  children: ReactElement;
  roles: SupportedRoles[];
  inverse?: boolean;
}

export const ShowForRoles = (props: ShowForRolesProps) => {
  const { children, inverse, roles } = props;
  const { loginData } = useAuthentication();

  if (!loginData?.role) {
    return null;
  }

  const isInRole =
    roles.indexOf((loginData?.role || "") as SupportedRoles) !== -1;

  if (!inverse) {
    return isInRole ? children : null;
  }

  return !isInRole ? children : null;
};
