import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Make } from "data";

interface ShowForHashboardProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
  makes?: Enum_Device_Make[];
}

export const ShowForHashboard = (props: ShowForHashboardProps) => {
  const { device, makes, children } = props;

  if (device?.attributes?.type !== Enum_Device_Type.HashBoard) {
    return null;
  }

  if (makes && makes.length !== 0) {
    if (makes.indexOf(device.attributes.make) === -1) {
      return null;
    }
  }

  return children;
};
