import { createContext, ReactNode, useContext } from "react";
import { useOrderItemSummary } from "features/order-items/data/hooks/order-items";
import type { UseOrderItemHookData } from "features/order-items/data/hooks/order-items";

interface OrderItemProviderProps {
  children: ReactNode;
  orderItemId: string;
}

const OrderItemContext = createContext<UseOrderItemHookData>(
  {} as UseOrderItemHookData
);

export const useOrderItem = () => {
  return useContext(OrderItemContext);
};

export const OrderItemProvider = (props: OrderItemProviderProps) => {
  const { orderItemId } = props;
  const data = useOrderItemSummary(orderItemId);
  return (
    <OrderItemContext.Provider value={data}>
      {props.children}
    </OrderItemContext.Provider>
  );
};
