import { Text } from "@chakra-ui/react";
import { Enum_Orderitem_Status } from "data";

import { RadioCard, RadioCardGroup } from "components/forms/RadioCardGroup";
import { _orderItemStatusToString } from "features/order-items/data";

interface QAFailedStatusSelectorProps {
  selectedStatus?: Enum_Orderitem_Status;
  onChange: (selectedStatus: Enum_Orderitem_Status) => void;
}

export const QAFailedStatusSelector = (props: QAFailedStatusSelectorProps) => {
  const { selectedStatus, onChange } = props;

  return !selectedStatus ? null : (
    <RadioCardGroup
      onChange={(status) => {
        onChange(status);
      }}
      value={selectedStatus}
      spacing="3"
    >
      {[
        Enum_Orderitem_Status.QaFail,
        Enum_Orderitem_Status.QaFailRecycled,
        Enum_Orderitem_Status.QaFailReturnToCustomer,
      ].map((status) => (
        <RadioCard key={status} value={status}>
          <Text
            data-cy="failed-status-selector"
            data-order-item-status={status}
            color="emphasized"
            fontWeight="medium"
            fontSize="sm"
          >
            {_orderItemStatusToString(status)}
          </Text>
        </RadioCard>
      ))}
    </RadioCardGroup>
  );
};
