import { useParams, Navigate } from "react-router-dom";
import { useCustomerDeviceById } from "features/customer-devices/data/hooks/customer-devices";
import { Loader } from "components/Loader";

interface ConnectedCustomerDevicePageProps {
  shortId: string;
}

export const ConnectedCustomerDevicePage = (
  props: ConnectedCustomerDevicePageProps
) => {
  const { loading, customerDevice, activeOrderItemId } = useCustomerDeviceById({
    shortId: props.shortId,
  });

  if (loading) {
    return <Loader />;
  }

  if (activeOrderItemId) {
    return (
      <Navigate to={`/orders/items/${activeOrderItemId}`} replace={true} />
    );
  }

  return (
    <strong>
      {customerDevice?.attributes?.device?.data?.attributes?.completeName}
    </strong>
  );
};

export const CustomerDevicePage = () => {
  const { shortId } = useParams();
  return shortId ? <ConnectedCustomerDevicePage shortId={shortId} /> : null;
};
