import { useNotifications } from "context/Notifications";
import {
  useCreateOrderPackageMutation,
  useUpdateOrderItemMutation,
  useOrderPackageByIdQuery,
  useUpdateOrderPackageMutation,
  OrderPackageDataFragment,
  CustomerDataFragment,
  OrderItemDataFragment,
  Enum_Orderitem_Status,
  Enum_Orderpackage_Status,
  OrderPackageByIdQuery,
  OrderPackageByIdQueryVariables,
  OrderPackageByIdDocument,
  OrderItemSummaryDocument,
} from "data";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface CreateOrderPackageInput {
  name: string;
  customer: CustomerDataFragment;
}

interface UseCreateOrderPackageHookData {
  createOrderPackage: (
    input: CreateOrderPackageInput
  ) => Promise<OrderPackageDataFragment | null | undefined>;
}

export const useCreateOrderPackage = (): UseCreateOrderPackageHookData => {
  const [doCreateOrderPackage] = useCreateOrderPackageMutation();
  const { createAction } = useCreateAction();
  return {
    async createOrderPackage({ customer, name }) {
      const { data } = await doCreateOrderPackage({
        variables: {
          data: {
            name,
            customer: customer.id,
          },
        },
      });

      await createAction({
        type: Enum_Action_Type.CreateOrderPackage,
        scope: Enum_Action_Scope.Customer,
        data: {
          customer: customer.id,
        },
      });

      return data?.createOrderPackage?.data;
    },
  };
};

interface AddOrderItemToOrderPackageInput {
  orderPackage: OrderPackageDataFragment;
  orderItem: OrderItemDataFragment;
}

interface UseAddOrderItemToOrderPackageHookData {
  addOrderItemToOrderPackage: (
    input: AddOrderItemToOrderPackageInput
  ) => Promise<OrderPackageDataFragment | null | undefined>;
}

export const useAddOrderItemToOrderPackage =
  (): UseAddOrderItemToOrderPackageHookData => {
    const { createAction } = useCreateAction();
    const [updateOrderItem] = useUpdateOrderItemMutation();

    return {
      async addOrderItemToOrderPackage(input) {
        const { orderItem, orderPackage } = input;

        if (!orderPackage.id || !orderItem.id) {
          return orderPackage;
        }

        await updateOrderItem({
          variables: {
            id: orderItem.id,
            data: {
              order_package: orderPackage.id,
              status: Enum_Orderitem_Status.Packaged,
            },
          },
          update(cache, { data }) {
            if (orderPackage.id && data?.updateOrderItem?.data) {
              const querySpec = {
                query: OrderPackageByIdDocument,
                variables: {
                  id: orderPackage.id,
                },
              };

              const existingPackage =
                cache.readQuery<
                  OrderPackageByIdQuery,
                  OrderPackageByIdQueryVariables
                >(querySpec);

              if (existingPackage) {
                cache.writeQuery<
                  OrderPackageByIdQuery,
                  OrderPackageByIdQueryVariables
                >(
                  Object.assign({}, querySpec, {
                    data: Object.assign({}, existingPackage, {
                      orderItems: Object.assign(
                        {},
                        existingPackage?.orderItems,
                        {
                          data: [
                            ...(existingPackage?.orderItems?.data || []),
                            data.updateOrderItem.data,
                          ],
                        }
                      ),
                    }),
                  })
                );
              }
            }
          },
          refetchQueries: [
            {
              query: OrderItemSummaryDocument,
              variables: {
                id: orderItem.id,
              },
            },
          ],
        });

        await createAction({
          type: Enum_Action_Type.PackageOrderItem,
          scope: Enum_Action_Scope.Customer,
          data: {
            order_package: orderPackage.id,
            ...expandActionDataPiece(orderItem),
          },
          context: {
            order: orderItem.attributes?.order?.data,
            device: orderItem.attributes?.device?.data,
            customerDevice: orderItem.attributes?.customer_device?.data,
          },
        });

        return orderPackage;
      },
    };
  };

interface UseRemoveOrderItemFromPackageHookData {
  removeOrderItemFromPackage: (
    orderItem: OrderItemDataFragment
  ) => Promise<OrderItemDataFragment | null | undefined>;
}

export const useRemoveOrderItemFromPackage =
  (): UseRemoveOrderItemFromPackageHookData => {
    const { createAction } = useCreateAction();
    const [updateOrderItem] = useUpdateOrderItemMutation();
    const { showSuccessMessage, showErrorMessage } = useNotifications();

    return {
      async removeOrderItemFromPackage(orderItem) {
        try {
          if (
            orderItem.attributes?.status !== Enum_Orderitem_Status.Packaged ||
            !orderItem.attributes?.order_package?.data
          ) {
            throw new Error("Item is not packaged");
          }

          const { data } = await updateOrderItem({
            variables: {
              id: orderItem.id || "",
              data: {
                order_package: null,
                status: orderItem.attributes
                  .previousStatus as unknown as Enum_Orderitem_Status,
              },
            },
            update(cache, { data }) {
              if (
                orderItem.attributes?.order_package?.data &&
                data?.updateOrderItem?.data
              ) {
                const querySpec = {
                  query: OrderPackageByIdDocument,
                  variables: {
                    id: orderItem.attributes?.order_package?.data.id || "",
                  },
                };

                const existingPackage =
                  cache.readQuery<
                    OrderPackageByIdQuery,
                    OrderPackageByIdQueryVariables
                  >(querySpec);

                cache.writeQuery<
                  OrderPackageByIdQuery,
                  OrderPackageByIdQueryVariables
                >(
                  Object.assign({}, querySpec, {
                    data: Object.assign({}, existingPackage, {
                      orderItems: Object.assign(
                        {},
                        existingPackage?.orderItems,
                        {
                          data: (
                            existingPackage?.orderItems?.data || []
                          ).filter((oi) => oi.id !== orderItem.id),
                        }
                      ),
                    }),
                  })
                );
              }
            },
            refetchQueries: [
              {
                query: OrderItemSummaryDocument,
                variables: {
                  id: orderItem.id,
                },
              },
            ],
          });

          await createAction({
            type: Enum_Action_Type.RemoveOrderItemFromPackage,
            scope: Enum_Action_Scope.Customer,
            data: {
              order_package: orderItem.attributes.order_package.data.id,
              ...expandActionDataPiece(orderItem),
            },
          });

          showSuccessMessage("Item removed from the package");

          return data?.updateOrderItem?.data;
        } catch (err) {
          showErrorMessage("Cannot remove this item from the package");
        }
      },
    };
  };

interface UserOrderPackageByIdHookData {
  orderPackage?: OrderPackageDataFragment | null;
  orderItems: OrderItemDataFragment[];
  loading: boolean;
}

interface UserOrderPackageByIdProps {
  orderPackageId: string;
}

export const useOrderPackageById = (
  props: UserOrderPackageByIdProps
): UserOrderPackageByIdHookData => {
  const { orderPackageId } = props;
  const { data, loading } = useOrderPackageByIdQuery({
    variables: {
      id: orderPackageId,
    },
  });
  return {
    orderPackage: data?.orderPackage?.data,
    orderItems: data?.orderItems?.data || [],
    loading,
  };
};

interface UseShipOrderPackageHookData {
  shipPackage(orderPackage: OrderPackageDataFragment): Promise<void>;
}

export const useShipOrderPackage = (): UseShipOrderPackageHookData => {
  const { createAction } = useCreateAction();
  const [updateOrderPackage] = useUpdateOrderPackageMutation();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  return {
    async shipPackage(orderPackage) {
      try {
        if (!orderPackage.id) {
          return;
        }

        await updateOrderPackage({
          variables: {
            id: orderPackage.id,
            data: {
              status: Enum_Orderpackage_Status.Shipped,
            },
          },
        });

        await createAction({
          type: Enum_Action_Type.ShipOrderPackage,
          scope: Enum_Action_Scope.Customer,
          data: {
            order_package: orderPackage.id,
            customer: orderPackage.attributes?.customer?.data?.id,
          },
        });

        showSuccessMessage("Package shipped");
      } catch (err) {
        showErrorMessage("Failed to ship package");
      }
    },
  };
};
