import {
  Button,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Text,
} from "@chakra-ui/react";
import {
  QuickInspectReport,
  NumberOfASICsDetected,
} from "features/diagnostics/data";

interface QuickInspectProps {
  report?: QuickInspectReport | null;
  onChange: (report: QuickInspectReport) => void;
}

export const QuickInspect = (props: QuickInspectProps) => {
  const { onChange, report } = props;

  const buildButtonProps = ({
    numberOfASICsDetected,
  }: {
    numberOfASICsDetected: NumberOfASICsDetected;
  }) => {
    const selected = report?.asicsDetected === numberOfASICsDetected;
    return {
      minWidth: 90,
      textTransform: "uppercase",
      onClick: () => {
        onChange({
          asicsDetected: numberOfASICsDetected,
        });
      },
      size: "sm",
      variant: "outline",
      colorScheme: selected ? "blue" : "gray",
      ...(selected ? { backgroundColor: "blue.50" } : {}),
    };
  };

  return (
    <Card>
      <CardHeader>
        <Text fontSize="larger" fontWeight="bold">
          Quick Inspect
        </Text>
      </CardHeader>
      <CardBody>
        <HStack spacing={4}>
          {/* @ts-ignore */}
          <Button
            {...buildButtonProps({
              numberOfASICsDetected: NumberOfASICsDetected.AllASICs,
            })}
            data-cy="option-all-asics"
          >
            All ASICs
          </Button>
          {/* @ts-ignore */}
          <Button
            {...buildButtonProps({
              numberOfASICsDetected: NumberOfASICsDetected.NoASICs,
            })}
            data-cy="option-no-asics"
          >
            No ASICs
          </Button>
          {/* @ts-ignore */}
          <Button
            {...buildButtonProps({
              numberOfASICsDetected: NumberOfASICsDetected.SomeASICs,
            })}
            data-cy="option-some-asics"
          >
            Some ASICs
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
};
