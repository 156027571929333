import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useInspectionRack } from "features/inspections/context/InspectionRack";
import { useNotifications } from "context/Notifications";
import { InspectionRackDataFragment } from "data";

interface CheckInDevicesProps {
  rack: InspectionRackDataFragment;
}

export const CheckInDevices = (props: CheckInDevicesProps) => {
  const { rack } = props;
  const { checkInDevices } = useInspectionRack();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const [serials, setSerials] = useState<string[]>([]);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Box bg="bg-surface" boxShadow="sm" borderRadius="lg" flex="1">
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
      >
        <Heading size="sm" flex={1}>
          {rack.attributes?.name} ({rack.attributes?.startIp})
        </Heading>
        <FormControl id="bio">
          <Textarea
            data-cy="bulk-check-in-serials-textarea"
            autoFocus
            onChange={(e) => {
              setValue(e.target.value);
              const s = e.target.value
                .replace(/\n/gi, "")
                .split(",")
                .map((s) => s.trim())
                .filter((s) => s !== "");
              setSerials(s);
            }}
            value={value}
            placeholder="Comma separated serial numbers list, use NA where this is no serial, (e.g. NGSBDXABBJDJC1644,HYDTYNGBAAJAI0RW3,FXDZYNGBAJHBI0AMX)"
            rows={3}
          />
          {/* <FormHelperText color="subtle">
            Write a short introduction about yourself
          </FormHelperText> */}
        </FormControl>
      </Stack>
      <Divider />
      <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
        <Button
          onClick={async () => {
            try {
              setLoading(true);
              await checkInDevices(serials);
              showSuccessMessage(`Checked in ${serials.length} devices`);
            } catch {
              showErrorMessage("Failed to check in devices");
            }
          }}
          isDisabled={serials.length === 0 || loading}
          type="submit"
          variant="primary"
        >
          Add {serials.length} devices
        </Button>
      </Flex>
    </Box>
  );
};
