import {
    ActionDataFragment,
    Enum_Action_Type,
    Enum_Order_Status,
    Enum_Orderitemaction_Action,
} from "data"
import { orderStatusToString } from "features/orders/data"
import { _orderItemStatusToString } from "features/order-items/data"
import { completeDeviceName } from "features/devices/data"

const getOrderItemActionDescription = (
    action?: Enum_Orderitemaction_Action | null
): string => {
    if (!action) {
        return ""
    }

    switch (action) {
        case Enum_Orderitemaction_Action.Clean:
            return "registered cleaning"
        case Enum_Orderitemaction_Action.Reflow:
            return "registered reflow"
        case Enum_Orderitemaction_Action.UpdateFirmware:
            return "updated firmware"
        case Enum_Orderitemaction_Action.ApplyThermalPaste:
            return "applied thermal paste"
        case Enum_Orderitemaction_Action.ReplaceControlBoard:
            return "replaced control board"
        case Enum_Orderitemaction_Action.ReplaceMinerFan:
            return "replaced miner fan"
        case Enum_Orderitemaction_Action.ReplacePsuFan:
            return "replaced PSU fan"
        case Enum_Orderitemaction_Action.ReplacePsu:
            return "replaced PSU"
        case Enum_Orderitemaction_Action.Purchase:
            return "purchased"
        default:
            return "unknown action"
    }
}

export const getActionDescription = (action: ActionDataFragment): string => {
    switch (action.attributes?.type) {
        case Enum_Action_Type.ReceiveOrder:
            return "received order"
        case Enum_Action_Type.ChangeOrderStatus:
            const { from: fromOrderStatus, to: toOrderStatus } =
                action.attributes.metadata
            return `changed order status from ${orderStatusToString(
                fromOrderStatus as Enum_Order_Status
            )} to ${orderStatusToString(toOrderStatus as Enum_Order_Status)}`
        case Enum_Action_Type.ChangeOrderItemStatus:
            const { from: fromOrderItemStatus, to: toOrderItemStatus } =
                action.attributes.metadata
            return `change status from ${_orderItemStatusToString(
                fromOrderItemStatus
            )} to ${_orderItemStatusToString(toOrderItemStatus)}`
        case Enum_Action_Type.AddOrderLayoutItem:
            const orderLayoutItem =
                action.attributes.order_layout_item?.data?.attributes
            return `bulk added ${
                orderLayoutItem?.quantity
            } ${completeDeviceName(orderLayoutItem?.device?.data)}'s`
        case Enum_Action_Type.RemoveOrderItem:
            return "removed order item"
        case Enum_Action_Type.ReceiveOrderItem:
            return "received"
        case Enum_Action_Type.DiagnoseOrderItem:
            return "inspected"
        case Enum_Action_Type.RepairOrderItem:
            return "repaired"
        case Enum_Action_Type.QaOrderItem:
            return "QA'ed"
        case Enum_Action_Type.PackageOrderItem:
            return "packaged"
        case Enum_Action_Type.RemoveOrderItemFromPackage:
            return "removed from package"
        case Enum_Action_Type.AddNote:
            return action.attributes.order_item?.data
                ? "added a note for"
                : "added a note"
        case Enum_Action_Type.ScrapForParts:
            return "scrapped for parts"
        case Enum_Action_Type.ReplaceOrderItem:
            return "replaced"
        case Enum_Action_Type.RegisterOrderItemAction:
            return getOrderItemActionDescription(
                action.attributes.order_item_action?.data?.attributes?.action
            )
        case Enum_Action_Type.AddTagToOrderItem:
            return `added tag ${action.attributes.metadata?.tag}`
        case Enum_Action_Type.RemoveTagFromOrderItem:
            return `removed tag ${action.attributes.metadata?.tag}`
        default:
            return ""
    }
}
