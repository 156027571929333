import { ReactNode } from "react";
import { Box, Container, Divider, Stack, Flex } from "@chakra-ui/react";
import { Step } from "components/Step";
import { WizardStep } from "context/Wizard";

interface WizardProps {
  steps: WizardStep[];
  currentStep: WizardStep | null;
  renderCurrentStep: (currentStep: WizardStep | null) => ReactNode | null;
  renderNav: () => ReactNode;
}

export const Wizard = (props: WizardProps) => {
  const { currentStep, renderCurrentStep, renderNav, steps } = props;
  return (
    <Container height="100%" py="4">
      <Flex bg="bg-surface" height="100%" direction="column">
        {steps.length !== 1 ? (
          <>
            <Stack spacing="0" direction={{ base: "column", md: "row" }}>
              {steps.map((step) => (
                <Step
                  key={step.index}
                  title={step.title}
                  isActive={currentStep?.index === step.index}
                  isCompleted={
                    currentStep ? step.index < currentStep.index : false
                  }
                  isFirstStep={step.index === 0}
                  isLastStep={steps.length === step.index + 1}
                />
              ))}
            </Stack>
            <Divider my="4" />
          </>
        ) : null}
        <Box flex="1" p="4">
          {renderCurrentStep(currentStep)}
        </Box>
        {currentStep ? (
          <Box pb="4">
            <Divider my="4" />
            {renderNav()}
          </Box>
        ) : null}
      </Flex>
    </Container>
  );
};
