import { ReactElement } from "react";
import { Enum_Device_Type, DeviceDataFragment } from "data";

interface ShowForDeviceTypesProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
  deviceTypes: Enum_Device_Type[];
}

export const ShowForDeviceTypes = (props: ShowForDeviceTypesProps) => {
  const { device, deviceTypes, children } = props;

  if (!device?.attributes?.type) {
    return null;
  }

  return deviceTypes.indexOf(device?.attributes?.type) !== -1 ? children : null;
};
