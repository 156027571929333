import { Box, Badge, HStack, Stack, Show } from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  DeviceDataFragment,
  OrderItemReplacementDataFragment,
} from "data";
import { PageHeader } from "components/layout/Page";
import { completeDeviceName } from "features/devices/data";
import { OrderItemStatusBadge } from "features/order-items/components/OrderItemStatusBadge";
import { DeviceTypeIcon } from "features/devices/components/DeviceTypeIcon";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderItemParentLink } from "features/order-items/components/OrderItemParentLink";
import { OrderItemReplacementLink } from "features/order-items/components/OrderItemReplacementLink";
import { AdvancedRepairBadge } from "features/order-items/components/AdvancedRepairBadge";

interface OrderItemPageTitleProps {
  orderItem: OrderItemDataFragment;
  replacements: OrderItemReplacementDataFragment[];
  isComplete?: boolean;
}

export const titleForOrderItem = (oi?: OrderItemDataFragment | null) => {
  let t = completeDeviceName(
    oi?.attributes?.device?.data as DeviceDataFragment,
    oi?.attributes?.customer_device?.data?.attributes?.hashrate || undefined
  );
  return `${t}`;
};

export const OrderItemPageTitle = (props: OrderItemPageTitleProps) => {
  const { orderItem, replacements, isComplete } = props;
  return (
    <Stack>
      <Box>
        <OrderItemStatusBadge editable orderItem={orderItem} />
      </Box>
      <HStack>
        <Show above="md">
          <DeviceTypeIcon
            w={8}
            h={8}
            deviceType={orderItem.attributes?.device?.data?.attributes?.type}
          />
        </Show>
        <PageHeader data-cy="order-item-page-title">
          {titleForOrderItem(orderItem)}
        </PageHeader>
        <CustomerDeviceShortIdLabel
          customerDevice={orderItem.attributes?.customer_device?.data}
        />
        <Show above="md">
          <AdvancedRepairBadge orderItem={orderItem} />
          {!isComplete ? <Badge colorScheme="red">Incomplete</Badge> : null}
        </Show>
      </HStack>
      <Show below="sm">
        <Stack>
          <AdvancedRepairBadge orderItem={orderItem} />
          {!isComplete ? <Badge colorScheme="red">Incomplete</Badge> : null}
        </Stack>
      </Show>
      <Box pl={10}>
        <HStack spacing={4}>
          <OrderItemParentLink prefix="Part of: " orderItem={orderItem} />
          {replacements.length !== 0 ? (
            <OrderItemReplacementLink
              orderItemReplacement={replacements[0]}
              orderItem={orderItem}
            />
          ) : null}
        </HStack>
      </Box>
    </Stack>
  );
};
