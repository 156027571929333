import { useContext } from "react";
import { OrderPackageDataFragment, OrderItemDataFragment } from "data";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";

interface PackageOrderItemsActionPanelData {
  orderPackage?: OrderPackageDataFragment | null;
  orderItems: OrderItemDataFragment[];
}

const packageOrderItemsActionPanel =
  generateContextAndProvider<PackageOrderItemsActionPanelData>();

export const usePackageOrderItemsActionPanel =
  (): ActionPanelContextData<PackageOrderItemsActionPanelData> => {
    const context = useContext(packageOrderItemsActionPanel.Context);

    if (!context) {
      throw new Error(
        "Calling usePackageOrderItemsActionPanel outside of PackageOrderItemsActionPanelProvider"
      );
    }

    return context;
  };

export const PackageOrderItemsActionPanelProvider =
  packageOrderItemsActionPanel.Provider;
