import "@fontsource-variable/inter";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";

const appTheme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: {
      25: "#F6F8FE",
      50: "#F0F4FE",
      100: "#D4DFFC",
      200: "#B7CBFB",
      300: "#8CACF9",
      400: "#5F89F7",
      500: "#3D6FF6",
      600: "#2D5DE6",
      700: "#1F4DE2",
      800: "#183FBA",
      900: "#133498"
    } },
  },
  theme
);

export default appTheme;
