import { useState } from "react";
import { DeviceDataFragment } from "data";
import { useDevices } from "features/devices/context/Devices";
import { MinerDetails } from "features/devices/components/MinerDetails";
import type { DeviceLayoutItem } from "features/devices/components/MinerDetails";
import { deviceTemplate } from "features/devices/data";

interface MinerDetailsContainerProps {
  device: DeviceDataFragment;
  onSubmit: ({ devices, hashrate }: { devices: DeviceDataFragment[]; hashrate?: string }) => void;
}

const deviceTemplateWithIdAndSelection = (
  device: DeviceDataFragment,
  devices: DeviceDataFragment[]
): DeviceLayoutItem[] => {
  return deviceTemplate(device, devices).map(({ device }) => ({
    device,
    isSelected: true,
    id: `${Math.floor(Math.random() * 100000)}`,
  }));
};

export const MinerDetailsContainer = (props: MinerDetailsContainerProps) => {
  const { devices: allDevices } = useDevices();
  const { onSubmit, device } = props;
  const [devices, setDevices] = useState<DeviceLayoutItem[]>(
    deviceTemplateWithIdAndSelection(device, allDevices)
  );
  const supportedHashrates = device.attributes?.supportedHashrates
    ? (device.attributes.supportedHashrates as string[])
    : undefined;
  const [hashrate, setHashrate] = useState<string | undefined>(
    supportedHashrates && supportedHashrates[0]
  );

  return (
    <MinerDetails
      onChangeDevices={(selectedDevices) => {
        setDevices(selectedDevices);
      }}
      onChangeHashrate={(selectedHashrate) => {
        setHashrate(selectedHashrate);
      }}
      device={device}
      devices={devices}
      supportedHashrates={supportedHashrates}
      hashrate={hashrate}
      isComplete={devices.reduce((flag, d) => flag && d.isSelected, true)}
      onNext={() => {
        onSubmit({
          devices: devices.filter((d) => d.isSelected).map((d) => d.device),
          hashrate,
        });
      }}
    />
  );
};
