import { AddIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useAddDevicesActionPanel } from "features/orders/context/AddDevicesActionPanel";

interface AddDevicesActionProps {
  variant: ActionComponentVariant;
}

export const AddDevicesAction = (props: AddDevicesActionProps) => {
  const { variant } = props;
  const { showActionPanel } = useAddDevicesActionPanel();

  return (
    <ActionComponent
      action={async () => {
        showActionPanel({});
      }}
      variant={variant}
      actionIcon={<AddIcon />}
      actionLabel="Add Devices"
      data-cy="action-add-devices"
    />
  );
};

AddDevicesAction.defaultProps = {
  variant: "button",
};
