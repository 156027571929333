import intersection from "lodash.intersection";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { DeviceTypeLabel } from "features/devices/components/DeviceTypeLabel";
import {
  Enum_Device_Type,
  Enum_Orderitem_Status,
  OrderDataFragment,
} from "data";
import { _orderItemStatusToString } from "features/order-items/data";

interface OrderItemsFiltersProps {
  order: OrderDataFragment;
  selectedStatus: Enum_Orderitem_Status[] | null;
  selectedDeviceType: Enum_Device_Type | null;
  onChange: ({
    selectedStatus,
    selectedDeviceType,
  }: {
    selectedStatus: Enum_Orderitem_Status[] | null;
    selectedDeviceType: Enum_Device_Type | null;
  }) => void;
}

interface OrderItemStatusFilterMenuListProps {
  order: OrderDataFragment;
  selectedStatus: Enum_Orderitem_Status[] | null;
  onChange: (selectedStatus: Enum_Orderitem_Status[] | null) => void;
}

const statusList = [
  {
    label: "Needs QA",
    filter: [
      Enum_Orderitem_Status.RepairedNeedsQa,
      Enum_Orderitem_Status.DiagnosedIsOk,
    ],
    dataCy: `order-items-filter-needs_qa`,
  },
  {
    label: "Needs Advanced Repair",
    filter: [
      Enum_Orderitem_Status.QaFail,
      Enum_Orderitem_Status.RepairedFailed,
    ],
    dataCy: `order-items-filter-needs_advanced_repair`,
  },
  ...[
    Enum_Orderitem_Status.QaFailRecycled,
    Enum_Orderitem_Status.QaPass,
    Enum_Orderitem_Status.DiagnosedNeedsRepair,
    Enum_Orderitem_Status.DiagnosedUnrepairable,
    Enum_Orderitem_Status.DiagnosisPending,
    Enum_Orderitem_Status.ExcessiveDamageScrapForParts,
    Enum_Orderitem_Status.Packaged,
    Enum_Orderitem_Status.QaFailReturnToCustomer,
    Enum_Orderitem_Status.Receiving,
    Enum_Orderitem_Status.ReplacedRecycled,
    Enum_Orderitem_Status.ReplacedReturnToCustomer,
    Enum_Orderitem_Status.Shipped,
  ].map((status) => ({
    label: _orderItemStatusToString(status),
    filter: [status],
    dataCy: `order-items-filter-${status}`,
  })),
];

const prettyFilterLabel = (filter: Enum_Orderitem_Status[]) => {
  if (filter.indexOf(Enum_Orderitem_Status.DiagnosedIsOk) !== -1) {
    return "Needs QA";
  }

  if (filter.indexOf(Enum_Orderitem_Status.QaFail) !== -1) {
    return "Needs Advanced Repair";
  }
  return _orderItemStatusToString(filter[0]);
};

const OrderItemStatusFilterMenuList = (
  props: OrderItemStatusFilterMenuListProps
) => {
  const { onChange, order, selectedStatus } = props;
  const availableStatusValues = order.attributes?.stats
    ?.filter((s) => s.count !== 0)
    .map((s) => s.status);

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        rightIcon={<ChevronDownIcon />}
        data-cy="order-items-status-filter-menu-button"
      >
        <Text>
          {!selectedStatus ? `Everything` : prettyFilterLabel(selectedStatus)}
        </Text>
      </MenuButton>
      <MenuList h="80" sx={{ overflow: "scroll" }}>
        {statusList.map(({ label, filter, dataCy }) => {
          if (intersection(filter, availableStatusValues).length === 0) {
            return null;
          }

          return (
            <MenuItem
              data-cy={dataCy}
              key={filter.join("_")}
              onClick={() => {
                onChange(filter);
              }}
            >
              <Text>{label}</Text>
            </MenuItem>
          );
        })}
        {selectedStatus !== null ? (
          <MenuItem
            data-cy={`order-items-filter-all-statuses`}
            onClick={() => {
              onChange(null);
            }}
          >
            <Text>Everything</Text>
          </MenuItem>
        ) : null}
      </MenuList>
    </Menu>
  );
};

export const OrderItemsFilters = (props: OrderItemsFiltersProps) => {
  const { selectedStatus, selectedDeviceType, onChange, order } = props;
  return (
    <>
      <HStack>
        <OrderItemStatusFilterMenuList
          order={order}
          onChange={(status) => {
            onChange({ selectedStatus: status, selectedDeviceType });
          }}
          selectedStatus={selectedStatus}
        />

        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<ChevronDownIcon />}
            data-cy="order-items-device-type-filter-menu-button"
          >
            {selectedDeviceType ? (
              <DeviceTypeLabel showTypeIcon deviceType={selectedDeviceType} />
            ) : (
              <Text>All Device Types</Text>
            )}
          </MenuButton>
          <MenuList>
            {[
              Enum_Device_Type.Miner,
              Enum_Device_Type.HashBoard,
              Enum_Device_Type.ControlBoard,
              Enum_Device_Type.Psu,
            ].map((deviceType) => (
              <MenuItem
                data-cy={`order-items-filter-${deviceType}`}
                onClick={() => {
                  onChange({
                    selectedStatus: selectedStatus,
                    selectedDeviceType: deviceType,
                  });
                }}
              >
                <DeviceTypeLabel showTypeIcon deviceType={deviceType} />
              </MenuItem>
            ))}
            {selectedDeviceType !== null ? (
              <MenuItem
                data-cy={`order-items-filter-all-device-types`}
                onClick={() => {
                  onChange({
                    selectedStatus: selectedStatus,
                    selectedDeviceType: null,
                  });
                }}
              >
                Show All Devices
              </MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
};
