import { useContext, ReactNode } from "react";
import { Stack } from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
  OrderDataFragment,
} from "data";
import { useLabelPrinter } from "context/LabelPrinter";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";
import { MinerDetailsContainer as MinerDetails } from "features/devices/containers/MinerDetails";
import { HashboardDetails } from "features/devices/components/HashboardDetails";
import { CustomerDeviceLabel } from "features/customer-devices/components/CustomerDeviceLabel";
import {
  ReceiveDeviceProvider,
  useReceiveDeviceContext,
} from "features/customer-devices/context/ReceiveDevice";
import { FindOrAddCustomerDevice } from "features/customer-devices/containers/FindOrAddCustomerDevice";
import { QuickInspect } from "features/diagnostics/components/hashboards/QuickInspect";

interface ReceiveDeviceTarget {
  orderItem?: OrderItemDataFragment;
  orderItemSubcomponent?: OrderItemSubcomponentDataFragment;
}

interface ReceiveDevicePanelData {
  target: ReceiveDeviceTarget;
}

const receiveDeviceActionPanel =
  generateContextAndProvider<ReceiveDevicePanelData>();

export const useReceivDeviceActionPanel =
  (): ActionPanelContextData<ReceiveDevicePanelData> => {
    const context = useContext(receiveDeviceActionPanel.Context);

    if (!context) {
      throw new Error(
        "Calling useReceivDeviceActionPanel outside of ReceiveDevicePanelProvider"
      );
    }

    return context;
  };

export const WrappedReceiveDevicePanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    getDevice,
    selectedDevice,
    receiveDeviceStage,
    setMinerDetails,
    setHashoardDetails,
    receiveDevice,
    canReceiveDevice,
    clear,
    quickInspectionReport,
    setQuickInspectionReport,
  } = useReceiveDeviceContext();
  const { printableRef, print } = useLabelPrinter();

  return (
    <receiveDeviceActionPanel.Provider
      onClose={() => {
        clear();
      }}
      enablePrimaryAction={canReceiveDevice}
      primaryButtonText="Receive device"
      onAction={async ({ data }) => {
        await receiveDevice();
        // XX: currently no way to test printing correctly
        // due to how react-to-print uses an iframe
        // @ts-ignore
        if (typeof window.Cypress === "undefined") {
          print();
        }
      }}
      renderHeader={({ data }) => null}
      renderBody={({ data }) => {
        const device = getDevice();
        return !device ? null : (
          <>
            {/*
                Step 1: render search UI to find/add customer device
            */}
            {receiveDeviceStage === "find" ? <FindOrAddCustomerDevice /> : null}
            {/*
                Step 2 (miners only): show miner details form
            */}
            {receiveDeviceStage === "miner-details" && device ? (
              <MinerDetails
                onSubmit={({ devices, hashrate }) => {
                  setMinerDetails({ subcomponentDevices: devices, hashrate });
                }}
                device={device}
              />
            ) : null}
            {/* 
                Step 2 (hashboards only): show hashboard details form + quick inspect
            */}
            {receiveDeviceStage === "hashboard-details" && device ? (
              <Stack spacing={5}>
                <QuickInspect
                  report={quickInspectionReport}
                  onChange={(r) => {
                    setQuickInspectionReport(r);
                  }}
                />
                <HashboardDetails
                  onSubmit={({ hashboardMetadata }) => {
                    setHashoardDetails({ hashboardMetadata });
                  }}
                  device={device}
                />
              </Stack>
            ) : null}
            {/*
                Step 3: show pintable device label
            */}
            {receiveDeviceStage === "display" && selectedDevice ? (
              <CustomerDeviceLabel
                printableRef={printableRef}
                device={selectedDevice}
                w="40"
                h="40"
              />
            ) : null}
          </>
        );
      }}
    >
      {children}
    </receiveDeviceActionPanel.Provider>
  );
};

export const ReceiveDevicePanelProvider = ({
  children,
  order,
}: {
  children: ReactNode;
  order: OrderDataFragment;
}) => {
  return (
    <ReceiveDeviceProvider order={order}>
      <WrappedReceiveDevicePanelProvider>
        {children}
      </WrappedReceiveDevicePanelProvider>
    </ReceiveDeviceProvider>
  );
};
