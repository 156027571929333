import { useState } from "react";
import {
  Button,
  Flex,
  Text,
  Spacer,
  Icon,
  Radio,
  RadioGroup,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { TiTimes } from "react-icons/ti";
import { Enum_Device_Type, OrderItemDataFragment } from "data";
import {
  TableContainer,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
} from "components/utils/Table";
import { ActionButton } from "components/utils/ActionButton";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import {
  HashboardDiagnosticsReport,
  MinerDiagnosticsReport,
  PSUDiagnosticsReport,
} from "features/diagnostics/data";
import { useCreateOrUpdateDiagnosticsReport } from "features/diagnostics/data/hooks/diagnostics";
import { SuggestedProblems } from "features/diagnostics/components/miners/SuggestedProblems";

interface CheckOrCrossProps {
  reportKey: keyof MinerDiagnosticsReport;
}

const CheckOrCross = (props: CheckOrCrossProps) => {
  const { reportKey } = props;
  const { data } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;

  const checkOfCross = (
    reportKey: keyof MinerDiagnosticsReport,
    minerDiagnosticsReport: MinerDiagnosticsReport
  ): "check" | "cross" => {
    switch (reportKey) {
      case "power":
        return minerDiagnosticsReport.power ? "check" : "cross";
      case "ipAddress":
        return minerDiagnosticsReport.ipAddress ? "check" : "cross";
      case "minerFans":
        return minerDiagnosticsReport.minerFans.filter((mf) => !mf.isOn)
          .length === 0
          ? "check"
          : "cross";
      case "psuFans":
        return minerDiagnosticsReport.psuFans.filter((pf) => !pf.isOn)
          .length === 0
          ? "check"
          : "cross";
      case "hashboards":
        return minerDiagnosticsReport.hashboards.filter(
          (hashboard) => !hashboard.isGood
        ).length === 0
          ? "check"
          : "cross";
      case "errorCodes":
        return minerDiagnosticsReport.errorCodes.length === 0
          ? "check"
          : "cross";
      default:
        return "check";
    }
  };
  return (
    <Icon
      as={
        checkOfCross(reportKey, minerDiagnosticsReport) === "check"
          ? MdCheckCircle
          : TiTimes
      }
    />
  );
};

const HashboardReportStats = (props: {
  hashboardDiagnosticsReport: HashboardDiagnosticsReport;
}) => {
  const {
    temperatureSensors,
    openTraces,
    initialASICsFound,
    asicsRepairsNeeded,
    boostCircuitRepairsNeeded,
    otherComponentsNeeded,
    notes,
  } = props.hashboardDiagnosticsReport;
  return (
    <>
      {typeof temperatureSensors !== "undefined" ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Temperature Sensors</Text>
          </Td>
          <Td>{`${temperatureSensors.filter((ts) => ts.isOn).length} / ${
            temperatureSensors.length
          }`}</Td>
          <Td>
            <Icon
              as={
                temperatureSensors.filter((ts) => !ts.isOn).length === 0
                  ? MdCheckCircle
                  : TiTimes
              }
            />
          </Td>
        </Tr>
      ) : null}
      <Tr>
        <Td>
          <Text fontWeight="bold">Open Traces</Text>
        </Td>
        <Td>{openTraces ? "Yes" : "No"}</Td>
        <Td />
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">ASICs Found</Text>
        </Td>
        <Td>{initialASICsFound}</Td>
        <Td />
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">ASICs Repairs Needed</Text>
        </Td>
        <Td>{asicsRepairsNeeded ? "Yes" : "No"}</Td>
        <Td>
          <Icon as={!asicsRepairsNeeded ? MdCheckCircle : TiTimes} />
        </Td>
      </Tr>
      {typeof boostCircuitRepairsNeeded !== "undefined" ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Boost Circuit Repairs Needed</Text>
          </Td>
          <Td>{boostCircuitRepairsNeeded ? "Yes" : "No"}</Td>
          <Td>
            <Icon as={!boostCircuitRepairsNeeded ? MdCheckCircle : TiTimes} />
          </Td>
        </Tr>
      ) : null}
      {otherComponentsNeeded.length !== 0 ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Other Components Needed</Text>
          </Td>
          <Td>{otherComponentsNeeded.join(", ")}</Td>
          <Td>
            <Icon as={TiTimes} />
          </Td>
        </Tr>
      ) : null}
      {notes ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Notes</Text>
          </Td>
          <Td>{notes}</Td>
          <Td />
        </Tr>
      ) : null}
    </>
  );
};

const PSUReportStats = (props: {
  psuDiagnosticsReport: PSUDiagnosticsReport;
}) => {
  const {
    inputFusesOk,
    inrushCurrentLimitersOk,
    mosfetsOk,
    powerDiodesOk,
    u25Ok,
  } = props.psuDiagnosticsReport;

  return (
    <>
      {[
        { label: "Are both input fuses OK?", isTrue: inputFusesOk },
        {
          label: "Are 4 inrush current limiters OK?",
          isTrue: inrushCurrentLimitersOk,
        },
        { label: "Are 6 MOSFETS OK?", isTrue: mosfetsOk },
        { label: "Are 4 power diodes OK?", isTrue: powerDiodesOk },
        { label: "Is U25 OK?", isTrue: u25Ok },
      ].map(({ label, isTrue }) => (
        <Tr key={label}>
          <Td>
            <Text fontWeight="bold">{label}</Text>
          </Td>
          <Td>{isTrue ? "Yes" : "No"}</Td>
          <Td />
        </Tr>
      ))}
    </>
  );
};

const MinerReportStats = (props: {
  minerDiagnosticsReport: MinerDiagnosticsReport;
}) => {
  const {
    power,
    ipAddress,
    noIPAddress,
    minerFans,
    psuFans,
    hashboards,
    errorCodes,
    finalHashrate,
    timeHashing,
    notes,
    controlBoardTemperature,
    attachments,
  } = props.minerDiagnosticsReport;

  if (!power) {
    return (
      <Tr>
        <Td>
          <Text fontWeight="bold">Power</Text>
        </Td>
        <Td>{power ? "ON" : "OFF"}</Td>
        <Td>
          <CheckOrCross reportKey="power" />
        </Td>
      </Tr>
    );
  } else {
    if (!ipAddress) {
      return (
        <>
          <Tr>
            <Td>
              <Text fontWeight="bold">Power</Text>
            </Td>
            <Td>{power ? "ON" : "OFF"}</Td>
            <Td>
              <CheckOrCross reportKey="power" />
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontWeight="bold">IP</Text>
            </Td>
            <Td>{ipAddress || "No connection"}</Td>
            <Td>
              <CheckOrCross reportKey="ipAddress" />
            </Td>
          </Tr>
        </>
      );
    }
  }

  return (
    <>
      <Tr>
        <Td>
          <Text fontWeight="bold">Power</Text>
        </Td>
        <Td>{power ? "ON" : "OFF"}</Td>
        <Td>
          <CheckOrCross reportKey="power" />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">IP</Text>
        </Td>
        <Td>{noIPAddress ? "No connection" : ipAddress}</Td>
        <Td>
          <CheckOrCross reportKey="ipAddress" />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">Miner Fans</Text>
        </Td>
        <Td>{`${minerFans.filter((mf) => mf.isOn).length} / ${
          minerFans.length
        }`}</Td>
        <Td>
          <CheckOrCross reportKey="minerFans" />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">PSU Fans</Text>
        </Td>
        <Td>{`${psuFans.filter((pf) => pf.isOn).length} / ${
          psuFans.length
        }`}</Td>
        <Td>
          <CheckOrCross reportKey="psuFans" />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">Hashboards</Text>
        </Td>
        <Td>
          {`${hashboards.filter((hashboard) => hashboard.isGood).length} / ${
            hashboards.length
          }`}{" "}
          OK
        </Td>
        <Td>
          <CheckOrCross reportKey="hashboards" />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <Text fontWeight="bold">Error Codes</Text>
        </Td>
        <Td>{errorCodes.join(", ")}</Td>
        <Td>
          <CheckOrCross reportKey="errorCodes" />
        </Td>
      </Tr>
      {finalHashrate ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Final Hashrate</Text>
          </Td>
          <Td colSpan={2}>{`${finalHashrate}TH`}</Td>
        </Tr>
      ) : null}
      {timeHashing ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Time Hashing</Text>
          </Td>
          <Td colSpan={2}>{`${timeHashing} minutes`}</Td>
        </Tr>
      ) : null}
      {controlBoardTemperature ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Control Board Temperature</Text>
          </Td>
          <Td colSpan={2}>{controlBoardTemperature}</Td>
        </Tr>
      ) : null}
      <Tr>
        <Td>
          <Text fontWeight="bold">Notes</Text>
        </Td>
        <Td colSpan={2}>{notes}</Td>
      </Tr>
      {attachments.length !== 0 ? (
        <Tr>
          <Td>
            <Text fontWeight="bold">Attachments</Text>
          </Td>
          <Td colSpan={2}>
            {attachments.map((a) => a.attributes?.name).join(", ")}
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

interface DiagnosticsReportSummaryProps {
  onPrevious: () => void;
  onAfterSubmitInspection: () => void;
  orderItem: OrderItemDataFragment;
}

export const DiagnosticsReportSummary = (
  props: DiagnosticsReportSummaryProps
) => {
  const { onPrevious, onAfterSubmitInspection, orderItem } = props;
  const [isDeviceOk, setIsDeviceOk] = useState<boolean>(true);
  const { data: diagnosticsReport } = useDiagnosticsWizard();
  const { createOrUpdateDiagnosticsReport } =
    useCreateOrUpdateDiagnosticsReport();

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Th colSpan={3}>Report Summary</Th>
          </Thead>
          <Tbody>
            {diagnosticsReport.reportType === "miner" ? (
              <MinerReportStats
                minerDiagnosticsReport={
                  diagnosticsReport as MinerDiagnosticsReport
                }
              />
            ) : null}
            {diagnosticsReport.reportType === "hashboard" ? (
              <HashboardReportStats
                hashboardDiagnosticsReport={
                  diagnosticsReport as HashboardDiagnosticsReport
                }
              />
            ) : null}
            {diagnosticsReport.reportType === "psu" ? (
              <PSUReportStats
                psuDiagnosticsReport={diagnosticsReport as PSUDiagnosticsReport}
              />
            ) : null}
            <Tr>
              <Td>
                <Text fontSize="larger" fontWeight="bold">
                  Inspection Conclusion
                </Text>
              </Td>
              <Td colSpan={2}>
                <RadioGroup
                  onChange={(value) => {
                    setIsDeviceOk(value === "1" ? true : false);
                  }}
                  value={isDeviceOk ? "1" : "0"}
                >
                  <HStack spacing="8">
                    <Radio data-cy="diagnostics-radio-ok" value="1">
                      OK
                    </Radio>
                    <Radio data-cy="diagnostics-radio-not-ok" value="0">
                      NOT OK
                    </Radio>
                  </HStack>
                </RadioGroup>

                <ShowForMiner device={orderItem.attributes?.device?.data}>
                  <>
                    {!isDeviceOk ? (
                      <Stack my="8" spacing="4">
                        <Text>What do you suspect is bad?</Text>
                        <SuggestedProblems />
                      </Stack>
                    ) : null}
                  </>
                </ShowForMiner>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Flex my="8" px="4" minWidth="max-content" alignItems="center" gap="2">
        <Button
          onClick={() => {
            onPrevious();
          }}
        >
          Previous
        </Button>
        <Spacer />
        <ActionButton
          action={async () => {
            await createOrUpdateDiagnosticsReport({
              report: diagnosticsReport,
              orderItem,
              isDeviceOk,
            });

            onAfterSubmitInspection();
          }}
          colorScheme="blue"
        >
          {orderItem.attributes?.device?.data?.attributes?.type ===
          Enum_Device_Type.Miner
            ? "Submit Inspection Report"
            : "Submit Diagnostics Report"}
        </ActionButton>
      </Flex>
    </>
  );
};
