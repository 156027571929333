import { useState } from "react";
import { OrderItemDataFragment, PsuRepairInput } from "data";
import { PSURepairDetailsForm } from "features/repairs/components/psu/PSURepairDetailsForm";
import { PSURepairSummaryForm } from "features/repairs/components/psu/RepairSummaryForm";
import { useRegisterPSURepair } from "features/repairs/data/hooks/repairs";

type RepairStage = "details" | "summary";

interface PSURepairProps {
  onComplete: () => void;
  orderItem: OrderItemDataFragment;
}

export const PSURepair = (props: PSURepairProps) => {
  const { orderItem, onComplete } = props;
  const [repairStage, setRepairStage] = useState<RepairStage>("details");
  const [report, setReport] = useState<PsuRepairInput | null>(null);
  const { registerRepair } = useRegisterPSURepair();

  if (repairStage === "details") {
    return (
      <PSURepairDetailsForm
        report={report}
        orderItem={orderItem}
        onSubmit={(repair) => {
          setReport(repair);
          setRepairStage("summary");
        }}
      />
    );
  }

  return (
    report && (
      <PSURepairSummaryForm
        orderItem={orderItem}
        report={report}
        onPrevious={() => {
          setRepairStage("details");
        }}
        onSubmit={async (r) => {
          await registerRepair({
            orderItem,
            repair: Object.assign(report, r, { order_item: orderItem.id }),
          });
          onComplete();
        }}
      />
    )
  );
};

/*
"quantityInputFusesReplaced": {
  "type": "integer"
},
"quantityInrushCurrentLimitersReplaced": {
  "type": "integer"
},
"quantityMOSFETsReplaced": {
  "type": "integer"
},
"quantityPowerDiodesReplaced": {
  "type": "integer"
},
"quantityBridgesReplaced": {
  "type": "integer"
},
"quantityRelaysReplaced": {
  "type": "integer"
},
"quantityCapacitorsReplaced": {
  "type": "integer"
},
"quantityICsReplaced": {
  "type": "integer"
},
"d25Replaced": {
  "type": "boolean"
},
"u25Replaced": {
  "type": "boolean"
},
"order_item": {
  "type": "relation",
  "relation": "oneToOne",
  "target": "api::order-item.order-item"
},
"isSuccessful": {
  "type": "boolean"
},
"repairTimeMinutes": {
  "type": "integer"
}
*/
