import { FormControl, Select as ChakraSelect } from "@chakra-ui/react";
import { useField, FieldAttributes } from "formik";
import { Label } from "./Label";
// import { ErrorMessage } from "./ErrorMessage";

export const Select = ({
  label,
  type,
  placeholder,
  children,
  ...props
}: FieldAttributes<any>) => {
  const [field, , helpers] = useField(props);
  return (
    <FormControl>
      <Label>{label}</Label>
      <ChakraSelect
        data-cy={`${field.name}_select`}
        mt={4}
        value={field.value}
        onChange={(e) => {
          helpers.setValue(e.currentTarget.value);
        }}
        placeholder={placeholder}
      >
        {children}
      </ChakraSelect>
    </FormControl>
  );
};

/* {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null} */
