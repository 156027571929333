import { useZohoSalesOrdersQuery, ZohoSalesOrderDataFragment } from "data";

interface UseZohoSalesOrdersHookData {
  loading: boolean;
  zohoSalesOrders: ZohoSalesOrderDataFragment[];
}

export const useZohoSalesOrders = (): UseZohoSalesOrdersHookData => {
  const { loading, data } = useZohoSalesOrdersQuery({
    fetchPolicy: "network-only",
  });
  return {
    loading,
    zohoSalesOrders: data?.zohoSalesOrders?.data || [],
  };
};
