import { useNavigate } from "react-router-dom";
import { ForgotPasswordForm } from "features/accounts/components/ForgotPasswordForm";
import { useAuthentication } from "features/accounts/context/Authentication";

export const ForgotPasswordFormContainer = () => {
  const { forgotPassword } = useAuthentication();
  const navigate = useNavigate();
  return (
    <ForgotPasswordForm
      onForgotPassword={async (data) => {
        await forgotPassword(data.email);
        navigate(`/account/password-reset-requested?email=${data.email}`);
      }}
    />
  );
};
