import {
  OrderItemDataFragment,
  CustomerDeviceDataFragment,
  OrderItemSubcomponentDataFragment,
  useCreateOrderItemReplacementMutation,
  Enum_Orderitemreplacement_Status,
  Enum_Device_Type,
  OrderItemSummaryDocument,
  OrderItemSummaryQuery,
  OrderItemSummaryQueryVariables,
} from "data";
import { useWrapWithNotifications } from "context/Notifications";
import {
  useCreateAction,
  expandActionDataPiece,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface ReplaceOrderItemProps {
  originalOrderItem: OrderItemDataFragment;
  orderItemSubcomponent: OrderItemSubcomponentDataFragment;
  replacementCustomerDevice: CustomerDeviceDataFragment;
  replacementStatus: Enum_Orderitemreplacement_Status;
}

interface UseReplaceOrderItemHookData {
  replaceOrderItem(props: ReplaceOrderItemProps): Promise<void | null>;
}

export const useReplaceOrderItem = (): UseReplaceOrderItemHookData => {
  const { createAction } = useCreateAction();
  const [createOrderItemReplacement] = useCreateOrderItemReplacementMutation();

  const replaceOrderItem = useWrapWithNotifications<
    ReplaceOrderItemProps,
    void
  >({
    successMessage: "Item replaced!",
    runner: async ({
      originalOrderItem,
      orderItemSubcomponent,
      replacementCustomerDevice,
      replacementStatus,
    }) => {
      if (
        !originalOrderItem.attributes?.device?.data?.attributes?.type ||
        [Enum_Device_Type.Psu, Enum_Device_Type.ControlBoard].indexOf(
          originalOrderItem.attributes?.device?.data?.attributes?.type
        ) === -1
      ) {
        throw new Error("Replacements not support for this device type");
      }

      if (
        !orderItemSubcomponent.attributes?.order_item?.data ||
        orderItemSubcomponent.attributes?.order_item?.data.id !==
          originalOrderItem.id
      ) {
        throw new Error("Order item and subcomponent mismatch");
      }

      // register replacement

      const { data: replacementData } = await createOrderItemReplacement({
        variables: {
          data: {
            original_order_item: originalOrderItem.id,
            parent: originalOrderItem.attributes.parent?.data?.id,
            replacement_customer_device: replacementCustomerDevice.id,
            order_item_subcomponent: orderItemSubcomponent.id,
            status: replacementStatus,
          },
        },
        update(cache, { data }) {
          if (!originalOrderItem.attributes?.parent?.data?.id) {
            return;
          }

          const orderItemSummaryQuerySpec = {
            query: OrderItemSummaryDocument,
            variables: {
              id: originalOrderItem.attributes?.parent?.data?.id,
            },
          };

          const orderItemSummary = cache.readQuery<
            OrderItemSummaryQuery,
            OrderItemSummaryQueryVariables
          >(orderItemSummaryQuerySpec);

          // update order item summary cache to consolidate replacement
          // - add newly created order replacement to the list of order replacements
          // - add newly created order item to the list of subcomponent order items
          const orderItemReplacement = data?.createOrderItemReplacement?.data;
          const replacementOrderItem =
            data?.createOrderItemReplacement?.data?.attributes
              ?.replacement_order_item?.data;

          if (orderItemSummary?.orderItemReplacements) {
            cache.writeQuery<
              OrderItemSummaryQuery,
              OrderItemSummaryQueryVariables
            >(
              Object.assign({}, orderItemSummaryQuerySpec, {
                data: Object.assign({}, orderItemSummary, {
                  orderItemReplacements: Object.assign(
                    {},
                    orderItemSummary.orderItemReplacements,
                    {
                      data: [
                        ...(orderItemSummary.orderItemReplacements?.data || []),
                        orderItemReplacement,
                      ],
                    }
                  ),
                }),
              })
            );
          }

          if (orderItemSummary?.subcomponentOrderItems) {
            cache.writeQuery<
              OrderItemSummaryQuery,
              OrderItemSummaryQueryVariables
            >(
              Object.assign({}, orderItemSummaryQuerySpec, {
                data: Object.assign({}, orderItemSummary, {
                  subcomponentOrderItems: Object.assign(
                    {},
                    orderItemSummary.subcomponentOrderItems,
                    {
                      data: [
                        ...(orderItemSummary.subcomponentOrderItems?.data ||
                          []),
                        replacementOrderItem,
                      ],
                    }
                  ),
                }),
              })
            );
          }
        },
      });

      await createAction({
        type: Enum_Action_Type.ReplaceOrderItem,
        scope: Enum_Action_Scope.OrderItem,
        data: {
          ...expandActionDataPiece(originalOrderItem),
          order_item_replacement:
            replacementData?.createOrderItemReplacement?.data?.id,
        },
      });
    },
  });
  return {
    replaceOrderItem,
  };
};
