import { Select } from "@chakra-ui/react";
import { useCurrentCustomer } from "features/customers/data/hooks/customers";

export const CustomerSelector = () => {
  const { availableCustomers, customer, setCurrentCustomer } =
    useCurrentCustomer();

  if (availableCustomers.length <= 1) {
    return null;
  }

  return (
    <Select
      onChange={(e) => {
        setCurrentCustomer(e.target.value);
        window.location.reload();
      }}
      value={customer.id!}
    >
      {availableCustomers.map((customer) => (
        <option key={customer.id} value={customer.id!}>
          {customer.attributes?.accountName}
        </option>
      ))}
    </Select>
  );
};
