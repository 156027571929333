import { Button } from "@chakra-ui/react";
import { OrdersListProvider } from "features/orders/context/ListOrdersForCustomer";
import { Page } from "components/layout/Page";
import { OrdersTableForCustomer } from "features/orders/components/OrdersTable";
import {
  CustomerIntakeFormPanelProvider,
  useCustomerInakeFormActionPanel,
} from "features/customers/context/CustomerIntakeFormPanel";
import { CustomerSelector } from "features/customers/components/CustomerSelector";
import { useCurrentCustomer } from "features/customers/data/hooks/customers";

interface ConnectedOrdersPageProps {
  loading: boolean;
}

const ConnectedOrdersPage = (props: ConnectedOrdersPageProps) => {
  const { showActionPanel } = useCustomerInakeFormActionPanel();
  const { loading } = props;
  return (
    <Page
      subTitle={<CustomerSelector />}
      actionButton={
        <Button
          variant="primary"
          onClick={() => {
            showActionPanel({});
          }}
        >
          Create order
        </Button>
      }
      loading={loading}
      title="Your Orders"
    >
      {loading ? null : <OrdersTableForCustomer />}
    </Page>
  );
};

export const OrdersPage = () => {
  const { customer } = useCurrentCustomer();

  return (
    <OrdersListProvider customer={customer?.id || ""}>
      <CustomerIntakeFormPanelProvider>
        <ConnectedOrdersPage loading={false} />
      </CustomerIntakeFormPanelProvider>
    </OrdersListProvider>
  );
};
