import debounce from "lodash.debounce";
import { useState, useMemo, useEffect } from "react";
import { Icon, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

interface SearchBoxProps {
  autoFocus?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBeforeChange?: (value: string) => string;
}

interface DebouncedSearchBoxProps {
  autoFocus?: boolean;
  timeout: number;
  placeholder?: string;
  onChange: (value: string) => void;
  onBeforeChange?: (value: string) => string;
}

export const SearchBox = (props: SearchBoxProps) => {
  const { value, onChange, onBeforeChange, placeholder, autoFocus } = props;
  return (
    <InputGroup width="full">
      <InputLeftElement pointerEvents="none">
        <Icon as={FiSearch} color="muted" boxSize="5" />
      </InputLeftElement>
      <Input
        autoFocus={autoFocus}
        value={value}
        onChange={(e) => {
          onChange(
            onBeforeChange ? onBeforeChange(e.target.value) : e.target.value
          );
        }}
        placeholder={placeholder || "Search"}
      />
    </InputGroup>
  );
};

export const DebouncedSearchBox = (props: DebouncedSearchBoxProps) => {
  const { placeholder, timeout, onChange, onBeforeChange, autoFocus } = props;
  const [value, setValue] = useState<string>("");

  const debouncedChangeHandler = useMemo(() => debounce(onChange, timeout), []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <SearchBox
      autoFocus={autoFocus}
      onBeforeChange={onBeforeChange}
      onChange={(v) => {
        setValue(v);
        debouncedChangeHandler(v);
      }}
      value={value}
      placeholder={placeholder}
    />
  );
};
