import { HashboardAdvancedInspectionReportInput } from "data";

export const normalizePowerData = (
  report: HashboardAdvancedInspectionReportInput
): HashboardAdvancedInspectionReportInput => {
  const flow = [
    {
      key: "powerMOSFETsOk",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.powerMOSFETsOk === true,
    },
    {
      key: "powerBoostCircuitVoltageOk",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.powerBoostCircuitVoltageOk === true,
    },
    {
      key: "powerGotCorrosion",
      test: () => true,
    },
  ];

  let unset = false;

  flow.forEach(({ key, test }) => {
    if (unset) {
      // @ts-ignore
      report[key] = undefined;
    } else {
      unset = !test(report);
    }
  });

  return report;
};

export const normalizeCommunicationData = (
  report: HashboardAdvancedInspectionReportInput
): HashboardAdvancedInspectionReportInput => {
  const flow = [
    {
      key: "communicationIsHashboardDetected",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => true,
    },
    {
      key: "communicationEEPROMOk",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.communicationEEPROMOk === true,
    },
    {
      key: "communicationPICOk",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.communicationPICOk === true,
    },
    {
      key: "communicationGotCorrosion",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.communicationGotCorrosion === true,
    },
  ];

  let unset = false;

  flow.forEach(({ key, test }) => {
    if (unset) {
      // @ts-ignore
      report[key] = undefined;
    } else {
      unset = !test(report);
    }
  });

  return report;
};

export const normalizeASICData = (
  report: HashboardAdvancedInspectionReportInput
): HashboardAdvancedInspectionReportInput => {
  const flow = [
    {
      key: "asicAllDetected",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => {
        return report.asicAllDetected === false;
      },
    },
    {
      key: "asicNumberASICsDetected",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => {
        return true;
      },
    },
    {
      key: "asicLDOsOk",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicLDOsOk === true,
    },
    {
      key: "asicIsRXChainComplete",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => {
        if (
          report.asicIsRXChainComplete === true &&
          report.asicNumberASICsDetected === 0
        ) {
          return false;
        }

        return report.asicIsRXChainComplete === true;
      },
    },
    {
      key: "asicIsTXChainComplete",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicIsTXChainComplete === true,
    },
    {
      key: "asicIsRSTChainComplete",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicIsRSTChainComplete === true,
    },
    {
      key: "asicIsBI0V",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicIsBI0V === true,
    },
    {
      key: "asicIsCLKChainComplete",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicIsCLKChainComplete === true,
    },
    {
      key: "asicGotCorrosion",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.asicGotCorrosion === true,
    },
  ];

  let unset = false;

  flow.forEach(({ key, test }) => {
    if (unset) {
      // @ts-ignore
      report[key] = undefined;
    } else {
      unset = !test(report);
    }
  });

  return report;
};

export const normalizeTemperatureData = (
  report: HashboardAdvancedInspectionReportInput
): HashboardAdvancedInspectionReportInput => {
  const flow = [
    {
      key: "temperatureAllTempSensorsDetected",
      test: (report: HashboardAdvancedInspectionReportInput): boolean =>
        report.temperatureAllTempSensorsDetected === false,
    },
    {
      key: "temperatureBadTempSensors",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => true,
    },
    {
      key: "temperatureGotBrokenTraces",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => true,
    },
    {
      key: "temperatureGotCorrosion",
      test: (report: HashboardAdvancedInspectionReportInput): boolean => true,
    },
  ];

  let unset = false;

  flow.forEach(({ key, test }) => {
    if (unset) {
      // @ts-ignore
      report[key] = undefined;
    } else {
      unset = !test(report);
    }
  });

  return report;
};
