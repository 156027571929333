import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { Stack, Button, Text, Divider } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useNotifications } from "context/Notifications";
import { TextInput } from "components/forms/fields";
import {
  useAuthentication,
  UserAlreadyExistsError,
} from "features/accounts/context/Authentication";

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const RegistrationForm = () => {
  const [showLoginLink, setShowLoginLink] = useState<boolean>(false);
  const { showErrorMessage } = useNotifications();
  const navigate = useNavigate();
  const { register } = useAuthentication();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={RegistrationSchema}
      onSubmit={async (values) => {
        try {
          await register({
            email: values.email,
            password: values.password,
            role: "customer",
          });
          navigate(
            `/account/confirm-email?email=${encodeURIComponent(values.email)}`
          );
        } catch (registrationError: any) {
          if (registrationError.message === UserAlreadyExistsError) {
            setShowLoginLink(true);
            showErrorMessage("This email is already in use. Please log in");
          } else {
            showErrorMessage("Something went wrong. Please try again later");
          }
        }
      }}
    >
      {({ errors, touched, isSubmitting, isValid }) => (
        <Form>
          <Stack spacing={2}>
            <TextInput
              label="Email Address"
              placeholder="What is your email address?"
              name="email"
              type="text"
            />
            <TextInput
              label="Password"
              placeholder="Pick a secure password"
              name="password"
              type="password"
            />
            <TextInput
              label="Confirm Password"
              placeholder="Pick a secure password"
              name="passwordConfirmation"
              type="password"
            />
          </Stack>
          <Stack mt={10}>
            <Button
              isDisabled={!isValid || isSubmitting}
              variant="primary"
              type="submit"
            >
              Register
            </Button>
            {showLoginLink ? (
              <Stack mt={10}>
                <Divider />
                <Text fontWeight="bold" pt={10}>
                  Already have an account?
                </Text>
                <Link to={"/account/login"}>
                  <Button
                    data-cy="login-link"
                    variant="link"
                    colorScheme="blue"
                    size="sm"
                  >
                    Log in
                  </Button>
                </Link>
              </Stack>
            ) : null}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
