import { Text, Stack, HStack } from "@chakra-ui/react";
import { useField, Field, FieldAttributes } from "formik";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

interface CheckboxesProps extends FieldAttributes<any> {
  supportedValues: string[];
}

export const Checkboxes = ({ supportedValues, ...props }: CheckboxesProps) => {
  const [, meta] = useField(props as FieldAttributes<any>);
  return (
    <Stack spacing={4}>
      <Label>{props.label}</Label>
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
      <Stack>
        {supportedValues.map((v) => (
          <label key={`${props.name}-${v}`}>
            <HStack className="relative flex items-start py-4">
              <Field type="checkbox" name={props.name} value={v} />
              <Text>{v}</Text>
            </HStack>
          </label>
        ))}
      </Stack>
    </Stack>
  );
};
