import { Badge } from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";

export const AdvancedRepairBadge = ({
  orderItem,
}: {
  orderItem: OrderItemDataFragment;
}) => {
  return orderItem.attributes?.advanced_repairs?.data.length !== 0 ? (
    <Badge data-cy="advanced-repair-badge" colorScheme="red">
      Advanced Repair
    </Badge>
  ) : null;
};
