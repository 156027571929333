import { HStack, Avatar, Text } from "@chakra-ui/react";
import { UserProfileDataFragment } from "data";

interface UserProfileLabelProps {
  userProfile?: UserProfileDataFragment | null;
}

export const UserProfileLabel = (props: UserProfileLabelProps) => {
  const { userProfile } = props;

  if (!userProfile) {
    return null;
  }

  const imageUrl =
    userProfile.attributes?.images?.data.length !== 0
      ? userProfile.attributes?.images?.data[0].attributes?.url
      : undefined;
  return userProfile ? (
    <HStack>
      <Avatar boxSize="6" name={userProfile.attributes?.name} src={imageUrl} />
      <Text>{userProfile.attributes?.name}</Text>
    </HStack>
  ) : null;
};
