import {
  useOrderItemsForCustomerDeviceQuery,
  CustomerDeviceDataFragment,
  OrderItemDataFragment,
} from "data";

interface CustomerDeviceHistory {
  orderItems: OrderItemDataFragment[];
}

interface UseCustomerDeviceHistoryHookData {
  loading: boolean;
  history?: CustomerDeviceHistory;
}

export const useCustomerDeviceHistory = ({
  customerDevice,
}: {
  customerDevice: CustomerDeviceDataFragment;
}): UseCustomerDeviceHistoryHookData => {
  const { loading, data } = useOrderItemsForCustomerDeviceQuery({
    variables: {
      customerDeviceId: customerDevice.id!,
    },
  });

  return loading
    ? { loading }
    : {
        loading,
        history: {
          orderItems: data?.orderItems?.data || [],
        },
      };
};
