import { Text, TextProps } from "@chakra-ui/react";
import { OrderPackageDataFragment, Enum_Orderpackage_Status } from "data";

interface OrderPackageStatusLabelProps extends TextProps {
  orderPackage?: OrderPackageDataFragment | null;
}

export const orderPackageStatusToString = (
  orderPackage: OrderPackageDataFragment
) => {
  switch (orderPackage.attributes?.status) {
    case Enum_Orderpackage_Status.Shipped:
      return "Shipped";
    default:
      return "Pending";
  }
};

export const OrderPackageStatusLabel = (
  props: OrderPackageStatusLabelProps
) => {
  const { orderPackage, ...rest } = props;
  return orderPackage ? (
    <Text {...rest}>{orderPackageStatusToString(orderPackage)}</Text>
  ) : null;
};
