import {
  Enum_Inspecteddevice_Hashingstatus,
  useInspectionByIdQuery,
} from "data";

export interface InspectionStats {
  gotPower?: boolean | null;
  gotResponse?: boolean | null;
  hashingStatus?: Enum_Inspecteddevice_Hashingstatus | null;
  count: number;
}

interface UseInspectionStatsHookData {
  loading: boolean;
  stats: InspectionStats[];
}

export const useInspectionStats = ({
  inspectionId,
}: {
  inspectionId: string;
}): UseInspectionStatsHookData => {
  const { loading, data } = useInspectionByIdQuery({
    variables: {
      inspectionId,
    },
    pollInterval: 1000 * 10,
  });
  return {
    loading,
    stats: (data?.inspection?.data?.attributes?.stats || []).map(
      ({ got_power, got_response, hashing_status, count }) => ({
        gotPower: got_power,
        gotResponse: got_response,
        hashingStatus: hashing_status,
        count,
      })
    ),
  };
};
