import * as Yup from "yup";
import { Button, Stack, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { OrderItemDataFragment, PsuInspectionReportInput } from "data";
import { YesNoButtons, SelectTime } from "components/forms/fields";
import { PSUInspectionReportPreview } from "features/diagnostics/components/psus/PSUInspectionReportPreview";

interface PSUDiagnosticsSummaryFormProps {
  orderItem: OrderItemDataFragment;
  report: PsuInspectionReportInput;
  onSubmit: (report: PsuInspectionReportInput) => Promise<void>;
  onPrevious: () => void;
}

const PSUDiagnosticsSummaryFormSchema = Yup.object().shape({
  isOk: Yup.boolean().required(),
  timeOfDiagnosisMinutes: Yup.number().required(),
});

export const PSUDiagnosticsSummaryForm = (
  props: PSUDiagnosticsSummaryFormProps
) => {
  const { orderItem, report, onPrevious, onSubmit } = props;
  return (
    <Formik
      initialValues={{
        isOk: true,
        timeOfDiagnosisMinutes: 15,
      }}
      validationSchema={PSUDiagnosticsSummaryFormSchema}
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(
          Object.assign({}, values, {
            timeOfDiagnosisMinutes: parseInt(
              `${values.timeOfDiagnosisMinutes}`
            ),
          })
        );
      }}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <Form>
          <Stack>
            <PSUInspectionReportPreview orderItem={orderItem} report={report} />
            <Stack mt={4} px={4}>
              <YesNoButtons label="Is PSU OK?" name="isOk" />
              <SelectTime
                name="timeOfDiagnosisMinutes"
                label="How much time did diagnosis take?"
                placeholder="Select time"
                start={15}
                end={150}
                step={15}
              />
              <HStack mt={8}>
                <Button
                  isDisabled={isSubmitting}
                  onClick={() => {
                    onPrevious();
                  }}
                  variant="outline"
                >
                  Previous
                </Button>
                <Button
                  isDisabled={isSubmitting}
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
