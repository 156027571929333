import { useContext, createContext, ReactNode } from "react";
import { useToast } from "@chakra-ui/react";

interface NotificationsContextData {
  showSuccessMessage: (message: string) => void;
  showErrorMessage: (message?: string) => void;
  showWarningMessage: (message: string) => void;
  showInfoMessage: (message: string) => void;
}

const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData
);

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = (props: NotificationsProviderProps) => {
  const toast = useToast();
  return (
    <NotificationsContext.Provider
      value={{
        showSuccessMessage: (message: string) => {
          toast({
            title: message,
            status: "success",
            isClosable: true,
          });
        },
        showErrorMessage: (message?: string) => {
          toast({
            title: message || "Something went wrong",
            status: "error",
            isClosable: true,
          });
        },
        showWarningMessage: (message: string) => {
          toast({
            title: message,
            status: "warning",
            isClosable: true,
          });
        },
        showInfoMessage: (message: string) => {
          toast({
            title: message,
            status: "info",
            isClosable: true,
          });
        },
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () =>
  useContext<NotificationsContextData>(NotificationsContext);

interface WrapWithNotificationsProps<Props, T> {
  runner: (props: Props) => Promise<T | null>;
  successMessage: string;
  errorMessage?: string;
}

export function useWrapWithNotifications<Props, T>(
  props: WrapWithNotificationsProps<Props, T>
): (p: Props) => Promise<T | null> {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { runner, successMessage, errorMessage } = props;
  return async (props: Props): Promise<T | null> => {
    try {
      const r = await runner(props);
      showSuccessMessage(successMessage);
      return r;
    } catch (err) {
      console.error(err);
      showErrorMessage(errorMessage);
    }

    return null;
  };
}
