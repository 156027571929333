import { useState, useEffect } from "react";
import {
  useCreateActionMutation,
  useListActionsQuery,
  useListActionsLazyQuery,
  ActionFiltersInput,
  ActionDataFragment,
  ActionInput,
  Enum_Action_Type,
  Enum_Action_Scope,
  Enum_Action_Client,
  OrderDataFragment,
  OrderItemDataFragment,
  UserProfileDataFragment,
  DeviceDataFragment,
  CustomerDeviceDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
  HashboardBasicRepairDataFragment,
  PsuInspectionReportDataFragment,
  PsuRepairDataFragment,
  OrderItemActionDataFragment,
} from "data";
import { getAppVersion, getUserAgent } from "lib";
import { useAuthentication } from "features/accounts/context/Authentication";
import { parseHashboardDiagnosis } from "features/diagnostics/data/helpers";

interface ActionContext {
  order?: OrderDataFragment | null;
  orderItem?: OrderItemDataFragment | null;
  userProfile?: UserProfileDataFragment | null;
  device?: DeviceDataFragment | null;
  customerDevice?: CustomerDeviceDataFragment | null;
  hashboardAdvancedInspectionReport?: HashboardAdvancedInspectionReportDataFragment | null;
  hashboardRepair?: HashboardBasicRepairDataFragment | null;
  psuInspectionReport?: PsuInspectionReportDataFragment | null;
  psuRepair?: PsuRepairDataFragment | null;
  orderItemAction?: OrderItemActionDataFragment | null;
}

interface UseCreateActionHookData {
  createAction: (data: {
    type: Enum_Action_Type;
    scope: Enum_Action_Scope;
    data: ActionInput;
    context?: ActionContext;
  }) => Promise<void>;
  pushToDataLayer: (data: { event: string; context?: ActionContext }) => void;
}

export const expandActionDataPiece = (
  item?: OrderDataFragment | OrderItemDataFragment | null
): ActionInput => {
  if (item?.__typename === "OrderEntity") {
    const order = item as OrderDataFragment;
    return {
      order: order.id,
      customer: order.attributes?.customer?.data?.id,
      zoho_sales_order: order.attributes?.zoho_sales_order?.data?.id,
    };
  }

  if (item?.__typename === "OrderItemEntity") {
    const orderItem = item as OrderItemDataFragment;
    return {
      order_item: orderItem.id,
      device: orderItem.attributes?.device?.data?.id,
      customer_device: orderItem.attributes?.customer_device?.data?.id,
      ...expandActionDataPiece(orderItem.attributes?.order?.data),
    };
  }

  return {};
};

export const expandActionContextForDataLayer = (
  context?: ActionContext
): Object => {
  if (!context) {
    return {};
  }

  const {
    device,
    customerDevice,
    order,
    userProfile,
    hashboardAdvancedInspectionReport,
    hashboardRepair,
    psuInspectionReport,
    psuRepair,
    orderItemAction,
  } = context;
  let base = {};

  if (order) {
    Object.assign(base, {
      order:
        order.attributes?.zoho_sales_order?.data?.attributes?.salesOrderNumber,
      customer:
        order.attributes?.zoho_sales_order?.data?.attributes?.accountName,
    });
  }

  // non customer
  if (userProfile && userProfile.attributes?.customers?.data.length === 0) {
    Object.assign(base, {
      teamMember: userProfile.attributes?.name,
    });
  }

  if (userProfile) {
    Object.assign(base, {
      userId: userProfile.attributes?.user?.data?.id,
    });
  }

  // customer
  if (userProfile && userProfile.attributes?.customers?.data.length !== 0) {
    const customer = userProfile.attributes?.customers?.data[0];
    Object.assign(base, {
      accountName: customer?.attributes?.accountName,
    });
  }

  if (device) {
    Object.assign(base, {
      deviceMake: device.attributes?.make,
      deviceModel: device.attributes?.model,
      deviceType: device.attributes?.type,
    });
  }

  if (customerDevice && customerDevice.attributes?.serialNumber) {
    Object.assign(base, {
      deviceSerialNumber: customerDevice.attributes?.serialNumber,
    });
  }

  // hashboard diagnostics
  if (hashboardAdvancedInspectionReport) {
    const { category, diagnosis } = parseHashboardDiagnosis(
      hashboardAdvancedInspectionReport.attributes?.diagnosis
    );

    Object.assign(base, {
      diagnostic_time:
        hashboardAdvancedInspectionReport.attributes?.timeOfDiagnosisMinutes,
      diagnostic_category: category,
      diagnostic_subcategory: diagnosis,
    });
  }

  // PSU diagnostics
  if (psuInspectionReport) {
    Object.assign(base, {
      diagnostic_time: psuInspectionReport.attributes?.timeOfDiagnosisMinutes,
    });
  }

  // hashboard repair
  if (hashboardRepair) {
    Object.assign(base, {
      repair_time: hashboardRepair.attributes?.repairTimeMinutes,
    });

    if (hashboardRepair.attributes?.isAdvancedRepair) {
      Object.assign(base, {
        advanced_repair: true,
      });
    }
  }

  // PSU repair
  if (psuRepair) {
    Object.assign(base, {
      repair_time: psuRepair.attributes?.repairTimeMinutes,
    });
  }

  // include hashboard type if available
  if (customerDevice?.attributes?.hashboardMetadata?.type) {
    Object.assign(base, {
      board_type: customerDevice?.attributes?.hashboardMetadata?.type,
    });
  }

  // order item action
  if (orderItemAction) {
    Object.assign(base, {
      order_item_action: orderItemAction.attributes?.action,
    });
  }

  return base;
};

const renameDataLayerEventIfNeeded = ({
  event,
  context,
}: {
  event: string;
  context?: ActionContext;
}): string => {
  if (!context) {
    return event;
  }

  if (event === Enum_Action_Type.RegisterOrderItemAction) {
    // XX: for Register Order Item action, we want to specicy explicit action name
    return `register_item_${context.orderItemAction?.attributes?.action}`;
  }

  return event;
};

export const useCreateAction = (): UseCreateActionHookData => {
  const [doCreateAction] = useCreateActionMutation();
  const { userProfile } = useAuthentication();
  const pushToDataLayer = ({
    event,
    context,
  }: {
    event: string;
    context?: ActionContext;
  }) => {
    if (
      // @ts-ignore
      typeof dataLayer === "undefined" ||
      // @ts-ignore
      typeof dataLayer.push === "undefined"
    ) {
      return;
    }

    // @ts-ignore
    dataLayer.push({
      event: renameDataLayerEventIfNeeded({ event, context }),
      meta: expandActionContextForDataLayer(
        Object.assign({}, context, {
          userProfile: userProfile || context?.userProfile,
        })
      ),
    });
  };
  return {
    pushToDataLayer,
    async createAction({ type, scope, data, context }) {
      const action = Object.assign({}, data, {
        type,
        scope,
        client: Enum_Action_Client.AcsWeb,
        userAgent: getUserAgent(),
        appVersion: getAppVersion(),
        user: userProfile?.attributes?.user?.data?.id,
        user_profile: userProfile?.id,
      });

      await doCreateAction({
        variables: {
          action,
        },
      });

      pushToDataLayer({ event: type, context });
    },
  };
};

interface UseActionsHookData {
  actions: ActionDataFragment[];
  loading: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  next: () => Promise<void>;
  previous: () => Promise<void>;
}

interface UseRealtimeActionsHookData {
  actions: ActionDataFragment[];
  loading: boolean;
}

const actionsFetchLimit = 10;

export const useActions = (filters: ActionFiltersInput): UseActionsHookData => {
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const adjustedFilters = Object.assign({}, filters, {
    type: {
      ne: "login",
    },
  });

  const { data, loading, refetch } = useListActionsQuery({
    variables: {
      filters: adjustedFilters,
      offset: 0,
      limit: actionsFetchLimit,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.actions?.meta.pagination) {
      setHasNext(
        data.actions.meta.pagination.pageCount >
          data.actions.meta.pagination.page
      );
      setCurrentPage(data.actions.meta.pagination.page);
    }
  }, [data?.actions]);

  return {
    actions: data?.actions?.data || [],
    loading: loading || fetching,
    hasNext,
    hasPrevious: currentPage > 1,
    next: async () => {
      setFetching(true);
      await refetch({
        filters: adjustedFilters,
        offset: currentPage * actionsFetchLimit,
        limit: actionsFetchLimit,
      });
      setFetching(false);
    },
    previous: async () => {
      setFetching(true);
      await refetch({
        filters: adjustedFilters,
        offset: (currentPage - 2) * actionsFetchLimit,
        limit: actionsFetchLimit,
      });
      setFetching(false);
    },
  };
};

export const useRealtimeActions = ({
  refreshTimeoutInMs,
}: {
  refreshTimeoutInMs: number;
}): UseRealtimeActionsHookData => {
  const [loading, setLoading] = useState<boolean>(true);
  const [actions, setActions] = useState<ActionDataFragment[]>([]);
  const [loadActions] = useListActionsLazyQuery();

  useEffect(() => {
    const interval = setInterval(() => {
      const runner = async () => {
        const { data } = await loadActions({
          variables: {
            filters: {
              type: {
                ne: "login",
              },
            },
            offset: 0,
            limit: 20,
          },
          fetchPolicy: "network-only",
        });
        const newActions = data?.actions?.data || [];

        // first run
        if (actions.length === 0) {
          setActions(newActions);
          setLoading(false);
          return;
        }

        const updatedActions: ActionDataFragment[] = [];

        for (let i = 0; i < newActions.length; i++) {
          if (newActions[i].id !== actions[0].id) {
            updatedActions.push(newActions[i]);
          } else {
            break;
          }
        }

        setActions([...updatedActions.reverse(), ...actions]);
      };
      runner();
    }, refreshTimeoutInMs);
    return () => clearInterval(interval);
  }, []);

  return {
    loading,
    actions: actions,
  };
};
