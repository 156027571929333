import { OrderDataFragment } from "data";
import { ActionsTimeline } from "features/actions/components/ActionsTimeline";

interface OrderActivitiesContainerProps {
  order: OrderDataFragment;
}

export const OrderActivitiesContainer = (
  props: OrderActivitiesContainerProps
) => {
  const { order } = props;
  return (
    <ActionsTimeline
      level="order"
      filters={{
        order: {
          id: {
            eq: order.id,
          },
        },
      }}
    />
  );
};
