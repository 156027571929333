import * as Yup from "yup";
import { Formik } from "formik";
import { TextArea, YesNoButtons } from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";

const ThermalsSchema = Yup.object().shape({
  thermalsIsThermalCompoundInGoodCondition: Yup.boolean().required(),
  thermalsNotes: Yup.string(),
});

export const ThermalsForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        thermalsIsThermalCompoundInGoodCondition:
          report.thermalsIsThermalCompoundInGoodCondition,
        thermalsNotes: report.thermalsNotes,
      }}
      validationSchema={ThermalsSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.thermalsIsThermalCompoundInGoodCondition !==
              "undefined" && isValid
          }
          canGoToPrevStep
          title="Thermals"
          values={values}
        >
          <YesNoButtons
            label="Is thermal compound in good condition?"
            name="thermalsIsThermalCompoundInGoodCondition"
          />
          <TextArea label="Notes (Optional)" name="thermalsNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
