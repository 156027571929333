import { Badge } from "@chakra-ui/react";
import {
  Enum_Inspecteddevice_Hashingstatus,
  InspectedDeviceDataFragment,
} from "data";
import { DeviceWithMinerScanData } from "features/inspections/data/hooks";
interface InspectedDeviceStatusProps {
  device: DeviceWithMinerScanData;
}

const prettyLabel = (device: InspectedDeviceDataFragment) => {
  if (!device.attributes) {
    return "Unknown";
  }

  const { gotPower, gotResponse, hashingStatus } = device.attributes;

  if (gotPower === null && gotResponse === null && hashingStatus === null) {
    return "Unknown";
  }

  if (gotPower === false) {
    return "No Power";
  }

  if (gotPower === null && gotResponse === false) {
    return "No Response";
  }

  switch (hashingStatus) {
    case Enum_Inspecteddevice_Hashingstatus.Hashing:
      return "Hashing";
    case Enum_Inspecteddevice_Hashingstatus.PartiallyHashing:
      return "Partially Hashing";
    case Enum_Inspecteddevice_Hashingstatus.NoHashing:
      return "Not Hashing";
    default:
      return "Got Power";
  }
};

const colorForStatus = (status: string) => {
  switch (status) {
    case "Hashing":
      return "green";
    case "Partially Hashing":
      return "orange";
    case "Got Power":
      return "blue";
    case "No Power":
      return "yellow";
    case "Not Hashing":
      return "red";
    case "No Response":
      return "gray";
    default:
      return "gray";
  }
};

export const InspectedDeviceStatus = (props: InspectedDeviceStatusProps) => {
  const { device } = props;
  const status = prettyLabel(device.device);
  return (
    <Badge
      data-cy="status-bage"
      data-inspected-device-status={status.toLowerCase()}
      {...(device.device.attributes?.gotPower ||
      device.device.attributes?.gotResponse
        ? {
            "data-got-power": "",
          }
        : {})}
      {...(device.device.attributes?.gotResponse === false
        ? {
            "data-no-response": "",
          }
        : {})}
      textAlign="center"
      colorScheme={colorForStatus(status)}
    >
      {status}
    </Badge>
  );
};
