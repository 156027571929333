import { Text, TextProps } from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";
import { orderItemStatusToString } from "features/order-items/data";

interface OrderItemStatusLabelProps extends TextProps {
  orderItem?: OrderItemDataFragment | null;
}

export const OrderItemStatusLabel = (props: OrderItemStatusLabelProps) => {
  const { orderItem, ...rest } = props;
  return (
    <Text
      data-order-item-status-label={orderItem?.attributes?.status}
      {...rest}
    >
      {orderItemStatusToString(orderItem)}
    </Text>
  );
};
