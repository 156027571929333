import { Badge, HStack, Text, Link, Icon } from "@chakra-ui/react";
import { SiMicrosoftexcel } from "react-icons/si";
import { PrintIcon } from "components/Icons";
import { DateTimeText } from "components/utils/DateTimeText";
import { OrderDataFragment } from "data";
import { LinkToCustomer } from "features/customers/components/LinkToCustomer";
import { ExpectedShipmentDateLabel } from "features/orders/components/ExpectedShipmentDateLabel";
import { DownloadReportButton } from "features/reports/components/DownloadReportButton";

interface OrderPageSubtitleProps {
  order: OrderDataFragment;
}

export const OrderPageSubtitle = (props: OrderPageSubtitleProps) => {
  const { order } = props;
  return (
    <HStack spacing="6">
      {order.attributes?.isArchived ? (
        <Badge variant="outline" size="sm" color="red">
          Archived
        </Badge>
      ) : null}
      <LinkToCustomer
        customer={
          order.attributes?.zoho_sales_order?.data?.attributes?.customer?.data
        }
      >
        <Text data-cy="link-to-customer-page">
          {order.attributes?.zoho_sales_order?.data?.attributes?.accountName}
        </Text>
      </LinkToCustomer>
      <DateTimeText format="MMM D" value={order.attributes?.createdAt} />
      <ExpectedShipmentDateLabel order={order} />
      {order.attributes?.zoho_sales_order?.data?.attributes?.manifestLink ? (
        <Link
          size="xs"
          href={
            order.attributes?.zoho_sales_order?.data?.attributes?.manifestLink
          }
          isExternal
        >
          <Icon mr="2" as={SiMicrosoftexcel} />
          See Manifest
        </Link>
      ) : null}
      <Link
        href={`/orders/print/${encodeURIComponent(
          order.attributes?.zoho_sales_order?.data?.attributes
            ?.salesOrderNumber || ""
        )}`}
        isExternal
      >
        <HStack>
          <PrintIcon />
          <Text>Print</Text>
        </HStack>
      </Link>
      <DownloadReportButton
        data-cy="download-billables-report"
        size="xs"
        label="Billables"
        variant="link"
        reportName="billables report"
        parameters={{ orderId: order.id }}
      />
      <DownloadReportButton
        data-cy="download-inventory-report"
        size="xs"
        variant="link"
        label="Inventory"
        reportName="inventory report"
        parameters={{ orderId: order.id }}
      />
      <DownloadReportButton
        data-cy="download-activity-report"
        size="xs"
        variant="link"
        label="Activity"
        reportName="activity report"
        parameters={{ orderId: order.id }}
      />
    </HStack>
  );
};
