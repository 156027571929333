import { Box, Stack, HStack, Divider } from "@chakra-ui/react";
import { GoPackage } from "react-icons/go";
import { OrderItemDataFragment } from "data";
import { SearchBox } from "components/forms/SearchBox";
import { IconActionButton } from "components/utils/IconActionButton";
import { CustomerDeviceCompleteNameLabel } from "features/customer-devices/components/CustomerDeviceCompleteNameLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderNameLabel } from "features/orders/components/OrderNameLabel";
import { OrderItemStatusBadge } from "features/order-items/components/OrderItemStatusBadge";

interface OrderItemPickerProps {
  orderItems: OrderItemDataFragment[];
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  onPackage: (orderItem: OrderItemDataFragment) => Promise<void>;
}

export const OrderItemPicker = (props: OrderItemPickerProps) => {
  const { searchTerm, onSearchTermChange, onPackage } = props;
  return (
    <>
      <Box mb="4">
        <SearchBox onChange={onSearchTermChange} value={searchTerm} />
      </Box>
      {props.orderItems.map((oi) => (
        <Box key={oi.id} bg="bg-surface" py="2">
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <HStack spacing="4">
              <Stack>
                <CustomerDeviceCompleteNameLabel
                  showDeviceTypeIcon
                  fontWeight="bold"
                  customerDevice={oi.attributes?.customer_device?.data}
                />
                <HStack>
                  <OrderItemStatusBadge orderItem={oi} />
                  <CustomerDeviceShortIdLabel
                    customerDevice={oi.attributes?.customer_device?.data}
                  />
                </HStack>

                <OrderNameLabel
                  color="muted"
                  fontSize="sm"
                  orderData={oi.attributes?.order?.data}
                />
              </Stack>
            </HStack>
            <Stack direction="row" spacing="3">
              <IconActionButton
                data-cy="add-data-to-package"
                data-order-item-id={oi.id}
                aria-label="Package"
                icon={<GoPackage />}
                size="xs"
                variant="primary"
                action={async () => {
                  await onPackage(oi);
                }}
              />
            </Stack>
          </Stack>
          <Divider mt="4" />
        </Box>
      ))}
    </>
  );
};
