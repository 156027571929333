import { LocationDataFragment, useLocationsQuery } from "data";

interface UseLocationsHookData {
  loading: boolean;
  locations: LocationDataFragment[];
}

export const useLocations = (): UseLocationsHookData => {
  const { loading, data } = useLocationsQuery();
  return {
    loading,
    locations: data?.locations?.data || [],
  };
};
