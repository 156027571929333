import { AddDevicesActionPanelProvider } from "features/orders/context/AddDevicesActionPanel";
import { AddDevicesAction } from "features/orders/components/AddDevicesAction";
import { ShowForAllTechs } from "features/accounts";

interface OrderPageActionsProps {}

export const OrderPageActions = (props: OrderPageActionsProps) => {
  return (
    <ShowForAllTechs>
      <AddDevicesActionPanelProvider>
        <AddDevicesAction variant="primary-action-button" />
      </AddDevicesActionPanelProvider>
    </ShowForAllTechs>
  );
};
