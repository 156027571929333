import { OrderItemDataFragment } from "data";
import { Loader } from "components/Loader";
import { useNotifications } from "context/Notifications";
import { OrderItemContextPanel } from "features/order-items/components/OrderItemContextPanel";
import {
  useDeleteOrderItem,
  useOrderItemSummary,
} from "features/order-items/data/hooks/order-items";
import { DiagnosticsContextMenuProvider } from "features/diagnostics/context/DiagnosticsContextMenu";
import { RepairsContextMenuProvider } from "features/repairs/context/RepairsContextMenu";

interface OrderItemContextPanelContainerProps {
  orderItem: OrderItemDataFragment;
  onAfterDelete: () => void;
}

export const OrderItemContextPanelContainer = (
  props: OrderItemContextPanelContainerProps
) => {
  const { loading, orderItem } = useOrderItemSummary(props.orderItem.id || "");
  const { showSuccessMessage } = useNotifications();
  const { onAfterDelete } = props;
  const { deleteOrderItem } = useDeleteOrderItem();
  return loading || !orderItem ? (
    <Loader />
  ) : (
    <DiagnosticsContextMenuProvider>
      <RepairsContextMenuProvider>
        <OrderItemContextPanel
          orderItem={orderItem}
          deleteOrderItem={async () => {
            await deleteOrderItem(orderItem);
            showSuccessMessage("Order item deleted");
            onAfterDelete();
          }}
        />
      </RepairsContextMenuProvider>
    </DiagnosticsContextMenuProvider>
  );
};
