import { ReactNode, useContext } from "react";
import { OrdersQueryVariables, OrdersQuery, OrdersDocument } from "data";
import { generateContextAndProvider, DataSourceSpec } from "context/DataSource";

const defaultSort = "createdAt:desc";

const { Context, Provider } = generateContextAndProvider<
  OrdersQuery,
  OrdersQueryVariables
>({
  parseFilters(searchParams) {
    const page = searchParams.find((sp) => sp[0] === "page");
    const sort = searchParams.find((sp) => sp[0] === "sort");
    const isArchived = searchParams.find((sp) => sp[0] === "isArchived");

    return {
      page: page ? parseInt(page[1] || "1") : 1,
      sort: sort ? sort[1] : defaultSort,
      filters:
        isArchived && isArchived[1] === "true"
          ? {
              isArchived: { eq: true },
            }
          : {
              or: [{ isArchived: { eq: false } }, { isArchived: { eq: null } }],
            },
    };
  },
  serializeFilters(filters) {
    const r: Array<[string, string]> = [];
    r.push(["page", filters.page ? `${filters.page}` : "1"]);
    r.push(["sort", (filters.sort as string) || defaultSort]);
    r.push(["isArchived", filters.filters?.isArchived?.eq ? "true" : "false"]);
    return r;
  },
  QueryDocument: OrdersDocument,
  fetchPolicy: "network-only",
});

interface CustomersProviderProps {
  children: ReactNode;
}

export const OrdersListProvider = (props: CustomersProviderProps) => {
  const { children } = props;
  return <Provider processParams={(params) => params}>{children}</Provider>;
};

export const useOrdersList = (): DataSourceSpec<
  OrdersQuery,
  OrdersQueryVariables
> => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useOrdersList used outside of OrdersListProvider");
  }

  return context;
};
