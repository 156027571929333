import memoize from "lodash.memoize";
import {
  Enum_Device_Make,
  Enum_Device_Model,
  Enum_Device_Type,
  Enum_Device_Series,
  DeviceDataFragment,
} from "data";

export const completeDeviceName = (
  device?: DeviceDataFragment | null,
  hashrate?: string | null
): string => {
  if (!device) {
    return "N/A";
  }
  const { make, model } = device?.attributes || {};
  return `${deviceMakeToString(make)} ${deviceModelToString(model)}${
    hashrate ? ` ${hashrate}` : ""
  }`;
};

const _deviceMakeToString = (deviceMake?: Enum_Device_Make): string => {
  switch (deviceMake) {
    case Enum_Device_Make.Amlogic:
      return "AmLogic";
    case Enum_Device_Make.Antminer:
      return "Antminer";
    case Enum_Device_Make.Avalon:
      return "Avalon";
    case Enum_Device_Make.BeagleBone:
      return "Beagle Bone";
    case Enum_Device_Make.Whatsminer:
      return "Whatsminer";
    case Enum_Device_Make.Xilinx:
      return "Xilinx";
    default:
      return "";
  }
};

export const deviceMakeToString = memoize(_deviceMakeToString);

export const deviceModelToString = (
  deviceModel?: Enum_Device_Model
): string => {
  if (!deviceModel) {
    return "";
  }
  return (deviceModel as string)
    .split("_")
    .map((el) => {
      if (el.match(/plus/gi)) {
        return el.replaceAll(/plus/gi, "+");
      }
      return el;
    })
    .map((el) => {
      return el.toUpperCase();
    })
    .join(" ")
    .replace(/\s\+/gi, "+");
};

const _deviceTypeToString = (deviceType?: Enum_Device_Type): string => {
  switch (deviceType) {
    case Enum_Device_Type.ControlBoard:
      return "Control Board";
    case Enum_Device_Type.HashBoard:
      return "Hash Board";
    case Enum_Device_Type.Miner:
      return "Miner";
    case Enum_Device_Type.Psu:
      return "PSU";
    default:
      return "";
  }
};

export const deviceTypeToString = memoize(_deviceTypeToString);

const _deviceSeriesToString = (deviceSeries?: Enum_Device_Series): string => {
  if (!deviceSeries) {
    return "";
  }
  const str = deviceSeries as string;

  return str.toUpperCase();
};

export const deviceSeriesToString = memoize(_deviceSeriesToString);

export const minerFansCount = (device?: DeviceDataFragment | null): number => {
  if (!device) {
    return 0;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Antminer &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 4;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Whatsminer &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 2;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Avalon &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 4;
  }

  return 0;
};

export const psuFansCount = (device?: DeviceDataFragment | null): number => {
  if (!device) {
    return 0;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Antminer &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 3;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Whatsminer &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 1;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Avalon &&
    device.attributes.type === Enum_Device_Type.Miner
  ) {
    return 3;
  }

  return 0;
};

export const temperatureSensorsCount = (
  device?: DeviceDataFragment | null
): number => {
  if (!device) {
    return 0;
  }

  if (
    device.attributes?.make === Enum_Device_Make.Antminer &&
    device.attributes.type === Enum_Device_Type.HashBoard
  ) {
    return 4;
  }

  return 0;
};

export const getNumberOfHashboardsForMiner = (
  device: DeviceDataFragment
): number => {
  if (device.attributes?.type !== Enum_Device_Type.Miner) {
    throw new Error("Not a miner");
  }

  if (
    [
      Enum_Device_Model.L3,
      Enum_Device_Model.L3Plus,
      Enum_Device_Model.L3PlusPlus,
    ].indexOf(device.attributes.model) !== -1
  ) {
    return 4;
  }

  return 3;
};

// subcomponent device template
export const deviceTemplate = (
  device: DeviceDataFragment,
  devices: DeviceDataFragment[]
): { device: DeviceDataFragment }[] => {
  const subcomponents: DeviceDataFragment[] = (
    device.attributes?.devices?.data || []
  ).map((deviceEntity) =>
    devices.find((d) => d.id === deviceEntity.id)
  ) as DeviceDataFragment[];

  const hashBoard = subcomponents.find(
    (s) => s.attributes?.type === Enum_Device_Type.HashBoard
  );
  const controlBoard = subcomponents.find(
    (s) => s.attributes?.type === Enum_Device_Type.ControlBoard
  );
  const pcu = subcomponents.find(
    (s) => s.attributes?.type === Enum_Device_Type.Psu
  );

  const numberOfHasboards = getNumberOfHashboardsForMiner(device);

  return [
    ...(hashBoard ? Array(numberOfHasboards).fill(hashBoard) : []),
    ...(pcu ? [pcu] : []),
    ...(controlBoard ? [controlBoard] : []),
  ].map((device) => ({
    device,
  }));
};
