import { OrderItemDataFragment } from "data";
import { ReplaceIcon } from "components/Icons";

interface OrderItemReplacementIndicatorProps {
  orderItem?: OrderItemDataFragment | null;
}

export const OrderItemReplacementIndicator = (
  props: OrderItemReplacementIndicatorProps
) => {
  const { orderItem } = props;

  if (
    !orderItem?.attributes?.replaced_by?.data?.id &&
    !orderItem?.attributes?.replacement_for?.data?.id
  ) {
    return null;
  }

  return <ReplaceIcon data-cy="order-item-replacement-indicator" />;
};
