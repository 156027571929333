import {
  Stack,
  Code,
  Text,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import {
  HashboardRepairDataFragment,
  HashboardBasicRepairDataFragment,
  PsuRepairDataFragment,
  PsuRepair,
  OrderItemDataFragment,
} from "data";
import { YesLabel, NoLabel } from "components/Labels";
import { ShowForAllTechs } from "features/accounts/components/ShowForAllTechs";
import { ActionCard } from "./Base";
import { PSURepairReportPreview } from "features/repairs/components/psu/PSURepairReportPreview";

interface RepairActionCardProps {
  orderItem: OrderItemDataFragment;
  basicHashboardRepair?: HashboardBasicRepairDataFragment | null;
  hashboardRepair?: HashboardRepairDataFragment | null;
  psuRepair?: PsuRepairDataFragment | null;
}

export const BasicHashboardRepairActionCard = ({
  repair,
}: {
  repair: HashboardBasicRepairDataFragment;
}) => {
  return (
    <Stack>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td>Trace repair</Td>
              <Td>
                {repair.attributes?.traceRepair ? <YesLabel /> : <NoLabel />}
              </Td>
            </Tr>
            <Tr>
              <Td>Corrosion cleaning</Td>
              <Td>
                {repair.attributes?.corrosionCleaning ? (
                  <YesLabel />
                ) : (
                  <NoLabel />
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>Advanced repair</Td>
              <Td>
                {repair.attributes?.isAdvancedRepair ? (
                  <YesLabel />
                ) : (
                  <NoLabel />
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>Additional issues</Td>
              <Td>
                {repair.attributes?.gotFurtherIssues ? (
                  <YesLabel />
                ) : (
                  <NoLabel />
                )}
              </Td>
            </Tr>
            <ShowForAllTechs>
              <Tr>
                <Td>Repair time</Td>
                <Td>{`${repair.attributes?.repairTimeMinutes} minutes`}</Td>
              </Tr>
            </ShowForAllTechs>
          </Tbody>
        </Table>
      </TableContainer>
      {repair.attributes?.notes ? (
        <Code>{repair.attributes?.notes}</Code>
      ) : null}
    </Stack>
  );
};

export const HashboardRepairActionCard = ({
  repair,
}: {
  repair: HashboardRepairDataFragment;
}) => {
  return (
    <Stack>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            {typeof repair.attributes?.tempSensorsReplaced !== "undefined" ? (
              <>
                <Tr>
                  <Td>Temperature sensors replaced</Td>
                  <Td>
                    {repair.attributes?.tempSensorsReplaced ? (
                      <YesLabel />
                    ) : (
                      <NoLabel />
                    )}
                  </Td>
                </Tr>
                {repair.attributes.quantityTempSensorsReplaced ? (
                  <Tr>
                    <Td># of temperature sensors replaced</Td>
                    <Td>
                      <Text>
                        {repair.attributes.quantityTempSensorsReplaced}
                      </Text>
                    </Td>
                  </Tr>
                ) : null}
              </>
            ) : null}
            {typeof repair.attributes?.openTracesRepaired !== "undefined" ? (
              <>
                <Tr>
                  <Td>Open traces repaired</Td>
                  <Td>
                    {repair.attributes?.openTracesRepaired ? (
                      <YesLabel />
                    ) : (
                      <NoLabel />
                    )}
                  </Td>
                </Tr>
                {repair.attributes.quantityOpenTracesRepaired ? (
                  <Tr>
                    <Td># of open traces repaired</Td>
                    <Td>
                      <Text>
                        {repair.attributes.quantityOpenTracesRepaired}
                      </Text>
                    </Td>
                  </Tr>
                ) : null}
              </>
            ) : null}
            {typeof repair.attributes?.asicRepairCompleted !== "undefined" ? (
              <>
                <Tr>
                  <Td>ASICs repaired</Td>
                  <Td>
                    {repair.attributes?.asicRepairCompleted ? (
                      <YesLabel />
                    ) : (
                      <NoLabel />
                    )}
                  </Td>
                </Tr>
                {repair.attributes.quantityASICsReplaced ? (
                  <Tr>
                    <Td># of ASICs replaced</Td>
                    <Td>
                      <Text>{repair.attributes.quantityASICsReplaced}</Text>
                    </Td>
                  </Tr>
                ) : null}
              </>
            ) : null}
            {typeof repair.attributes?.boostCircuitRepaired !== "undefined" ? (
              <Tr>
                <Td>Boost circuit repaired</Td>
                <Td>
                  {repair.attributes?.boostCircuitRepaired ? (
                    <YesLabel />
                  ) : (
                    <NoLabel />
                  )}
                </Td>
              </Tr>
            ) : null}
            {repair.attributes?.componentsReplaced &&
            repair.attributes?.componentsReplaced.length !== 0 ? (
              <Tr>
                <Td>Components replaced</Td>
                <Td>
                  <Text>
                    {repair.attributes?.componentsReplaced.join(", ")}
                  </Text>
                </Td>
              </Tr>
            ) : null}
            {repair.attributes?.estimatedTimeInHours ? (
              <ShowForAllTechs>
                <Tr>
                  <Td>Time</Td>
                  <Td>{`${repair.attributes?.estimatedTimeInHours} hours`}</Td>
                </Tr>
              </ShowForAllTechs>
            ) : null}
            <Tr>
              <Td>Success</Td>
              <Td>
                {repair.attributes?.isSuccessful ? <YesLabel /> : <NoLabel />}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {repair.attributes?.notes ? (
        <Code>{repair.attributes?.notes}</Code>
      ) : null}
    </Stack>
  );
};

export const RepairActionCard = ({
  basicHashboardRepair,
  hashboardRepair,
  psuRepair,
  orderItem,
}: RepairActionCardProps) => (
  <ActionCard>
    {hashboardRepair ? (
      <HashboardRepairActionCard repair={hashboardRepair} />
    ) : null}
    {basicHashboardRepair ? (
      <BasicHashboardRepairActionCard repair={basicHashboardRepair} />
    ) : null}
    {psuRepair?.attributes && (
      <PSURepairReportPreview
        orderItem={orderItem}
        report={psuRepair.attributes as PsuRepair}
      />
    )}
  </ActionCard>
);
