import { Text, HStack, Stack, Box, Show } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { DeviceDataFragment, OrderItemDataFragment } from "data";
import { ShowForAllTechs } from "features/accounts";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";
import { CustomerDeviceCompleteNameLabel } from "features/customer-devices/components/CustomerDeviceCompleteNameLabel";
import { DeviceMakeIcon } from "features/devices/components/DeviceMakeIcon";
import { OrderItemCompleteBage } from "features/order-items/components/OrderItemCompleteBadge";
import { OrderItemStatusLabel } from "features/order-items/components/OrderItemStatusLabel";
import { OrderItemHostedStatusBadgeContainer as OrderItemHostedStatusBadge } from "features/order-items/containers/OrderItemHostedStatusBadge";
import { AdvancedRepairBadge } from "features/order-items/components/AdvancedRepairBadge";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderItemsTableActions } from "features/order-items/components/OrderItemsTableActions";
import { OrderItemParentLink } from "features/order-items/components/OrderItemParentLink";
import { OrderItemReplacementIndicator } from "features/order-items/components/OrderItemReplacementIndicator";

const ItemTitle = ({ orderItem }: { orderItem: OrderItemDataFragment }) => {
  const isDeviceCheckedIn = orderItem.attributes?.customer_device?.data;

  if (!isDeviceCheckedIn) {
    return (
      <DeviceCompleteNameLabel
        showDeviceTypeIcon
        device={orderItem.attributes?.device?.data as DeviceDataFragment}
      />
    );
  }

  const checkedInDevice = (
    <HStack fontWeight="bold">
      <OrderItemReplacementIndicator orderItem={orderItem} />
      <CustomerDeviceCompleteNameLabel
        showDeviceTypeIcon
        customerDevice={orderItem.attributes?.customer_device?.data}
      />
      {orderItem.attributes?.customer_device?.data ? (
        <CustomerDeviceShortIdLabel
          customerDevice={orderItem.attributes?.customer_device?.data}
        />
      ) : null}
    </HStack>
  );

  return (
    <>
      <ShowForAllTechs>
        <Link
          data-order-item-id={orderItem.id}
          data-cy="link-to-order-item"
          to={`/orders/items/${orderItem.id}`}
        >
          {checkedInDevice}
        </Link>
      </ShowForAllTechs>
      <ShowForAllTechs inverse>{checkedInDevice}</ShowForAllTechs>
    </>
  );
};

export const Item = ({ orderItem }: { orderItem: OrderItemDataFragment }) => {
  const customerDevice = orderItem.attributes?.customer_device?.data;
  const device = orderItem.attributes?.device?.data?.attributes;
  return (
    <>
      <HStack
        data-row-order-item-id={orderItem.id}
        data-order-item-id={orderItem.id}
        data-order-item-device={`${device?.type}-${device?.make}-${device?.model}`}
        data-order-item-status={orderItem.attributes?.status}
        {...(customerDevice?.attributes?.serialNumber
          ? {
              "data-order-item-serial": customerDevice.attributes.serialNumber,
            }
          : {})}
        data-cy="order-item-table-entry"
        key={orderItem.id}
      >
        <HStack>
          <Show above="md">
            <DeviceMakeIcon
              deviceOrMake={orderItem.attributes?.device?.data}
              borderRadius={2}
              borderWidth={2}
              w={8}
              h={8}
            />
          </Show>

          <Stack pl="2">
            <HStack>
              <ItemTitle orderItem={orderItem} />
              <AdvancedRepairBadge orderItem={orderItem} />
            </HStack>{" "}
            <HStack fontSize="smaller" color="muted">
              <OrderItemStatusLabel orderItem={orderItem} />
              <OrderItemCompleteBage orderItem={orderItem} />
              <OrderItemHostedStatusBadge orderItem={orderItem} />
              {customerDevice?.attributes?.serialNumber ? (
                <Text>{`Serial #: ${customerDevice?.attributes?.serialNumber}`}</Text>
              ) : null}
            </HStack>
            <OrderItemParentLink
              prefix="part of"
              color="muted"
              fontSize="small"
              orderItem={orderItem}
            />
          </Stack>
        </HStack>
        <Show above="md">
          <ShowForAllTechs>
            <Box flex="1">
              <OrderItemsTableActions orderItem={orderItem} />
            </Box>
          </ShowForAllTechs>
        </Show>
      </HStack>
      <Show below="sm">
        <ShowForAllTechs>
          <Box flex="1" pt="4">
            <OrderItemsTableActions orderItem={orderItem} />
          </Box>
        </ShowForAllTechs>
      </Show>
    </>
  );
};
