import { createContext, ReactNode, useState } from "react";
import { useStep } from "components/Step";

export interface WizardStep {
  index: number;
  title: string;
}

export interface WizardContextData<Step, WizardData> {
  data: WizardData;
  updateData: (data: WizardData) => void;
  steps: Step[];
  currentStep: Step | null;
  canGoToNextStep: boolean;
  canGoToPrevStep: boolean;
  isLastStep: boolean;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  disableNav: () => void;
  enableNav: () => void;
  isNavEnabled: boolean;
}

interface ProviderProps<Step, WizardData> {
  steps: Step[];
  initialWizardData: WizardData;
  children: ReactNode;
  validateCurrentWizardData: (
    currentStep: Step | null,
    data: WizardData
  ) => boolean;
}

export function generateContextAndProvider<
  Step extends WizardStep,
  WizardData
>() {
  const Context =
    createContext<WizardContextData<Step, WizardData> | null>(null);
  const Provider = (props: ProviderProps<Step, WizardData>) => {
    const { steps, initialWizardData, children, validateCurrentWizardData } =
      props;
    const [isNavEnabled, setIsNavEnabled] = useState<boolean>(true);
    const [data, setData] = useState<WizardData>(initialWizardData);
    const [
      currentStepId,
      { canGoToPrevStep, isLastStep, goToNextStep, goToPrevStep },
    ] = useStep({
      maxStep: steps.length - 1,
      initialStep: 0,
    });
    const currentStep =
      steps.length !== 0
        ? steps.find((s) => s.index === currentStepId) || null
        : null;

    return (
      <Context.Provider
        value={{
          steps,
          data,
          updateData(newData) {
            setData(newData);
          },
          currentStep,
          isLastStep,
          canGoToNextStep: validateCurrentWizardData(currentStep, data),
          canGoToPrevStep,
          goToNextStep,
          goToPrevStep,
          disableNav() {
            setIsNavEnabled(false);
          },
          enableNav() {
            setIsNavEnabled(true);
          },
          isNavEnabled,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
}
