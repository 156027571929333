import uniq from "lodash.uniq";
import sortBy from "lodash.sortby";
import { useCallback, useMemo } from "react";
import {
  DeviceDataFragment,
  useDevicesQuery,
  Enum_Device_Type,
  Enum_Device_Series,
} from "data";

export interface UseDevicesDataHookData {
  loading: boolean;
  devices: DeviceDataFragment[];
  deviceTypes: Enum_Device_Type[];
  makesByDeviceType: (deviceType: Enum_Device_Type | null) => string[];
  devicesByTypeAndMake: (
    deviceType: Enum_Device_Type | null,
    deviceMake: string | null
  ) => DeviceDataFragment[];
  deviceSeriesByTypeAndMake: (
    deviceType: Enum_Device_Type | null,
    deviceMake: string | null
  ) => Enum_Device_Series[];
  getParentDevice: (
    device?: DeviceDataFragment | null
  ) => DeviceDataFragment | null;
}

export const useDevicesData = (): UseDevicesDataHookData => {
  const { loading, data } = useDevicesQuery();
  const devices = useMemo(() => data?.devices?.data || [], [data]);
  const deviceTypes = sortBy(
    uniq(
      devices
        .map((d) => d.attributes?.type)
        .filter((t) => typeof t !== "undefined")
    ) as Enum_Device_Type[]
  );
  return {
    loading,
    devices,
    deviceTypes,
    getParentDevice: (device) => {
      if (!device) {
        return null;
      }

      return (
        devices.find((d) =>
          d.attributes?.devices?.data.find((dd) => dd.id === device.id)
        ) || null
      );
    },
    makesByDeviceType: useCallback(
      (deviceType) => {
        return !deviceType
          ? []
          : (sortBy(
              uniq(
                devices
                  .filter((d) => d.attributes?.type === deviceType)
                  .map(
                    (d) => d.attributes?.manufacturer?.data?.attributes?.name
                  )
                  .filter((t) => typeof t !== "undefined")
              )
            ) as string[]);
      },
      [devices]
    ),
    devicesByTypeAndMake: useCallback(
      (deviceType, deviceMake) => {
        if (!deviceType || !deviceMake) {
          return [];
        }
        return sortBy(
          devices.filter(
            (d) =>
              d.attributes?.type === deviceType &&
              d.attributes.manufacturer?.data?.attributes?.name === deviceMake
          ),
          (d) => d.attributes?.model
        );
      },
      [devices]
    ),
    deviceSeriesByTypeAndMake: useCallback(
      (deviceType, deviceMake) => {
        if (!deviceType || !deviceMake) {
          return [];
        }
        return sortBy(
          uniq(
            devices
              .filter(
                (d) =>
                  d.attributes?.type === deviceType &&
                  d.attributes.manufacturer?.data?.attributes?.name ===
                    deviceMake
              )
              .map((d) => d.attributes?.series) as Enum_Device_Series[]
          )
        );
      },
      [devices]
    ),
  };
};
