import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "components/utils/Table";
import { OrderPackageDataFragment } from "data";
import { LinkToOrderPackage } from "features/order-packages/components/LinkToOrderPackage";
import { OrderPackageStatusLabel } from "features/order-packages/components/OrderPackageStatusLabel";

interface OrderPackagesTableProps {
  orderPackages: OrderPackageDataFragment[];
}

export const OrderPackagesTable = (props: OrderPackagesTableProps) => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.orderPackages.map((orderPackage) => (
            <Tr key={orderPackage.id}>
              <Td fontWeight="bold">
                <LinkToOrderPackage orderPackage={orderPackage}>
                  {orderPackage.attributes?.name}
                </LinkToOrderPackage>
              </Td>
              <Td>
                <OrderPackageStatusLabel orderPackage={orderPackage} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
