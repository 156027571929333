import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  VisuallyHidden,
  MenuDivider,
} from "@chakra-ui/react";
import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { PrintIcon, ContextualMenuIcon } from "components/Icons";
import { LabelPrinterProvider, useLabelPrinter } from "context/LabelPrinter";
import { CustomerDeviceLabel } from "features/customer-devices/components/CustomerDeviceLabel";
import { DiagnosticsAction } from "features/diagnostics/components/DiagnosticsAction";
import { RepairAction } from "features/repairs/components/RepairAction";
import { QAAction } from "features/qa/components/QAAction";
import { ScrapForPartsAction } from "features/order-items/components/ScrapForPartsAction";
import { ReturnToCustomerAction } from "features/order-items/components/ReturnToCustomerAction";
import { RecycleAction } from "features/order-items/components/RecycleAction";
import {
  CleanAction,
  ReflowAction,
  ApplyThermalPasteAction,
  PurchaseAction,
  UpdateFirmwareAction,
  ReplaceControlBoardAction,
  ReplacePSUAction,
  ReplacePSUFanAction,
  ReplaceFanAction,
} from "features/order-items/components";
import { ShowForOrderItemStatus } from "./ShowForOrderItemStatus";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { ShowForHashboard } from "features/devices/components/ShowForHashboard";
import { ShowForPSU } from "features/devices/components/ShowForPSU";

interface OrderItemPageActionsProps {
  orderItem: OrderItemDataFragment;
}

const ConnectedOrderItemPageActions = (props: OrderItemPageActionsProps) => {
  const { orderItem } = props;
  const { printableRef, print } = useLabelPrinter();
  const customerDevice = orderItem.attributes?.customer_device?.data;

  return (
    <>
      {/* Render hidden CustomerDeviceLabel for printing */}
      {customerDevice ? (
        <VisuallyHidden>
          <CustomerDeviceLabel printableRef={printableRef} device={customerDevice} />
          <CustomerDeviceLabel printableRef={printableRef} device={customerDevice} />
        </VisuallyHidden>
      ) : null}
      <HStack>
        {/* Render DiagnosticsAction button for specific order item status */}
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[Enum_Orderitem_Status.DiagnosisPending]}
        >
          <DiagnosticsAction orderItem={orderItem} variant='primary-action-button' />
        </ShowForOrderItemStatus>
        {/* Render RepairAction button for specific order item statuses */}
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[
            Enum_Orderitem_Status.DiagnosedNeedsRepair,
            Enum_Orderitem_Status.RepairedFailed,
            Enum_Orderitem_Status.QaFail,
          ]}
        >
          <RepairAction orderItem={orderItem} variant='primary-action-button' />
        </ShowForOrderItemStatus>
        {/* Render QAAction button for specific order item statuses */}
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[Enum_Orderitem_Status.RepairedNeedsQa, Enum_Orderitem_Status.DiagnosedIsOk]}
        >
          <QAAction variant='primary-action-button' orderItem={orderItem} />
        </ShowForOrderItemStatus>
        {/* Render ScrapForPartsAction button for specific order item status */}
        <ShowForOrderItemStatus
          orderItem={orderItem}
          status={[Enum_Orderitem_Status.QaFailRecycled]}
        >
          <ScrapForPartsAction variant='primary-action-button' orderItem={orderItem} />
        </ShowForOrderItemStatus>
        {/* Dropdown menu for additional actions */}
        <Menu>
          <MenuButton
            as={IconButton}
            data-cy='order-item-context-menu'
            aria-label='Options'
            icon={<ContextualMenuIcon />}
            variant='ghost'
          />
          <MenuList>
            {/* Render Print Label menu item if customerDevice exists */}
            {customerDevice ? (
              <MenuItem
                onClick={() => {
                  print();
                }}
                icon={<PrintIcon />}
              >
                Print Label
              </MenuItem>
            ) : null}
            <DiagnosticsAction orderItem={orderItem} variant='menu-item' />

            {/* Render miner-specific actions */}
            <ShowForMiner
              device={orderItem.attributes?.customer_device?.data?.attributes?.device?.data}
            >
              <>
                <UpdateFirmwareAction orderItem={orderItem} />
                <MenuDivider />
                <ReplaceFanAction orderItem={orderItem} />
                <ReplaceControlBoardAction orderItem={orderItem} />
                <MenuDivider />
                <ReplacePSUAction orderItem={orderItem} />
                <ReplacePSUFanAction orderItem={orderItem} />
              </>
            </ShowForMiner>
            {/* Render hashboard-specific actions */}
            <ShowForHashboard
              device={orderItem.attributes?.customer_device?.data?.attributes?.device?.data}
            >
              <>
                <CleanAction orderItem={orderItem} />
                <ReflowAction orderItem={orderItem} />
                <ApplyThermalPasteAction orderItem={orderItem} />
              </>
            </ShowForHashboard>
            {/* Render PSU-specific actions */}
            <ShowForPSU
              device={orderItem.attributes?.customer_device?.data?.attributes?.device?.data}
            >
              <ReplacePSUFanAction orderItem={orderItem} />
            </ShowForPSU>
            {/* Render additional actions for specific order item statuses */}
            <ShowForOrderItemStatus
              inverse
              orderItem={orderItem}
              status={[
                Enum_Orderitem_Status.QaFailRecycled,
                Enum_Orderitem_Status.QaFailReturnToCustomer,
              ]}
            >
              <>
                <MenuDivider />
                <PurchaseAction orderItem={orderItem} />
                <ReturnToCustomerAction orderItem={orderItem} variant='menu-item' />
                <RecycleAction orderItem={orderItem} variant='menu-item' />
                <ScrapForPartsAction orderItem={orderItem} variant='menu-item' />
              </>
            </ShowForOrderItemStatus>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
};

// Wrap ConnectedOrderItemPageActions with LabelPrinterProvider
export const OrderItemPageActions = (props: OrderItemPageActionsProps) => {
  return (
    <LabelPrinterProvider>
      <ConnectedOrderItemPageActions {...props} />
    </LabelPrinterProvider>
  );
};
