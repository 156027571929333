import { createContext, ReactNode, useContext } from "react";
import {
  InspectionDataFragment,
  InspectionRackDataFragment,
  useRacksForInspectionQuery,
} from "data";

interface InspectionRacksProviderProps {
  inspection: InspectionDataFragment;
  children: ReactNode;
}

interface InspectionRacksData {
  loading: boolean;
  racks: InspectionRackDataFragment[];
}

const InspectionRacksContext = createContext<InspectionRacksData>(
  {} as InspectionRacksData
);

export const useInspectionRacks = () => {
  return useContext(InspectionRacksContext);
};

export const InspectionRacksProvider = (
  props: InspectionRacksProviderProps
) => {
  const { loading, data } = useRacksForInspectionQuery({
    variables: {
      inspectionId: props.inspection.id!,
    },
  });

  return (
    <InspectionRacksContext.Provider
      value={{
        loading,
        racks: loading ? [] : data?.inspectionRacks?.data || [],
      }}
    >
      <>{props.children}</>
    </InspectionRacksContext.Provider>
  );
};
