import { Icon } from "@chakra-ui/react";
import { SpreadsheetIcon } from "components/Icons";
import { ActionButton, ActionButtonProps } from "components/utils/ActionButton";
import { useDownloadReport } from "features/reports/data";

interface DownloadReportButtonProps extends Omit<ActionButtonProps, "action"> {
  reportName: string;
  parameters?: object;
  label?: string;
  "data-cy"?: string;
}

export const DownloadReportButton = (props: DownloadReportButtonProps) => {
  const { reportName, label, parameters, ...rest } = props;
  const { downloadReport } = useDownloadReport();
  return (
    <ActionButton
      data-cy={props["data-cy"] || "download-report"}
      {...rest}
      action={async () => {
        await downloadReport({ reportName, parameters });
      }}
    >
      <Icon mr="2" as={SpreadsheetIcon} />
      {label || "Download CSV"}
    </ActionButton>
  );
};
