import { Text, Circle } from "@chakra-ui/react";
import {
  Enum_Orderitem_Status,
  OrderStatDataFragment,
  OrderDataFragment,
} from "data";

interface OrderStatsBadgeProps {
  order: OrderDataFragment;
}

const completedCount = (orderStats: OrderStatDataFragment[]): number => {
  return orderStats
    .filter(
      (stat) =>
        [
          Enum_Orderitem_Status.Packaged,
          Enum_Orderitem_Status.Shipped,
          Enum_Orderitem_Status.ExcessiveDamageScrapForParts,
          Enum_Orderitem_Status.QaFailRecycled,
          Enum_Orderitem_Status.QaFailReturnToCustomer,
          Enum_Orderitem_Status.ReplacedRecycled,
          Enum_Orderitem_Status.ReplacedReturnToCustomer,
        ].indexOf(stat.status as Enum_Orderitem_Status) !== -1
    )
    .reduce((sum, stat) => sum + (stat.count || 0), 0);
};

const percentageToColor = (
  percentage: number
): "red.500" | "green.500" | "orange.300" => {
  if (percentage < 50) {
    return "red.500";
  }

  if (percentage < 90) {
    return "orange.300";
  }

  return "green.500";
};

export const OrderStatsBadge = (props: OrderStatsBadgeProps) => {
  const { order } = props;
  const orderStats = order.attributes?.stats;

  if (!orderStats) {
    return null;
  }

  const total = orderStats.reduce((sum, stat) => sum + (stat.count || 0), 0);
  const completed = completedCount(orderStats);

  if (total === 0) {
    return (
      <Circle size="40px" bg="gray.500" color="white">
        <Text fontSize="small">N/A</Text>
      </Circle>
    );
  }

  const completionPercentage = Math.floor((completed / total) * 100);

  return (
    <Circle
      size="30px"
      bg={percentageToColor(completionPercentage)}
      color="white"
    >
      <Text fontSize="xs">{completionPercentage}%</Text>
    </Circle>
  );
};
