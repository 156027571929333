import {
  FormControl,
  Box,
  Input,
  RadioGroup,
  Radio,
  HStack,
} from "@chakra-ui/react";
import { parseIntOrUndefined } from "lib";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { HashboardDiagnosticsReport } from "features/diagnostics/data";

export const ASICsDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const hashboardDiagnosticsReport = data as HashboardDiagnosticsReport;
  return (
    <>
      <FormControl>
        <Input
          placeholder="Initial number of ASICs found"
          type="number"
          value={hashboardDiagnosticsReport.initialASICsFound}
          onChange={(e) => {
            updateData(
              Object.assign({}, hashboardDiagnosticsReport, {
                initialASICsFound: parseIntOrUndefined(e.currentTarget.value),
              })
            );
          }}
        />
      </FormControl>
      <Box my="4">
        <RadioGroup
          onChange={(value) => {
            updateData(
              Object.assign({}, hashboardDiagnosticsReport, {
                asicsRepairsNeeded: value === "1" ? true : false,
              })
            );
          }}
          value={hashboardDiagnosticsReport.asicsRepairsNeeded ? "1" : "0"}
        >
          <HStack spacing="8">
            <Radio value="1">ASIC repairs Needed</Radio>
            <Radio value="0">No</Radio>
          </HStack>
        </RadioGroup>
      </Box>
    </>
  );
};
