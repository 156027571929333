import {
  useCreateOrderMutation,
  OrderDataFragment,
  ZohoSalesOrderDataFragment,
  LocationDataFragment,
} from "data";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface UserOrdersDataHookData {
  createOrderFromZohoSalesOrder: (
    zohoSalesOrder: ZohoSalesOrderDataFragment,
    location: LocationDataFragment
  ) => Promise<OrderDataFragment | undefined | null>;
}

export const useOrdersData = (): UserOrdersDataHookData => {
  const [createOrder] = useCreateOrderMutation();
  const { createAction } = useCreateAction();
  return {
    async createOrderFromZohoSalesOrder(zohoSalesOrder, location) {
      if (!zohoSalesOrder.id || !location.id) {
        return;
      }

      const d = await createOrder({
        variables: {
          zohoSalesOrderId: zohoSalesOrder.id,
          locationId: location.id,
        },
      });

      const order = d.data?.createOrder?.data;

      await createAction({
        data: {
          ...expandActionDataPiece(order),
        },
        scope: Enum_Action_Scope.Order,
        type: Enum_Action_Type.ReceiveOrder,
        context: {
          order,
        },
      });

      return order;
    },
  };
};
