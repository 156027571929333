import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { OrderItemDataFragment } from "data";
import { OrderItemContextPanelContainer as OrderItemContextPanel } from "features/order-items/containers/OrderItemContextPanel";
import { LabelPrinterProvider } from "context/LabelPrinter";

interface OrderItemContextMenuProviderProps {
  children: ReactNode;
}

interface OrderItemContextMenuData {
  openMenu: (orderItem: OrderItemDataFragment) => void;
}

const OrderItemContextMenuContext = createContext<OrderItemContextMenuData>(
  {} as OrderItemContextMenuData
);

export const useOrderItemContextMenu = () => {
  return useContext(OrderItemContextMenuContext);
};

export const OrderItemContextMenuProvider = (
  props: OrderItemContextMenuProviderProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderItem, setOrderItem] =
    useState<OrderItemDataFragment | null>(null);
  const closeDrawer = () => {
    onClose();
    setOrderItem(null);
  };
  return (
    <OrderItemContextMenuContext.Provider
      value={{
        openMenu: useCallback((oi) => {
          setOrderItem(oi);
          onOpen();
        }, []),
      }}
    >
      {props.children}
      <Drawer
        isOpen={isOpen && orderItem !== null}
        placement="right"
        size="md"
        onClose={() => {
          closeDrawer();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton data-cy="action-panel-close-button" />
          <DrawerHeader />
          <DrawerBody>
            {orderItem ? (
              <LabelPrinterProvider>
                <OrderItemContextPanel
                  onAfterDelete={() => {
                    closeDrawer();
                  }}
                  orderItem={orderItem}
                />
              </LabelPrinterProvider>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </OrderItemContextMenuContext.Provider>
  );
};
