import { Box, Text, Alert, AlertIcon } from "@chakra-ui/react";
import { RadioCard, RadioCardGroup } from "components/forms/RadioCardGroup";
import { SearchBox } from "components/forms/SearchBox";
import { ZohoSalesOrderDataFragment } from "data";

interface ZohoSalesOrdersSelectorProps {
  orders: ZohoSalesOrderDataFragment[];
  currentSearchTerm: string;
  selectedSalesOrderId: string;
  onUpdateSelectedSalesOrderId: (orderId: string) => void;
  onUpdateSearchTerm: (value: string) => void;
}

export const ZohoSalesOrdersSelector = ({
  currentSearchTerm,
  selectedSalesOrderId,
  onUpdateSelectedSalesOrderId,
  orders,
  onUpdateSearchTerm,
}: ZohoSalesOrdersSelectorProps) => (
  <>
    <Box my="4">
      <SearchBox onChange={onUpdateSearchTerm} value={currentSearchTerm} />
    </Box>
    {orders.length === 0 ? (
      <Alert status="info">
        <AlertIcon />
        No valid Sales Orders found in Zoho. Make sure to set sales order status
        to "In transit to ACS"
      </Alert>
    ) : (
      <RadioCardGroup
        onChange={(orderId) => {
          onUpdateSelectedSalesOrderId(orderId);
        }}
        value={selectedSalesOrderId}
        spacing="3"
      >
        {orders.map((order) => {
          const address =
            order.attributes?.shippingAddress ||
            order.attributes?.billingAddress;
          const addressElements = !address
            ? []
            : [address.street, address.state, address.zip].filter(
                (ae) => ae !== "" && ae !== null
              );
          return (
            <RadioCard
              key={order.attributes?.zohoId}
              data-cy={`zoho-sales-order-${order.attributes?.zohoId}`}
              value={order.attributes?.zohoId || ""}
            >
              <Text color="emphasized" fontWeight="medium" fontSize="sm">
                {order.attributes?.accountName} -{" "}
                {order.attributes?.salesOrderNumber}
              </Text>
              <Text color="muted" fontSize="sm">
                {order.attributes?.customer?.data?.attributes?.accountName}
                {addressElements.length !== 0
                  ? ` - ${addressElements.join(", ")}`
                  : ""}
              </Text>
            </RadioCard>
          );
        })}
      </RadioCardGroup>
    )}
  </>
);
