import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  BoxProps,
  Divider,
  Stack,
  Text,
  Circle,
  Icon,
  SquareProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HiCheck } from "react-icons/hi";

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive } = props;
  return (
    <Circle
      size="8"
      bg={isCompleted ? "accent" : "inherit"}
      borderWidth={isCompleted ? "0" : "2px"}
      borderColor={isActive ? "accent" : "inherit"}
      {...props}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="inverted" boxSize="5" />
      ) : (
        <Circle bg={isActive ? "accent" : "border"} size="3" />
      )}
    </Circle>
  );
};

interface StepProps extends BoxProps {
  title: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
}

export const Step = (props: StepProps) => {
  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    ...stackProps
  } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const orientation = useBreakpointValue<"horizontal" | "vertical">({
    base: "vertical",
    md: "horizontal",
  });

  return (
    <Stack
      spacing="4"
      direction={{ base: "row", md: "column" }}
      flex="1"
      {...stackProps}
    >
      <Stack
        spacing="0"
        align="center"
        direction={{ base: "column", md: "row" }}
      >
        <Divider
          display={isMobile ? "none" : "initial"}
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isFirstStep
              ? "transparent"
              : isCompleted || isActive
              ? "accent"
              : "inherit"
          }
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isCompleted ? "accent" : isLastStep ? "transparent" : "inherit"
          }
        />
      </Stack>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? "8" : "0"}
        align={{ base: "start", md: "center" }}
      >
        <Text color="emphasized" fontWeight="medium">
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};

interface Helpers {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  reset: () => void;
  canGoToNextStep: boolean;
  canGoToPrevStep: boolean;
  isLastStep: boolean;
  setStep: Dispatch<SetStateAction<number>>;
}

interface UseStepProps {
  maxStep: number;
  initialStep?: number;
}

export const useStep = (props: UseStepProps): [number, Helpers] => {
  const { maxStep, initialStep = 0 } = props;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const canGoToNextStep = useMemo(
    () => currentStep + 1 <= maxStep,
    [currentStep, maxStep]
  );
  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep]);
  const isLastStep = useMemo(
    () => currentStep === maxStep,
    [currentStep, maxStep]
  );

  const setStep = useCallback(
    (step: unknown) => {
      const newStep = step instanceof Function ? step(currentStep) : step;
      if (newStep >= 0 && newStep <= maxStep) {
        setCurrentStep(newStep);
        return;
      }
      throw new Error("Step not valid");
    },
    [maxStep, currentStep]
  );

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1);
    }
  }, [canGoToNextStep]);

  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep((step) => step - 1);
    }
  }, [canGoToPrevStep]);

  const reset = useCallback(() => {
    setCurrentStep(0);
  }, []);

  return [
    currentStep,
    {
      goToNextStep,
      goToPrevStep,
      canGoToNextStep,
      canGoToPrevStep,
      isLastStep,
      setStep,
      reset,
    },
  ];
};
