import { createContext, useContext, ReactNode } from "react";
import { useDevicesData } from "features/devices/data/hooks/devices";
import type { UseDevicesDataHookData } from "features/devices/data/hooks/devices";

const DevicesContext = createContext<UseDevicesDataHookData>(
  {} as UseDevicesDataHookData
);

interface DevicesProviderProps {
  children: ReactNode;
}

export const DevicesProvider = (props: DevicesProviderProps) => {
  const devicesData = useDevicesData();

  return (
    <DevicesContext.Provider value={devicesData}>
      {props.children}
    </DevicesContext.Provider>
  );
};

export const useDevices = () => useContext(DevicesContext);
