import * as Yup from "yup";
import { Formik } from "formik";
import { TextArea, YesNoButtons } from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";

const VisualInspectionSchema = Yup.object().shape({
  visualGotCorrosion: Yup.boolean().required(),
  visualGotBurnDamage: Yup.boolean().required(),
  visualGotPhysicalDamage: Yup.boolean().required(),
  visualIsRepairable: Yup.boolean().required(),
  visualNotes: Yup.string(),
});

export const VisualInspectionForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        visualGotCorrosion:
          typeof report.visualGotCorrosion !== "undefined"
            ? report.visualGotCorrosion
            : false,
        visualGotBurnDamage:
          typeof report.visualGotBurnDamage !== "undefined"
            ? report.visualGotBurnDamage
            : false,
        visualGotPhysicalDamage:
          typeof report.visualGotPhysicalDamage !== "undefined"
            ? report.visualGotPhysicalDamage
            : false,
        visualIsRepairable: report.visualIsRepairable,
        visualNotes: report.visualNotes,
      }}
      validationSchema={VisualInspectionSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.visualIsRepairable !== "undefined" && isValid
          }
          canGoToPrevStep={false}
          title="Visual Inspection"
          values={values}
        >
          <YesNoButtons label="Corrosion" name="visualGotCorrosion" />

          <YesNoButtons label="Burn damage" name="visualGotBurnDamage" />

          <YesNoButtons
            label="Physical damage"
            name="visualGotPhysicalDamage"
          />

          <YesNoButtons label="Repairable" name="visualIsRepairable" />

          <TextArea label="Notes (Optional)" name="visualNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
