import { Stack, Icon, Text } from "@chakra-ui/react";
import {
  CheckboxGridCardGroup,
  CheckboxCard,
} from "components/forms/CheckboxCardGroup";
import {
  HashboardIcon,
  ControlBoardIcon,
  PSUIcon,
  IconType,
} from "components/Icons";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { MinerDiagnosticsReport } from "features/diagnostics/data";

const PossibleProblems = ["Hashboard", "Control Board", "PSU"];

const getIconFromString = (str: string): IconType | undefined => {
  switch (str) {
    case "Hashboard":
      return HashboardIcon;
    case "Control Board":
      return ControlBoardIcon;
    case "PSU":
      return PSUIcon;
    default:
      return undefined;
  }
};

export const SuggestedProblems = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const currentReport = data as MinerDiagnosticsReport;
  return (
    <CheckboxGridCardGroup
      onChange={(suggestedProblems) => {
        updateData(
          Object.assign({}, currentReport, {
            suggestedProblems,
          })
        );
      }}
      value={currentReport.suggestedProblems || []}
    >
      {PossibleProblems.map((problem) => {
        return (
          <CheckboxCard key={problem} value={problem}>
            <Stack alignItems={"center"}>
              <Icon as={getIconFromString(problem)} w={6} h={6} />
              <Text>{problem}</Text>
            </Stack>
          </CheckboxCard>
        );
      })}
    </CheckboxGridCardGroup>
  );
};
