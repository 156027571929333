// XX: we use REST API for login/register since it has better error handling
// compared to its GraphQL version
// ALSO: we rely on cookies sent over by the auth handler
const graphqlAPIEndpoint = process.env.REACT_APP_ACS_BACKOFFICE_UI_APOLLO_URI;
const restAPIEndpoint = graphqlAPIEndpoint?.replace("graphql", "api");

export const UserAlreadyExistsError = "User already exists";
export const EmailNotConfirmed = "Email address is not confirmed";

export const register = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const r = await fetch(`${restAPIEndpoint}/auth/local/register`, {
    method: "POST",
    body: JSON.stringify({
      username: email,
      email,
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const d = await r.json();

  if (d.error) {
    if ((d.error.message || "").match(/already taken/gi)) {
      throw new Error(UserAlreadyExistsError);
    }

    throw new Error(d.error.message);
  }
};

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<void> => {
  const r = await fetch(`${restAPIEndpoint}/auth/local`, {
    method: "POST",
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  const d = await r.json();

  if (d.error) {
    if ((d.error.message || "").match(/not confirmed/gi)) {
      throw new Error(EmailNotConfirmed);
    }

    throw new Error(d.error.message);
  }
};

export const logout = async (): Promise<void> => {
  await fetch(`${restAPIEndpoint}/auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
};

export const forgotPassword = async ({
  email,
}: {
  email: string;
}): Promise<void> => {
  await fetch(`${restAPIEndpoint}/auth/forgot-password`, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const resetPassword = async ({
  password,
  passwordConfirmation,
  code,
}: {
  password: string;
  passwordConfirmation: string;
  code: string;
}): Promise<void> => {
  const r = await fetch(`${restAPIEndpoint}/auth/reset-password`, {
    method: "POST",
    body: JSON.stringify({ code, password, passwordConfirmation }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const d = await r.json();

  if (d.error) {
    throw new Error(d.error.message);
  }
};

export const resendConfirmationEmail = async (email: string): Promise<void> => {
  const r = await fetch(`${restAPIEndpoint}/auth/send-email-confirmation`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const d = await r.json();

  if (d.error) {
    throw new Error(d.error.message);
  }
};
