import { useState } from "react";
import * as Yup from "yup";
import { Stack, Button, Divider, Text, Checkbox, Link } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { CustomerIntakeFormInput, CustomerProfileFragment } from "data";
import {
  Checkboxes,
  TextInput,
  TextArea,
  FileUpload,
} from "components/forms/fields";

const IntakeSchema = Yup.object().shape({
  typesOfEquipment: Yup.array().of(Yup.string()).required("Required"),
  totalPieces: Yup.number()
    .typeError("Please provide a valid number")
    .required("Required"),
  manufacturersOfEquipment: Yup.array().of(Yup.string()).required("Required"),
  manifest: Yup.mixed(),
  referenceNumber: Yup.string(),
  notes: Yup.string(),
  attention: Yup.string().required("Required"),
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zipCode: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
});

interface IntakeFormProps {
  customer: CustomerProfileFragment;
  onSubmit: ({
    data,
    manifestFile,
  }: {
    data: CustomerIntakeFormInput;
    manifestFile: File | null;
  }) => Promise<void>;
}

export const IntakeForm = ({ onSubmit, customer }: IntakeFormProps) => {
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState<boolean>(false);
  const { details } = customer.attributes || {};
  return (
    <Formik
      initialValues={{
        typesOfEquipment: "",
        totalPieces: "",
        manufacturersOfEquipment: "",
        manifest: "",
        referenceNumber: "",
        notes: "",
        attention:
          details?.firstName && details?.lastName
            ? [details.firstName, details.lastName].join(" ")
            : "",
        addressLine1: details?.shippingAddress?.addressLine1 || "",
        addressLine2: details?.shippingAddress?.addressLine2 || "",
        city: details?.shippingAddress?.city || "",
        state: details?.shippingAddress?.state || "",
        zipCode: details?.shippingAddress?.zipCode || "",
        country: "US",
        phone: "",
      }}
      validationSchema={IntakeSchema}
      onSubmit={async (values) => {
        const {
          manifest,
          totalPieces,
          attention,
          addressLine1,
          addressLine2,
          city,
          state,
          zipCode,
          country,
          phone,
          ...data
        } = values;
        await onSubmit({
          // @ts-ignore
          data: Object.assign(
            {},
            data,
            {
              totalPieces: parseInt(totalPieces, 10),
            },
            {
              shippingAddress: {
                attention,
                addressLine1,
                addressLine2: addressLine2 === "" ? null : addressLine2,
                city,
                state,
                zipCode,
                country,
                phone,
              },
            }
          ),
          // @ts-ignore
          manifestFile: manifest !== "" ? (manifest as File) : null,
        });
      }}
    >
      {({ errors, touched, isSubmitting, isValid }) => (
        <Form>
          <Stack spacing={2}>
            <Checkboxes
              label="What type of equipment are you sending?"
              name="typesOfEquipment"
              supportedValues={[
                "Fully assembled miners",
                "Hash boards",
                "Control boards",
                "Power supplies",
              ]}
            />
            <Checkboxes
              label="Choose the manufacturer of your equipment"
              name="manufacturersOfEquipment"
              supportedValues={["Bitmain", "Canaan", "MicroBT", "Other"]}
            />
            <TextInput
              label="How many total pieces of equipment are you sending?"
              placeholder="How many total pieces of equipment are you sending?"
              name="totalPieces"
              type="text"
            />
            <TextInput
              label="Reference Number (Optional)"
              placeholder="Do you have reference number for this order?"
              name="referenceNumber"
              type="text"
            />
            <TextArea
              label="Notes (Optional)"
              placeholder="Is there anything else we need to know before getting started?"
              name="notes"
            />
            <FileUpload
              label="Upload manifest with serial numbers (csv or excel)"
              name="manifest"
            />
            <Text mt={8} fontSize="lg" fontWeight="bold">
              Shipping Address
            </Text>
            <Divider />
            <TextInput label="Attention" name="attention" type="text" />
            <TextInput label="Address line 1" name="addressLine1" type="text" />
            <TextInput label="Address line 2" name="addressLine2" type="text" />
            <TextInput label="City" name="city" type="text" />
            <TextInput label="State" name="state" type="text" />
            <TextInput label="Zip" name="zipCode" type="text" />
            <TextInput label="Country" name="country" type="text" />
            <TextInput label="Phone" name="phone" type="text" />
          </Stack>
          <Stack spacing={5} mt={10}>
            <Checkbox
              data-cy="accept-terms-and-conditions"
              onChange={(e) => {
                setAcceptTermsAndConditions(e.currentTarget.checked);
              }}
              isChecked={acceptTermsAndConditions}
            >
              I accept{" "}
              <Link
                href="https://help.advancedcryptoservices.com/en/articles/8436692-terms-conditions"
                isExternal
              >
                terms and conditions
              </Link>
            </Checkbox>
            <Button
              isDisabled={!isValid || isSubmitting || !acceptTermsAndConditions}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
