import {
  useQuery,
  DocumentNode,
  WatchQueryFetchPolicy,
  OperationVariables,
} from "@apollo/client";
import { createContext, ReactNode } from "react";
import { useDataSourceSearchParams } from "data/data-source";

export interface DataSourceSpec<
  Query,
  QueryVariables extends OperationVariables
> {
  loading: boolean;
  data?: Query;
  refetch: () => Promise<void>;
  params: QueryVariables;
  setParams: (params: QueryVariables) => void;
}

interface ProviderProps<QueryVariables> {
  children: ReactNode;
  processParams?: (params: QueryVariables) => QueryVariables;
}

interface GeneratorSpec<QueryVariables> {
  parseFilters: (searchParams: Array<[string, string?]>) => QueryVariables;
  serializeFilters: (filters: QueryVariables) => Array<[string, string?]>;
  QueryDocument: DocumentNode;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function generateContextAndProvider<
  Query,
  QueryVariables extends OperationVariables
>(spec: GeneratorSpec<QueryVariables>) {
  const { QueryDocument, parseFilters, serializeFilters, fetchPolicy } = spec;
  const Context =
    createContext<DataSourceSpec<Query, QueryVariables> | null>(null);

  const Provider = (props: ProviderProps<QueryVariables>) => {
    const { children, processParams } = props;
    const { fromURLSearchParams, toURLSearchParams } =
      useDataSourceSearchParams<QueryVariables>({
        parseFilters,
        serializeFilters,
      });
    const { loading, data, refetch } = useQuery<Query, QueryVariables>(
      QueryDocument,
      {
        variables: processParams
          ? processParams(fromURLSearchParams())
          : fromURLSearchParams(),
        fetchPolicy,
      }
    );

    return (
      <Context.Provider
        value={{
          loading,
          data,
          refetch: async () => {
            await refetch();
          },
          params: fromURLSearchParams(),
          setParams: (params) => {
            toURLSearchParams(params);
          },
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
}
