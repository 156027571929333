import { useState } from "react";
import { Box, Button, HStack, Stack, Textarea } from "@chakra-ui/react";
import {
  UploadFileDataFragment,
  OrderItemDataFragment,
  OrderDataFragment,
} from "data";
import { Upload } from "features/uploads/components/Upload";
import { ListUploads } from "features/uploads/components/ListUploads";
import { useCreateNote } from "features/notes/data/hooks/notes";

interface NoteComposerProps {
  attachedTo: OrderItemDataFragment | OrderDataFragment;
}

export const NoteComposer = (props: NoteComposerProps) => {
  const { attachedTo } = props;
  const [working, setWorking] = useState<boolean>(false);
  const [content, setContent] = useState<string | null>(null);
  const [uploads, setUploads] = useState<UploadFileDataFragment[]>([]);
  const canSubmit = (content && content !== "") || uploads.length !== 0;
  const { createNote } = useCreateNote();

  return (
    <Stack>
      <Textarea
        onChange={(e) => {
          setContent(
            e.currentTarget.value.length !== 0 ? e.currentTarget.value : null
          );
        }}
        value={content || ""}
        placeholder="Notes"
      />
      <ListUploads uploads={uploads} />
      <HStack textAlign="right" pb="4">
        <Upload
          dropzoneVariant="small"
          promptText="Select file to upload"
          onStartUpload={() => {
            setWorking(true);
          }}
          onUpload={(files) => {
            setWorking(false);
            setUploads((currentFiles) => [...files, ...currentFiles]);
          }}
        />
        <Box flex="1">
          <Button
            isLoading={working}
            isDisabled={!canSubmit || working}
            onClick={async () => {
              try {
                setWorking(true);
                await createNote({
                  note: {
                    content,
                    attachments: uploads
                      .map((u) => u.id)
                      .filter((id) => id) as string[],
                  },
                  attachedTo,
                });

                setContent(null);
                setUploads([]);
              } finally {
                setWorking(false);
              }
            }}
          >
            Post
          </Button>
        </Box>
      </HStack>
    </Stack>
  );
};
