import {
  InspectionRackDataFragment,
  InspectionDataFragment,
  InspectedDeviceDataFragment,
  useCreateCustomerDeviceMutation,
  useCreateInspectedDeviceMutation,
} from "data";
import { useInspectionSettings } from "features/inspections/context/InspectionSettings";

export const MISSING_SERIAL_PLACEHOLDER = "na";

interface BulkCheckinParams {
  inspection: InspectionDataFragment;
  serialNumbers: string[];
  rack: InspectionRackDataFragment;
}

interface UseBulkCheckinHookData {
  bulkCheckin: (
    params: BulkCheckinParams
  ) => Promise<InspectedDeviceDataFragment[]>;
}

export const useBulkCheckin = (): UseBulkCheckinHookData => {
  const { targetDevice } = useInspectionSettings();
  const [createCustomerDevice] = useCreateCustomerDeviceMutation();
  const [createInspectedDevice] = useCreateInspectedDeviceMutation();

  return {
    async bulkCheckin(params) {
      const results = [];
      const { serialNumbers, inspection, rack } = params;
      for (let i = 0; i < serialNumbers.length; i++) {
        const serialNumber = serialNumbers[i];

        const result = await createCustomerDevice({
          variables: {
            serialNumber:
              serialNumber.toLowerCase() === MISSING_SERIAL_PLACEHOLDER
                ? undefined
                : serialNumber,
            device: targetDevice.id!,
            customer: inspection.attributes?.customer?.data?.id || "",
          },
        });

        const { data } = await createInspectedDevice({
          variables: {
            data: {
              customer_device: result.data?.createCustomerDevice?.data?.id,
              inspection: inspection.id,
              inspection_rack: rack.id!,
              rackPosition: i,
            },
          },
        });

        if (data?.createInspectedDevice?.data) {
          results.push(data.createInspectedDevice.data);
        }
      }
      return results;
    },
  };
};
