import {
  Button,
  ButtonProps,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  label: string;
  path: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { label, path, onClick, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const navigate = useNavigate();
  const location = useLocation();

  return isDesktop ? (
    <Button
      onClick={(e) => {
        navigate(path);
        if (onClick) {
          onClick(e);
        }
      }}
      {...(location.pathname.match(path) ? { "aria-current": "page" } : {})}
    >
      {label}
    </Button>
  ) : (
    <Button
      onClick={(e) => {
        navigate(path);
        if (onClick) {
          onClick(e);
        }
      }}
      variant="ghost"
      justifyContent="start"
      {...buttonProps}
      {...(location.pathname.match(path) ? { "aria-current": "page" } : {})}
    >
      <HStack spacing="3">
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
