import { useState } from "react";
import { Loader } from "components/Loader";
import {
  DeviceSelector,
  DeviceSelectorStage,
} from "features/devices/components/DeviceSelector";
import { useDevices } from "features/devices/context/Devices";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Series } from "data";

interface DeviceSelectorContainerProps {
  onDeviceChange?: (device: DeviceDataFragment | null) => void;
}

export const DeviceSelectorContainer = (
  props: DeviceSelectorContainerProps
) => {
  const { onDeviceChange } = props;
  const [deviceType, setDeviceType] = useState<Enum_Device_Type | null>(null);
  const [deviceMake, setDeviceMake] = useState<string | null>(null);
  const [deviceSeries, setDeviceSeries] = useState<Enum_Device_Series | null>(
    null
  );
  const [stage, setStage] = useState<DeviceSelectorStage>("device_type");
  const {
    deviceTypes,
    makesByDeviceType,
    devicesByTypeAndMake,
    deviceSeriesByTypeAndMake,
    loading,
  } = useDevices();
  const [selectedDevice, setSelectedDevice] =
    useState<DeviceDataFragment | null>(null);

  return loading ? (
    <Loader />
  ) : (
    <DeviceSelector
      goBack={() => {
        if (selectedDevice) {
          setStage("device");
          setSelectedDevice(null);
          if (onDeviceChange) {
            onDeviceChange(null);
          }
          return;
        }

        if (stage === "device") {
          if (deviceSeries !== null) {
            setDeviceSeries(null);
            setStage("series");
          } else {
            setDeviceMake(null);
            setStage("make");
          }

          return;
        }

        if (stage === "make") {
          setDeviceType(null);
          setDeviceMake(null);
          setStage("device_type");
          return;
        }

        if (stage === "series") {
          setDeviceSeries(null);
          setDeviceMake(null);
          setStage("make");
          return;
        }
      }}
      stage={stage}
      selectedDeviceType={deviceType}
      selectedDeviceMake={deviceMake}
      selectedDeviceSeries={deviceSeries}
      selectedDevice={selectedDevice}
      onSelectDevice={(device) => {
        setSelectedDevice(device);

        if (onDeviceChange) {
          onDeviceChange(device);
        }
      }}
      devices={devicesByTypeAndMake(deviceType, deviceMake).filter((d) => {
        if (deviceSeries) {
          return d.attributes?.series === deviceSeries;
        }
        return true;
      })}
      deviceTypes={deviceTypes}
      makes={makesByDeviceType(deviceType)}
      deviceSeries={deviceSeriesByTypeAndMake(deviceType, deviceMake)}
      onSelectDeviceType={(deviceType: Enum_Device_Type) => {
        setDeviceType(deviceType);
        if (deviceType) {
          setStage("make");
        }
      }}
      onSelectDeviceMake={(deviceMake) => {
        setDeviceMake(deviceMake);

        // show series selection for bitmain devices
        // and whatsminer PSUs
        if (deviceMake && deviceMake.match(/bitmain/gi)) {
          setStage("series");
        } else if (
          deviceType === Enum_Device_Type.Psu &&
          deviceMake &&
          deviceMake.match(/microbt/gi)
        ) {
          setStage("series");
        } else {
          setStage("device");
        }
      }}
      onSelectDeviceSeries={(ds) => {
        setDeviceSeries(ds);
        setStage("device");
      }}
    />
  );
};
