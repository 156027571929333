import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { createContext, ReactNode, useContext, useState } from "react";
import { OrderItemDataFragment } from "data";
import { OrderItemActionPanelHeader } from "features/order-items/components/OrderItemActionPanelHeader";
import { RepairWizard } from "features/repairs/components/RepairWizard";

interface RepairsContextMenuProviderProps {
  children: ReactNode;
}

interface RepairsContextMenuData {
  openRepairsMenu: (orderItem: OrderItemDataFragment) => void;
  orderItem: OrderItemDataFragment | null;
}

const RepairsContextMenuContext = createContext<RepairsContextMenuData>(
  {} as RepairsContextMenuData
);

export const useRepairsContextMenu = () => {
  return useContext(RepairsContextMenuContext);
};

export const RepairsContextMenuProvider = (
  props: RepairsContextMenuProviderProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderItem, setOrderItem] =
    useState<OrderItemDataFragment | null>(null);

  const closeDrawer = () => {
    onClose();
    setOrderItem(null);
  };

  return (
    <RepairsContextMenuContext.Provider
      value={{
        orderItem,
        openRepairsMenu: (oi) => {
          setOrderItem(oi);
          onOpen();
        },
      }}
    >
      {props.children}
      <Drawer
        isOpen={isOpen && orderItem !== null}
        placement="right"
        size="xl"
        onClose={() => {
          closeDrawer();
        }}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important">
          <DrawerCloseButton />
          <DrawerHeader>
            <OrderItemActionPanelHeader orderItem={orderItem} />
          </DrawerHeader>
          <DrawerBody>
            {orderItem ? (
              <RepairWizard
                orderItem={orderItem}
                onClose={() => {
                  closeDrawer();
                }}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </RepairsContextMenuContext.Provider>
  );
};
