import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextArea,
  YesNoButtons,
  OkNotOkButtons,
} from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";
import { normalizePowerData } from "./lib";

const PowerCircuitSchema = Yup.object().shape({
  powerMOSFETsOk: Yup.boolean().required(),
  powerBoostCircuitVoltageOk: Yup.boolean().when("powerMOSFETsOk", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  powerGotCorrosion: Yup.boolean().when("powerBoostCircuitVoltageOk", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  powerNotes: Yup.string(),
});

export const PowerCircuitForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        powerMOSFETsOk:
          typeof report.powerMOSFETsOk !== "undefined"
            ? report.powerMOSFETsOk
            : true,
        powerBoostCircuitVoltageOk:
          typeof report.powerBoostCircuitVoltageOk !== "undefined"
            ? report.powerBoostCircuitVoltageOk
            : true,
        powerGotCorrosion:
          typeof report.powerGotCorrosion !== "undefined"
            ? report.powerGotCorrosion
            : false,
        powerNotes: report.powerNotes,
      }}
      validationSchema={PowerCircuitSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.powerMOSFETsOk !== "undefined" && isValid
          }
          canGoToPrevStep
          title="Power Circuit"
          values={normalizePowerData(values)}
        >
          <OkNotOkButtons label="MOSFETs" name="powerMOSFETsOk" />
          {values.powerMOSFETsOk ? (
            <OkNotOkButtons
              label="Boost Circuit Voltage"
              name="powerBoostCircuitVoltageOk"
            />
          ) : null}
          {values.powerBoostCircuitVoltageOk ? (
            <YesNoButtons label="Corrosion" name="powerGotCorrosion" />
          ) : null}
          <TextArea label="Notes (Optional)" name="powerNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
