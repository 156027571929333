import { Text } from "@chakra-ui/react";
import { RadioCard, RadioCardGroup } from "components/forms/RadioCardGroup";
import { LocationDataFragment } from "data";

interface LocationSelectorProps {
  locations: LocationDataFragment[];
  selectedLocationId?: string | null;
  onUpdateSelectedLocationId: (location: string) => void;
}

export const LocationSelector = ({
  locations,
  selectedLocationId,
  onUpdateSelectedLocationId,
}: LocationSelectorProps) => (
  <RadioCardGroup
    onChange={(locationId) => {
      onUpdateSelectedLocationId(locationId);
    }}
    value={selectedLocationId || undefined}
    spacing="3"
  >
    {locations.map((location) => (
      <RadioCard key={location.id} value={location.id || ""}>
        <Text
          data-cy="location-selector-location"
          color="emphasized"
          fontWeight="medium"
          fontSize="sm"
        >
          {location.attributes?.name}
        </Text>
      </RadioCard>
    ))}
  </RadioCardGroup>
);
