import { Button, Flex, Spacer } from "@chakra-ui/react";

interface WizardNavProps {
  canGoToPrevStep: boolean;
  canGoToNextStep: boolean;
  isNavEnabled: boolean;
  next: () => void;
  previous: () => void;
}

export const WizardNav = (props: WizardNavProps) => {
  const { canGoToPrevStep, canGoToNextStep, isNavEnabled, next, previous } =
    props;
  return (
    <Flex minWidth="max-content" alignItems="center" gap="2">
      {canGoToPrevStep ? (
        <Button
          isDisabled={!isNavEnabled}
          onClick={() => {
            previous();
          }}
        >
          Previous
        </Button>
      ) : null}
      <Spacer />
      {canGoToNextStep ? (
        <Button
          isDisabled={!isNavEnabled}
          onClick={() => {
            next();
          }}
        >
          Next
        </Button>
      ) : null}
    </Flex>
  );
};
