import { HStack, IconButton, Box } from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { Enum_Orderitem_Status, OrderItemDataFragment } from "data";
import { ShowForOrderItemStatus } from "features/order-items/components/ShowForOrderItemStatus";
import { useOrderItemContextMenu } from "features/order-items/context/OrderItemContextMenu";
import { DiagnosticsAction } from "features/diagnostics/components/DiagnosticsAction";
import { RepairAction } from "features/repairs/components/RepairAction";
import { QAAction } from "features/qa/components/QAAction";
import { ReceiveOrderItemAction } from "features/order-items/components/ReceiveOrderItemAction";

interface OrderItemsTableActionsProps {
  orderItem: OrderItemDataFragment;
}

export const OrderItemsTableActions = (props: OrderItemsTableActionsProps) => {
  const { orderItem } = props;
  const { openMenu } = useOrderItemContextMenu();
  return (
    <HStack maxWidth="100%">
      <Box flex="1"></Box>
      <ReceiveOrderItemAction variant="small-button" orderItem={orderItem} />
      <ShowForOrderItemStatus
        orderItem={orderItem}
        status={[Enum_Orderitem_Status.DiagnosisPending]}
      >
        <DiagnosticsAction variant="small-button" orderItem={orderItem} />
      </ShowForOrderItemStatus>
      <ShowForOrderItemStatus
        orderItem={orderItem}
        status={[Enum_Orderitem_Status.DiagnosedNeedsRepair]}
      >
        <RepairAction variant="small-button" orderItem={orderItem} />
      </ShowForOrderItemStatus>
      <ShowForOrderItemStatus
        orderItem={orderItem}
        status={[
          Enum_Orderitem_Status.RepairedNeedsQa,
          Enum_Orderitem_Status.DiagnosedIsOk,
        ]}
      >
        <QAAction variant="small-button" orderItem={orderItem} />
      </ShowForOrderItemStatus>

      <IconButton
        data-cy="order-item-context-menu-button"
        variant="ghost"
        size="xs"
        aria-label="Open order item context menu"
        icon={<BsThreeDots />}
        onClick={() => {
          openMenu(orderItem);
        }}
      />
    </HStack>
  );
};
