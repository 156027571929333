import { ShowForAllTechs } from "features/accounts";
import { RealtimeActionsTimeline } from "features/actions/components/ActionsTimeline";

export const FeedPage = () => (
  <>
    <ShowForAllTechs>
      <RealtimeActionsTimeline />
    </ShowForAllTechs>
  </>
);
