import { createContext, useContext, useState } from "react";
import {
  Enum_Hashboardadvancedinspectionreport_Diagnosis,
  HashboardAdvancedInspectionReportInput,
  OrderItemDataFragment,
  UploadFileDataFragment,
} from "data";
import { Loader } from "components/Loader";
import { Wizard } from "components/wizard/Wizard";
import { WizardStep } from "context/Wizard";
import { VisualInspectionForm } from "features/diagnostics/components/hashboards/advanced/Visual";
import { PowerCircuitForm } from "features/diagnostics/components/hashboards/advanced/Power";
import { CommunicationCircuitForm } from "features/diagnostics/components/hashboards/advanced/Communication";
import { ASICCircuitForm } from "features/diagnostics/components/hashboards/advanced/ASIC";
import { TemperatureSensorsForm } from "features/diagnostics/components/hashboards/advanced/TemperatureSensors";
import { ThermalsForm } from "features/diagnostics/components/hashboards/advanced/Thermals";
import { SummaryForm } from "features/diagnostics/components/hashboards/advanced/Summary";
import { useCreateAdvancedHashboardInspectionReport } from "features/diagnostics/data/hooks/diagnostics";

interface AdvancedHashboardDiagnosticsWizardProviderProps {
  orderItem: OrderItemDataFragment;
  onComplete: () => void;
}

interface AdvancedHashboardDiagnosticsWizardData {
  uploads: UploadFileDataFragment[];
  setUploads: (uploads: UploadFileDataFragment[]) => void;
  report: HashboardAdvancedInspectionReportInput;
  next: (report: HashboardAdvancedInspectionReportInput) => Promise<void>;
  prev: (report: HashboardAdvancedInspectionReportInput) => void;
}

const AdvancedHashboardDiagnosticsWizardContext =
  createContext<AdvancedHashboardDiagnosticsWizardData>(
    {} as AdvancedHashboardDiagnosticsWizardData
  );

export const useAdvancedHashboardDiagnosticsWizard = () => {
  return useContext(AdvancedHashboardDiagnosticsWizardContext);
};

const steps: WizardStep[] = [
  {
    index: 0,
    title: "Visual",
  },
  {
    index: 1,
    title: "Power",
  },
  {
    index: 2,
    title: "Comm",
  },
  {
    index: 3,
    title: "ASIC",
  },
  {
    index: 4,
    title: "Temp",
  },
  {
    index: 5,
    title: "Thermals",
  },
  {
    index: 6,
    title: "Summary",
  },
];

export const AdvancedHashboardDiagnosticsWizardProvider = (
  props: AdvancedHashboardDiagnosticsWizardProviderProps
) => {
  const { createReport } = useCreateAdvancedHashboardInspectionReport();
  const { onComplete, orderItem } = props;
  const [report, setReport] = useState<HashboardAdvancedInspectionReportInput>(
    {}
  );
  const [uploads, setUploads] = useState<UploadFileDataFragment[]>([]);
  const [stepBeforeSummary, setStepBeforeSummary] = useState<
    WizardStep | undefined
  >();
  const [currentStep, setCurrentStep] = useState<WizardStep>(steps[0]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const moveToSummaryStep = (
    report: HashboardAdvancedInspectionReportInput
  ) => {
    const lastStep = steps.find((s) => s.index === steps.length - 1);

    if (lastStep) {
      // preserve current step so we can always go back from summary
      setStepBeforeSummary(currentStep);
      setReport(report);
      setCurrentStep(lastStep);
    }
  };

  return (
    <AdvancedHashboardDiagnosticsWizardContext.Provider
      value={{
        uploads,
        setUploads(uploads) {
          setUploads(uploads);
        },
        report,
        async next(report) {
          const nextStep = steps.find((s) => s.index > currentStep.index);

          if (!nextStep) {
            // submit report
            setIsSubmitting(true);

            await createReport({
              report: Object.assign(report, {
                uploads: uploads.map((u) => u.id),
              }),
              orderItem,
              isOk:
                report.diagnosis ===
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AdvancedDiagnosisIntermittentIssues,
            });

            onComplete();

            return;
          }

          if (currentStep.index === 0) {
            // Visual
            if (report.visualIsRepairable === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.Unrepairable;
              return moveToSummaryStep(report);
            }
          } else if (currentStep.index === 1) {
            // Power circuit diagnosis
            if (report.powerMOSFETsOk === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.PowerMosfetCircuit;
              return moveToSummaryStep(report);
            } else if (report.powerBoostCircuitVoltageOk === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.PowerBoostCircuit;
              return moveToSummaryStep(report);
            }
          } else if (currentStep.index === 2) {
            // Communication circuit
            if (report.communicationEEPROMOk === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.CommunicationsEeprom;
              return moveToSummaryStep(report);
            } else if (report.communicationPICOk === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.CommunicationsPic;
              return moveToSummaryStep(report);
            }
          } else if (currentStep.index === 3) {
            // ASCIC circuit
            if (report.asicLDOsOk === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicPowerLdo;
              return moveToSummaryStep(report);
            } else if (report.asicIsRXChainComplete === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalRx;
              return moveToSummaryStep(report);
            } else if (report.asicIsTXChainComplete === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalTx;
              return moveToSummaryStep(report);
            } else if (
              report.asicNumberASICsDetected === 0 &&
              !report.asicAllDetected
            ) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalSignalGeneration;
              return moveToSummaryStep(report);
            } else if (report.asicIsRSTChainComplete === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalRst;
              return moveToSummaryStep(report);
            } else if (report.asicIsBI0V === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalBi;
              return moveToSummaryStep(report);
            } else if (report.asicIsCLKChainComplete === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalClk;
              return moveToSummaryStep(report);
            } else if (report.asicGotOtherProblem) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicCircuitOther;
              return moveToSummaryStep(report);
            }
          } else if (currentStep.index === 4) {
            // Temperature sensors
            if (report.temperatureAllTempSensorsDetected === false) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.TempSensorTempSensorCircuit;
              return moveToSummaryStep(report);
            }
          } else if (currentStep.index === 5) {
            // Thermals
            if (!report.thermalsIsThermalCompoundInGoodCondition) {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.ThermalsThermalCompoundReplacement;
              return moveToSummaryStep(report);
            } else {
              report.diagnosis =
                Enum_Hashboardadvancedinspectionreport_Diagnosis.AdvancedDiagnosisIntermittentIssues;
              return moveToSummaryStep(report);
            }
          }

          setReport(report);

          if (nextStep) {
            setCurrentStep(nextStep);
          }
        },
        prev(report) {
          if (stepBeforeSummary) {
            // move to the last known step before summary
            setCurrentStep(stepBeforeSummary);
            setStepBeforeSummary(undefined);
            setReport(report);

            return;
          }

          // slice + reverse so it does not mutate
          const previousStep = steps
            .slice()
            .reverse()
            .find((s) => s.index < currentStep.index);

          if (previousStep) {
            setCurrentStep(previousStep);
          }

          setReport(report);
        },
      }}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <Wizard
          steps={steps}
          currentStep={currentStep}
          renderCurrentStep={(currentStep) => {
            switch (currentStep?.index) {
              case 0:
                return <VisualInspectionForm />;
              case 1:
                return <PowerCircuitForm />;
              case 2:
                return <CommunicationCircuitForm />;
              case 3:
                return <ASICCircuitForm />;
              case 4:
                return <TemperatureSensorsForm />;
              case 5:
                return <ThermalsForm />;
              case 6:
                return <SummaryForm />;
            }
            return null;
          }}
          renderNav={() => null}
        />
      )}
    </AdvancedHashboardDiagnosticsWizardContext.Provider>
  );
};
