import { useContext, useState, ReactNode } from "react";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";
import {
  BulkDeviceSelection,
  BulkDeviceSelectorContainer as BulkDeviceSelector,
} from "features/devices/containers/BulkDeviceSelector";
import { useOrderDetails } from "features/orders/context/OrderDetails";
import { useOrderItemsForOrder } from "features/order-items/context/OrderItemsForOrder";
import { useLayoutItems } from "features/orders/data/hooks/order-layout-items";

interface AddDevicesActionPanelData {}

const AddDevicesActionPanel =
  generateContextAndProvider<AddDevicesActionPanelData>();

export const useAddDevicesActionPanel =
  (): ActionPanelContextData<AddDevicesActionPanelData> => {
    const context = useContext(AddDevicesActionPanel.Context);

    if (!context) {
      throw new Error(
        "Calling useAddDevicesActionPanel outside of AddDevicesActionPanelProvider"
      );
    }

    return context;
  };

export const AddDevicesActionPanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { order } = useOrderDetails();
  const orderItemsContext = useOrderItemsForOrder();
  const { createOrderLayoutItem } = useLayoutItems();
  const [bulkDeviceSelection, setBulkDeviceSelection] =
    useState<BulkDeviceSelection>({
      device: null,
      quantity: 0,
    });

  return (
    <AddDevicesActionPanel.Provider
      enablePrimaryAction={
        bulkDeviceSelection.device !== null &&
        bulkDeviceSelection.quantity !== 0
      }
      primaryButtonText="Add Devices"
      onAction={async () => {
        if (order && bulkDeviceSelection.device) {
          await createOrderLayoutItem(
            order,
            bulkDeviceSelection.device,
            bulkDeviceSelection.quantity
          );

          if (orderItemsContext?.refetch) {
            await orderItemsContext?.refetch();
          }
        }
      }}
      onClose={() => {
        setBulkDeviceSelection({
          device: null,
          quantity: 0,
        });
      }}
      renderHeader={({ data }) => null}
      renderBody={({ data }) => {
        return (
          <BulkDeviceSelector
            onSelectionChange={(selection) => {
              setBulkDeviceSelection(selection);
            }}
          />
        );
      }}
    >
      {children}
    </AddDevicesActionPanel.Provider>
  );
};
