import { HStack } from "@chakra-ui/react";
import { ActionButton } from "components/utils/ActionButton";

interface AddCustomerDeviceProps {
  serialNumber: string;
  onAddDevice: (serialNumber?: string) => Promise<void>;
}

export const AddCustomerDevice = (props: AddCustomerDeviceProps) => {
  const { serialNumber, onAddDevice } = props;
  return (
    <>
      <strong>No device with serial {serialNumber} found</strong>
      <HStack>
        <ActionButton
          data-cy="add-device-with-serial"
          action={async () => {
            await onAddDevice(serialNumber);
          }}
        >
          Use {serialNumber}
        </ActionButton>
        <ActionButton
          data-cy="add-device-without-serial"
          action={async () => {
            await onAddDevice();
          }}
        >
          No Serial
        </ActionButton>
      </HStack>
    </>
  );
};
