import { TemperatureIcon } from "components/Icons";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { FansDiagnostics } from "features/diagnostics/components/miners/FansDiagnostics";
import { HashboardDiagnosticsReport } from "features/diagnostics/data";

export const TemperatureSensorsDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const hashboardDiagnosticsReport = data as HashboardDiagnosticsReport;
  return (
    <FansDiagnostics
      icon={TemperatureIcon}
      prefix="Temperature Sensor"
      fans={hashboardDiagnosticsReport.temperatureSensors || []}
      onChange={(sensors) => {
        updateData(
          Object.assign({}, hashboardDiagnosticsReport, {
            temperatureSensors: sensors,
          })
        );
      }}
    />
  );
};
