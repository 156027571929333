import { HStack, Text } from "@chakra-ui/react";
import { LinkToOrder } from "features/orders/components/LinkToOrder";
import { OrderNameLabel } from "features/orders/components/OrderNameLabel";
import { LocationLabel } from "features/locations/components/LocationLabel";
import { OrderItemTags } from "features/order-item-tags/components/OrderItemTags";
import { OrderItemDataFragment } from "data";

interface OrderItemPageSubtitleProps {
  orderItem: OrderItemDataFragment;
}

export const OrderItemPageSubtitle = (props: OrderItemPageSubtitleProps) => {
  const { orderItem } = props;
  const order = orderItem.attributes?.order?.data;
  return order ? (
    <HStack>
      <LinkToOrder order={order}>
        <OrderNameLabel orderData={order} />
      </LinkToOrder>
      <LocationLabel location={orderItem.attributes?.location?.data} />
      {orderItem.attributes?.customer_device?.data?.attributes?.serialNumber ? (
        <Text color="muted">{`serial #: ${orderItem.attributes?.customer_device?.data?.attributes?.serialNumber}`}</Text>
      ) : null}
      <OrderItemTags orderItem={orderItem} />
    </HStack>
  ) : null;
};
