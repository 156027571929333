import FileSaver from "file-saver";
import { useAuthentication } from "features/accounts/context/Authentication";
import { useNotifications } from "context/Notifications";
import {
  useReportSqlTemplatesLazyQuery,
  useCreateReportRequestMutation,
  useReportRequestLazyQuery,
} from "data";
import { sleep } from "lib";

interface UseDownloadReport {
  downloadReport: ({
    reportName,
    parameters,
  }: {
    reportName: string;
    parameters?: object;
  }) => Promise<void>;
}

export const useDownloadReport = (): UseDownloadReport => {
  const { loginData } = useAuthentication();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const [getReportTemplates] = useReportSqlTemplatesLazyQuery();
  const [getReportRequest] = useReportRequestLazyQuery();
  const [requestReport] = useCreateReportRequestMutation();

  return {
    async downloadReport({ reportName, parameters }) {
      const reportTemplates = await getReportTemplates();
      const t = (reportTemplates.data?.reportSqlTemplates?.data || []).find(
        (t) => t.attributes?.name === reportName
      );

      if (!t) {
        showErrorMessage("This report is not available!");
        return;
      }

      const fileName = `${new Date().getTime()}.csv`;
      const { data, errors } = await requestReport({
        variables: {
          data: {
            fileName,
            report_sql_template: t.id,
            user: loginData?.user?.id,
            params: parameters,
          },
        },
      });

      if (errors || !data || !data.createReportRequest?.data?.id) {
        showErrorMessage("This report is not available!");
        return;
      }

      let completeReport = null;
      let tries = 0;
      const maxTries = 5;

      while (tries < maxTries) {
        tries++;

        const { data: completeReportData } = await getReportRequest({
          variables: {
            id: data.createReportRequest.data.id,
          },
          fetchPolicy: "network-only",
        });

        if (
          completeReportData &&
          completeReportData.reportRequest?.data?.attributes?.url
        ) {
          completeReport = completeReportData.reportRequest?.data;
          break;
        }

        await sleep(1000);
      }

      if (completeReport && completeReport.attributes?.url) {
        showSuccessMessage("Your report is ready!");
        FileSaver.saveAs(completeReport.attributes?.url, fileName);
      } else {
        showErrorMessage("Failed to generate your report. Please try again.");
      }
    },
  };
};
