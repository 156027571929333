import { useState, useEffect } from "react";
import {
  RadioGroup,
  Radio,
  HStack,
  Stack,
  Textarea,
  FormControl,
  FormLabel,
  Divider,
} from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  Enum_Device_Type,
  PsuQaReportInput,
  Enum_Orderitem_Status,
  UploadFileDataFragment,
} from "data";
import { QAReportInput, getInitialQAReport } from "features/qa/data";
import { ShowForDeviceTypes } from "features/devices/components/ShowForDeviceTypes";
import { QAFailedStatusSelector } from "features/qa/components/QAFailedStatusSelector";
import { Upload } from "features/uploads/components/Upload";
import { ListUploads } from "features/uploads/components/ListUploads";

interface CreateQAReportFormProps {
  orderItem?: OrderItemDataFragment | null;
  onChange: ({
    formInput,
    orderItemStatus,
  }: {
    formInput: QAReportInput;
    orderItemStatus: Enum_Orderitem_Status;
  }) => void;
  onUploading: (uploading: boolean) => void;
}

export const CreateQAReportForm = (props: CreateQAReportFormProps) => {
  const { orderItem, onChange, onUploading } = props;

  const [report, setReport] = useState<QAReportInput>(
    getInitialQAReport(orderItem)
  );
  const [orderItemStatus, setOrderItemStatus] = useState<Enum_Orderitem_Status>(
    Enum_Orderitem_Status.QaPass
  );
  const [uploads, setUploads] = useState<UploadFileDataFragment[]>([]);

  useEffect(() => {
    onChange({
      formInput: report,
      orderItemStatus,
    });
  }, [report, orderItemStatus, onChange]);

  if (!orderItem) {
    return null;
  }

  return (
    <>
      <ShowForDeviceTypes
        device={orderItem.attributes?.device?.data}
        deviceTypes={[Enum_Device_Type.Psu]}
      >
        <Stack spacing="6">
          <FormControl>
            <FormLabel as="legend">
              Do you have proper power to the fans?
            </FormLabel>

            <RadioGroup
              onChange={(value) => {
                setReport((currentReport) =>
                  Object.assign({}, currentReport, {
                    fanPower: value === "1",
                  })
                );
              }}
              value={(report as PsuQaReportInput).fanPower ? "1" : "0"}
            >
              <HStack spacing="8">
                <Radio value="1">Yes</Radio>
                <Radio value="0">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel as="legend">
              Do you have proper power to rails?
            </FormLabel>

            <RadioGroup
              onChange={(value) => {
                setReport((currentReport) =>
                  Object.assign({}, currentReport, {
                    railPower: value === "1",
                  })
                );
              }}
              value={(report as PsuQaReportInput).railPower ? "1" : "0"}
            >
              <HStack spacing="8">
                <Radio value="1">Yes</Radio>
                <Radio value="0">No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Textarea
            autoFocus
            onChange={(e) => {
              setReport((currentReport) =>
                Object.assign({}, currentReport, {
                  notes: e.currentTarget.value,
                })
              );
            }}
            value={report.notes || ""}
            placeholder="Notes"
          />
        </Stack>
      </ShowForDeviceTypes>
      <ShowForDeviceTypes
        device={orderItem.attributes?.device?.data}
        deviceTypes={[
          Enum_Device_Type.Miner,
          Enum_Device_Type.HashBoard,
          Enum_Device_Type.ControlBoard,
        ]}
      >
        <Stack spacing="4">
          <Textarea
            autoFocus
            onChange={(e) => {
              setReport((currentReport) =>
                Object.assign({}, currentReport, {
                  notes: e.currentTarget.value,
                })
              );
            }}
            value={report.notes || ""}
            placeholder="Notes"
          />
        </Stack>
      </ShowForDeviceTypes>
      <RadioGroup
        onChange={(value) => {
          const pass = value === "1";

          setReport((currentReport) =>
            Object.assign({}, currentReport, {
              pass,
            })
          );
          setOrderItemStatus(
            pass ? Enum_Orderitem_Status.QaPass : Enum_Orderitem_Status.QaFail
          );
        }}
        value={report.pass ? "1" : "0"}
        my="6"
      >
        <HStack spacing="8">
          <Radio data-cy="radio-qa-pass" value="1">
            Pass
          </Radio>
          <Radio data-cy="radio-qa-fail" value="0">
            Fail
          </Radio>
        </HStack>
      </RadioGroup>
      <QAFailedStatusSelector
        selectedStatus={
          [
            Enum_Orderitem_Status.QaFail,
            Enum_Orderitem_Status.QaFailRecycled,
            Enum_Orderitem_Status.QaFailReturnToCustomer,
          ].indexOf(orderItemStatus) === -1
            ? undefined
            : orderItemStatus
        }
        onChange={(status) => {
          setOrderItemStatus(status);
        }}
      />
      <Stack>
        <Divider />
        <ListUploads uploads={uploads} />
        <Upload
          onStartUpload={() => {
            onUploading(true);
          }}
          onUpload={(uploadedFiles) => {
            onUploading(false);
            if (uploadedFiles && uploadedFiles.length !== 0) {
              setUploads((currentUploads) => [
                ...currentUploads,
                ...uploadedFiles,
              ]);
              setReport((currentReport) =>
                Object.assign({}, currentReport, {
                  attachments: [
                    ...(currentReport.attachments || []),
                    ...uploadedFiles.map((uf) => uf.id),
                  ],
                })
              );
            }
          }}
          promptText="Add attachments for the report"
        />
      </Stack>
    </>
  );
};
