import * as Yup from "yup";
import { HStack, Stack, Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { Enum_Device_Make, OrderItemDataFragment, PsuRepairInput } from "data";
import { YesNoButtons, TextInput, TextArea } from "components/forms/fields";
import { ShowForMake } from "features/devices/components/ShowForMake";

interface PSURepairDetailsFormProps {
  orderItem: OrderItemDataFragment;
  onSubmit: (repair: PsuRepairInput) => void;
  report: PsuRepairInput | null;
}

const getInitialValues = (orderItem: OrderItemDataFragment) => {
  if (
    orderItem.attributes?.device?.data?.attributes?.make ===
    Enum_Device_Make.Whatsminer
  ) {
    return {
      quantityInputFusesReplaced: 0,
      quantityInrushCurrentLimitersReplaced: 0,
      quantityMOSFETsReplaced: 0,
      quantityBridgesReplaced: 0,
      quantityRelaysReplaced: 0,
      quantityCapacitorsReplaced: 0,
      quantityICsReplaced: 0,
      otherComponentsDamaged: false,
      notes: "",
    };
  }

  return {
    quantityInputFusesReplaced: 0,
    quantityInrushCurrentLimitersReplaced: 0,
    quantityMOSFETsReplaced: 0,
    quantityPowerDiodesReplaced: 0,
    u25Replaced: false,
  };
};

const getSchema = (orderItem: OrderItemDataFragment) => {
  if (
    orderItem.attributes?.device?.data?.attributes?.make ===
    Enum_Device_Make.Whatsminer
  ) {
    return Yup.object().shape({
      quantityInputFusesReplaced: Yup.number().required(),
      quantityInrushCurrentLimitersReplaced: Yup.number().required(),
      quantityMOSFETsReplaced: Yup.number().required(),
      quantityBridgesReplaced: Yup.number().required(),
      quantityRelaysReplaced: Yup.number().required(),
      quantityCapacitorsReplaced: Yup.number().required(),
      quantityICsReplaced: Yup.number().required(),
      otherComponentsDamaged: Yup.boolean().required(),
      notes: Yup.string(),
    });
  }

  return Yup.object().shape({
    quantityInputFusesReplaced: Yup.number().required(),
    quantityInrushCurrentLimitersReplaced: Yup.number().required(),
    quantityMOSFETsReplaced: Yup.number().required(),
    quantityPowerDiodesReplaced: Yup.number().required(),
    u25Replaced: Yup.boolean().required(),
  });
};

export const PSURepairDetailsForm = (props: PSURepairDetailsFormProps) => {
  const { orderItem, onSubmit, report } = props;

  return (
    <Formik
      initialValues={report || getInitialValues(orderItem)}
      validationSchema={getSchema(orderItem)}
      validateOnBlur
      onSubmit={async (values) => {
        onSubmit(values);
      }}
    >
      {() => (
        <Form>
          <Stack px={4}>
            <ShowForMake
              device={orderItem.attributes?.device?.data}
              make={Enum_Device_Make.Antminer}
            >
              <Stack spacing={4}>
                <TextInput
                  type="number"
                  name="quantityInputFusesReplaced"
                  label="How many input fuses were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityInrushCurrentLimitersReplaced"
                  label="How many inrush current limiters were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityMOSFETsReplaced"
                  label="How many MOSFETs were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityPowerDiodesReplaced"
                  label="How many power diodes were replaced?"
                />
                <YesNoButtons label="Was U25 replaced?" name="u25Replaced" />
              </Stack>
            </ShowForMake>
            <ShowForMake
              device={orderItem.attributes?.device?.data}
              make={Enum_Device_Make.Whatsminer}
            >
              <Stack spacing={4}>
                <TextInput
                  type="number"
                  name="quantityInputFusesReplaced"
                  label="How many input fuses were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityInrushCurrentLimitersReplaced"
                  label="How many inrush current limiters were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityMOSFETsReplaced"
                  label="How many MOSFETs were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityBridgesReplaced"
                  label="How many bridges were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityRelaysReplaced"
                  label="How many relays were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityCapacitorsReplaced"
                  label="How many capacitors were replaced?"
                />
                <TextInput
                  type="number"
                  name="quantityICsReplaced"
                  label="How many ICs were replaced?"
                />
                <YesNoButtons
                  label="Are other components damaged?"
                  name="otherComponentsDamaged"
                />
                <TextArea label="Notes" name="notes" />
              </Stack>
            </ShowForMake>
            <HStack mt={8}>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </HStack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
