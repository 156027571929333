import { useState, useEffect, ReactNode } from "react";
import {
  useLookupCustomerDevicesQuery,
  CustomerDeviceDataFragment,
  DeviceDataFragment,
  OrderDataFragment,
} from "data";
import { FindCustomerDevice } from "features/customer-devices/components/FindCustomerDevice";

interface FindCustomerDeviceContainerProps {
  order: OrderDataFragment;
  device: DeviceDataFragment | null;
  onFoundDevice: (device: CustomerDeviceDataFragment | null) => void;
  nothingFoundTemplate: (
    serialNumber: string,
    onDeviceAdded: (device: CustomerDeviceDataFragment) => void
  ) => ReactNode;
}

export const FindCustomerDeviceContainer = (
  props: FindCustomerDeviceContainerProps
) => {
  const { device, onFoundDevice, order, nothingFoundTemplate } = props;
  const [query, setQuery] = useState<string>("");
  const { loading: searching, data } = useLookupCustomerDevicesQuery({
    variables: {
      query,
      deviceId: device?.id || "",
      order: order.id || "",
    },
    fetchPolicy: "network-only",
  });
  const duplicateDevice = query !== "" && data?.orderItems?.data.length !== 0;

  useEffect(() => {
    if (!duplicateDevice) {
      onFoundDevice(
        data?.customerDevices?.data && data?.customerDevices?.data.length !== 0
          ? data?.customerDevices?.data[0]
          : null
      );
    }
  }, [data, query, duplicateDevice]);

  return (
    <FindCustomerDevice
      duplicateDevice={duplicateDevice}
      nothingFound={query !== "" && data?.customerDevices?.data.length === 0}
      nothingFoundTemplate={nothingFoundTemplate(query, (device) => {
        if (device.attributes?.shortId) {
          setQuery(device.attributes?.shortId);
        }
      })}
      device={
        data?.customerDevices?.data && data?.customerDevices?.data.length !== 0
          ? data?.customerDevices?.data[0]
          : null
      }
      onQueryChange={(query) => setQuery(query)}
      searching={searching}
    />
  );
};
