import { Input, Stack } from "@chakra-ui/react";
import { useField, FieldAttributes } from "formik";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export const TextInput = ({ label, type, ...props }: FieldAttributes<any>) => {
  const [field, meta] = useField(props);
  return (
    <Stack spacing={2}>
      <Label>{label}</Label>

      <Input
        type={type}
        data-cy={`field-${field.name}`}
        isInvalid={meta.touched && meta.error ? true : false}
        errorBorderColor="crimson"
        {...field}
      />

      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </Stack>
  );
};
