import { Badge, HStack, Text } from "@chakra-ui/react";
import { GiServerRack } from "react-icons/gi";
import { OrderItemDataFragment } from "data";
import { ActionButton } from "components/utils/ActionButton";

interface OrderItemHostedStatusBadgeProps {
  orderItem: OrderItemDataFragment;
  onUpdateHostedStatus: (isHosted: boolean) => Promise<void>;
  editable?: boolean;
}

export const OrderItemHostedStatusBadge = (
  props: OrderItemHostedStatusBadgeProps
) => {
  const { orderItem, editable, onUpdateHostedStatus } = props;
  const isHosted = orderItem.attributes?.isHosted;

  if (!isHosted && !editable) {
    return null;
  }

  if (!isHosted && editable) {
    return (
      <ActionButton
        action={async () => {
          await onUpdateHostedStatus(true);
        }}
      >
        <HStack>
          <GiServerRack />
          <Text>Host</Text>
        </HStack>
      </ActionButton>
    );
  }

  if (editable) {
    return (
      <ActionButton
        colorScheme="red"
        action={async () => {
          await onUpdateHostedStatus(false);
        }}
      >
        <HStack>
          <GiServerRack />
          <Text>Stop Hosting</Text>
        </HStack>
      </ActionButton>
    );
  }

  return isHosted ? (
    <Badge size="xs" variant="outline" colorScheme="green">
      <HStack>
        <GiServerRack />
        <Text>Hosted</Text>
      </HStack>
    </Badge>
  ) : null;
};
