import { createContext, ReactNode, useState } from "react";
import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
} from "@chakra-ui/react";
import { ActionButton } from "components/utils/ActionButton";

export interface ActionPanelContextData<T> {
  showActionPanel: (data: T) => void;
  closeActionPanel: () => void;
}

interface RenderActionPanelContentProps<T> {
  data?: T | null;
  onShow: () => void;
  onClose: () => void;
}

interface ProviderProps<T> {
  children: ReactNode;
  renderHeader: (props: RenderActionPanelContentProps<T>) => ReactNode;
  renderBody: (props: RenderActionPanelContentProps<T>) => ReactNode;
  renderFooter?: (props: RenderActionPanelContentProps<T>) => ReactNode;
  enablePrimaryAction?: boolean;
  primaryButtonText?: string;
  onAction: (props: RenderActionPanelContentProps<T>) => Promise<void>;
  onClose?: () => void;
}

export function generateContextAndProvider<ActionPanelData>() {
  const Context =
    createContext<ActionPanelContextData<ActionPanelData> | null>(null);

  const Provider = (props: ProviderProps<ActionPanelData>) => {
    const {
      children,
      renderHeader,
      renderBody,
      renderFooter,
      enablePrimaryAction,
      primaryButtonText,
      onAction,
    } = props;
    const [actionPanelData, setActionPanelData] =
      useState<ActionPanelData | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const closeDrawer = () => {
      onClose();
      setActionPanelData(null);

      if (props.onClose) {
        props.onClose();
      }
    };

    return (
      <Context.Provider
        value={{
          showActionPanel: (data) => {
            setActionPanelData(data);
            onOpen();
          },
          closeActionPanel: () => {
            closeDrawer();
          },
        }}
      >
        {children}
        <Drawer
          isOpen={isOpen && actionPanelData !== null}
          placement="right"
          size="md"
          onClose={() => {
            closeDrawer();
          }}
        >
          <DrawerOverlay />
          <DrawerContent height="100% !important">
            <DrawerCloseButton data-cy="action-panel-close-button" />
            <DrawerHeader>
              {renderHeader({
                data: actionPanelData,
                onShow: onOpen,
                onClose: () => {
                  closeDrawer();
                },
              })}
            </DrawerHeader>
            <DrawerBody overflowY="scroll">
              {renderBody({
                data: actionPanelData,
                onShow: onOpen,
                onClose: () => {
                  closeDrawer();
                },
              })}
            </DrawerBody>

            {renderFooter ? (
              renderFooter({
                data: actionPanelData,
                onShow: onOpen,
                onClose: () => {
                  closeDrawer();
                },
              })
            ) : (
              <DrawerFooter>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    closeDrawer();
                  }}
                >
                  Cancel
                </Button>
                <ActionButton
                  data-cy="action-panel-primary-button"
                  action={async () => {
                    await onAction({
                      data: actionPanelData,
                      onShow: onOpen,
                      onClose: () => {
                        closeDrawer();
                      },
                    });
                    closeDrawer();
                  }}
                  isDisabled={!enablePrimaryAction}
                >
                  {primaryButtonText}
                </ActionButton>
              </DrawerFooter>
            )}
          </DrawerContent>
        </Drawer>
      </Context.Provider>
    );
  };

  return { Context, Provider };
}
