import { Text, HStack } from "@chakra-ui/react";
import { AppVersionIcon } from "components/Icons";
import { getAppVersion } from "lib";

export const AppVersion = () => {
  return (
    <HStack>
      <AppVersionIcon size="12" />
      <Text data-cy="app-version-label" color="muted" fontSize="small">
        {`App Version ${getAppVersion()}`}
      </Text>
    </HStack>
  );
};
