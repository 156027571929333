import { ReactElement } from "react";
import { ShowForRoles } from "./ShowForRoles";

interface ShowForAllTechsProps {
  children: ReactElement;
  inverse?: boolean;
}

export const ShowForAllTechs = (props: ShowForAllTechsProps) => {
  const { children, inverse } = props;
  return (
    <ShowForRoles roles={["CheckInTech", "AdminTech"]} inverse={inverse}>
      {children}
    </ShowForRoles>
  );
};
