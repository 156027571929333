import { ReactNode } from "react";
import { Stack, Text, Center } from "@chakra-ui/react";
import { CustomerDeviceDataFragment } from "data";
import { DebouncedSearchBox } from "components/forms/SearchBox";
import { Loader } from "components/Loader";
import { WarningIcon } from "components/Icons";

interface FindCustomerDeviceProps {
  device: CustomerDeviceDataFragment | null;
  onQueryChange: (query: string) => void;
  searching: boolean;
  nothingFoundTemplate: ReactNode;
  nothingFound: boolean;
  duplicateDevice: boolean;
}

export const FindCustomerDevice = (props: FindCustomerDeviceProps) => {
  const {
    onQueryChange,
    nothingFound,
    nothingFoundTemplate,
    searching,
    duplicateDevice,
  } = props;
  return (
    <>
      <DebouncedSearchBox
        autoFocus
        placeholder="Search by serial number or ACS ID"
        timeout={500}
        onChange={(q) => onQueryChange(q)}
        onBeforeChange={(q) => {
          // look for URLs that get pasted into the search box
          const matches = q.match(/device\/[a-z]{6}$/gi);

          if (matches && matches.length !== 0) {
            const parts = matches[0].split("device/");
            if (parts && parts.length === 2) {
              return parts[1];
            }
          }

          return q;
        }}
      />
      {searching ? <Loader /> : null}
      {nothingFound ? nothingFoundTemplate : null}
      {duplicateDevice ? (
        <Stack py="8" data-cy="duplicate-receive-device-error">
          <Center>
            <WarningIcon size="30" />
          </Center>
          <Center>
            <Text>Duplicate Serial #/Short ACS Code</Text>
          </Center>
        </Stack>
      ) : null}
    </>
  );
};
