import { useState } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Enum_Orderpackage_Status } from "data";
import { Loader } from "components/Loader";
import { ShipIcon, ContextualMenuIcon } from "components/Icons";
import { useShipOrderPackage } from "features/order-packages/data/hooks/order-packages";
import { OrderItemPickerContainer as OrderItemPicker } from "features/order-packages/containers/OrderItemPicker";
import { useOrderPackage } from "features/order-packages/context/OrderPackage";
import {
  usePackageOrderItemsActionPanel,
  PackageOrderItemsActionPanelProvider,
} from "features/order-packages/context/PackageOrderItemsActionPanel";

const ConnectedOrderPackagePageActions = () => {
  const [shipping, setShipping] = useState<boolean>(false);
  const { shipPackage } = useShipOrderPackage();
  const { loading, orderPackage, orderItems } = useOrderPackage();
  const orderPackageStatus = orderPackage?.attributes?.status;
  const { showActionPanel } = usePackageOrderItemsActionPanel();
  return loading || shipping ? (
    <Loader />
  ) : (
    <HStack>
      {orderPackageStatus !== Enum_Orderpackage_Status.Shipped ? (
        <>
          <Button
            variant="primary"
            onClick={() => {
              showActionPanel({ orderPackage, orderItems });
            }}
          >
            Add Items
          </Button>
          {orderItems.length !== 0 ? (
            <Menu>
              <MenuButton
                data-cy="page-contextual-action-menu"
                as={IconButton}
                aria-label="Options"
                icon={<ContextualMenuIcon />}
                variant="ghost"
              />
              <MenuList>
                <MenuItem
                  onClick={async () => {
                    if (orderPackage) {
                      setShipping(true);
                      await shipPackage(orderPackage);
                      setShipping(false);
                    }
                  }}
                  icon={<ShipIcon />}
                >
                  Ship Package
                </MenuItem>
              </MenuList>
            </Menu>
          ) : null}
        </>
      ) : null}
    </HStack>
  );
};

export const OrderPackagePageActions = () => {
  return (
    <PackageOrderItemsActionPanelProvider
      onAction={async () => {}}
      renderFooter={() => null}
      renderHeader={() => null}
      renderBody={({ data }) => {
        const customer = data?.orderPackage?.attributes?.customer?.data;
        return customer ? <OrderItemPicker customer={customer} /> : null;
      }}
    >
      <ConnectedOrderPackagePageActions />
    </PackageOrderItemsActionPanelProvider>
  );
};
