import {
  Stack,
  Code,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import {
  MinerQaReportDataFragment,
  HashboardQaReportDataFragment,
  ControlBoardQaReportDataFragment,
  PsuQaReportDataFragment,
} from "data";
import { YesLabel, NoLabel, PassLabel, FailLabel } from "components/Labels";
import { ActionCard } from "./Base";

interface QAActionCardProps {
  minerReport?: MinerQaReportDataFragment | null;
  hashboardReport?: HashboardQaReportDataFragment | null;
  controlBoardReport?: ControlBoardQaReportDataFragment | null;
  psuReport?: PsuQaReportDataFragment | null;
}

const PassAndNotes = ({
  pass,
  notes,
}: {
  pass?: boolean | null;
  notes?: string | null;
}) => (
  <Stack>
    {pass ? <PassLabel /> : <FailLabel />}
    {notes ? <Code>{notes}</Code> : null}
  </Stack>
);

const MinerQAActionCard = ({
  report,
}: {
  report: MinerQaReportDataFragment;
}) => (
  <PassAndNotes
    pass={report.attributes?.pass}
    notes={report.attributes?.notes}
  />
);

const HashboardQAActionCard = ({
  report,
}: {
  report: HashboardQaReportDataFragment;
}) => (
  <PassAndNotes
    pass={report.attributes?.pass}
    notes={report.attributes?.notes}
  />
);

const ControlBoardQAActionCard = ({
  report,
}: {
  report: ControlBoardQaReportDataFragment;
}) => (
  <PassAndNotes
    pass={report.attributes?.pass}
    notes={report.attributes?.notes}
  />
);

const PSUQAActionCard = ({ report }: { report: PsuQaReportDataFragment }) => {
  return (
    <Stack>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td>Fan power</Td>
              <Td>
                {report.attributes?.fanPower ? <YesLabel /> : <NoLabel />}
              </Td>
            </Tr>
            <Tr>
              <Td>Rail power</Td>
              <Td>
                {report.attributes?.railPower ? <YesLabel /> : <NoLabel />}
              </Td>
            </Tr>
            <Tr>
              <Td>Result</Td>
              <Td>{report.attributes?.pass ? <PassLabel /> : <FailLabel />}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {report.attributes?.notes ? (
        <Code>{report.attributes?.notes}</Code>
      ) : null}
    </Stack>
  );
};

export const QAActionCard = ({
  minerReport,
  hashboardReport,
  controlBoardReport,
  psuReport,
}: QAActionCardProps) => (
  <ActionCard>
    {minerReport ? <MinerQAActionCard report={minerReport} /> : null}
    {hashboardReport ? (
      <HashboardQAActionCard report={hashboardReport} />
    ) : null}
    {controlBoardReport ? (
      <ControlBoardQAActionCard report={controlBoardReport} />
    ) : null}
    {psuReport ? <PSUQAActionCard report={psuReport} /> : null}
  </ActionCard>
);
