import { useField, FieldAttributes } from "formik";
import { Dropzone } from "features/uploads/components/Dropzone";
import { ErrorMessage } from "./ErrorMessage";

export const FileUpload = ({ label, ...props }: FieldAttributes<any>) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <Dropzone
        variant={"normal"}
        onDrop={async (files) => {
          helpers.setValue(files[0]);
        }}
        promptText={label}
      />
      {field.value ? <strong>{(field.value as File).name}</strong> : null}
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </>
  );
};
