import { GoLocation } from "react-icons/go";
import { Text, HStack } from "@chakra-ui/react";
import { LocationDataFragment } from "data";

interface LocationLabelProps {
  location?: LocationDataFragment | null;
}

export const LocationLabel = (props: LocationLabelProps) => {
  const { location } = props;
  return !location ? null : (
    <HStack>
      <GoLocation />
      <Text fontSize="smaller">{location.attributes?.name}</Text>
    </HStack>
  );
};
