import { ReactElement } from "react";
import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";

interface ShowForOrderItemStatusProps {
  children: ReactElement | null;
  orderItem?: OrderItemDataFragment | null;
  status: Enum_Orderitem_Status[];
  inverse?: boolean;
}

export const ShowForOrderItemStatus = (props: ShowForOrderItemStatusProps) => {
  const { children, orderItem, status, inverse } = props;

  if (!orderItem?.attributes?.status) {
    return null;
  }

  if (inverse) {
    return status.indexOf(orderItem.attributes?.status) === -1
      ? children
      : null;
  }

  return status.indexOf(orderItem.attributes?.status) !== -1 ? children : null;
};
