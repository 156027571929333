import { Box, Flex, Divider } from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";
import { NotesList } from "features/notes/components/NotesList";
import { NoteComposer } from "features/notes/components/NoteComposer";

interface NotesForOrderItemProps {
  orderItem: OrderItemDataFragment;
  showComposer: boolean;
}

export const NotesForOrderItem = (props: NotesForOrderItemProps) => {
  const { orderItem, showComposer } = props;
  return (
    <Flex height="100%" direction="column">
      <Box flex="1">
        <NotesList filters={{ order_item: { id: { eq: orderItem.id! } } }} />
      </Box>
      {showComposer ? (
        <Box py="4">
          <Divider mb={2} />
          <NoteComposer attachedTo={orderItem} />
        </Box>
      ) : null}
    </Flex>
  );
};
