import { ReactNode } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { Form } from "formik";
import { WizardNav } from "components/wizard/WizardNav";
import { Upload } from "features/uploads/components/Upload";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { ListUploads } from "features/uploads/components/ListUploads";

interface FormWrapperProps {
  title: string;
  children: ReactNode;
  canGoToPrevStep: boolean;
  canGoToNextStep: boolean;
  isValid: boolean;
  values: object;
}

export const FormWrapper = (props: FormWrapperProps) => {
  const { title, children, canGoToNextStep, canGoToPrevStep, isValid, values } =
    props;
  const { next, prev, report, uploads, setUploads } =
    useAdvancedHashboardDiagnosticsWizard();
  return (
    <>
      <Form>
        <Stack spacing={6}>
          <Text fontWeight="bold" fontSize="larger">
            {title}
          </Text>
          {children}
          <Upload
            onStartUpload={() => {}}
            onUpload={(uploadedFiles) => {
              if (uploadedFiles && uploadedFiles.length !== 0) {
                setUploads([...uploads, ...uploadedFiles]);
              }
            }}
            promptText="Add attachments for the report"
          />
          <ListUploads uploads={uploads} />
        </Stack>
      </Form>
      <Box mt="8">
        <WizardNav
          canGoToPrevStep={canGoToPrevStep}
          canGoToNextStep={canGoToNextStep}
          isNavEnabled
          next={() => {
            if (isValid) {
              next(Object.assign({}, report, values));
            }
          }}
          previous={() => {
            prev(Object.assign({}, report, values));
          }}
        />
      </Box>
    </>
  );
};
