import { HStack, Text } from "@chakra-ui/react";
import {
  Enum_Inspecteddevice_Hashingstatus,
  InspectionDataFragment,
} from "data";
import {
  useInspectionStats,
  InspectionStats as InspectionStatsT,
} from "features/inspections/data/hooks";
import type {} from "features/inspections/data/hooks";

interface InspectionStatsProps {
  inspection: InspectionDataFragment;
}

const countByStatus = (
  stats: InspectionStatsT[],
  status: Enum_Inspecteddevice_Hashingstatus
): number => {
  return stats
    .filter((s) => s.hashingStatus === status)
    .reduce((s, el) => s + el.count, 0);
};

export const InspectionStats = (props: InspectionStatsProps) => {
  const { loading, stats } = useInspectionStats({
    inspectionId: props.inspection.id!,
  });
  return !loading && stats.length !== 0 ? (
    <HStack spacing="8">
      <HStack>
        <Text color="green">Fully hashing: </Text>
        <Text color="green" fontWeight="bold" fontSize="larger">
          {countByStatus(stats, Enum_Inspecteddevice_Hashingstatus.Hashing)}
        </Text>
      </HStack>
      <HStack>
        <Text color="orange">Partially hashing: </Text>
        <Text color="orange" fontWeight="bold" fontSize="larger">
          {countByStatus(
            stats,
            Enum_Inspecteddevice_Hashingstatus.PartiallyHashing
          )}
        </Text>
      </HStack>
      <HStack>
        <Text color="red">Not hashing: </Text>
        <Text color="red" fontWeight="bold" fontSize="larger">
          {countByStatus(stats, Enum_Inspecteddevice_Hashingstatus.NoHashing)}
        </Text>
      </HStack>
    </HStack>
  ) : null;
};
