import {
  Enum_Device_Type,
  Enum_Device_Make,
  OrderItemDataFragment,
  MinerInspectionReportDataFragment,
  HashboardInspectionReportDataFragment,
  PsuInspectionReportDataFragment,
  UploadFileDataFragment,
  ActionDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
} from "data";
import { validateIPAddress } from "lib";
import { WizardStep } from "context/Wizard";
import {
  minerFansCount,
  psuFansCount,
  temperatureSensorsCount,
} from "features/devices/data";

export type ExistingReport =
  | MinerInspectionReportDataFragment
  | HashboardInspectionReportDataFragment
  | PsuInspectionReportDataFragment;

export interface FanSpec {
  id: number;
  isOn: boolean;
}

export interface MinerFanSpec extends FanSpec {}
export interface PSUFanSpec extends FanSpec {}
export interface HashboardTemperatureSensorSpec extends FanSpec {}

export interface HashboardSpec {
  id: number;
  hashrate?: number;
  numberOfASICs?: number;
  temperature?: number;
  isGood?: boolean;
}

export interface DiagnosticsReport {
  // existing report
  id?: string;
  reportType: "miner" | "hashboard" | "psu";
}

export interface MinerDiagnosticsReport extends DiagnosticsReport {
  power: boolean;
  ipAddress?: string | null;
  noIPAddress?: boolean;
  minerFans: MinerFanSpec[];
  psuFans: PSUFanSpec[];
  hashboards: HashboardSpec[];
  errorCodes: string[];
  finalHashrate?: number;
  notes?: string;
  timeHashing?: number;
  controlBoardTemperature?: number;
  suggestedProblems?: string[];
  attachments: UploadFileDataFragment[];
}

export interface HashboardDiagnosticsReport extends DiagnosticsReport {
  temperatureSensors?: HashboardTemperatureSensorSpec[];
  openTraces: boolean;
  initialASICsFound?: number;
  asicsRepairsNeeded: boolean;
  boostCircuitRepairsNeeded?: boolean;
  otherComponentsNeeded: string[];
  notes?: string;
}

export interface PSUDiagnosticsReport extends DiagnosticsReport {
  inputFusesOk: boolean;
  inrushCurrentLimitersOk: boolean;
  mosfetsOk: boolean;
  powerDiodesOk: boolean;
  u25Ok: boolean;
}

const makeMinerFans = (numberOfFans: number): MinerFanSpec[] =>
  Array(numberOfFans)
    .fill(0)
    .map((_, id) => ({ id, isOn: true }));
const makePSUFans = (numberOfFans: number): PSUFanSpec[] =>
  Array(numberOfFans)
    .fill(0)
    .map((_, id) => ({ id, isOn: true }));
const makeTemperatureSensors = (
  numberOfSensors: number
): HashboardTemperatureSensorSpec[] =>
  Array(numberOfSensors)
    .fill(0)
    .map((_, id) => ({ id, isOn: true }));

export const getInitialDiagnosticsReport = (
  orderItem: OrderItemDataFragment,
  existingReport?: ExistingReport
): DiagnosticsReport => {
  // psu
  if (
    orderItem.attributes?.device?.data?.attributes?.type ===
    Enum_Device_Type.Psu
  ) {
    if (existingReport) {
      const existingPsuReport = (
        existingReport as PsuInspectionReportDataFragment
      ).attributes;

      return {
        id: existingReport.id,
        reportType: "psu",
        inputFusesOk: existingPsuReport?.inputFusesOk,
        inrushCurrentLimitersOk: existingPsuReport?.inrushCurrentLimitersOk,
        mosfetsOk: existingPsuReport?.mosfetsOk,
        powerDiodesOk: existingPsuReport?.powerDiodesOk,
        u25Ok: existingPsuReport?.u25Ok,
      } as PSUDiagnosticsReport;
    }

    return {
      reportType: "psu",
      inputFusesOk: false,
      inrushCurrentLimitersOk: false,
      mosfetsOk: false,
      powerDiodesOk: false,
      u25Ok: false,
    } as DiagnosticsReport;
  }

  // miner
  if (
    orderItem.attributes?.device?.data?.attributes?.type ===
    Enum_Device_Type.Miner
  ) {
    const countMinerFans = minerFansCount(orderItem.attributes?.device?.data);
    const minerFans = makeMinerFans(countMinerFans);

    const countPsuFans = psuFansCount(orderItem.attributes?.device?.data);
    const psuFans = makePSUFans(countPsuFans);

    if (existingReport) {
      const existingMinerReport = (
        existingReport as MinerInspectionReportDataFragment
      ).attributes;
      return {
        id: existingReport.id,
        reportType: "miner",
        power: existingMinerReport?.power,
        ipAddress: existingMinerReport?.ipAddress,
        noIPAddress:
          existingMinerReport?.ipAddress === null ||
          typeof existingMinerReport?.ipAddress === "undefined",
        minerFans: minerFans.map((f, i) => {
          // XX: map between number of working miner fans (minerFanCount)
          // and individual miner flags
          // unfortunately we loose specific fan mapping when we create
          // a report, so we need to restore this info here
          return Object.assign(f, {
            isOn: i < existingMinerReport?.minerFanCount!,
          });
        }),
        psuFans: psuFans.map((f, i) => {
          // XX: same as miner fans above
          return Object.assign(f, {
            isOn: i < existingMinerReport?.psuFanCount!,
          });
        }),
        hashboards: existingMinerReport?.hashboards?.map((h, i) => {
          return {
            id: i,
            hashrate: h?.hashrate,
            numberOfASICs: h?.numberOfASICs,
            temperature: h?.temperature,
            isGood: h?.isGood,
          };
        }),
        errorCodes: existingMinerReport?.errorCodes || [],
        finalHashrate: existingMinerReport?.finalHashrate,
        notes: existingMinerReport?.notes,
        timeHashing: existingMinerReport?.timeHashing,
        controlBoardTemperature: existingMinerReport?.controlBoardTemperature,
        suggestedProblems: existingMinerReport?.suggestedProblems,
        attachments: existingMinerReport?.attachments?.data || [],
      } as DiagnosticsReport;
    }

    const base: {
      reportType: "miner" | "hashboard" | "psu";
      power: boolean;
      hashboards: HashboardSpec[];
      errorCodes: string[];
      attachments: UploadFileDataFragment[];
    } = {
      reportType: "miner",
      power: true,
      hashboards: (orderItem.attributes?.order_item_subcomponents?.data || [])
        .filter(
          (ois) =>
            ois.attributes?.device?.data?.attributes?.type ===
            Enum_Device_Type.HashBoard
        )
        .map((_, id) => {
          return {
            id,
            isGood: true,
          };
        }),
      errorCodes: [],
      attachments: [],
    };

    return Object.assign({}, base, {
      minerFans,
      psuFans,
    });
  }

  // hashboard

  const base: {
    reportType: "miner" | "hashboard" | "psu";
    openTraces: boolean;
    asicsRepairsNeeded: boolean;
    otherComponentsNeeded: string[];
  } = {
    reportType: "hashboard",
    openTraces: true,
    asicsRepairsNeeded: false,
    otherComponentsNeeded: [],
  };

  if (existingReport) {
    const existingHashboardReport = (
      existingReport as HashboardInspectionReportDataFragment
    ).attributes;

    return {
      id: existingReport.id || undefined,
      reportType: "hashboard",
      openTraces: existingHashboardReport?.openTraces,
      initialASICsFound: existingHashboardReport?.initialASICsFound,
      asicsRepairsNeeded: existingHashboardReport?.asicsRepairsNeeded,
      otherComponentsNeeded: existingHashboardReport?.otherComponentsNeeded,
      boostCircuitRepairsNeeded:
        orderItem.attributes?.device?.data?.attributes?.make ===
        Enum_Device_Make.Whatsminer
          ? existingHashboardReport?.boostCircuitRepairsNeeded
          : undefined,
      temperatureSensors:
        orderItem.attributes?.device?.data?.attributes?.make ===
        Enum_Device_Make.Antminer
          ? makeTemperatureSensors(
              temperatureSensorsCount(orderItem.attributes?.device?.data)
            ).map((ts, i) => {
              return Object.assign({}, ts, {
                isOn: i < existingHashboardReport?.numberOfTemperatureSensors!,
              });
            })
          : undefined,
    } as HashboardDiagnosticsReport;
  }

  return Object.assign(
    {},
    base,
    orderItem.attributes?.device?.data?.attributes?.make ===
      Enum_Device_Make.Whatsminer
      ? {
          boostCircuitRepairsNeeded: false,
        }
      : {},
    orderItem.attributes?.device?.data?.attributes?.make ===
      Enum_Device_Make.Antminer
      ? {
          temperatureSensors: makeTemperatureSensors(
            temperatureSensorsCount(orderItem.attributes?.device?.data)
          ),
        }
      : {}
  );
};

export enum InspectionStepType {
  Power,
  ControlBoard,
  MinerFans,
  PSUFans,
  Hashboards,
  Errors,
  OtherInputs,
  TemperatureSensors,
  OpenTraces,
  ASICs,
  BoostCircuit,
  OtherComponentsNeeded,
  BasicPSUInputs,
}

export interface InspectionStep extends WizardStep {
  type: InspectionStepType;
}

export const getInspectionStepsForOrderItem = (
  orderItem: OrderItemDataFragment
): InspectionStep[] => {
  const type = orderItem.attributes?.device?.data?.attributes?.type;

  if (type === Enum_Device_Type.Psu) {
    return [
      {
        type: InspectionStepType.BasicPSUInputs,
        title: "Basics",
        index: 0,
      },
    ];
  }

  if (type === Enum_Device_Type.Miner) {
    return [
      { type: InspectionStepType.Power, title: "Power" },
      { type: InspectionStepType.ControlBoard, title: "Control Board" },
      { type: InspectionStepType.MinerFans, title: "Miner Fans" },
      { type: InspectionStepType.PSUFans, title: "PSU Fans" },
      { type: InspectionStepType.Hashboards, title: "Hashboards" },
      ...(orderItem.attributes?.device?.data?.attributes?.make !==
      Enum_Device_Make.Avalon
        ? [{ type: InspectionStepType.Errors, title: "Errors" }]
        : []),
      { type: InspectionStepType.OtherInputs, title: "Other" },
    ].map((step, index) => Object.assign({}, step, { index }));
  }

  if (type === Enum_Device_Type.HashBoard) {
    return [
      ...(orderItem.attributes?.device?.data?.attributes?.make ===
      Enum_Device_Make.Antminer
        ? [
            {
              type: InspectionStepType.TemperatureSensors,
              title: "Temperature Sensors",
            },
          ]
        : []),
      {
        type: InspectionStepType.OpenTraces,
        title: "Open Traces",
      },
      {
        type: InspectionStepType.ASICs,
        title: "ASICs",
      },
      ...(orderItem.attributes?.device?.data?.attributes?.make ===
      Enum_Device_Make.Whatsminer
        ? [
            {
              type: InspectionStepType.BoostCircuit,
              title: "Boost Circuit",
            },
          ]
        : []),
      {
        type: InspectionStepType.OtherComponentsNeeded,
        title: "Other Components",
      },
    ].map((step, index) => Object.assign({}, step, { index }));
  }

  return [];
};

const validateMinerDiagnosticsReport = (
  minerDiagnosticsReport: MinerDiagnosticsReport,
  currentStep: InspectionStep,
  orderItem: OrderItemDataFragment
): boolean => {
  if (currentStep.type === InspectionStepType.ControlBoard) {
    return (
      minerDiagnosticsReport.noIPAddress ||
      validateIPAddress(minerDiagnosticsReport.ipAddress || "")
    );
  }

  if (currentStep.type === InspectionStepType.Hashboards) {
    for (let i = 0; i < minerDiagnosticsReport.hashboards.length; i++) {
      const board = minerDiagnosticsReport.hashboards[i];
      // XX: Avalons do not report number of ASICs
      const fields = [
        board.temperature,
        board.hashrate,
        ...(orderItem.attributes?.device?.data?.attributes?.make !==
        Enum_Device_Make.Avalon
          ? [board.numberOfASICs]
          : []),
      ];

      if (fields.filter((v) => typeof v === "undefined").length !== 0) {
        return false;
      }
    }
    return true;
  }

  if (currentStep.type === InspectionStepType.OtherInputs) {
    return (
      [
        minerDiagnosticsReport.finalHashrate,
        minerDiagnosticsReport.timeHashing,
        ...(orderItem.attributes?.device?.data?.attributes?.make ===
        Enum_Device_Make.Avalon
          ? [minerDiagnosticsReport.controlBoardTemperature]
          : []),
      ].filter((v) => typeof v === "undefined").length === 0
    );
  }

  return true;
};

const validateHashboardDiagnosticsReport = (
  hashboardDiagnosticsReport: HashboardDiagnosticsReport,
  currentStep: InspectionStep,
  orderItem: OrderItemDataFragment
): boolean => {
  if (currentStep.type === InspectionStepType.ASICs) {
    return typeof hashboardDiagnosticsReport.initialASICsFound !== "undefined";
  }

  return true;
};

export const canGoToNextStepWithCurrentReport = (
  currentStep: InspectionStep | null,
  diagnosticsReport: DiagnosticsReport | null,
  orderItem: OrderItemDataFragment
): boolean => {
  if (!currentStep || !diagnosticsReport) {
    return false;
  }

  const type = orderItem.attributes?.device?.data?.attributes?.type;

  if (type === Enum_Device_Type.Psu) {
    return true;
  }

  if (type === Enum_Device_Type.Miner) {
    return validateMinerDiagnosticsReport(
      diagnosticsReport as MinerDiagnosticsReport,
      currentStep,
      orderItem
    );
  }

  if (type === Enum_Device_Type.HashBoard) {
    return validateHashboardDiagnosticsReport(
      diagnosticsReport as HashboardDiagnosticsReport,
      currentStep,
      orderItem
    );
  }

  return false;
};

export type InspectionReportData =
  | MinerInspectionReportDataFragment
  | HashboardInspectionReportDataFragment
  | PsuInspectionReportDataFragment
  | HashboardAdvancedInspectionReportDataFragment;

export interface InspectionReportWithActionContext<
  T extends InspectionReportData
> {
  report?: T;
  action?: ActionDataFragment;
}

export type MinerInspectionReportWithActionContext =
  InspectionReportWithActionContext<MinerInspectionReportDataFragment>;
export type HashboardInspectionReportWithActionContext =
  InspectionReportWithActionContext<
    | HashboardInspectionReportDataFragment
    | HashboardAdvancedInspectionReportDataFragment
  >;
export type PSUInspectionReportWithActionContext =
  InspectionReportWithActionContext<PsuInspectionReportDataFragment>;

export type InspectionReport =
  | MinerInspectionReportWithActionContext
  | HashboardInspectionReportWithActionContext
  | PSUInspectionReportWithActionContext;

interface InspectionReportSummaryEntry {
  label: string;
  value: string;
  isGood?: boolean;
}

interface InspectionReportSummary {
  items: InspectionReportSummaryEntry[];
}

export const summarizeInsectionReport = (
  inspectionReport?: InspectionReportData
): InspectionReportSummary => {
  const summary: InspectionReportSummary = {
    items: [],
  };

  if (!inspectionReport) {
    return summary;
  }

  const device =
    inspectionReport.attributes?.order_item?.data?.attributes?.device?.data;

  if (inspectionReport.__typename === "HashboardInspectionReportEntity") {
    const hashboardInspectionReport =
      inspectionReport as HashboardInspectionReportDataFragment;
    const totalTemperatureSensorsCount = temperatureSensorsCount(device);

    if (
      typeof hashboardInspectionReport.attributes
        ?.numberOfTemperatureSensors !== "undefined" &&
      totalTemperatureSensorsCount !== 0
    ) {
      summary.items.push({
        label: "Temperature Sensors",
        value: `${hashboardInspectionReport.attributes?.numberOfTemperatureSensors} / ${totalTemperatureSensorsCount}`,
        isGood:
          hashboardInspectionReport.attributes?.numberOfTemperatureSensors ===
          totalTemperatureSensorsCount,
      });
    }

    if (
      typeof hashboardInspectionReport.attributes?.initialASICsFound !==
      "undefined"
    ) {
      summary.items.push({
        label: "Initial ASICs Found",
        value: `${hashboardInspectionReport.attributes?.initialASICsFound}`,
      });
    }

    summary.items.push({
      label: "ASIC Repairs Needed",
      value: `${
        hashboardInspectionReport.attributes?.asicsRepairsNeeded ? "Yes" : "No"
      }`,
      isGood: !hashboardInspectionReport.attributes?.asicsRepairsNeeded,
    });

    summary.items.push({
      label: "Open Traces",
      value: `${
        hashboardInspectionReport.attributes?.openTraces ? "Yes" : "No"
      }`,
    });

    if (
      typeof hashboardInspectionReport.attributes?.boostCircuitRepairsNeeded !==
      "undefined"
    ) {
      summary.items.push({
        label: "Boost Circuit Repairs Needed",
        value: `${
          hashboardInspectionReport.attributes?.boostCircuitRepairsNeeded
            ? "Yes"
            : "No"
        }`,
        isGood:
          !hashboardInspectionReport.attributes?.boostCircuitRepairsNeeded,
      });
    }

    if (
      typeof hashboardInspectionReport.attributes?.otherComponentsNeeded !==
        "undefined" &&
      hashboardInspectionReport.attributes?.otherComponentsNeeded.length !== 0
    ) {
      summary.items.push({
        label: "Other Components Needed",
        value: `${hashboardInspectionReport.attributes?.otherComponentsNeeded.join(
          ", "
        )}`,
        isGood: false,
      });
    }

    if (hashboardInspectionReport.attributes?.notes) {
      summary.items.push({
        label: "Notes",
        value: hashboardInspectionReport.attributes?.notes,
      });
    }
  }

  if (inspectionReport.__typename === "MinerInspectionReportEntity") {
    const minerInspectionReport =
      inspectionReport as MinerInspectionReportDataFragment;
    const totalMinerFans = minerFansCount(device);
    const totalPSUFans = psuFansCount(device);

    summary.items.push({
      label: "Power",
      value: minerInspectionReport.attributes?.power ? "ON" : "OFF",
      isGood: minerInspectionReport.attributes?.power || false,
    });

    if (!minerInspectionReport.attributes?.power) {
      if (minerInspectionReport.attributes?.suggestedProblems) {
        summary.items.push({
          label: "Suspected Issues",
          value: `${minerInspectionReport.attributes.suggestedProblems.join(
            ", "
          )}`,
        });
      }
      return summary;
    }

    summary.items.push({
      label: "IP",
      value: minerInspectionReport.attributes?.ipAddress || "N/A",
      isGood: minerInspectionReport.attributes?.ipAddress ? true : false,
    });

    if (!minerInspectionReport.attributes.ipAddress) {
      if (minerInspectionReport.attributes.suggestedProblems) {
        summary.items.push({
          label: "Suspected Issues",
          value: `${minerInspectionReport.attributes.suggestedProblems.join(
            ", "
          )}`,
        });
      }
      return summary;
    }

    summary.items.push({
      label: "Miner Fans",
      value: `${minerInspectionReport.attributes.minerFanCount} / ${totalMinerFans}`,
      isGood: minerInspectionReport.attributes.minerFanCount === totalMinerFans,
    });

    summary.items.push({
      label: "PSU Fans",
      value: `${minerInspectionReport.attributes.psuFanCount} / ${totalPSUFans}`,
      isGood: minerInspectionReport.attributes.psuFanCount === totalPSUFans,
    });

    summary.items.push({
      label: "Hashboards",
      value: `${
        minerInspectionReport.attributes.hashboards?.filter((h) => h?.isGood)
          .length
      } / ${minerInspectionReport.attributes.hashboards?.length}`,
      isGood:
        minerInspectionReport.attributes.hashboards?.filter((h) => !h?.isGood)
          .length === 0,
    });

    if (minerInspectionReport.attributes.errorCodes.length !== 0) {
      summary.items.push({
        label: "Error Codes",
        value: `${minerInspectionReport.attributes.errorCodes.join(", ")}`,
        isGood: false,
      });
    }

    if (minerInspectionReport.attributes.finalHashrate) {
      summary.items.push({
        label: "Final Hashrate",
        value: `${minerInspectionReport.attributes.finalHashrate} TH`,
      });
    }

    if (minerInspectionReport.attributes.timeHashing) {
      summary.items.push({
        label: "Time Hashing",
        value: `${minerInspectionReport.attributes.timeHashing} minutes`,
      });
    }

    if (minerInspectionReport.attributes.controlBoardTemperature) {
      summary.items.push({
        label: "Control Board Temperature",
        value: `${minerInspectionReport.attributes.controlBoardTemperature}`,
      });
    }

    if (minerInspectionReport.attributes.notes) {
      summary.items.push({
        label: "Notes",
        value: `${minerInspectionReport.attributes.notes}`,
      });
    }

    if (minerInspectionReport.attributes.suggestedProblems) {
      summary.items.push({
        label: "Suspected Issues",
        value: `${minerInspectionReport.attributes.suggestedProblems.join(
          ", "
        )}`,
      });
    }
  }

  if (inspectionReport.__typename === "PsuInspectionReportEntity") {
    const psuInspectionReport =
      inspectionReport as PsuInspectionReportDataFragment;

    summary.items.push({
      label: "Are both input fuses OK?",
      value: psuInspectionReport.attributes?.inputFusesOk ? "Yes" : "No",
      isGood: psuInspectionReport.attributes?.inputFusesOk === true,
    });

    summary.items.push({
      label: "Are 4 inrush current limiters OK?",
      value: psuInspectionReport.attributes?.inrushCurrentLimitersOk
        ? "Yes"
        : "No",
      isGood: psuInspectionReport.attributes?.inrushCurrentLimitersOk === true,
    });

    summary.items.push({
      label: "Are 6 MOSFETS OK?",
      value: psuInspectionReport.attributes?.mosfetsOk ? "Yes" : "No",
      isGood: psuInspectionReport.attributes?.mosfetsOk === true,
    });

    summary.items.push({
      label: "Are 4 power diodes OK?",
      value: psuInspectionReport.attributes?.powerDiodesOk ? "Yes" : "No",
      isGood: psuInspectionReport.attributes?.powerDiodesOk === true,
    });

    summary.items.push({
      label: "Is U25 OK?",
      value: psuInspectionReport.attributes?.u25Ok ? "Yes" : "No",
      isGood: psuInspectionReport.attributes?.u25Ok === true,
    });
  }

  return summary;
};

export enum NumberOfASICsDetected {
  AllASICs,
  SomeASICs,
  NoASICs,
}

export interface QuickInspectReport {
  asicsDetected: NumberOfASICsDetected;
}
