import {
  FormControl,
  Select as ChakraSelect,
  SelectProps,
} from "@chakra-ui/react";

export const Select = (props: SelectProps) => {
  const { children, ...rest } = props;
  return (
    <FormControl>
      <ChakraSelect {...rest}>{children}</ChakraSelect>
    </FormControl>
  );
};
