import { Outlet } from "react-router-dom";
import { Container, Stack } from "@chakra-ui/react";

export const AuthLayout = () => (
  <Container
    maxW="lg"
    py={{ base: "12", md: "24" }}
    px={{ base: "0", sm: "8" }}
  >
    <Stack spacing="8">
      <Outlet />
    </Stack>
  </Container>
);
