import { Stack, HStack, Divider } from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";
import { CustomerDeviceCompleteNameLabel } from "features/customer-devices/components/CustomerDeviceCompleteNameLabel";
import { DeviceTypeLabel } from "features/devices/components/DeviceTypeLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderItemStatusLabel } from "features/order-items/components/OrderItemStatusLabel";

interface OrderItemActionPanelHeaderProps {
  orderItem?: OrderItemDataFragment | null;
}

export const OrderItemActionPanelHeader = (
  props: OrderItemActionPanelHeaderProps
) => {
  const { orderItem } = props;
  return orderItem ? (
    <Stack>
      <HStack>
        <CustomerDeviceCompleteNameLabel
          fontSize="md"
          customerDevice={orderItem.attributes?.customer_device?.data}
          showMakeIcon
        />
        <CustomerDeviceShortIdLabel
          customerDevice={orderItem.attributes?.customer_device?.data}
        />
      </HStack>
      <HStack>
        <DeviceTypeLabel
          fontSize="small"
          fontWeight="normal"
          showTypeIcon
          deviceType={orderItem.attributes?.device?.data?.attributes?.type}
        />
        <OrderItemStatusLabel
          fontWeight="normal"
          fontSize="small"
          orderItem={orderItem}
        />
      </HStack>
      <Divider />
    </Stack>
  ) : null;
};
