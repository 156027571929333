import { createContext, useContext, useState } from "react";
import { OrderItemDataFragment } from "data";
import { Loader } from "components/Loader";
import { HashboardBasicRepair } from "features/repairs/components/hashboard/BasicRepair";
import { useLatestHashboardInspection } from "features/diagnostics/data/hooks/diagnostics";
import { useRegisterHashboardRepair } from "features/repairs/data/hooks/repairs";

interface HashboardRepairWizardProviderProps {
  orderItem: OrderItemDataFragment;
  onComplete: () => void;
}

interface HashboardRepairWizardData {}

const HashboardRepairWizardContext = createContext<HashboardRepairWizardData>(
  {} as HashboardRepairWizardData
);

export const useHashboardRepairWizard = () => {
  return useContext(HashboardRepairWizardContext);
};

export const HashboardRepairWizardProvider = (
  props: HashboardRepairWizardProviderProps
) => {
  const { orderItem, onComplete } = props;
  const { registerRepair } = useRegisterHashboardRepair();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { loading, report } = useLatestHashboardInspection({
    orderItemId: orderItem.id!,
  });
  return (
    <HashboardRepairWizardContext.Provider value={{}}>
      {isSubmitting || loading ? (
        <Loader />
      ) : (
        <HashboardBasicRepair
          latestReport={report}
          onSubmit={async (repair) => {
            setIsSubmitting(true);

            await registerRepair({
              orderItem,
              repair,
              associatedDiagnostics: report,
            });

            setIsSubmitting(false);
            onComplete();
          }}
        />
      )}
    </HashboardRepairWizardContext.Provider>
  );
};
