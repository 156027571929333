import { HStack, Text, TextProps } from "@chakra-ui/react";
import { DeviceDataFragment } from "data";
import { completeDeviceName } from "features/devices/data";
import { DeviceMakeIcon } from "./DeviceMakeIcon";
import { DeviceTypeIcon } from "./DeviceTypeIcon";

interface DeviceCompleteNameLabelProps extends TextProps {
  device?: DeviceDataFragment | null;
  hashrate?: string | null;
  showMakeIcon?: boolean;
  showDeviceTypeIcon?: boolean;
}

export const DeviceCompleteNameLabel = (
  props: DeviceCompleteNameLabelProps
) => {
  const { device, showMakeIcon, showDeviceTypeIcon, hashrate, ...rest } = props;
  return (
    <HStack>
      {showMakeIcon && device ? (
        <DeviceMakeIcon deviceOrMake={device} w={4} h={4} />
      ) : null}
      {showDeviceTypeIcon ? (
        <DeviceTypeIcon deviceType={device?.attributes?.type} />
      ) : null}
      <Text {...rest}>{completeDeviceName(device, hashrate)}</Text>
    </HStack>
  );
};
