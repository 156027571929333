import {
  useCustomerDetailsQuery,
  useCustomerProfileQuery,
  CustomerDataFragment,
  CustomerProfileFragment,
  OrderDataFragment,
  OrderPackageDataFragment,
} from "data";
import { useLocalStorage } from "data/hooks/local-storage";
import { useAuthentication } from "features/accounts/context/Authentication";

interface UseCustomerDetailsHookData {
  loading: boolean;
  customer?: CustomerDataFragment | null;
  orders?: OrderDataFragment[];
  orderPackages?: OrderPackageDataFragment[];
  refetch: () => Promise<void>;
}

interface UseCurrentCustomerProfileHookData {
  loading: boolean;
  customer?: CustomerProfileFragment | null;
}

interface UseCustomerDetailsProps {
  customerId: string;
}

export const useCustomerDetails = (
  props: UseCustomerDetailsProps
): UseCustomerDetailsHookData => {
  const { loading, data, refetch } = useCustomerDetailsQuery({
    variables: {
      customerId: props.customerId,
    },
    fetchPolicy: "network-only",
  });
  return {
    async refetch() {
      await refetch();
    },
    loading,
    customer: data?.customer?.data,
    orders: data?.orders?.data || [],
    orderPackages: data?.orderPackages?.data || [],
  };
};

interface UseCurrentCustomerHookData {
  // this is current customer
  customer: CustomerDataFragment;
  // these are all the customer accounts associated with the logged in user
  availableCustomers: CustomerDataFragment[];
  // specify current customer for the logged in user (multi customer scenarios)
  setCurrentCustomer: (customerId: string) => void;
  clearCurrentCustomer: () => void;
}

const currentCustomerLocalStorageKey = "currentCustomer";

export const useCurrentCustomer = (): UseCurrentCustomerHookData => {
  const { userProfile } = useAuthentication();
  const [currentCustomer, setCurrentCustomer] =
    useLocalStorage<CustomerDataFragment | null>(
      currentCustomerLocalStorageKey,
      null
    );
  if (
    userProfile?.attributes?.customers?.data.length === 0 ||
    !userProfile?.attributes?.customers?.data[0]
  ) {
    throw new Error("Not a customer");
  }

  let presetCustomer = currentCustomer;

  if (
    presetCustomer &&
    !userProfile?.attributes?.customers?.data.find(
      (c) => c.id === presetCustomer?.id
    )
  ) {
    // this could happen if a customer logs out and then logs back in
    // using a different account with different customers associated with it
    presetCustomer = null;
  }

  return {
    customer: presetCustomer || userProfile?.attributes?.customers?.data[0],
    availableCustomers: userProfile.attributes.customers.data,
    setCurrentCustomer(customerId) {
      setCurrentCustomer(
        (userProfile.attributes?.customers?.data || []).find(
          (c) => c.id === customerId
        ) || null
      );
    },
    clearCurrentCustomer() {
      setCurrentCustomer(null);
    },
  };
};

export const useCurrentCustomerProfile =
  (): UseCurrentCustomerProfileHookData => {
    const { customer } = useCurrentCustomer();
    const { data, loading } = useCustomerProfileQuery({
      variables: {
        customerId: customer.id!,
      },
    });
    return {
      loading,
      customer: data?.customer?.data,
    };
  };
