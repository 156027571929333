import { ReactElement } from "react";
import { ShowForRoles } from "./ShowForRoles";

interface ShowForCustomersProps {
  children: ReactElement;
  inverse?: boolean;
}

export const ShowForCustomers = (props: ShowForCustomersProps) => {
  const { children, inverse } = props;
  return (
    <ShowForRoles roles={["Customer"]} inverse={inverse}>
      {children}
    </ShowForRoles>
  );
};
