import {
  Text,
  SimpleGrid,
  Stack,
  HStack,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { Enum_Orderitem_Status, OrderDataFragment } from "data";
import { QuestionIcon } from "components/Icons";
import { _orderItemStatusToShortString } from "features/order-items/data";

interface OrderStatsForCustomerProps {
  order: OrderDataFragment;
}

enum StatCategory {
  WorkInProgress,
  Pending,
  Fixed,
  CannotFix,
}

const labelForStatCategory = (statCategory: StatCategory): string => {
  switch (statCategory) {
    case StatCategory.WorkInProgress:
      return "Work in progress";
    case StatCategory.Pending:
      return "Pending";
    case StatCategory.Fixed:
      return "Repaired";
    case StatCategory.CannotFix:
      return "Unrepairable";
  }
};

const categoriesForGroupings = {
  [StatCategory.Fixed]: [
    Enum_Orderitem_Status.Packaged,
    Enum_Orderitem_Status.Shipped,
    Enum_Orderitem_Status.QaPass,
  ],
  [StatCategory.CannotFix]: [
    Enum_Orderitem_Status.ExcessiveDamageScrapForParts,
    Enum_Orderitem_Status.DiagnosedUnrepairable,
    Enum_Orderitem_Status.QaFailRecycled,
    Enum_Orderitem_Status.QaFailReturnToCustomer,
    Enum_Orderitem_Status.ReplacedRecycled,
    Enum_Orderitem_Status.ReplacedReturnToCustomer,
  ],
  [StatCategory.Pending]: [
    Enum_Orderitem_Status.Receiving,
    Enum_Orderitem_Status.DiagnosisPending,
  ],
  [StatCategory.WorkInProgress]: [
    Enum_Orderitem_Status.DiagnosedIsOk,
    Enum_Orderitem_Status.DiagnosedNeedsRepair,
    Enum_Orderitem_Status.QaFail,
    Enum_Orderitem_Status.RepairedFailed,
    Enum_Orderitem_Status.RepairedNeedsQa,
  ],
};

const countForCategory = (
  order: OrderDataFragment,
  statCategory: StatCategory
): number => {
  return (order.attributes?.stats || [])
    .filter((s) => {
      switch (statCategory) {
        case StatCategory.Fixed:
          return (
            categoriesForGroupings[StatCategory.Fixed].indexOf(s.status) !== -1
          );
        case StatCategory.CannotFix:
          return (
            categoriesForGroupings[StatCategory.CannotFix].indexOf(s.status) !==
            -1
          );
        case StatCategory.Pending:
          return (
            categoriesForGroupings[StatCategory.Pending].indexOf(s.status) !==
            -1
          );
        case StatCategory.WorkInProgress:
          return (
            categoriesForGroupings[StatCategory.WorkInProgress].indexOf(
              s.status
            ) !== -1
          );
        default:
          return [];
      }
    })
    .map((s) => s.count)
    .reduce((s, c) => s + c, 0);
};

const explainerLabel = (statCategory: StatCategory): string => {
  const buildLabel = (values: Enum_Orderitem_Status[]): string => {
    return values.map((v) => _orderItemStatusToShortString(v)).join(", ");
  };
  switch (statCategory) {
    case StatCategory.Fixed:
      return buildLabel(categoriesForGroupings[StatCategory.Fixed]);
    case StatCategory.CannotFix:
      return buildLabel(categoriesForGroupings[StatCategory.CannotFix]);
    case StatCategory.Pending:
      return buildLabel(categoriesForGroupings[StatCategory.Pending]);
    case StatCategory.WorkInProgress:
      return buildLabel(categoriesForGroupings[StatCategory.WorkInProgress]);
    default:
      return "";
  }
};

const Stat = ({
  statCategory,
  count,
}: {
  statCategory: StatCategory;
  count: number;
}) => {
  return count === 0 ? null : (
    <Stack p="2" textAlign="center">
      <Text fontWeight="bold" fontSize="xl">
        {count}
      </Text>
      <Center>
        <HStack>
          <Text color="muted">{labelForStatCategory(statCategory)}</Text>
          <Tooltip shouldWrapChildren label={explainerLabel(statCategory)}>
            <QuestionIcon size={14} />
          </Tooltip>
        </HStack>
      </Center>
    </Stack>
  );
};

export const OrderStatsForCustomer = (props: OrderStatsForCustomerProps) => {
  const { order } = props;
  return (
    <SimpleGrid minChildWidth="150px" spacing="20px">
      {[
        StatCategory.WorkInProgress,
        StatCategory.Pending,
        StatCategory.Fixed,
        StatCategory.CannotFix,
      ].map((statCategory) => (
        <Stat
          key={`stat-${statCategory}`}
          statCategory={statCategory}
          count={countForCategory(order, statCategory)}
        />
      ))}
    </SimpleGrid>
  );
};
