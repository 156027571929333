import { Text, TextProps } from "@chakra-ui/react";
import { ORDER_ITEM_TAGS } from "data";

const prettyTagText = (text: string): string => {
  switch (text) {
    case ORDER_ITEM_TAGS.MinerConsolidatedGood:
      return "Miner Consolidated Good";
    case ORDER_ITEM_TAGS.MinerConsolidatedBad:
      return "Miner Consolidated Bad";
    case ORDER_ITEM_TAGS.QuickInspectAllASICs:
      return "Quick Inspect: ALL ASICS";
    case ORDER_ITEM_TAGS.QuickInspectNoASICs:
      return "Quick Inspect: NO ASICS";
    case ORDER_ITEM_TAGS.QuickInspectSomeASICs:
      return "Quick Inspect: SOME ASICS";
    default:
      return text;
  }
};

interface OrderItemTagTextProps extends TextProps {
  tag: string;
  showTypeIcon?: boolean;
}

export const OrderItemTagText = (props: OrderItemTagTextProps) => {
  const { tag, showTypeIcon, ...rest } = props;
  return <Text {...rest}>{prettyTagText(tag)}</Text>;
};
