import {
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

interface NumberPickerProps {
  value: number;
  label: string;
  onChange: (value: number) => void;
  inputProps?: object;
}

export const NumberPicker = (props: NumberPickerProps) => {
  const { value, label, inputProps, onChange } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <NumberInput onChange={(_, value) => onChange(value)} value={value}>
        <NumberInputField {...(inputProps || {})} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};
