// Import necessary types and functions
import {
  OrderItemDataFragment,
  useUpdateOrderItemMutation,
  Enum_Orderitem_Status,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "data";
import { useWrapWithNotifications } from "context/Notifications";
import { useCreateAction, expandActionDataPiece } from "features/actions/data/hooks/actions";

// Define the return type for the hook
interface UseScrapeForPartsHookData {
  scrapeForParts: () => Promise<OrderItemDataFragment | null>;
}

// Custom hook for scrapping an order item for parts
export const useScrapForParts = ({
  orderItem,
}: {
  orderItem: OrderItemDataFragment;
}): UseScrapeForPartsHookData => {
  // Use the mutation hook to update the order item
  const [updateOrderItem] = useUpdateOrderItemMutation();
  // Use the hook to create an action
  const { createAction } = useCreateAction();

  // Wrap the scrapping process with notifications
  const scrapeForParts = useWrapWithNotifications<void, OrderItemDataFragment>({
    successMessage: "Item scrapped",
    runner: async () => {
      // Update the order item status
      const { data } = await updateOrderItem({
        variables: {
          id: orderItem.id || "",
          data: {
            status: Enum_Orderitem_Status.ExcessiveDamageScrapForParts,
          },
        },
      });

      // Create an action to log the scrapping
      await createAction({
        type: Enum_Action_Type.ScrapForParts,
        scope: Enum_Action_Scope.OrderItem,
        data: {
          ...expandActionDataPiece(orderItem),
        },
        context: {
          order: orderItem.attributes?.order?.data,
          device: orderItem.attributes?.device?.data,
          customerDevice: orderItem.attributes?.customer_device?.data,
        },
      });

      // Return the updated order item data or null
      return data?.updateOrderItem?.data || null;
    },
  });

  // Return the scrapeForParts function
  return {
    scrapeForParts,
  };
};
