import { ShowForAllTechs, ShowForCustomers } from "features/accounts";
import { OrdersPage as OrdersPageForTechs } from "./techs/Orders";
import { OrdersPage as OrdersPageForCustomers } from "./customers/Orders";

export const OrdersPage = () => (
  <>
    <ShowForAllTechs>
      <OrdersPageForTechs />
    </ShowForAllTechs>
    <ShowForCustomers>
      <OrdersPageForCustomers />
    </ShowForCustomers>
  </>
);
