import memoize from "lodash.memoize";
import { OrderDataFragment, Enum_Order_Status } from "data";

export const completeOrderName = (order?: OrderDataFragment | null) => {
  if (!order) {
    return "";
  }
  return `${order.attributes?.zoho_sales_order?.data?.attributes?.accountName} - ${order.attributes?.zoho_sales_order?.data?.attributes?.salesOrderNumber}`;
};

export const shortOrderName = (order?: OrderDataFragment | null) => {
  if (!order) {
    return "";
  }
  return `${order.attributes?.zoho_sales_order?.data?.attributes?.salesOrderNumber}`;
};

const _orderStatusToString = (status?: Enum_Order_Status): string => {
  switch (status) {
    case Enum_Order_Status.Complete:
      return "Complete";
    case Enum_Order_Status.Receiving:
      return "Receiving";
    case Enum_Order_Status.Wip:
      return "Work in Progress";
    default:
      return "";
  }
};

export const orderStatusToString = memoize(_orderStatusToString);
