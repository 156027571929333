import { useContext, useState, ReactNode } from "react";
import { Enum_Orderitem_Status, OrderItemDataFragment } from "data";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";
import { OrderItemActionPanelHeader } from "features/order-items/components/OrderItemActionPanelHeader";
import { CreateQAReportForm } from "features/qa/components/CreateQAReportForm";
import { validateQAReport, QAReportInput } from "features/qa/data";
import { useCreateQAReport } from "features/qa/data/hooks/qa";

interface QAActionPanelData {
  orderItem: OrderItemDataFragment;
}

const qaActionPanel = generateContextAndProvider<QAActionPanelData>();

export const useQAActionPanel =
  (): ActionPanelContextData<QAActionPanelData> => {
    const context = useContext(qaActionPanel.Context);

    if (!context) {
      throw new Error(
        "Calling useQAActionPanel outside of QAActionPanelProvider"
      );
    }

    return context;
  };

export const QAActionPanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { createReport } = useCreateQAReport();
  const [report, setReport] = useState<QAReportInput | null>(null);
  const [orderItemStatus, setOrderItemStatus] =
    useState<Enum_Orderitem_Status | null>(null);
  const [enablePrimaryAction, setEnablePrimaryAction] =
    useState<boolean>(false);
  return (
    <qaActionPanel.Provider
      enablePrimaryAction={enablePrimaryAction && report !== null}
      primaryButtonText="Submit QA Report"
      onAction={async ({ data }) => {
        if (data?.orderItem && report && orderItemStatus) {
          await createReport({
            orderItem: data.orderItem,
            report,
            orderItemStatus,
          });
        }
      }}
      renderHeader={({ data }) => (
        <OrderItemActionPanelHeader orderItem={data?.orderItem} />
      )}
      renderBody={({ data }) => {
        return (
          <CreateQAReportForm
            onUploading={(uploading) => {
              setEnablePrimaryAction(!uploading);
            }}
            onChange={({ formInput: report, orderItemStatus }) => {
              setReport(report);
              setOrderItemStatus(orderItemStatus);
              setEnablePrimaryAction(validateQAReport(report, data?.orderItem));
            }}
            orderItem={data?.orderItem}
          />
        );
      }}
    >
      {children}
    </qaActionPanel.Provider>
  );
};
