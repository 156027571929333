import { useCallback } from "react";
import {
  useOrderItemSummaryQuery,
  useUpdateOrderItemMutation,
  useDeleteOrderItemMutation,
  useDeleteOrderItemSubcomponentMutation,
  useOrderItemsReadyForPackagingQuery,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
  OrderItemReplacementDataFragment,
  Enum_Orderitem_Status,
  CustomerDataFragment,
  OrderLayoutItemsDocument,
  OrderLayoutItemsQuery,
  OrderLayoutItemsQueryVariables,
  OrderItemInput,
} from "data";
import { isOrderItemComplete } from "features/order-items/data";
import { useNotifications } from "context/Notifications";
import { useOrderItemsForOrder } from "features/order-items/context/OrderItemsForOrder";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface UseDeleteOrderItemHookData {
  deleteOrderItem: (orderItem: OrderItemDataFragment) => Promise<void>;
}

interface UseDeleteOrderItemSubcomponentHookData {
  deleteOrderItemSubcomponent: (
    orderItemSubcomponent: OrderItemSubcomponentDataFragment
  ) => Promise<void>;
}

export const useDeleteOrderItemSubcomponent =
  (): UseDeleteOrderItemSubcomponentHookData => {
    const [doDeleteOrderItemSubcomponent] =
      useDeleteOrderItemSubcomponentMutation();
    return {
      async deleteOrderItemSubcomponent(orderItemSubcomponent) {
        if (!orderItemSubcomponent.id) {
          return;
        }
        await doDeleteOrderItemSubcomponent({
          variables: {
            id: orderItemSubcomponent.id,
          },
          update(cache, el) {
            const deletedId = el.data?.deleteOrderItemSubcomponent?.data?.id;
            const orderItemId =
              orderItemSubcomponent.attributes?.parent?.data?.id;

            if (
              !deletedId ||
              !orderItemId ||
              !orderItemSubcomponent.attributes?.parent?.data
            ) {
              return;
            }

            cache.modify({
              id: cache.identify(orderItemSubcomponent.attributes?.parent.data),
              fields: {
                attributes(currentAttributes) {
                  return Object.assign({}, currentAttributes, {
                    order_item_subcomponents: Object.assign(
                      {},
                      currentAttributes.order_item_subcomponents,
                      {
                        data: currentAttributes.order_item_subcomponents.data.filter(
                          (ois: { __ref: string }) =>
                            ois.__ref !== cache.identify(orderItemSubcomponent)
                        ),
                      }
                    ),
                  });
                },
              },
            });
          },
        });
      },
    };
  };

export const useDeleteOrderItem = (): UseDeleteOrderItemHookData => {
  const { createAction } = useCreateAction();
  const [doDeleteOrderItem] = useDeleteOrderItemMutation();
  const orderItemsContext = useOrderItemsForOrder();
  return {
    async deleteOrderItem(orderItem) {
      if (!orderItem.id) {
        return;
      }
      await doDeleteOrderItem({
        variables: {
          id: orderItem.id,
        },
        update(cache) {
          // when top level order items get removed,
          // we will need to update corresponding order layout item

          if (orderItem.attributes?.parent?.data) {
            return;
          }

          const querySpec = {
            query: OrderLayoutItemsDocument,
            variables: {
              orderId: orderItem.attributes?.order?.data?.id || "",
            },
          };

          const orderLayoutItems = cache.readQuery<
            OrderLayoutItemsQuery,
            OrderLayoutItemsQueryVariables
          >(querySpec);

          if (!orderLayoutItems) {
            return;
          }

          const layoutItem = orderLayoutItems.orderLayoutItems?.data.find(
            (oli) =>
              oli.attributes?.device?.data?.id ===
              orderItem.attributes?.device?.data?.id
          );

          if (!layoutItem || !layoutItem.attributes?.quantity) {
            return;
          }

          const currentLayoutItems =
            orderLayoutItems?.orderLayoutItems?.data || [];

          cache.writeQuery<
            OrderLayoutItemsQuery,
            OrderLayoutItemsQueryVariables
          >(
            Object.assign({}, querySpec, {
              data: {
                orderLayoutItems: Object.assign(
                  {},
                  orderLayoutItems?.orderLayoutItems,
                  {
                    data: [
                      ...currentLayoutItems.filter(
                        (li) => li.id !== layoutItem.id
                      ),
                      ...(layoutItem.attributes?.quantity === 1
                        ? []
                        : [
                            Object.assign({}, layoutItem, {
                              attributes: Object.assign(
                                {},
                                layoutItem.attributes,
                                {
                                  quantity: layoutItem.attributes?.quantity - 1,
                                }
                              ),
                            }),
                          ]),
                    ],
                  }
                ),
              },
            })
          );
        },
      });

      await createAction({
        type: Enum_Action_Type.RemoveOrderItem,
        scope: Enum_Action_Scope.Order,
        data: {
          ...expandActionDataPiece(orderItem.attributes?.order?.data),
          metadata: {
            order_item: orderItem,
          },
        },
        context: {
          order: orderItem.attributes?.order?.data,
          device: orderItem.attributes?.device?.data,
          customerDevice: orderItem.attributes?.customer_device?.data,
        },
      });

      await orderItemsContext?.refetch();
    },
  };
};

export interface UseOrderItemHookData {
  loading: boolean;
  orderItem?: OrderItemDataFragment | null;
  subcomponentOrderItems: OrderItemDataFragment[];
  replacements: OrderItemReplacementDataFragment[];
  isComplete: () => boolean;
  refetch: () => Promise<void>;
}

export const useOrderItemSummary = (
  orderItemId: string
): UseOrderItemHookData => {
  const { loading, data, refetch } = useOrderItemSummaryQuery({
    variables: {
      id: orderItemId,
    },
  });
  const orderItem = data?.orderItem?.data || null;
  const subcomponentOrderItems = data?.subcomponentOrderItems?.data || [];
  return {
    loading,
    orderItem,
    subcomponentOrderItems,
    replacements: data?.orderItemReplacements?.data || [],
    refetch: useCallback(async () => {
      await refetch();
    }, [refetch]),
    isComplete: () => {
      return orderItem ? isOrderItemComplete(orderItem) : false;
    },
  };
};

interface UseUpdateOrderItemStatusHookData {
  updateOrderItemStatus: (
    orderItem: OrderItemDataFragment,
    status: Enum_Orderitem_Status,
    showMessage?: boolean
  ) => Promise<void>;
}

export const useUpdateOrderItemStatus =
  (): UseUpdateOrderItemStatusHookData => {
    const { showSuccessMessage } = useNotifications();
    const { createAction } = useCreateAction();
    const [updateOrderItem] = useUpdateOrderItemMutation();
    const orderItemsContext = useOrderItemsForOrder();

    return {
      async updateOrderItemStatus(orderItem, status, trackAction = true) {
        await updateOrderItem({
          variables: {
            id: orderItem.id!,
            data: {
              status,
            },
          },
        });

        if (trackAction) {
          await createAction({
            type: Enum_Action_Type.ChangeOrderItemStatus,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              metadata: {
                from: orderItem.attributes?.status,
                to: status,
              },
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
            },
          });
          showSuccessMessage("Status updated");
        }

        if (orderItemsContext?.refetch) {
          await orderItemsContext?.refetch();
        }
      },
    };
  };

interface UseUpdateOrderItemHostedStatus {
  updateOrderItemHostedStatus: (
    orderItemId: string,
    isHosted: boolean
  ) => Promise<void>;
}

export const useUpdateOrderItemHostedStatus =
  (): UseUpdateOrderItemHostedStatus => {
    const { showSuccessMessage } = useNotifications();
    const [updateOrderItem] = useUpdateOrderItemMutation();

    return {
      async updateOrderItemHostedStatus(orderItemId, isHosted) {
        await updateOrderItem({
          variables: {
            id: orderItemId,
            data: {
              isHosted,
            },
          },
        });
        showSuccessMessage("Status updated");
      },
    };
  };

interface UseOrderItemsReadyForPackagingHookData {
  refetch: () => Promise<void>;
  orderItems: OrderItemDataFragment[];
  loading: boolean;
}

interface UseOrderItemsReadyForPackagingProps {
  customer: CustomerDataFragment;
}

export const useOrderItemsReadyForPackaging = (
  props: UseOrderItemsReadyForPackagingProps
): UseOrderItemsReadyForPackagingHookData => {
  const { loading, data, refetch } = useOrderItemsReadyForPackagingQuery({
    variables: {
      customerId: props.customer.id as string,
    },
    fetchPolicy: "network-only",
  });
  return {
    async refetch() {
      await refetch();
    },
    loading,
    orderItems: data?.orderItems?.data || [],
  };
};

interface UseUpdateOrderItemHookData {
  updateOrderItem: (orderItemId: string, data: OrderItemInput) => Promise<void>;
}

export const useUpdateOrderItem = (): UseUpdateOrderItemHookData => {
  const [updateOrderItem] = useUpdateOrderItemMutation();
  return {
    async updateOrderItem(orderItemId, data) {
      await updateOrderItem({
        variables: {
          id: orderItemId,
          data,
        },
      });
    },
  };
};
