import { TextProps } from "@chakra-ui/react";
import { CustomerDeviceDataFragment } from "data";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";

interface CustomerDeviceCompleteNameLabelProps extends TextProps {
  customerDevice?: CustomerDeviceDataFragment | null;
  showMakeIcon?: boolean;
  showDeviceTypeIcon?: boolean;
}

export const CustomerDeviceCompleteNameLabel = (
  props: CustomerDeviceCompleteNameLabelProps
) => {
  const { customerDevice, ...rest } = props;

  if (!customerDevice) {
    return null;
  }

  return (
    <DeviceCompleteNameLabel
      device={customerDevice.attributes?.device?.data}
      hashrate={customerDevice.attributes?.hashrate}
      {...rest}
    />
  );
};
