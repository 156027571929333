import { HStack } from "@chakra-ui/react";
import { GoPackage } from "react-icons/go";
import { OrderPackageDataFragment } from "data";
import { PageHeader } from "components/layout/Page";
import { OrderPackageStatusBadge } from "features/order-packages/components/OrderPackageStatusBadge";

interface OrderPackagePageTitleProps {
  orderPackage: OrderPackageDataFragment;
}

export const OrderPackagePageTitle = (props: OrderPackagePageTitleProps) => {
  const { orderPackage } = props;
  return (
    <HStack>
      <GoPackage size="30" />
      <PageHeader>{orderPackage.attributes?.name}</PageHeader>
      <OrderPackageStatusBadge orderPackage={orderPackage} />
    </HStack>
  );
};
