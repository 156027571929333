import * as React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
// @ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const checkMessageForUnauthorized = (message: string) => {
    if (message && message.match(/forbidden|401|policy failed/gi)) {
      if (
        !window.location.href.match(/unauthorized/gi) &&
        window.location.pathname !== "/" &&
        window.location.pathname !== "/account/login" &&
        window.location.pathname !== "/account/register" &&
        window.location.pathname !== "/account/forgot-password" &&
        window.location.pathname !== "/account/reset-password"
      ) {
        window.location.href = `${window.location.protocol}//${window.location.host}/account/login`;
      }
    }
  };

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      checkMessageForUnauthorized(message);
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    checkMessageForUnauthorized(networkError.message);
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_ACS_BACKOFFICE_UI_APOLLO_URI,
  credentials: "include",
});

const client = new ApolloClient({
  // XX: https://github.com/jaydenseric/apollo-upload-client/issues/285
  // @ts-ignore
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({}),
});

export const Provider = ({ children }: { children: React.ReactNode }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
