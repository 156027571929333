import { useContext, createContext, ReactNode, useState } from "react";
import {
  CustomerProfileCreationRequestInput,
  useCreateCustomerProfileCreationRequestMutation,
} from "data";
import { useAuthentication } from "features/accounts/context/Authentication";
import { useNotifications } from "context/Notifications";

type Step = "General Info" | "Shipping Address" | "Billing Address";

interface CompleteCustomerProfileContextData {
  step: Step;
  data: CustomerProfileCreationRequestInput;
  updateData: (data: CustomerProfileCreationRequestInput) => void;
  next: () => void;
  prev: () => void;
  billingAddressSameAsShipping: boolean;
  toggleBillingAddressSameAsShipping: () => void;
  submit: (data: CustomerProfileCreationRequestInput) => Promise<void>;
  loading: boolean;
  error?: String;
  success: boolean;
}

const CompleteCustomerProfileContext =
  createContext<CompleteCustomerProfileContextData>(
    {} as CompleteCustomerProfileContextData
  );

interface CompleteCustomerProfileProviderProps {
  children: ReactNode;
}

export const CompleteCustomerProfileProvider = (
  props: CompleteCustomerProfileProviderProps
) => {
  const [createUserProfile] = useCreateCustomerProfileCreationRequestMutation();
  const { loginData } = useAuthentication();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [step, setStep] = useState<Step>("General Info");
  const [data, setData] = useState<CustomerProfileCreationRequestInput>({});
  const [billingAddressSameAsShipping, setBillingAddressSameAsShipping] =
    useState<boolean>(true);

  return (
    <CompleteCustomerProfileContext.Provider
      value={{
        success,
        loading,
        error,
        billingAddressSameAsShipping,
        toggleBillingAddressSameAsShipping: () => {
          if (billingAddressSameAsShipping) {
            // when we are about to switch off, clean up
            setData(
              Object.assign({}, data, {
                billingAddress: undefined,
              })
            );
          }

          setBillingAddressSameAsShipping(!billingAddressSameAsShipping);
        },
        data,
        updateData(d) {
          setData(Object.assign({}, data, d));
        },
        step,
        next() {
          if (step === "General Info") {
            setStep("Shipping Address");
          } else if (step === "Shipping Address") {
            setStep("Billing Address");
          }
        },
        prev() {
          if (step === "Shipping Address") {
            setStep("General Info");
          } else if (step === "Billing Address") {
            setStep("Shipping Address");
          }
        },
        async submit(data) {
          if (loginData?.user?.id) {
            setLoading(true);
            try {
              await createUserProfile({
                variables: {
                  input: Object.assign({}, data, {
                    user: loginData?.user?.id,
                  }),
                },
              });
              setSuccess(true);
            } catch {
              setError(
                "Looks like someone has already claimed this company account. Please contact support@advancedcryptoservices.com"
              );
            } finally {
              setLoading(false);
            }
          }
        },
      }}
    >
      {props.children}
    </CompleteCustomerProfileContext.Provider>
  );
};

export const useCompleteCustomerProfile = () =>
  useContext<CompleteCustomerProfileContextData>(
    CompleteCustomerProfileContext
  );
