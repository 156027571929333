import { OrderItemDataFragment } from "data";
import { ShowForHashboard } from "features/devices/components/ShowForHashboard";
import { ShowForPSU } from "features/devices/components/ShowForPSU";
import { HashboardRepairWizardProvider } from "features/repairs/context/HashboardRepairWizard";
import { PSURepair } from "features/repairs/components/psu/PSURepair";

interface RepairWizardProps {
  orderItem: OrderItemDataFragment;
  onClose: () => void;
}

export const RepairWizard = (props: RepairWizardProps) => {
  const { orderItem, onClose } = props;

  return (
    <>
      <ShowForHashboard device={orderItem.attributes?.device?.data}>
        <HashboardRepairWizardProvider
          onComplete={onClose}
          orderItem={orderItem}
        />
      </ShowForHashboard>
      <ShowForPSU device={orderItem.attributes?.device?.data}>
        <PSURepair onComplete={onClose} orderItem={orderItem} />
      </ShowForPSU>
    </>
  );
};
