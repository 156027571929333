import { Text, TextProps } from "@chakra-ui/react";
import moment from "moment";

interface DateTimeTextProps extends TextProps {
  value: string;
  // XX: https://momentjs.com/docs/#/displaying/format/
  format: string;
  prefix?: string;
}

export const formatDateTime = (dt: string, format: string) =>
  moment(dt).format(format);

export const DateTimeText = (props: DateTimeTextProps) => {
  const { value, format, prefix, ...rest } = props;
  return (
    <Text {...rest}>{`${prefix ? `${prefix} ` : ""}${moment(value).format(
      format
    )}`}</Text>
  );
};

export const DateTimeTextFromNow = (
  props: Omit<DateTimeTextProps, "format">
) => {
  const { value, ...rest } = props;
  return <Text {...rest}>{moment(value).fromNow()}</Text>;
};
