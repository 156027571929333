import { useSearchParams, useNavigate } from "react-router-dom";
import { useNotifications } from "context/Notifications";
import { ResetPasswordForm } from "features/accounts/components/ResetPasswordForm";
import { useAuthentication } from "features/accounts/context/Authentication";

interface ResetPasswordFormContainerProps {
  welcome: boolean;
}

export const ResetPasswordFormContainer = ({
  welcome,
}: ResetPasswordFormContainerProps) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get("code");
  const { showSuccessMessage } = useNotifications();
  const { resetPassword } = useAuthentication();
  return code ? (
    <ResetPasswordForm
      welcome={welcome}
      onResetPassword={async (data) => {
        await resetPassword({
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          code,
        });
        showSuccessMessage(
          welcome
            ? "Your account is ready! Please log in"
            : "Password reset successfully"
        );
        navigate("/account/login");
      }}
    />
  ) : null;
};
