import {
  useUpdateOrderMutation,
  OrderInput,
  OrderDataFragment,
  Enum_Order_Status,
} from "data";
import { useWrapWithNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface UpdateOrderProps {
  orderId: string;
  data: OrderInput;
}

interface UseUpdateOrderHookData {
  updateOrder: (props: UpdateOrderProps) => Promise<void | null>;
}

interface UseUpdateOrderStatusHookData {
  updateOrderStatus: ({
    order,
    status,
  }: {
    order: OrderDataFragment;
    status: Enum_Order_Status;
  }) => Promise<void>;
}

export const useUpdateOrder = (): UseUpdateOrderHookData => {
  const [doUpdateOrder] = useUpdateOrderMutation();
  const updateOrder = useWrapWithNotifications<UpdateOrderProps, void>({
    successMessage: "Order updated",
    runner: async (props) => {
      const { orderId, data } = props;
      await doUpdateOrder({
        variables: {
          id: orderId,
          data,
        },
      });
    },
  });
  return { updateOrder };
};

export const useUpdateOrderStatus = (): UseUpdateOrderStatusHookData => {
  const { createAction } = useCreateAction();
  const { updateOrder } = useUpdateOrder();
  return {
    async updateOrderStatus({ order, status }) {
      await updateOrder({
        orderId: order.id!,
        data: {
          status,
        },
      });
      await createAction({
        data: {
          ...expandActionDataPiece(order),
          metadata: {
            from: order.attributes?.status,
            to: status,
          },
        },
        scope: Enum_Action_Scope.Order,
        type: Enum_Action_Type.ChangeOrderStatus,
        context: {
          order,
        },
      });
    },
  };
};
