import { Stack, Button, Card, CardBody, Text } from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  DeviceDataFragment,
  ComponentHashboardHashboardMetadataInput,
  Enum_Componenthashboardhashboardmetadata_Type,
} from "data";
import {
  TextInput,
  TextArea,
  RadioCardGroupGrid,
} from "components/forms/fields";

const RegistrationSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf([
      Enum_Componenthashboardhashboardmetadata_Type.Metal,
      Enum_Componenthashboardhashboardmetadata_Type.Pcb,
    ])
    .required(),
  lNumber: Yup.string(),
  metadata: Yup.string(),
});

interface HashboardDetailsProps {
  device: DeviceDataFragment;
  onSubmit: ({
    hashboardMetadata,
  }: {
    hashboardMetadata: ComponentHashboardHashboardMetadataInput;
  }) => void;
}

export const HashboardDetails = (props: HashboardDetailsProps) => {
  const { onSubmit } = props;
  return (
    <Card>
      <CardBody>
        <Formik
          initialValues={{
            type: null,
            lNumber: "",
            metadata: "",
          }}
          validationSchema={RegistrationSchema}
          onSubmit={(values) => {
            if (!values.type) {
              return;
            }
            onSubmit({
              hashboardMetadata: {
                type: values.type as Enum_Componenthashboardhashboardmetadata_Type,
                lNumber: values.lNumber === "" ? null : values.lNumber,
                metadata: values.metadata === "" ? null : values.metadata,
              },
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={2}>
                <RadioCardGroupGrid
                  label="Board Type"
                  name="type"
                  supportedValues={[
                    Enum_Componenthashboardhashboardmetadata_Type.Metal,
                    Enum_Componenthashboardhashboardmetadata_Type.Pcb,
                  ]}
                  prettyValue={(
                    v: Enum_Componenthashboardhashboardmetadata_Type
                  ) => {
                    if (
                      v === Enum_Componenthashboardhashboardmetadata_Type.Metal
                    ) {
                      return "🤘 Metal";
                    } else if (
                      v === Enum_Componenthashboardhashboardmetadata_Type.Pcb
                    ) {
                      return "PCB";
                    }
                  }}
                />

                <TextInput
                  label="L#"
                  placeholder="L#"
                  name="lNumber"
                  type="text"
                />

                <TextArea
                  label="Additional metadata"
                  placeholder="Additional metadata"
                  name="metadata"
                />
              </Stack>
              <Stack mt={10}>
                <Button
                  isDisabled={isSubmitting}
                  variant="outline"
                  type="submit"
                >
                  Save Hashboard Details
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
