import {
  Box,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useBreakpointValue,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "components/Logo";
import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";
import { UserProfile } from "./UserProfile";
import { ShowForAllTechs } from "features/accounts";
import { ColorModeSwitcher } from "components/utils/ColorModeSwitcher";
import { SearchBox } from "features/search/components/SearchBox";
import { NavButton } from "./NavButton";

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Box
      as="nav"
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
    >
      <Container py={{ base: "3", lg: "4" }}>
        <Flex justify="space-between">
          <HStack spacing="4">
            <Logo />
            {isDesktop ? (
              <ButtonGroup variant="ghost" spacing="1">
                <NavButton label="Orders" path="/orders" />
                <ShowForAllTechs>
                  <>
                    <NavButton label="Customers" path="/customers" />
                    <NavButton label="Inspections" path="/inspections" />
                    <NavButton label="Feed" path="/feed" />
                  </>
                </ShowForAllTechs>
              </ButtonGroup>
            ) : null}
          </HStack>

          {isDesktop ? (
            <HStack spacing="4">
              <SearchBox />
              <ColorModeSwitcher />
              <UserProfile />
            </HStack>
          ) : (
            <HStack>
              <SearchBox />
              <ToggleButton
                isOpen={isOpen}
                aria-label="Open Menu"
                onClick={onToggle}
              />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap
                // Only disabled for showcase
                // trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <Sidebar
                    onAfterNav={() => {
                      onToggle();
                    }}
                  />
                </DrawerContent>
              </Drawer>
            </HStack>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
