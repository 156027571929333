import { LayoutShell } from "features/accounts/components/LayoutShell";
import { RegistrationForm } from "features/accounts/components/RegistrationForm";
import { RedirectIfLoggedIn } from "features/accounts/containers/RedirectIfLoggedIn";

export const RegistrationPage = () => {
  return (
    <RedirectIfLoggedIn>
      <LayoutShell title="Join ACS" subtitle="Create your ACS account">
        <RegistrationForm />
      </LayoutShell>
    </RedirectIfLoggedIn>
  );
};
