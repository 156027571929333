export const getIpsRange = ({
  startIp,
  start,
  stepRow,
  stepCol,
  devicesPerRow,
  count,
}: {
  start: number;
  startIp: string;
  stepRow: number;
  stepCol: number;
  devicesPerRow: number;
  count: number;
}) => {
  const ipParts = startIp.split(".");
  const base = parseInt(ipParts.pop() || "0");
  const colIndices: number[] = [];

  for (let i = 0; i < devicesPerRow; i++) {
    colIndices.push(start + i * stepCol);
  }

  const results = [];

  for (let row = 0; row < count / devicesPerRow; row++) {
    for (let col = 0; col < devicesPerRow; col++) {
      const lastPiece = base + stepRow * (row + 1) + colIndices[col];
      results.push([...ipParts, lastPiece].join("."));
    }
  }

  return results;
};
