import { HStack, Tag, Text } from "@chakra-ui/react";
import { OrderDataFragment } from "data";
import { DeviceTypeIcon } from "features/devices/components/DeviceTypeIcon";

interface OrderStatsByDeviceTypeProps {
  order: OrderDataFragment;
}

export const OrderStatsByDeviceType = (props: OrderStatsByDeviceTypeProps) => {
  const { order } = props;
  return order.attributes?.deviceTypeStats?.length !== 0 ? (
    <HStack mt={2}>
      {order.attributes?.deviceTypeStats?.map(({ type, count }) => (
        <Tag>
          <DeviceTypeIcon boxSize="4" deviceType={type} />
          <Text ml={2} fontSize="small">
            {count}
          </Text>
        </Tag>
      ))}
    </HStack>
  ) : null;
};
