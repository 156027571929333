import { ShowForAllTechs, ShowForCustomers } from "features/accounts";
import { OrderPage as OrderPageForTechs } from "./techs/Order";
import { OrderPage as OrderPageForCustomers } from "./customers/Order";

export const OrderPage = () => (
  <>
    <ShowForAllTechs>
      <OrderPageForTechs />
    </ShowForAllTechs>
    <ShowForCustomers>
      <OrderPageForCustomers />
    </ShowForCustomers>
  </>
);
