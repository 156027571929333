import sortBy from "lodash.sortby";
import {
  NonMinerOrderItemSummary,
  MinerOrderItemSummary,
} from "features/order-items/components/OrderItemSummary";
import {
  Enum_Device_Type,
  OrderDataFragment,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
} from "data";
import { useNotifications } from "context/Notifications";
import { useDeleteOrderItemSubcomponent } from "features/order-items/data/hooks/order-items";
import { OrderItemContextMenuProvider } from "features/order-items/context/OrderItemContextMenu";

interface OrderItemSummaryContainerProps {
  order: OrderDataFragment;
  orderItem: OrderItemDataFragment;
  subcomponentOrderItems: OrderItemDataFragment[];
}

export const OrderItemSummaryContainer = (
  props: OrderItemSummaryContainerProps
) => {
  const { showSuccessMessage } = useNotifications();
  const { deleteOrderItemSubcomponent } = useDeleteOrderItemSubcomponent();
  const { orderItem, subcomponentOrderItems } = props;
  const sortedOrderItemSubcomponents = sortBy(
    orderItem.attributes?.order_item_subcomponents?.data || [],
    [
      (ois) => {
        const orderItemExists = subcomponentOrderItems.find(
          (soi) => ois.attributes?.order_item?.data?.id === soi.id
        );
        return orderItemExists ? 0 : 1;
      },
    ]
  );
  console.log(orderItem.attributes?.device?.data?.attributes?.type);

  return orderItem.attributes?.device?.data?.attributes?.type ===
    Enum_Device_Type.Miner ? (
    <OrderItemContextMenuProvider>
      <MinerOrderItemSummary
        onDeleteSubcomponent={async (orderItemSubcomponent) => {
          await deleteOrderItemSubcomponent(orderItemSubcomponent);
          showSuccessMessage("Component deleted!");
        }}
        orderItem={orderItem}
        orderItemSubcomponents={sortedOrderItemSubcomponents}
        orderItemForSubcomponent={(
          orderItemSubcomponent: OrderItemSubcomponentDataFragment
        ) => {
          return (
            (orderItemSubcomponent.attributes?.order_item?.data?.id &&
              subcomponentOrderItems.find(
                (soi) =>
                  soi.id ===
                  orderItemSubcomponent.attributes?.order_item?.data?.id
              )) ||
            null
          );
        }}
        customerDeviceForSubcomponent={(
          orderItemSubcomponent: OrderItemSubcomponentDataFragment
        ) => {
          if (!orderItemSubcomponent.attributes?.order_item?.data?.id) {
            return null;
          }
          const oi = subcomponentOrderItems.find(
            (soi) =>
              soi.id === orderItemSubcomponent.attributes?.order_item?.data?.id
          );
          return oi?.attributes?.customer_device?.data || null;
        }}
      />
    </OrderItemContextMenuProvider>
  ) : (
    <NonMinerOrderItemSummary orderItem={orderItem} />
  );
};
