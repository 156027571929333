import { OrderItemDataFragment, OrderItemSubcomponentDataFragment } from "data";
import { ReceiveDeviceIcon } from "components/Icons";
import { ActionComponent, ActionComponentVariant } from "components/ActionComponent";
import { useReceivDeviceActionPanel } from "features/customer-devices/context/ReceiveDevicePanel";
import { useReceiveDeviceContext } from "features/customer-devices/context/ReceiveDevice";

interface ReceiveOrderItemActionProps {
  orderItem?: OrderItemDataFragment;
  orderItemSubcomponent?: OrderItemSubcomponentDataFragment;
  variant: ActionComponentVariant;
}

export const ReceiveOrderItemAction = (props: ReceiveOrderItemActionProps) => {
  const { orderItem, orderItemSubcomponent, variant } = props;
  const { showActionPanel } = useReceivDeviceActionPanel();
  const { setTarget } = useReceiveDeviceContext();

  if (orderItem && orderItem.attributes?.customer_device?.data) {
    return null;
  }

  if (orderItemSubcomponent && orderItemSubcomponent.attributes?.order_item?.data) {
    return null;
  }

  return (
    <ActionComponent
      action={async () => {
        if (orderItem) {
          setTarget(orderItem);
        } else if (orderItemSubcomponent) {
          setTarget(orderItemSubcomponent);
        }

        showActionPanel({
          target: { orderItem },
        });
      }}
      variant={variant}
      actionIcon={<ReceiveDeviceIcon />}
      actionLabel='Receive'
      {...(orderItem ? { "data-order-item-id": orderItem.id } : {})}
      {...(orderItemSubcomponent
        ? { "data-order-item-subcomponent-id": orderItemSubcomponent.id }
        : {})}
      data-cy='action-receive-device'
    />
  );
};

ReceiveOrderItemAction.defaultProps = {
  variant: "button",
};
