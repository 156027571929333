import {
  Card,
  CardBody,
  Text,
  Stack,
  Center,
  Link,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { DeviceWithMinerScanData } from "features/inspections/data/hooks";
import { InspectedDeviceStatus } from "features/inspections/components/InspectedDeviceStatus";
import { DeviceCompleteNameLabel } from "features/devices/components";
import { isInspectedDeviceValid } from "features/inspections/data/hooks";

interface InspectedDeviceProps {
  device: DeviceWithMinerScanData;
  onClick?: () => void;
}

const shortSerial = (serial?: string | null) => {
  if (!serial) {
    return serial;
  }

  if (serial.length <= 8) {
    return serial;
  }

  return `${serial.substring(0, 4)}...${serial.substring(serial.length - 4)}`;
};

export const InspectedDevice = (props: InspectedDeviceProps) => {
  const { device, onClick } = props;
  return (
    <Card
      data-inspected-device-ip={device.ip}
      variant="outline"
      maxW="sm"
      {...(!isInspectedDeviceValid(device.device)
        ? { borderColor: "red" }
        : {})}
    >
      <CardBody>
        <Center>
          <Stack mt="6" spacing="3">
            <Stack
              spacing="3"
              {...(onClick
                ? {
                    cursor: "pointer",
                    onClick: () => {
                      onClick();
                    },
                  }
                : {})}
            >
              <Center>
                <DeviceCompleteNameLabel
                  fontSize="x-small"
                  device={
                    device.device.attributes?.customer_device?.data?.attributes
                      ?.device?.data
                  }
                />
              </Center>
              <InspectedDeviceStatus device={device} />
              <Text textAlign="center" color="blue.600" fontSize="xl">
                {
                  device.device.attributes?.customer_device?.data?.attributes
                    ?.shortId
                }
              </Text>
              {device.device.attributes?.customer_device?.data?.attributes
                ?.serialNumber ? (
                <Text textAlign="center" fontSize="xs" color="muted">
                  {shortSerial(
                    device.device.attributes?.customer_device?.data?.attributes
                      ?.serialNumber
                  )}
                </Text>
              ) : null}
            </Stack>
            <Center>
              <Text fontWeight="bold" fontSize="small">
                {/* @ts-ignore */}
                <Link href={`http://root:root@${device.ip}`} isExternal>
                  {device.ip}
                </Link>
              </Text>
            </Center>
            {(device.minerData?.hashboards || []).length !== 0 ? (
              <TableContainer>
                <Table size="sm" variant="simple">
                  <Tbody>
                    {device.minerData?.hashboards.map((hashboard) => (
                      <Tr key={hashboard.slot}>
                        <Td>
                          <Text fontWeight="bold">Slot {hashboard.slot}</Text>
                        </Td>
                        <Td>
                          {hashboard.missing ? (
                            <Text color="red">Missing</Text>
                          ) : (
                            <Text>{hashboard.hashrate}</Text>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : null}
          </Stack>
        </Center>
      </CardBody>
    </Card>
  );
};
