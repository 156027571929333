import { RadioGroup, Radio, HStack } from "@chakra-ui/react";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { MinerDiagnosticsReport } from "features/diagnostics/data";

export const PowerDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;
  return (
    <RadioGroup
      onChange={(value) => {
        updateData(
          Object.assign({}, minerDiagnosticsReport, {
            power: value === "1" ? true : false,
          })
        );
      }}
      value={minerDiagnosticsReport.power ? "1" : "0"}
    >
      <HStack spacing="8">
        <Radio value="1">Miner powers ON</Radio>
        <Radio value="0">Miner does NOT power</Radio>
      </HStack>
    </RadioGroup>
  );
};
