import { useState } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

export interface ActionButtonProps extends Omit<ButtonProps, "onClick"> {
  action: () => Promise<void>;
  confirmationMessage?: string;
}

export const ActionButton = (props: ActionButtonProps) => {
  const { children, action, confirmationMessage, ...rest } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Button
      onClick={async () => {
        if (confirmationMessage) {
          // eslint-disable-next-line
          if (!confirm(confirmationMessage)) {
            return;
          }
        }

        setIsLoading(true);

        const actionWrapper = async () => {
          try {
            await action();
          } finally {
            setIsLoading(false);
          }
        };

        actionWrapper();
      }}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  );
};
