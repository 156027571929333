import FileSaver from "file-saver";
import { useCreateInspectionReportMutation } from "data";

interface UseInspectionReportHookData {
  generateReport: (inspectionId: string) => Promise<void>;
}

export const useInspectionReport = (): UseInspectionReportHookData => {
  const [createInspectionReport] = useCreateInspectionReportMutation();
  return {
    async generateReport(inspectionId) {
      const { data } = await createInspectionReport({
        variables: {
          data: {
            inspection: inspectionId,
          },
        },
      });
      const file = new File(
        [data?.createInspectionReport?.data?.attributes?.csv || ""],
        `${new Date().getTime()}.csv`,
        {
          type: "text/csv;charset=utf-8",
        }
      );
      FileSaver.saveAs(file);
    },
  };
};
