import { Text } from "@chakra-ui/react";
import {
  CustomersProvider,
  useCustomers,
} from "features/customers/context/Customers";
import { NotFound } from "components/NotFound";
import { Page } from "components/layout/Page";
import { ShowForAllTechs, ShowForCustomers } from "features/accounts";
import { LinkToCustomer } from "features/customers/components/LinkToCustomer";
import { DataListWithFiltersAndPagination } from "components/DataList/index";

export const ConnectedCustomersPage = () => {
  const { loading, data, setParams, params } = useCustomers();
  return (
    <Page
      loading={loading}
      title="Customers"
      subTitle="See and manage all the customers in the system"
    >
      <DataListWithFiltersAndPagination
        loading={loading}
        data={data?.customers?.data || []}
        pagination={data?.customers?.meta.pagination}
        onPageChange={(page) => {
          setParams(Object.assign({}, params, { page }));
        }}
        renderItem={({ item: customer }) => (
          <LinkToCustomer customer={customer}>
            <Text fontWeight="bold">{customer.attributes?.accountName}</Text>
          </LinkToCustomer>
        )}
      />
    </Page>
  );
};

export const CustomersPage = () => {
  return (
    <>
      <ShowForAllTechs>
        <CustomersProvider>
          <ConnectedCustomersPage />
        </CustomersProvider>
      </ShowForAllTechs>
      <ShowForCustomers>
        <NotFound />
      </ShowForCustomers>
    </>
  );
};
