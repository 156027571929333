import { ReactElement, useState } from "react";
import { Box, Container, FormControl } from "@chakra-ui/react";
import { UploadFileDataFragment } from "data";
import { Loader } from "components/Loader";
import {
  Dropzone,
  DropzoneVariant,
} from "features/uploads/components/Dropzone";
import { useUpload } from "features/uploads/data/hooks/upload";
import { useNotifications } from "context/Notifications";

interface UploadProps {
  promptText: string;
  onStartUpload: () => void;
  onUpload: (files: UploadFileDataFragment[]) => void;
  dropzoneVariant?: DropzoneVariant;
}

const ConditionalContainer = ({
  container,
  children,
}: {
  container: boolean;
  children: ReactElement;
}) => {
  return container ? <Container maxW="lg">{children}</Container> : children;
};

export const Upload = (props: UploadProps) => {
  const { dropzoneVariant, promptText, onStartUpload, onUpload } = props;
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { uploadFiles } = useUpload();
  const [uploading, setUploading] = useState<boolean>(false);
  return (
    <Box
      as="section"
      bg="bg-surface"
      py={dropzoneVariant === "normal" ? { base: "4", md: "8" } : 0}
    >
      <ConditionalContainer container={dropzoneVariant === "normal"}>
        {uploading ? (
          <Loader />
        ) : (
          <FormControl id="file-uploader">
            <Dropzone
              variant={dropzoneVariant || "normal"}
              onDrop={async (files) => {
                onStartUpload();

                setUploading(true);
                try {
                  const uploadedFiles = await uploadFiles({ files });
                  onUpload(uploadedFiles);
                  showSuccessMessage(
                    files.length === 1
                      ? `File uploaded successfully`
                      : `${files.length} files uploaded successfully`
                  );
                } catch (e) {
                  showErrorMessage("File upload failed");
                  onUpload([]);
                } finally {
                  setUploading(false);
                }
              }}
              promptText={promptText}
            />
          </FormControl>
        )}
      </ConditionalContainer>
    </Box>
  );
};
