import { HStack, Text, TextProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { OrderItemDataFragment, OrderItemReplacementDataFragment } from "data";
import { ReplaceIcon } from "components/Icons";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";

interface OrderItemReplacementLinkProps extends TextProps {
  orderItem: OrderItemDataFragment;
  orderItemReplacement: OrderItemReplacementDataFragment;
}

export const OrderItemReplacementLink = (
  props: OrderItemReplacementLinkProps
) => {
  const { orderItem, orderItemReplacement, ...rest } = props;

  // is current order item a replacement for a different device
  const replacementFor =
    orderItemReplacement.attributes?.replacement_order_item?.data?.id ===
    orderItem.id
      ? orderItemReplacement.attributes?.original_order_item?.data
      : null;
  // was current order item replaced by a device
  const replacedBy =
    orderItemReplacement.attributes?.original_order_item?.data?.id ===
    orderItem.id
      ? orderItemReplacement.attributes?.replacement_order_item?.data
      : null;

  if (!replacementFor && !replacedBy) {
    return null;
  }

  const d = replacementFor || replacedBy;

  return (
    <Link to={`/orders/items/${d?.id}`}>
      <HStack>
        <ReplaceIcon />
        <Text {...rest}>{replacedBy ? "replaced by" : "replacement for"}</Text>
        <HStack>
          <DeviceCompleteNameLabel
            showMakeIcon
            fontWeight="bold"
            {...rest}
            device={d?.attributes?.device?.data}
          />
          <CustomerDeviceShortIdLabel
            customerDevice={d?.attributes?.customer_device?.data}
          />
        </HStack>
      </HStack>
    </Link>
  );
};
