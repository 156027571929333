import { useState } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { PasswordField } from "components/forms/PasswordField";
import { useNotifications } from "context/Notifications";

interface ResetPasswordFormProps {
  welcome: boolean;
  onResetPassword: (data: ResetPasswordFormData) => Promise<void>;
}

interface ResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

interface ResetPasswordFormValidation {
  isInvalid: boolean;
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { showErrorMessage } = useNotifications();
  const { onResetPassword, welcome } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ResetPasswordFormData>({
    password: "",
    passwordConfirmation: "",
  });
  const [validationData, setValidationData] =
    useState<ResetPasswordFormValidation>({
      isInvalid: false,
    });

  const onSubmit = async () => {
    const isInvalid =
      formData.password === "" ||
      formData.password !== formData.passwordConfirmation;
    setValidationData({
      isInvalid,
    });

    if (isInvalid) {
      return;
    }

    setIsLoading(true);

    try {
      await onResetPassword(formData);
    } catch {
      showErrorMessage("Cannot reset password. Please request new reset.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <Stack spacing="5">
        <PasswordField
          placeholder={welcome ? "Password" : "New password"}
          isInvalid={validationData.isInvalid}
          onChange={(e) => {
            setFormData((fd) =>
              Object.assign({}, fd, { password: e.target.value })
            );
          }}
          value={formData.password}
          errorBorderColor="crimson"
        />
        <PasswordField
          id="confirm-password"
          name="passwordConfirmation"
          placeholder={welcome ? "Confirm password" : "Confirm new password"}
          isInvalid={validationData.isInvalid}
          onChange={(e) => {
            setFormData((fd) =>
              Object.assign({}, fd, { passwordConfirmation: e.target.value })
            );
          }}
          value={formData.passwordConfirmation}
          errorBorderColor="crimson"
        />
      </Stack>
      <Stack py="6" spacing="6">
        <Button isLoading={isLoading} type="submit" variant="primary">
          {welcome ? "Create password" : "Request password reset"}
        </Button>
      </Stack>
    </form>
  );
};
