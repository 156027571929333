import { Icon, IconProps } from "@chakra-ui/react";
import { Enum_Device_Type } from "data";
import {
  PSUIcon,
  MinerIcon,
  ControlBoardIcon,
  HashboardIcon,
} from "components/Icons";

const iconForDeviceType = (dt?: Enum_Device_Type) => {
  switch (dt) {
    case Enum_Device_Type.Psu:
      return PSUIcon;
    case Enum_Device_Type.Miner:
      return MinerIcon;
    case Enum_Device_Type.ControlBoard:
      return ControlBoardIcon;
    case Enum_Device_Type.HashBoard:
      return HashboardIcon;
  }
};

interface DeviceTypeIconProps extends Omit<IconProps, "as"> {
  deviceType?: Enum_Device_Type;
}

export const DeviceTypeIcon = (props: DeviceTypeIconProps) => {
  const { deviceType, ...rest } = props;
  return <Icon as={iconForDeviceType(deviceType)} w={6} h={6} {...rest} />;
};
