import { Image, ImageProps } from "@chakra-ui/react";
import { DeviceDataFragment } from "data";

export const imageURLForDeviceMake = (make: string | null): string | null => {
  return make && `/images/${make.toLowerCase().replace(" ", "")}.webp`;
};

interface DeviceMakeIconProps extends Omit<ImageProps, "src"> {
  deviceOrMake?: DeviceDataFragment | string | null;
}

export const DeviceMakeIcon = (props: DeviceMakeIconProps) => {
  const { deviceOrMake, ...rest } = props;

  if (!deviceOrMake) {
    return null;
  }

  const make =
    typeof deviceOrMake === "string"
      ? deviceOrMake
      : (deviceOrMake as DeviceDataFragment).attributes?.make;
  return (
    <Image
      src={imageURLForDeviceMake(make || null) || undefined}
      w={6}
      h={6}
      {...rest}
    />
  );
};
