import {
  Divider,
  Flex,
  HStack,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "components/Logo";
import { ColorModeSwitcher } from "components/utils/ColorModeSwitcher";
import { ShowForAllTechs } from "features/accounts";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { FooterLinks } from "components/layout/FooterLinks";

interface SidebarProps {
  onAfterNav: () => void;
}

export const Sidebar = ({ onAfterNav }: SidebarProps) => (
  <Flex as="section" minH="100vh" bg="bg-canvas">
    <Flex
      flex="1"
      bg="bg-surface"
      overflowY="auto"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      maxW={{ base: "full", sm: "xs" }}
      py={{ base: "6", sm: "8" }}
      px={{ base: "4", sm: "6" }}
    >
      <Stack justify="space-between" spacing="1">
        <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
          <Logo />
          <Stack spacing="1">
            <NavButton
              onClick={() => {
                onAfterNav();
              }}
              label="Orders"
              path="/orders"
            />

            <ShowForAllTechs>
              <>
                <NavButton
                  onClick={() => {
                    onAfterNav();
                  }}
                  label="Customers"
                  path="/customers"
                />
                <NavButton
                  onClick={() => {
                    onAfterNav();
                  }}
                  label="Inspections"
                  path="/inspections"
                />
                <NavButton
                  onClick={() => {
                    onAfterNav();
                  }}
                  label="Feed"
                  path="/feed"
                />
              </>
            </ShowForAllTechs>
          </Stack>
        </Stack>
        <Stack spacing={{ base: "5", sm: "6" }}>
          <FooterLinks />
          <Divider />
          <HStack>
            <UserProfile />
            <ColorModeSwitcher />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  </Flex>
);
