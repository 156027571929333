import { createContext, ReactNode, useState, useContext } from "react";
import { OrderDataFragment } from "data";
import { useOrdersData } from "features/orders/data/hooks/orders";
import type { ReceiveOrderPayload } from "features/orders/containers/ReceiveOrder";

interface OrdersContextData {
  readonly receiveOrderPayload: ReceiveOrderPayload;
  setOrderPayload: (orderPayload: ReceiveOrderPayload) => void;
  receiveOrder: () => Promise<OrderDataFragment | null | undefined>;
}

interface OrdersProviderProps {
  children: ReactNode;
}

const OrdersContext = createContext<OrdersContextData>({} as OrdersContextData);

export const useOrders = () => {
  return useContext(OrdersContext);
};

export const OrdersProvider = (props: OrdersProviderProps) => {
  const { createOrderFromZohoSalesOrder } = useOrdersData();
  const [receiveOrderPayload, setOrderPayload] = useState<ReceiveOrderPayload>({
    zohoSalesOrder: null,
    location: null,
  });
  return (
    <OrdersContext.Provider
      value={{
        receiveOrderPayload,
        setOrderPayload,
        receiveOrder: async () => {
          if (
            receiveOrderPayload.location &&
            receiveOrderPayload.zohoSalesOrder
          ) {
            return createOrderFromZohoSalesOrder(
              receiveOrderPayload.zohoSalesOrder,
              receiveOrderPayload.location
            );
          }
        },
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};
