import * as Yup from "yup";
import { Formik } from "formik";
import {
  TextInput,
  TextArea,
  YesNoButtons,
  OkNotOkButtons,
} from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";
import { normalizeASICData } from "./lib";

const ASICCircuitSchema = Yup.object().shape({
  asicAllDetected: Yup.boolean().required(),
  asicNumberASICsDetected: Yup.number().when("asicAllDetected", {
    is: false,
    then: () => Yup.number().required(),
  }),
  asicLDOsOk: Yup.boolean().when(
    ["asicAllDetected", "asicNumberASICsDetected"],
    {
      is: (asicAllDetected: boolean, asicNumberASICsDetected: string) => {
        return (
          !asicAllDetected && typeof asicNumberASICsDetected !== "undefined"
        );
      },
      then: () => Yup.boolean().required(),
    }
  ),
  asicIsRXChainComplete: Yup.boolean().when("asicLDOsOk", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  asicIsTXChainComplete: Yup.boolean().when(
    ["asicIsRXChainComplete", "asicNumberASICsDetected"],
    {
      is: (asicIsRXChainComplete: boolean, asicNumberASICsDetected: string) => {
        if (typeof asicNumberASICsDetected !== "undefined") {
          if (parseInt(asicNumberASICsDetected) === 0) {
            if (asicIsRXChainComplete) {
              return false;
            }
          }
        }

        return asicIsRXChainComplete;
      },
      then: () => Yup.boolean().required(),
    }
  ),
  asicIsRSTChainComplete: Yup.boolean().when("asicIsTXChainComplete", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  asicIsBI0V: Yup.boolean().when("asicIsRSTChainComplete", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  asicIsCLKChainComplete: Yup.boolean().when("asicIsBI0V", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  asicGotCorrosion: Yup.boolean().when("asicIsCLKChainComplete", {
    is: true,
    then: () => Yup.boolean().required(),
  }),
  asicGotOtherProblem: Yup.boolean().when(
    ["asicIsCLKChainComplete", "asicAllDetected"],
    {
      is: (asicIsCLKChainComplete: boolean, asicAllDetected: boolean) =>
        asicIsCLKChainComplete || asicAllDetected,
      then: () => Yup.boolean().required(),
    }
  ),
  asicNotes: Yup.string(),
});

export const ASICCircuitForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();

  return (
    <Formik
      initialValues={{
        asicAllDetected: report.asicAllDetected,
        asicNumberASICsDetected: report.asicNumberASICsDetected,
        asicLDOsOk: report.asicLDOsOk,
        asicIsRXChainComplete: report.asicIsRXChainComplete,
        asicIsTXChainComplete: report.asicIsTXChainComplete,
        asicIsRSTChainComplete: report.asicIsRSTChainComplete,
        asicIsBI0V: report.asicIsBI0V,
        asicIsCLKChainComplete: report.asicIsCLKChainComplete,
        asicGotCorrosion: report.asicGotCorrosion,
        asicGotOtherProblem: report.asicGotOtherProblem,
        asicNotes: report.asicNotes,
      }}
      validationSchema={ASICCircuitSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => {
        const {
          asicNumberASICsDetected,
          asicLDOsOk,
          asicGotOtherProblem,
          asicIsTXChainComplete,
        } = ASICCircuitSchema.describe({
          value: values,
        }).fields;
        return (
          <FormWrapper
            isValid={isValid}
            canGoToNextStep={isValid}
            canGoToPrevStep
            title="ASIC Circuit"
            values={normalizeASICData(values)}
          >
            <>
              <YesNoButtons
                label="All ASICs detected?"
                name="asicAllDetected"
              />
              {
                // @ts-ignore
                !asicNumberASICsDetected.optional ? (
                  <TextInput
                    type="number"
                    name="asicNumberASICsDetected"
                    label="How many ASICs detected?"
                  />
                ) : null
              }
              {
                // @ts-ignore
                !asicLDOsOk.optional ? (
                  <OkNotOkButtons label="LDOs" name="asicLDOsOk" />
                ) : null
              }
              {values.asicLDOsOk ? (
                <YesNoButtons
                  label="RX chain complete?"
                  name="asicIsRXChainComplete"
                />
              ) : null}
              {
                // @ts-ignore
                !asicIsTXChainComplete.optional ? (
                  <YesNoButtons
                    label="TX chain complete?"
                    name="asicIsTXChainComplete"
                  />
                ) : null
              }
              {values.asicIsTXChainComplete ? (
                <YesNoButtons
                  label="RST chain complete?"
                  name="asicIsRSTChainComplete"
                />
              ) : null}
              {values.asicIsRSTChainComplete ? (
                <YesNoButtons label="BI = 0V?" name="asicIsBI0V" />
              ) : null}
              {values.asicIsBI0V ? (
                <YesNoButtons
                  label="CLK chain complete?"
                  name="asicIsCLKChainComplete"
                />
              ) : null}
              {values.asicIsCLKChainComplete ? (
                <YesNoButtons label="Corrosion" name="asicGotCorrosion" />
              ) : null}

              {
                // @ts-ignore
                !asicGotOtherProblem.optional ? (
                  <YesNoButtons
                    label="Other problem"
                    name="asicGotOtherProblem"
                  />
                ) : null
              }
              <TextArea label="Notes (Optional)" name="powerNotes" />
            </>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
