import {
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  Hide,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Logo } from "components/Logo";
import { AppVersion } from "components/utils/AppVersion";
import { LoginForm } from "features/accounts/components/LoginForm";
import { RedirectIfLoggedIn } from "features/accounts/containers/RedirectIfLoggedIn";

export const HomePage = () => (
  <RedirectIfLoggedIn>
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={useBreakpointValue({
        md: mode(
          "linear(to-r, gray.600 50%, white 50%)",
          "linear(to-r, gray.600 50%, gray.900 50%)"
        ),
      })}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex
              direction="column"
              px={{ base: "4", md: "8" }}
              height="full"
              color="on-accent"
            >
              <Flex align="center" h="24">
                <Logo />
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack color="white" spacing="6">
                    <Heading size={useBreakpointValue({ md: "lg", xl: "xl" })}>
                      ASIC Repair
                      <br />
                      Antminer & Whatsminer
                    </Heading>
                    <Text fontSize="lg" maxW="md" fontWeight="medium">
                      ASIC repair services provided by a team of technicians
                      with IPC® 7711C/7721C Rework, Modification and Repair of
                      Electronics certification. Official MicroBT® Authorized
                      Warranty Service Provider
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="white" fontSize="sm">
                  © 2023 Advanced Crypto Services LLC. All rights reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <Box
            px={{ base: "4", md: "8" }}
            py={{ base: "12", md: "48" }}
            width="full"
            maxW="md"
          >
            <Stack>
              <Hide above="sm">
                <Box h={12}>
                  <Center>
                    <Logo />
                  </Center>
                </Box>
              </Hide>
              <LoginForm />
              <AppVersion />
            </Stack>
          </Box>
        </Center>
      </Flex>
    </Flex>
  </RedirectIfLoggedIn>
);
