import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Make } from "data";

interface ShowForDevicesWeCanRepairProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
}

export const canRepair = ({
  device,
}: {
  device?: DeviceDataFragment | null;
}): boolean => {
  if (!device) {
    return false;
  }

  if (device?.attributes?.type === Enum_Device_Type.HashBoard) {
    return true;
  }

  if (
    device?.attributes?.type === Enum_Device_Type.Psu &&
    [Enum_Device_Make.Antminer, Enum_Device_Make.Whatsminer].indexOf(
      device.attributes.make
    ) !== -1
  ) {
    return true;
  }

  return false;
};

// Devices we suport when it comes to repairs
// - hashboards
// - Bitmain PSUs
// - Whatsminer PSUs
export const ShowForDevicesWeCanRepair = (
  props: ShowForDevicesWeCanRepairProps
) => {
  const { device, children } = props;

  return canRepair({ device }) ? children : null;
};
