import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Box,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { OrderItemDataFragment } from "data";
import { useNotifications } from "context/Notifications";
import { useUpdateOrderItem } from "features/order-items/data/hooks/order-items";
import { validateIPAddress } from "lib";

export const MinerIpAddressForm = ({
  orderItem,
}: {
  orderItem: OrderItemDataFragment;
}) => {
  // regex to validate IP address

  const [ip, setIp] = useState<string>("");
  const [isValidIp, setIsValidIp] = useState<boolean>(
    validateIPAddress(orderItem.attributes?.ip_address || "")
  );
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { updateOrderItem } = useUpdateOrderItem();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidIp) {
      return;
    }

    try {
      updateOrderItem(orderItem.id!, { ip_address: ip });
      showSuccessMessage("IP address saved");
    } catch (error) {
      showErrorMessage("Failed to save IP address");
    }
  };

  return (
    <FormControl w="sm" pb={{ base: "4", md: "8" }}>
      <form onSubmit={handleSubmit}>
        <Flex>
          <Input
            id="ip_address"
            size="sm"
            placeholder=" "
            data-peer
            value={ip}
            onChange={(e) => {
              setIp(e.target.value);
              setIsValidIp(validateIPAddress(e.target.value));
            }}
          />
          <FormLabel htmlFor="ip_address" variant="floating" size="sm">
            IP Address
          </FormLabel>
          <Button
            isDisabled={!isValidIp}
            ml={2}
            size="sm"
            variant="outline"
            type="submit"
          >
            Save IP
          </Button>
        </Flex>
      </form>
      <Box>
        {orderItem.attributes?.ip_address ? (
          <Link href={`http://${orderItem.attributes.ip_address}`} isExternal>
            {orderItem.attributes.ip_address} <ExternalLinkIcon mx="2px" />
          </Link>
        ) : null}
      </Box>
    </FormControl>
  );
};
