import {
  OrderDataFragment,
  DeviceDataFragment,
  OrderLayoutItemsDocument,
  useCreateOrderLayoutItemMutation,
} from "data";
import type {
  OrderLayoutItemsQuery,
  OrderLayoutItemsQueryVariables,
} from "data";
import { useWrapWithNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Type,
  Enum_Action_Scope,
} from "features/actions";
import { useOrderItemsForOrder } from "features/order-items/context/OrderItemsForOrder";

interface UseLayoutItemsHookData {
  createOrderLayoutItem: (
    order: OrderDataFragment,
    device: DeviceDataFragment,
    quantity: number
  ) => Promise<void>;
}

interface CreateOrderLayoutItemProps {
  order: OrderDataFragment;
  device: DeviceDataFragment;
  quantity: number;
}

export const useLayoutItems = (): UseLayoutItemsHookData => {
  const { createAction } = useCreateAction();
  const orderItemsContext = useOrderItemsForOrder();
  const [doCreateOrderLayoutItem] = useCreateOrderLayoutItemMutation();

  const createOrderLayoutItem = useWrapWithNotifications<
    CreateOrderLayoutItemProps,
    void
  >({
    successMessage: "Devices added!",
    runner: async ({ order, device, quantity }) => {
      if (!order.id) {
        return;
      }

      const { data } = await doCreateOrderLayoutItem({
        variables: {
          data: {
            device: device.id,
            quantity,
            order: order.id,
          },
        },
        update(cache, { data }) {
          if (!data?.createOrderLayoutItem?.data) {
            return;
          }

          const querySpec = {
            query: OrderLayoutItemsDocument,
            variables: {
              orderId: order.id as string,
            },
          };

          const orderLayoutItems =
            cache.readQuery<
              OrderLayoutItemsQuery,
              OrderLayoutItemsQueryVariables
            >(querySpec);

          if (!orderLayoutItems) {
            return;
          }

          cache.writeQuery<
            OrderLayoutItemsQuery,
            OrderLayoutItemsQueryVariables
          >(
            Object.assign({}, querySpec, {
              data: {
                orderLayoutItems: Object.assign(
                  {},
                  orderLayoutItems?.orderLayoutItems,
                  {
                    data: [
                      ...(orderLayoutItems?.orderLayoutItems?.data || []),
                      data?.createOrderLayoutItem?.data,
                    ],
                  }
                ),
              },
            })
          );
        },
      });

      await createAction({
        type: Enum_Action_Type.AddOrderLayoutItem,
        scope: Enum_Action_Scope.Order,
        data: {
          ...expandActionDataPiece(order),
          order_layout_item: data?.createOrderLayoutItem?.data?.id,
          device: device.id,
        },
        context: {
          order,
          device,
        },
      });

      await orderItemsContext?.refetch();
    },
  });

  return {
    async createOrderLayoutItem(order, device, quantity) {
      await createOrderLayoutItem({ order, device, quantity });
    },
  };
};
