import { Text, TextProps, HStack } from "@chakra-ui/react";
import { Enum_Device_Type } from "data";
import { deviceTypeToString } from "features/devices/data";
import { DeviceTypeIcon } from "./DeviceTypeIcon";

interface DeviceTypeLabelProps extends TextProps {
  deviceType?: Enum_Device_Type;
  showTypeIcon?: boolean;
}

export const DeviceTypeLabel = (props: DeviceTypeLabelProps) => {
  const { deviceType, showTypeIcon, ...rest } = props;
  return (
    <HStack>
      {showTypeIcon ? (
        <DeviceTypeIcon deviceType={deviceType} w={4} h={4} />
      ) : null}
      <Text {...rest}>{deviceTypeToString(deviceType)}</Text>
    </HStack>
  );
};
