import { Badge, BadgeProps } from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";
import { isOrderItemComplete } from "features/order-items/data";

interface OrderItemCompleteBadgeProps extends BadgeProps {
  orderItem: OrderItemDataFragment;
}

export const OrderItemCompleteBage = (props: OrderItemCompleteBadgeProps) => {
  return !isOrderItemComplete(props.orderItem) ? (
    <Badge size="xs" colorScheme="red" variant="outline">
      Incomplete
    </Badge>
  ) : null;
};
