import { Box, Container, useBreakpointValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { DevicesProvider } from "features/devices/context/Devices";
import { Navbar } from "components/nav/Navbar";
import { AuthRequired } from "features/accounts";
import { FooterLinks } from "./FooterLinks";

export const Layout = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <AuthRequired>
      <DevicesProvider>
        <Box
          {...(isDesktop ? {} : { backgroundColor: "bg-surface" })}
          as="section"
          height="100vh"
          overflowY="auto"
        >
          <Navbar />
          <Container pt={{ base: "8", lg: "12" }} pb={{ base: "12", lg: "24" }}>
            <Outlet />
            {isDesktop ? <FooterLinks /> : null}
          </Container>
        </Box>
      </DevicesProvider>
    </AuthRequired>
  );
};
