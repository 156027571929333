import React from "react";
import {
  Drawer,
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
} from "@chakra-ui/react";
import { ActionButton } from "components/utils/ActionButton";

type Props = {
  children: React.ReactNode;
  title: string;
  enablePrimaryAction: boolean;
  onAction: () => Promise<void>;
  primaryButtonText: string;
  isOpen: boolean;
  onClose: () => Promise<void>;
};

export const ActionPanel = (props: Props) => {
  const {
    children,
    title,
    enablePrimaryAction,
    onAction,
    isOpen,
    onClose,
    primaryButtonText,
  } = props;
  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <ActionButton
            data-cy="action-panel-primary-button"
            action={() => onAction()}
            isDisabled={!enablePrimaryAction}
          >
            {primaryButtonText}
          </ActionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
