import {
  OrderItemDataFragment,
  Enum_Orderitem_Status,
  Enum_Device_Type,
  Enum_Orderitemreplacement_Status,
} from "data";

export const isOrderItemComplete = (orderItem: OrderItemDataFragment): boolean => {
  if (orderItem.attributes?.status === Enum_Orderitem_Status.Receiving) {
    return true;
  }

  if (orderItem.attributes?.device?.data?.attributes?.type !== Enum_Device_Type.Miner) {
    return true;
  }

  const subcomponents = orderItem.attributes.order_item_subcomponents?.data || [];

  return (
    subcomponents.filter(
      (s) => s.attributes?.device?.data?.attributes?.type === Enum_Device_Type.HashBoard
    ).length === 3 &&
    subcomponents.filter(
      (s) => s.attributes?.device?.data?.attributes?.type === Enum_Device_Type.ControlBoard
    ).length === 1 &&
    subcomponents.filter(
      (s) => s.attributes?.device?.data?.attributes?.type === Enum_Device_Type.Psu
    ).length === 1
  );
};

export const _orderItemStatusToString = (
  orderItemStatus?: Enum_Orderitem_Status | null,
  deviceType?: Enum_Device_Type
): string => {
  switch (orderItemStatus) {
    case Enum_Orderitem_Status.Receiving:
      return "Receiving";
    case Enum_Orderitem_Status.DiagnosedNeedsRepair:
      return deviceType === Enum_Device_Type.Miner
        ? "Inspection Complete > Needs Repair"
        : "Diagnosis Complete > Needs Repair";
    case Enum_Orderitem_Status.DiagnosedIsOk:
      return deviceType === Enum_Device_Type.Miner
        ? "Inspection Complete > Needs QA"
        : "Diagnosis Complete > Needs QA";
    case Enum_Orderitem_Status.DiagnosedUnrepairable:
      return "Unrepairable";
    case Enum_Orderitem_Status.DiagnosisPending:
      return deviceType === Enum_Device_Type.Miner ? "Pending Inspection" : "Pending Diagnosis";
    case Enum_Orderitem_Status.ExcessiveDamageScrapForParts:
      return "Scrap For Parts";
    case Enum_Orderitem_Status.Packaged:
      return "Packaged -> Can Ship";
    case Enum_Orderitem_Status.Purchased:
      return "Purchased by ACS";
    case Enum_Orderitem_Status.QaFail:
      return "QA Failed > Needs Advanced Repair";
    case Enum_Orderitem_Status.QaFailRecycled:
      return "QA Failed > Recycle";
    case Enum_Orderitem_Status.QaFailReturnToCustomer:
      return "QA Failed > Return to Customer";
    case Enum_Orderitem_Status.QaPass:
      return "QA Passed";
    case Enum_Orderitem_Status.RepairedFailed:
      return "Repair Fail > Needs Advanced Repair";
    case Enum_Orderitem_Status.RepairedNeedsQa:
      return "Repair Success > Needs QA";
    case Enum_Orderitem_Status.Shipped:
      return "Shipped";
    case Enum_Orderitem_Status.ReplacedRecycled:
      return "Replaced > Refurbish";
    case Enum_Orderitem_Status.ReplacedReturnToCustomer:
      return "Replaced > Return to Customer";
    default:
      return "N/A";
  }
};

export const _orderItemStatusToShortString = (
  orderItemStatus?: Enum_Orderitem_Status | null,
  deviceType?: Enum_Device_Type
): string => {
  switch (orderItemStatus) {
    case Enum_Orderitem_Status.Receiving:
      return "Receiving";
    case Enum_Orderitem_Status.DiagnosedNeedsRepair:
      return "Needs Repair";
    case Enum_Orderitem_Status.DiagnosedIsOk:
      return "Needs QA";
    case Enum_Orderitem_Status.DiagnosedUnrepairable:
      return "Unrepairable";
    case Enum_Orderitem_Status.DiagnosisPending:
      return deviceType === Enum_Device_Type.Miner ? "Pending Inspection" : "Pending Diagnosis";
    case Enum_Orderitem_Status.ExcessiveDamageScrapForParts:
      return "Scrap For Parts";
    case Enum_Orderitem_Status.Packaged:
      return "Packaged -> Can Ship";
    case Enum_Orderitem_Status.QaFail:
      return "QA Failed > Needs Advanced Repair";
    case Enum_Orderitem_Status.QaFailRecycled:
      return "QA Failed > Recycle";
    case Enum_Orderitem_Status.QaFailReturnToCustomer:
      return "QA Failed > Return to Customer";
    case Enum_Orderitem_Status.QaPass:
      return "QA Passed";
    case Enum_Orderitem_Status.RepairedFailed:
      return "Needs Advanced Repair";
    case Enum_Orderitem_Status.RepairedNeedsQa:
      return "Needs QA";
    case Enum_Orderitem_Status.Shipped:
      return "Shipped";
    case Enum_Orderitem_Status.ReplacedRecycled:
      return "Refurbish";
    case Enum_Orderitem_Status.ReplacedReturnToCustomer:
      return "Return to Customer";
    default:
      return "N/A";
  }
};

export const replacementStatusToString = (status: Enum_Orderitemreplacement_Status) => {
  switch (status) {
    case Enum_Orderitemreplacement_Status.Refurbish:
      return "Refurbish";
    case Enum_Orderitemreplacement_Status.ReturnToCustomer:
      return "Return to Customer";
    case Enum_Orderitemreplacement_Status.ScrapForParts:
      return "Scrap for Parts";
  }
};

export const orderItemStatusToString = (orderItem?: OrderItemDataFragment | null): string => {
  if (!orderItem) {
    return "N/A";
  }

  return _orderItemStatusToString(
    orderItem.attributes?.status,
    orderItem.attributes?.device?.data?.attributes?.type
  );
};
