import {
  ApolloError,
  useInpsectionDetailsQuery,
  InspectionDataFragment,
} from "data";

interface UseInspectionDetailsHookData {
  loading: boolean;
  error?: ApolloError;
  inspection?: InspectionDataFragment;
}

export const useInspectionDetails = ({
  inspectionId,
}: {
  inspectionId: string;
}): UseInspectionDetailsHookData => {
  const { loading, data, error } = useInpsectionDetailsQuery({
    variables: {
      inspectionId,
    },
  });
  return {
    loading,
    error,
    inspection: data?.inspection?.data || undefined,
  };
};
