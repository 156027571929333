import {
  Badge,
  HStack,
  Stack,
  Text,
  Box,
  Divider,
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SortIcon } from "components/Icons";
import { DataListWithFiltersAndPagination } from "components/DataList";
import { DateTimeText } from "components/utils";
import { ShowForAllTechs, ShowForCustomers } from "features/accounts";
import { LinkToOrder } from "features/orders/components/LinkToOrder";
import { OrderStatsBadge } from "features/orders/components/OrderStats";
import { OrderStatsForCustomer } from "features/orders/components/OrderStatsForCustomer";
import { OrderStatsByDeviceType } from "features/orders/components/OrderStatsByDeviceType";
import { completeOrderName, shortOrderName } from "features/orders/data";
import { OrderDataFragment } from "data";
import { OrderStatusBadge } from "features/orders/components/OrderStatusBadge";
import { ExpectedShipmentDateLabel } from "features/orders/components/ExpectedShipmentDateLabel";
import { useOrdersList } from "features/orders/context/ListOrders";
import { useOrdersList as useOrdersListForCustomer } from "features/orders/context/ListOrdersForCustomer";
import { DownloadReportButton } from "features/reports/components/DownloadReportButton";

export const OrderTableEntry = ({ order }: { order: OrderDataFragment }) => {
  return order ? (
    <HStack alignItems='start' key={order.id}>
      <ShowForAllTechs>
        <OrderStatsBadge order={order} />
      </ShowForAllTechs>
      <Stack w='full'>
        <HStack>
          <Box>
            <ShowForAllTechs>
              <LinkToOrder order={order}>
                <Text fontWeight='bold'>{completeOrderName(order)}</Text>
              </LinkToOrder>
            </ShowForAllTechs>
            <ShowForCustomers>
              <LinkToOrder order={order}>
                <Text fontSize='2xl' fontWeight='bold'>
                  {shortOrderName(order)}
                </Text>
              </LinkToOrder>
            </ShowForCustomers>
          </Box>
          <Box textAlign='right' flexGrow={2}>
            <OrderStatusBadge orderData={order} />
          </Box>
        </HStack>
        <ShowForCustomers>
          <Box pt={4}>
            <Stack spacing='4'>
              <HStack>
                <Text color='muted' fontSize='small'>
                  {order.attributes?.zoho_sales_order?.data?.attributes?.accountName}
                </Text>
                <Text color='muted' fontSize='small'>
                  &bull;
                </Text>
                <DateTimeText
                  color='muted'
                  fontSize='small'
                  value={order.attributes?.createdAt}
                  format={"MMM D, YYYY"}
                />
              </HStack>
              <HStack>
                <DownloadReportButton
                  data-cy='download-inventory-report'
                  size='xs'
                  variant='link'
                  label='Inventory'
                  reportName='inventory report'
                  parameters={{ orderId: order.id }}
                />
                <DownloadReportButton
                  data-cy='download-activity-report'
                  size='xs'
                  variant='link'
                  label='Activity'
                  reportName='activity report'
                  parameters={{ orderId: order.id }}
                />
              </HStack>
            </Stack>
          </Box>
        </ShowForCustomers>
        <ShowForAllTechs>
          <HStack>
            {order.attributes?.isArchived ? (
              <Badge variant='outline' size='sm' color='red'>
                Archived
              </Badge>
            ) : null}
            <DateTimeText
              fontSize='smaller'
              value={order.attributes?.createdAt}
              format={"MMM D, YYYY"}
            />
            <Text fontSize='smaller'>
              <ExpectedShipmentDateLabel order={order} />
            </Text>
          </HStack>
        </ShowForAllTechs>
        <ShowForAllTechs>
          <OrderStatsByDeviceType order={order} />
        </ShowForAllTechs>
        <Divider pb={4} />
        <OrderStatsForCustomer order={order} />
      </Stack>
    </HStack>
  ) : null;
};

export const OrdersTable = () => {
  const { data, loading, params, setParams } = useOrdersList();
  const supportedSorting = [
    {
      value: "createdAt:desc",
      label: "By Date (Newest first)",
    },
    {
      value: "createdAt:asc",
      label: "By Date (Oldest first)",
    },
    {
      value: "expectedShipmentDate:desc",
      label: "By Target Ship Date (Newest first)",
    },
    {
      value: "expectedShipmentDate:asc",
      label: "By Target Ship Date (Oldest first)",
    },
    {
      value: "zoho_sales_order.accountName",
      label: "By Customer",
    },
  ];

  return (
    <DataListWithFiltersAndPagination
      loading={loading}
      data={data?.orders?.data || []}
      pagination={data?.orders?.meta.pagination}
      onPageChange={(page) => {
        setParams(Object.assign({}, params, { page }));
      }}
      renderItem={({ item: order }) => <OrderTableEntry order={order} />}
      renderFilters={() => {
        const showingActive = params.filters?.isArchived?.eq !== true;

        return (
          <HStack>
            {/* Archived VS active */}
            <Menu>
              <MenuButton as={Button} variant='ghost' rightIcon={<ChevronDownIcon />}>
                <HStack>
                  <Text>{showingActive ? "Active Orders" : "Archived"}</Text>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    if (showingActive) {
                      setParams(
                        Object.assign({}, params, {
                          filters: {
                            isArchived: { eq: true },
                          },
                          page: 1,
                        })
                      );
                    } else {
                      setParams(
                        Object.assign({}, params, {
                          filters: {
                            or: [{ isArchived: { eq: false } }, { isArchived: { eq: null } }],
                          },
                          page: 1,
                        })
                      );
                    }
                  }}
                >
                  {showingActive ? "Archived" : "Active"}
                </MenuItem>
              </MenuList>
            </Menu>
            {/* Sorting */}
            <Menu>
              <MenuButton as={Button} variant='ghost' rightIcon={<ChevronDownIcon />}>
                <HStack>
                  <SortIcon />
                  <Text>{supportedSorting.find((s) => s.value === params.sort)?.label}</Text>
                </HStack>
              </MenuButton>
              <MenuList>
                {supportedSorting
                  .filter(({ value }) => value !== params.sort)
                  .map(({ value, label }) => (
                    <MenuItem
                      key={value} // Add this line
                      onClick={() => {
                        setParams(
                          Object.assign({}, params, {
                            sort: value,
                            page: 1,
                          })
                        );
                      }}
                    >
                      {label}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </HStack>
        );
      }}
    />
  );
};

export const OrdersTableForCustomer = () => {
  const { data, loading, params, setParams } = useOrdersListForCustomer();
  return (
    <DataListWithFiltersAndPagination
      loading={loading}
      data={data?.orders?.data || []}
      pagination={data?.orders?.meta.pagination}
      onPageChange={(page) => {
        setParams(Object.assign({}, params, { page }));
      }}
      renderItem={({ item: order }) => <OrderTableEntry order={order} />}
    />
  );
};
