import { Text, TextProps } from "@chakra-ui/react";
import { Enum_Device_Series } from "data";
import { deviceSeriesToString } from "features/devices/data";

interface DeviceSeriesLabelProps extends TextProps {
  deviceSeries?: Enum_Device_Series;
  includeSeriesSuffix?: boolean;
}

export const DeviceSeriesLabel = (props: DeviceSeriesLabelProps) => {
  const { deviceSeries, includeSeriesSuffix, ...rest } = props;
  return (
    <Text {...rest}>
      {deviceSeriesToString(deviceSeries)}
      {includeSeriesSuffix ? " series" : ""}
    </Text>
  );
};
