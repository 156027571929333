import { useState } from "react";
import {
  Box,
  CloseButton,
  Container,
  Icon,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { TestModeIcon } from "components/Icons";

export const TestModeBanner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [hidden, setHidden] = useState<boolean>(false);
  return !hidden ? (
    <Box className="no-print" data-cy="test-mode-banner" as="section">
      <Box bg="blue.500" color="on-accent">
        <Container py={{ base: "4", md: "2.5" }} position="relative">
          <CloseButton
            onClick={() => {
              setHidden(true);
            }}
            display={{ md: "none" }}
            position="absolute"
            right="2"
            top="2"
          />
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            spacing={{ base: "3", md: "2" }}
          >
            <Stack
              spacing="4"
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}
            >
              {!isMobile && (
                <Square size="12" bg="blue.400" borderRadius="md">
                  <Icon as={TestModeIcon} boxSize="6" />
                </Square>
              )}
              <Stack spacing="0.5" pe={{ base: "4", md: "0" }}>
                <Text color="white" fontWeight="medium">
                  The App is running in test mode!
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  ) : null;
};
