import { useInspectionSettings } from "features/inspections/context/InspectionSettings";

export interface ScannerMinerData {
  ip: string;
  mac: string;
  fans: { speed: number }[];
  make: string;
  fan_1: number;
  fan_2: number;
  fan_3: number;
  fan_4: number;
  model: string;
  errors: any[];
  fw_ver: string;
  api_ver: string;
  fan_psu: number;
  nominal: boolean;
  wattage: number;
  env_temp: number;
  hashrate: number;
  hostname: string;
  _hashrate: number;
  efficiency: number;
  hashboards: {
    slot: number;
    temp: number | null;
    chips: number | null;
    missing: boolean | null;
    hashrate: number | null;
    chip_temp: number | null;
    expected_chips: number;
  }[];
  left_chips: number;
  pool_1_url: string;
  pool_2_url: string;
  pool_split: string;
  fault_light: null;
  expected_chips: number;
  pool_1_user: string;
  pool_2_user: string;
  right_chips: number;
  total_chips: number;
  center_chips: number;
  wattage_limit: number;
  left_board_temp: number;
  temperature_avg: number;
  expected_hashboards: number;
  expected_hashrate: number;
  right_board_temp: number;
  center_board_temp: number;
  left_board_hashrate: number;
  percent_expected_chips: number | null;
  left_board_chip_temp: number;
  right_board_hashrate: number;
  center_board_hashrate: number;
  percent_expected_wattage: number;
  right_board_chip_temp: number;
  center_board_chip_temp: number;
  percent_expected_hashrate: number | null;
  summary?: {
    id: number;
    STATUS: any;
    SUMMARY?: {
      "GHS 5s": number;
      "GHS av": number;
      Elapsed: number;
      "GHS 30m": number;
    }[];
  };
}

export interface ScannerResponse {
  miner_data?: ScannerMinerData | null;
  kernel_log?: string | null;
}

interface UseMinerScannerHookData {
  scan: ({
    ips,
  }: {
    ips: string[];
  }) => Promise<{ [key: string]: ScannerResponse }>;
  ping: ({ ips }: { ips: string[] }) => Promise<string[]>;
  healthCheck: () => Promise<boolean>;
  configureMiningPool: ({
    minerIp,
    groupName,
    url,
    username,
    password,
  }: {
    minerIp: string;
    groupName: string;
    url: string;
    username: string;
    password: string;
  }) => Promise<void>;
}

export const useMinerScanner = (): UseMinerScannerHookData => {
  const { scannerURL } = useInspectionSettings();
  return {
    async configureMiningPool({ minerIp, groupName, url, username, password }) {
      const response = await fetch(`${scannerURL}/set-pool-config`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          miner_ip: minerIp,
          group_name: groupName,
          url,
          username,
          password,
        }),
      });
      return response.json();
    },
    async scan({ ips }) {
      try {
        const d = await fetch(`${scannerURL}/scan?ips=${ips.join(",")}`);
        return d.json();
      } catch (error) {
        console.error("Miner scanner failed with", error);
      }
    },
    async ping({ ips }) {
      try {
        const d = await fetch(`${scannerURL}/ping?ips=${ips.join(",")}`);
        return Object.keys(await d.json());
      } catch (error) {
        console.error("Miner ping failed with", error);
      }
      return [];
    },
    async healthCheck() {
      try {
        const d = await fetch(`${scannerURL}/flask-health-check`);
        return (await d.text()) === "success";
      } catch (error) {
        return false;
      }
    },
  };
};
