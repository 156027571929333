import {
  CustomerIntakeFormDataFragment,
  CustomerIntakeFormInput,
  useSubmitCustomerIntakeFormMutation,
} from "data";
import { useCurrentCustomer } from "features/customers/data/hooks/customers";
import { useUpload } from "features/uploads/data/hooks/upload";

interface UseSubmitCustomerIntakeFormHookData {
  submitIntakeForm: ({
    data,
    manifestFile,
  }: {
    data: CustomerIntakeFormInput;
    manifestFile: File | null;
  }) => Promise<CustomerIntakeFormDataFragment | null>;
}

export const useSubmitCustomerIntakeForm =
  (): UseSubmitCustomerIntakeFormHookData => {
    const { customer } = useCurrentCustomer();
    const { uploadFile } = useUpload();

    const [submit] = useSubmitCustomerIntakeFormMutation();

    return {
      async submitIntakeForm({ data, manifestFile }) {
        const manifest =
          manifestFile && (await uploadFile({ file: manifestFile }));

        const { data: d } = await submit({
          variables: {
            data: Object.assign(
              {},
              data,
              {
                customer: customer.id,
              },
              manifest
                ? {
                    manifest: manifest?.id,
                  }
                : {}
            ),
          },
        });
        return d?.createCustomerIntakeForm?.data || null;
      },
    };
  };
