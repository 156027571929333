import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Make } from "data";

interface ShowForMakeProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
  make: Enum_Device_Make;
}

export const ShowForMake = (props: ShowForMakeProps) => {
  const { device, make, children } = props;

  if (!device || device.attributes?.make !== make) {
    return null;
  }

  return children;
};
