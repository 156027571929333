import { useSearchParams } from "react-router-dom";
import { ResetPasswordFormContainer as ResetPasswordForm } from "features/accounts/containers/ResetPasswordForm";
import { LayoutShell } from "features/accounts/components/LayoutShell";

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  // XX: welcome version of the reset password
  const welcome = searchParams.get("welcome");

  return (
    <LayoutShell
      title={welcome ? "Welcome to ACS" : "Set your new password"}
      subtitle={
        welcome
          ? "Pick a password"
          : "Don't have an account? Please contact ACS team"
      }
    >
      <ResetPasswordForm welcome={welcome ? true : false} />
    </LayoutShell>
  );
};
