import { InspectDeviceIcon, DiagnoseDeviceIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { Enum_Device_Type, OrderItemDataFragment } from "data";
import { canInspect } from "features/devices/components/ShowForDevicesWeCanInspect";
import { useDiagnosticsContextMenu } from "features/diagnostics/context/DiagnosticsContextMenu";
import { ExistingReport } from "features/diagnostics/data";

interface DiagnosticsActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
  existingReport?: ExistingReport;
}

export const DiagnosticsAction = (props: DiagnosticsActionProps) => {
  const { orderItem, existingReport, variant } = props;
  const { openDiagnosticsMenu } = useDiagnosticsContextMenu();
  const deviceType = orderItem.attributes?.device?.data?.attributes?.type;

  const getActionLabel = (): string => {
    if (!existingReport) {
      return deviceType === Enum_Device_Type.Miner ? "Inspect" : "Diagnose";
    }
    return "Edit";
  };

  const actionIcon =
    deviceType === Enum_Device_Type.Miner ? (
      <InspectDeviceIcon />
    ) : (
      <DiagnoseDeviceIcon />
    );

  if (!deviceType) {
    return null;
  }

  if (!canInspect({ device: orderItem.attributes?.device?.data })) {
    return null;
  }

  return (
    <ActionComponent
      action={async () => {
        openDiagnosticsMenu(orderItem, existingReport);
      }}
      variant={variant}
      actionIcon={actionIcon}
      actionLabel={getActionLabel()}
      data-order-item-id={orderItem.id}
      data-cy={
        deviceType === Enum_Device_Type.Miner
          ? "inspect-action-button"
          : "diagnose-action-button"
      }
    />
  );
};

DiagnosticsAction.defaultProps = {
  variant: "button",
};
