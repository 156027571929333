import { Enum_Orderitem_Status } from "./graphql-apollo";

export const ALL_ORDER_ITEM_STATUS = Object.values(Enum_Orderitem_Status);

export const ORDER_ITEM_TAGS = {
  QuickInspectAllASICs: "quick-inspect-all-asics",
  QuickInspectNoASICs: "quick-inspect-no-asics",
  QuickInspectSomeASICs: "quick-inspect-some-asics",
  MinerConsolidatedGood: "miner-consolidated-good",
  MinerConsolidatedBad: "miner-consolidated-bad",
};

export { ApolloError } from "@apollo/client";
export {
  useCompletePartsDataQuery,
  useAsicTesterDevicesQuery,
  useZohoSalesOrdersQuery,
  useOrdersQuery,
  useOrdersLazyQuery,
  useCreateOrderMutation,
  useDevicesQuery,
  useDevicesLazyQuery,
  useFindDeviceLazyQuery,
  useOrderDetailsQuery,
  useOrderItemsForOrderQuery,
  useOrderItemsForCustomerDeviceQuery,
  useOrderLayoutItemsQuery,
  useCreateOrderLayoutItemMutation,
  useDeleteOrderLayoutItemMutation,
  useFindCustomerDevicesQuery,
  useFindCustomerDevicesLazyQuery,
  useFindCustomersQuery,
  useFindOrdersQuery,
  useLookupCustomerDevicesQuery,
  useCreateCustomerDeviceMutation,
  useUpdateCustomerDeviceMutation,
  useUpdateOrderItemMutation,
  useOrderItemByIdQuery,
  useOrderItemSummaryQuery,
  useCreateOrderItemMutation,
  useCreateOrderItemSubcomponentMutation,
  useUpdateOrderItemSubcomponentMutation,
  useDeleteOrderItemMutation,
  useDeleteOrderItemSubcomponentMutation,
  useHashboardRepairsForOrderItemQuery,
  useCreateHashboardRepairMutation,
  usePsuRepairsForOrderItemQuery,
  useCreatePsuRepairMutation,
  useRegisterAdvancedRepairMutation,
  useDeleteAdvancedRepairMutation,
  useInspectionsQuery,
  useRacksForInspectionQuery,
  useUpdateInspectedDeviceMutation,
  useUpdateInspectionRackMutation,
  useInspectedDevicesForRackQuery,
  useInspectionByIdQuery,
  useListActionsQuery,
  useListActionsLazyQuery,
  Enum_Device_Type,
  Enum_Device_Model,
  Enum_Device_Series,
  Enum_Orderitem_Status,
  Enum_Orderitem_Previousstatus,
  Enum_Device_Make,
  Enum_Order_Status,
  Enum_Action_Scope,
  Enum_Action_Type,
  Enum_Action_Client,
  Enum_Orderpackage_Status,
  Enum_Orderitemreplacement_Status,
  Enum_Inspection_Status,
  Enum_Inspecteddevice_Hashingstatus,
  Enum_Hashboardadvancedinspectionreport_Diagnosis,
  Enum_Componenthashboardhashboardmetadata_Type,
  Enum_Orderitemaction_Action,
  OrderDetailsDocument,
  OrderItemByIdDocument,
  OrderItemSummaryDocument,
  OrderLayoutItemsDocument,
  OrderItemsForOrderDocument,
  MinerInspectionReportsForOrderItemDocument,
  HashboardInspectionReportsForOrderItemDocument,
  PsuInspectionReportsForOrderItemDocument,
  NotesDocument,
  OrderPackageByIdDocument,
  CustomersDocument,
  OrdersDocument,
  OrdersForCustomerDocument,
  InspectionsDocument,
  useUserProfileQuery,
  useUserProfileLazyQuery,
  useLocationsQuery,
  useCustomerDeviceByIdQuery,
  useCustomersQuery,
  useCustomerDetailsQuery,
  useCreateOrderPackageMutation,
  useUpdateOrderPackageMutation,
  useOrderPackageByIdQuery,
  useOrderItemsReadyForPackagingQuery,
  useCreateMinerInspectionReportMutation,
  useUpdateMinerInspectionReportMutation,
  useMinerInspectionReportsForOrderItemQuery,
  useCreateHashboardInspectionReportMutation,
  useCreateHashboardAdvancedInspectionReportMutation,
  useUpdateHashboardInspectionReportMutation,
  useHashboardInspectionReportsForOrderItemQuery,
  useLatestHashboardInspectionReportsForOrderItemQuery,
  useCreatePsuInspectionReportMutation,
  useUpdatePsuInspectionReportMutation,
  usePsuInspectionReportsForOrderItemQuery,
  useUploadMutation,
  useMultipleUploadMutation,
  useApplicationConfigurationQuery,
  useCreateActionMutation,
  useCreateControlBoardQaReportMutation,
  useCreateHashboardQaReportMutation,
  useCreateMinerQaReportMutation,
  useCreatePsuQaReportMutation,
  useNotesQuery,
  useCreateNoteMutation,
  useCreateOrderItemReplacementMutation,
  useCreateReceiveDeviceRequestMutation,
  useCreateInspectedDeviceMutation,
  useInpsectionDetailsQuery,
  useUpdateInspectionMutation,
  useDeleteInspectedDeviceMutation,
  useDeleteCustomerDeviceMutation,
  OrderItemDataFragmentDoc,
  useMeLazyQuery,
  useUpdateOrderMutation,
  useCreateInspectionMutation,
  useCreateInspectionRackMutation,
  useSubmitCustomerIntakeFormMutation,
  useCreateCustomerProfileCreationRequestMutation,
  useCustomerProfileQuery,
  useCreateOrderItemActionMutation,
  useCreateInspectionReportMutation,
  useReportSqlTemplatesLazyQuery,
  useReportRequestLazyQuery,
  useCreateReportRequestMutation,
} from "./graphql-apollo";
export type {
  MeDataFragment,
  PaginationDataFragment,
  DeviceEntity as Device,
  PartEntity as Part,
  SupplierPartEntity as SupplierPart,
  AsicTesterDeviceEntity as AsicTesterDevice,
  ZohoSalesOrderEntity,
  ZohoSalesOrderDataFragment,
  OrderDataFragment,
  OrderStatDataFragment,
  DeviceDataFragment,
  OrderLayoutItemDataFragment,
  OrderItemDataFragment,
  OrderItemInput,
  OrderItemSubcomponentDataFragment,
  CustomerDeviceDataFragment,
  CustomerDeviceInput,
  CustomerDataFragment,
  LoginDataFragment,
  UserProfileDataFragment,
  LocationDataFragment,
  NoteDataFragment,
  NoteInput,
  NoteFiltersInput,
  ReceiveDeviceRequestDataFragment,
  ReceiveDeviceRequestInput,
  OrderDetailsQuery,
  OrderDetailsQueryVariables,
  OrderItemSummaryQuery,
  OrderItemSummaryQueryVariables,
  OrdersQueryVariables,
  OrdersForCustomerQueryVariables,
  OrderItemByIdQuery,
  OrderItemByIdQueryVariables,
  OrderLayoutItemsQuery,
  OrderItemsForOrderQuery,
  OrderLayoutItemsQueryVariables,
  OrderPackageDataFragment,
  OrderPackageByIdQuery,
  OrderPackageByIdQueryVariables,
  CustomersQueryVariables,
  UploadFileDataFragment,
  MinerInspectionReportDataFragment,
  HashboardInspectionReportDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
  PsuInspectionReportDataFragment,
  PsuInspectionReportInput,
  PsuInspectionReport,
  ApplicationConfigurationDataFragment,
  HashboardRepairDataFragment,
  HashboardBasicRepairDataFragment,
  HashboardBasicRepairInput,
  PsuRepairDataFragment,
  PsuRepairInput,
  PsuRepair,
  ActionDataFragment,
  ActionInput,
  ControlBoardQaReportDataFragment,
  ControlBoardQaReportInput,
  HashboardQaReportDataFragment,
  HashboardQaReportInput,
  MinerQaReportDataFragment,
  MinerQaReportInput,
  PsuQaReportDataFragment,
  PsuQaReportInput,
  CreateHashboardRepairMutation,
  CreatePsuRepairMutation,
  OrderItemReplacementDataFragment,
  OrderInput,
  OrderItemsForOrderQueryVariables,
  CustomersQuery,
  OrdersQuery,
  OrdersForCustomerQuery,
  InspectionDataFragment,
  InspectedDeviceDataFragment,
  InspectionRackDataFragment,
  ActionFiltersInput,
  MinerInspectionReportInput,
  HashboardInspectionReportInput,
  HashboardAdvancedInspectionReportInput,
  CustomerIntakeFormDataFragment,
  CustomerIntakeFormInput,
  CustomerProfileCreationRequestInput,
  CustomerProfileFragment,
  ComponentHashboardHashboardMetadataInput,
  OrderItemActionInput,
  OrderItemActionDataFragment,
  InspectionReportDataFragment,
  InspectionsQueryVariables,
  InspectionsQuery,
} from "./graphql-apollo";
