import { Text, TextProps } from "@chakra-ui/react";
import { Enum_Hashboardadvancedinspectionreport_Diagnosis } from "data";
import { parseHashboardDiagnosis } from "features/diagnostics/data/helpers";

interface HashboardDiagnosisLabelProps extends TextProps {
  diagnosis?: Enum_Hashboardadvancedinspectionreport_Diagnosis | null;
}

export const HashboardDiagnosisLabel = (
  props: HashboardDiagnosisLabelProps
) => {
  const { diagnosis, ...rest } = props;

  if (!diagnosis) {
    return null;
  }

  const { category, diagnosis: diagnosisDetails } =
    parseHashboardDiagnosis(diagnosis);

  return (
    <Text {...rest}>
      {category} {">"} {diagnosisDetails}
    </Text>
  );
};
