import {
  TableContainer,
  Table,
  Tbody,
  Td,
  Tr,
  Text,
  Button,
  Stack,
} from "@chakra-ui/react";
import { MultilineText } from "components/Labels";
import { useNotifications } from "context/Notifications";
import { useOrderItem } from "features/order-items/context/OrderItem";
import { useAdvancedRepair } from "features/repairs/data/hooks/repairs";

export const OrderItemSettings = () => {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { orderItem, refetch } = useOrderItem();
  const { registerAdvancedRepair, deleteAdvancedRepair } = useAdvancedRepair();
  const advancedRepairs = orderItem?.attributes?.advanced_repairs?.data || [];
  const hashboardMetadata =
    orderItem?.attributes?.customer_device?.data?.attributes?.hashboardMetadata;

  return !orderItem ? null : (
    <Stack spacing={10}>
      {hashboardMetadata ? (
        <TableContainer>
          <Table variant="simple">
            <Tbody>
              {hashboardMetadata?.type ? (
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Board type:</Text>
                  </Td>
                  <Td textAlign="right">{hashboardMetadata?.type}</Td>
                </Tr>
              ) : null}
              {hashboardMetadata?.lNumber ? (
                <Tr>
                  <Td>
                    <Text fontWeight="bold">L#:</Text>
                  </Td>
                  <Td textAlign="right">{hashboardMetadata?.lNumber}</Td>
                </Tr>
              ) : null}
              {hashboardMetadata?.metadata ? (
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Metadata:</Text>
                  </Td>
                  <Td textAlign="right">
                    <MultilineText>{hashboardMetadata?.metadata}</MultilineText>
                  </Td>
                </Tr>
              ) : null}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td>
                <Text fontWeight="bold">Advanced Repair:</Text>
              </Td>
              <Td textAlign="right">
                {advancedRepairs.length !== 0 ? (
                  <Button
                    onClick={async () => {
                      try {
                        await deleteAdvancedRepair({ orderItem });
                        await refetch();
                        showSuccessMessage("Removed advanced repair");
                      } catch (err) {
                        showErrorMessage("Cannot remove advanced repair flag");
                      }
                    }}
                    size="sm"
                    variant="outline"
                    color="green"
                  >
                    Remove Advanced Repair
                  </Button>
                ) : (
                  <Button
                    onClick={async () => {
                      try {
                        await registerAdvancedRepair({ orderItem });
                        await refetch();
                        showSuccessMessage("Advanced repair registered");
                      } catch (err) {
                        showErrorMessage("Failed to register advanced repair");
                      }
                    }}
                    size="sm"
                    variant="outline"
                    color="red"
                  >
                    Register Advanced Repair
                  </Button>
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
