import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { RecycleIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";

interface RecycleActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const RecycleAction = (props: RecycleActionProps) => {
  const { orderItem, variant } = props;
  const { updateOrderItemStatus } = useUpdateOrderItemStatus();
  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await updateOrderItemStatus(
            orderItem,
            Enum_Orderitem_Status.QaFailRecycled,
            true
          );
        }
      }}
      variant={variant}
      actionIcon={<RecycleIcon />}
      actionLabel="Recycle"
      data-cy="action-recycle"
    />
  );
};

RecycleAction.defaultProps = {
  variant: "button",
};
