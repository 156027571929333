import {
  Badge,
  HStack,
  Button,
  CircularProgress,
  Text,
} from "@chakra-ui/react";
import { OrderItemDataFragment } from "data";
import { TagIcon, AddIcon } from "components/Icons";
import { useOrderItemTagger } from "features/order-item-tags/context/OrderItemTagger";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { OrderItemTagText } from "features/order-item-tags/components/OrderItemTagText";
import { DeleteIcon } from "components/Icons";
import { useState } from "react";

const OrderItemTag = ({
  orderItem,
  tag,
}: {
  orderItem: OrderItemDataFragment;
  tag: string;
}) => {
  const [working, setWorking] = useState<boolean>(false);
  const { removeTag } = useOrderItemTagger();
  return !working ? (
    <Badge data-cy={`tag-${tag}`} key={tag} variant="outline">
      <HStack>
        <TagIcon />
        <OrderItemTagText tag={tag} />
        <DeleteIcon
          data-cy={`remove-tag-${tag}`}
          cursor="pointer"
          onClick={async () => {
            setWorking(true);
            await removeTag({ orderItem, tag });
            setWorking(false);
          }}
        />
      </HStack>
    </Badge>
  ) : (
    <CircularProgress size="20px" isIndeterminate color="green.300" />
  );
};

export const OrderItemTags = ({
  orderItem,
}: {
  orderItem: OrderItemDataFragment;
}) => {
  const { show } = useOrderItemTagger();
  return (
    <HStack>
      {(orderItem.attributes?.tags || []).map((t: string) => (
        <OrderItemTag key={t} orderItem={orderItem} tag={t} />
      ))}
      {/* XX: for now adding tags is only available for miners - this might change in the future */}
      <ShowForMiner device={orderItem.attributes?.device?.data}>
        <Button
          onClick={() => {
            show({ orderItem });
          }}
          size="xs"
          variant="outline"
          data-cy="add-tag-to-order-item"
        >
          <HStack>
            <AddIcon />
            <Text>Add Tag</Text>
          </HStack>
        </Button>
      </ShowForMiner>
    </HStack>
  );
};
