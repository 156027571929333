import { Icon, Text } from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { TiTimes } from "react-icons/ti";
import {
  TableContainer,
  SimpleTable,
  Tbody,
  Tr,
  Td,
} from "components/utils/Table";
import {
  MinerInspectionReportDataFragment,
  HashboardInspectionReportDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
  PsuInspectionReportDataFragment,
} from "data";
import { parseHashboardDiagnosis } from "features/diagnostics/data/helpers";
import { summarizeInsectionReport } from "features/diagnostics/data";
import { ReportAttachments } from "features/diagnostics/components/ReportAttachments";
import { MultilineText } from "components/Labels";

interface DiagnosticsReportProps {
  report:
    | MinerInspectionReportDataFragment
    | HashboardInspectionReportDataFragment
    | PsuInspectionReportDataFragment
    | HashboardAdvancedInspectionReportDataFragment;
}

const AdvancedHashboardDiagnosticsReport = ({
  report,
}: {
  report: HashboardAdvancedInspectionReportDataFragment;
}) => {
  const { category, diagnosis } = parseHashboardDiagnosis(
    report.attributes?.diagnosis
  );
  const reportNotes = [
    {
      label: "Visual",
      value: report.attributes?.visualNotes,
    },
    {
      label: "Power",
      value: report.attributes?.powerNotes,
    },
    {
      label: "Communication",
      value: report.attributes?.communicationNotes,
    },
    {
      label: "ASICs",
      value: report.attributes?.asicNotes,
    },
    {
      label: "Temperature",
      value: report.attributes?.temperatureNotes,
    },
    {
      label: "Thermals",
      value: report.attributes?.thermalsNotes,
    },
    {
      label: "Summary",
      value: report.attributes?.notes,
    },
  ];

  return (
    <TableContainer>
      <SimpleTable>
        <Tbody>
          <Tr>
            <Td>
              <Text fontWeight="bold">Diagnosis</Text>
            </Td>
            <Td>
              <Text>
                {category} {">"} {diagnosis}
              </Text>
            </Td>
          </Tr>
          {reportNotes.map(({ label, value }) =>
            value ? (
              <Tr>
                <Td>
                  <Text fontWeight="bold">{label}</Text>
                </Td>
                <Td>
                  <MultilineText>{value}</MultilineText>
                </Td>
              </Tr>
            ) : null
          )}
        </Tbody>
      </SimpleTable>
      <ReportAttachments attachments={report.attributes?.uploads?.data || []} />
    </TableContainer>
  );
};

export const DiagnosticsReport = (props: DiagnosticsReportProps) => {
  const { report } = props;
  const summary = summarizeInsectionReport(report);
  return report.__typename === "HashboardAdvancedInspectionReportEntity" ? (
    <AdvancedHashboardDiagnosticsReport
      report={report as HashboardAdvancedInspectionReportDataFragment}
    />
  ) : (
    <TableContainer>
      <SimpleTable>
        <Tbody>
          {summary.items.map(({ label, value, isGood }) => (
            <Tr>
              <Td>{label}</Td>
              <Td>
                <MultilineText>{value}</MultilineText>
              </Td>
              <Td textAlign="right">
                {typeof isGood !== "undefined" ? (
                  <Icon as={isGood ? MdCheckCircle : TiTimes} />
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </SimpleTable>
      {report.__typename === "MinerInspectionReportEntity" ? (
        <ReportAttachments
          attachments={
            (report as MinerInspectionReportDataFragment).attributes
              ?.attachments?.data || []
          }
        />
      ) : null}
    </TableContainer>
  );
};
