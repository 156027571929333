import {
  Divider,
  Textarea,
  Stack,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { SiSpeedtest } from "react-icons/si";
import { BiTimer } from "react-icons/bi";
import { parseIntOrUndefined } from "lib";
import { Enum_Device_Make, OrderItemDataFragment } from "data";
import { TemperatureIcon } from "components/Icons";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { MinerDiagnosticsReport } from "features/diagnostics/data";
import { Upload } from "features/uploads/components/Upload";
import { ReportAttachments } from "features/diagnostics/components/ReportAttachments";

interface OtherInputsDiagnosticsProps {
  orderItem: OrderItemDataFragment;
}

export const OtherInputsDiagnostics = (props: OtherInputsDiagnosticsProps) => {
  const { orderItem } = props;
  const { data, updateData, enableNav, disableNav } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;
  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel>Final Hashrate</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={SiSpeedtest} color="gray.300" />}
          />
          <Input
            onChange={(e) => {
              updateData(
                Object.assign({}, minerDiagnosticsReport, {
                  finalHashrate: parseIntOrUndefined(e.currentTarget.value),
                })
              );
            }}
            type="number"
            placeholder="Final Hashrate"
            value={minerDiagnosticsReport?.finalHashrate}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Time hashing (in minutes)</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={BiTimer} color="gray.300" />}
          />
          <Input
            onChange={(e) => {
              updateData(
                Object.assign({}, minerDiagnosticsReport, {
                  timeHashing: parseIntOrUndefined(e.currentTarget.value),
                })
              );
            }}
            type="number"
            placeholder="Time hashing (in minutes)"
            value={minerDiagnosticsReport?.timeHashing}
          />
        </InputGroup>
      </FormControl>
      <Divider />
      <ShowForMiner
        device={orderItem?.attributes?.device?.data}
        makes={[Enum_Device_Make.Avalon]}
      >
        <>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={TemperatureIcon} color="gray.300" />}
            />
            <Input
              onChange={(e) => {
                updateData(
                  Object.assign({}, minerDiagnosticsReport, {
                    controlBoardTemperature: parseIntOrUndefined(
                      e.currentTarget.value
                    ),
                  })
                );
              }}
              type="number"
              placeholder="Control Board Temperature"
              value={minerDiagnosticsReport.controlBoardTemperature}
            />
          </InputGroup>
          <Textarea
            onChange={(e) => {
              updateData(
                Object.assign({}, minerDiagnosticsReport, {
                  notes: e.currentTarget.value,
                })
              );
            }}
            value={minerDiagnosticsReport?.notes}
            placeholder="Notes"
          />
        </>
      </ShowForMiner>
      <ReportAttachments attachments={minerDiagnosticsReport.attachments} />
      <Upload
        onStartUpload={() => {
          disableNav();
        }}
        onUpload={(uploadedFiles) => {
          enableNav();
          if (uploadedFiles && uploadedFiles.length !== 0) {
            updateData(
              Object.assign({}, minerDiagnosticsReport, {
                attachments: [
                  ...minerDiagnosticsReport.attachments,
                  ...uploadedFiles,
                ],
              })
            );
          }
        }}
        promptText="Add attachments for the report"
      />
    </Stack>
  );
};
