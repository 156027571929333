import { createContext, ReactNode, useContext, useState } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Stack,
  HStack,
} from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  ORDER_ITEM_TAGS,
  useUpdateOrderItemMutation,
} from "data";
import { TagIcon } from "components/Icons";
import { ShowForMiner } from "features/devices/components/ShowForMiner";
import { OrderItemTagText } from "features/order-item-tags/components/OrderItemTagText";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface OrderItemTaggerData {
  show: ({ orderItem }: { orderItem: OrderItemDataFragment }) => void;
  removeTag: ({
    orderItem,
    tag,
  }: {
    orderItem: OrderItemDataFragment;
    tag: string;
  }) => Promise<void>;
}

const OrderItemTaggerContext = createContext<OrderItemTaggerData>(
  {} as OrderItemTaggerData
);

export const OrderItemTaggerProvider = (props: { children: ReactNode }) => {
  const [orderItem, setOrderItem] = useState<OrderItemDataFragment | null>(
    null
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateOrderItem] = useUpdateOrderItemMutation();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { createAction } = useCreateAction();

  return (
    <OrderItemTaggerContext.Provider
      value={{
        show({ orderItem: oi }) {
          setOrderItem(oi);
          onOpen();
        },
        async removeTag({ orderItem: oi, tag }) {
          try {
            // eslint-disable-next-line
            if (confirm("Are you sure you want to remove this tag?")) {
              await updateOrderItem({
                variables: {
                  id: oi.id!,
                  data: {
                    tags: (oi?.attributes?.tags || []).filter(
                      (t: string) => t !== tag
                    ),
                  },
                },
              });
              await createAction({
                type: Enum_Action_Type.RemoveTagFromOrderItem,
                scope: Enum_Action_Scope.OrderItem,
                data: {
                  ...expandActionDataPiece(orderItem),
                  metadata: {
                    tag,
                  },
                },
                context: {
                  order: oi.attributes?.order?.data,
                  device: oi.attributes?.device?.data,
                  customerDevice: oi.attributes?.customer_device?.data,
                },
              });
              showSuccessMessage("Tag removed successfully");
            }
          } catch (e) {
            showErrorMessage("Failed to remove tag");
          }
        },
      }}
    >
      {props.children}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={10}>
            <ShowForMiner device={orderItem?.attributes?.device?.data}>
              <Stack>
                {[
                  ORDER_ITEM_TAGS.MinerConsolidatedGood,
                  ORDER_ITEM_TAGS.MinerConsolidatedBad,
                ]
                  .filter((tag) => {
                    return !orderItem?.attributes?.tags?.includes(tag);
                  })
                  .map((tag) => (
                    <Button
                      size="xs"
                      variant="outline"
                      data-cy={`add-tag-${tag}`}
                      onClick={async () => {
                        if (!orderItem) {
                          return;
                        }
                        try {
                          await updateOrderItem({
                            variables: {
                              id: orderItem.id!,
                              data: {
                                tags: [
                                  ...(orderItem?.attributes?.tags || []),
                                  tag,
                                ],
                              },
                            },
                          });

                          await createAction({
                            type: Enum_Action_Type.AddTagToOrderItem,
                            scope: Enum_Action_Scope.OrderItem,
                            data: {
                              ...expandActionDataPiece(orderItem),
                              metadata: {
                                tag,
                              },
                            },
                            context: {
                              order: orderItem.attributes?.order?.data,
                              device: orderItem.attributes?.device?.data,
                              customerDevice:
                                orderItem.attributes?.customer_device?.data,
                            },
                          });

                          showSuccessMessage("Tag added successfully");
                          onClose();
                        } catch (e) {
                          showErrorMessage("Failed to add tag");
                          onClose();
                        }
                      }}
                    >
                      <HStack>
                        <TagIcon />
                        <OrderItemTagText tag={tag} />
                      </HStack>
                    </Button>
                  ))}
              </Stack>
            </ShowForMiner>
          </ModalBody>
        </ModalContent>
      </Modal>
    </OrderItemTaggerContext.Provider>
  );
};

export const useOrderItemTagger = () => {
  return useContext(OrderItemTaggerContext);
};
