import { useParams } from "react-router-dom";
import { Page } from "components/layout/Page";
import {
  OrderPackageProvider,
  useOrderPackage,
} from "features/order-packages/context/OrderPackage";
import { PackagedOrderItems } from "features/order-packages/components/PackagedOrderItems";
import { OrderPackagePageTitle } from "features/order-packages/components/OrderPackagePageTitle";
import { OrderPackagePageSubtitle } from "features/order-packages/components/OrderPackagePageSubtitle";
import { OrderPackagePageActions } from "features/order-packages/components/OrderPackagePageActions";

const ConnectedOrderPackagePage = () => {
  const { orderPackage, orderItems, loading } = useOrderPackage();
  return (
    <Page
      loading={loading}
      title={
        orderPackage ? (
          <OrderPackagePageTitle orderPackage={orderPackage} />
        ) : (
          "Loading"
        )
      }
      subTitle={orderPackage ? <OrderPackagePageSubtitle /> : "Loading"}
      actionButton={<OrderPackagePageActions />}
    >
      <PackagedOrderItems orderItems={orderItems} />
    </Page>
  );
};

export const OrderPackagePage = () => {
  const { orderPackageId } = useParams();
  return orderPackageId ? (
    <OrderPackageProvider orderPackageId={orderPackageId}>
      <ConnectedOrderPackagePage />
    </OrderPackageProvider>
  ) : null;
};
