import { ReactNode } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Skeleton,
  Stack,
  Text,
  HeadingProps,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ActionPanel } from "components/layout/ActionPanel";

interface ActionPanelProps {
  title: string;
  children: ReactNode;
  onAction: () => Promise<void>;
  onClose: () => Promise<void>;
  enablePrimaryAction: boolean;
}

interface PageProps {
  children: ReactNode;
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  primaryActionButtonText?: string;
  actionPanel?: ActionPanelProps;
  actionButton?: ReactNode;
  loading?: boolean;
}

export const PageHeader = (props: HeadingProps) => (
  <Heading
    size={useBreakpointValue({ base: "xs", md: "sm" })}
    fontWeight="bold"
    {...props}
  />
);

export const Page = (props: PageProps) => {
  const {
    title,
    subTitle,
    primaryActionButtonText,
    actionPanel,
    actionButton,
    loading,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleClose = async () => {
    onClose();
    if (actionPanel) {
      await actionPanel.onClose();
    }
  };

  return (
    <Box
      as="section"
      bg="bg-surface"
      pt={{ base: "4", md: "8" }}
      pb={{ base: "12", md: "24" }}
    >
      <Container p="0">
        <Stack
          spacing="4"
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <Skeleton isLoaded={loading ? false : true}>
            <Stack spacing="4">
              {typeof title === "string" ? (
                <PageHeader>{title}</PageHeader>
              ) : (
                title
              )}
              {typeof subTitle === "string" ? (
                <Text>{subTitle}</Text>
              ) : (
                subTitle || null
              )}
            </Stack>
          </Skeleton>
          <Skeleton isLoaded={loading ? false : true}>
            {actionPanel && primaryActionButtonText ? (
              <Stack direction="row" spacing="3">
                <Button
                  onClick={() => {
                    onOpen();
                  }}
                  variant="primary"
                >
                  {primaryActionButtonText}
                </Button>
              </Stack>
            ) : null}
            {actionButton || null}
          </Skeleton>
        </Stack>
        <Divider my={4} />
        <Skeleton isLoaded={loading ? false : true}>
          <Box>{props.children}</Box>
        </Skeleton>
      </Container>
      {actionPanel && primaryActionButtonText ? (
        <ActionPanel
          isOpen={isOpen}
          title={actionPanel.title}
          enablePrimaryAction={actionPanel.enablePrimaryAction}
          primaryButtonText={primaryActionButtonText}
          onAction={async () => {
            await actionPanel.onAction();
            await handleClose();
          }}
          onClose={async () => {
            await handleClose();
          }}
        >
          {actionPanel.children}
        </ActionPanel>
      ) : null}
    </Box>
  );
};
