import { useOrderDetails } from "features/orders/context/OrderDetails";
import { useOrderLayout } from "features/orders/context/OrderLayout";
import { OrderSummary } from "features/orders/components/OrderSummary";
import { Loader } from "components";

export const OrderSummaryContainer = () => {
  const { isLoadingOrderDetails, order } = useOrderDetails();
  const { loading, orderLayoutItems } = useOrderLayout();
  return isLoadingOrderDetails || loading || !order ? (
    <Loader />
  ) : (
    <OrderSummary order={order} orderLayoutItems={orderLayoutItems} />
  );
};
