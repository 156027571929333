import {
  Stack,
  StackDivider,
  HStack,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import { CustomerIcon, OrderIcon } from "components/Icons";
import { Loader } from "components/Loader";
import { useSearch } from "features/search/data/hooks/search";
import { DeviceMakeIcon } from "features/devices/components/DeviceMakeIcon";
import { CustomerDeviceCompleteNameLabel } from "features/customer-devices/components/CustomerDeviceCompleteNameLabel";
import { LinkToCustomerDevice } from "features/customer-devices/components/LinkToCustomerDevice";
import { LinkToCustomer } from "features/customers/components/LinkToCustomer";
import { LinkToOrder } from "features/orders/components/LinkToOrder";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { OrderNameLabel } from "features/orders/components/OrderNameLabel";

interface AutocompleteSearchResultsProps {
  query: string;
  onClickResult: () => void;
}

export const AutocompleteSearchResults = (
  props: AutocompleteSearchResultsProps
) => {
  const { loading, results } = useSearch(props.query);

  return loading ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <Box bg="bg-surface" py="4">
      {!results.hasResults ? (
        <Center>
          <Text>Nothing Found 🤷‍♂️</Text>
        </Center>
      ) : null}
      <Stack divider={<StackDivider />} spacing="4">
        {results.orders.map((order) => (
          <LinkToOrder
            key={order.id}
            order={order}
            onClick={() => {
              props.onClickResult();
            }}
          >
            <Stack fontSize="sm" px="4" spacing="4">
              <Stack direction="row" justify="space-between" spacing="4">
                <HStack spacing="3">
                  <OrderIcon />
                  <Box>
                    <Text fontWeight="bold">
                      <OrderNameLabel orderData={order} />
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Stack>
          </LinkToOrder>
        ))}

        {results.customers.map((customer) => (
          <LinkToCustomer
            key={customer.id}
            customer={customer}
            onClick={() => {
              props.onClickResult();
            }}
          >
            <Stack fontSize="sm" px="4" spacing="4">
              <Stack direction="row" justify="space-between" spacing="4">
                <HStack spacing="3">
                  <CustomerIcon />
                  <Box>
                    <Text fontWeight="bold">
                      {customer.attributes?.accountName}
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Stack>
          </LinkToCustomer>
        ))}

        {results.customerDevices.map((customerDevice) => (
          <LinkToCustomerDevice
            onClick={() => {
              props.onClickResult();
            }}
            key={customerDevice.attributes?.shortId}
            customerDevice={customerDevice}
          >
            <Stack fontSize="sm" px="4" spacing="4">
              <Stack direction="row" justify="space-between" spacing="4">
                <HStack spacing="3">
                  <DeviceMakeIcon
                    boxSize="4"
                    deviceOrMake={customerDevice.attributes?.device?.data}
                  />
                  <Box>
                    <HStack>
                      <CustomerDeviceCompleteNameLabel
                        fontWeight="medium"
                        color="emphasized"
                        customerDevice={customerDevice}
                      />
                      <CustomerDeviceShortIdLabel
                        customerDevice={customerDevice}
                      />
                    </HStack>

                    <Text color="muted">
                      {
                        customerDevice.attributes?.customer?.data?.attributes
                          ?.accountName
                      }
                      {customerDevice.attributes?.serialNumber
                        ? ` - serial #: ${customerDevice.attributes?.serialNumber}`
                        : ""}
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Stack>
          </LinkToCustomerDevice>
        ))}
      </Stack>
    </Box>
  );
};
