import { useParams } from "react-router-dom";
import { Page } from "components/layout/Page";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "components/utils/Tabs";
import { OrderActivitiesContainer as OrderActivities } from "features/orders/containers/OrderActivities";
import {
  OrderDetailsProvider,
  useOrderDetails,
} from "features/orders/context/OrderDetails";
import { OrderPageSubtitle } from "features/orders/components/OrderPageSubtitle";
import { OrderPageTitle } from "features/orders/components/OrderPageTitle";
import { OrderPageActions } from "features/orders/components/OrderPageActions";
import { NotesForOrder } from "features/notes/components/NotesForOrder";

const ConnectedOrderPage = () => {
  const { isLoadingOrderDetails, order } = useOrderDetails();
  return (
    <Page
      loading={isLoadingOrderDetails}
      title={order ? <OrderPageTitle order={order} /> : "Loading Order"}
      subTitle={order ? <OrderPageSubtitle order={order} /> : "Loading Order"}
      actionButton={<OrderPageActions />}
    >
      <Tabs isLazy>
        <TabList>
          <Tab>Activity</Tab>
          <Tab>Notes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {order ? <OrderActivities order={order} /> : null}
          </TabPanel>
          <TabPanel>
            {order ? (
              <NotesForOrder showComposer={false} order={order} />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export const OrderPage = () => {
  const { orderId } = useParams();
  return orderId ? (
    <OrderDetailsProvider orderId={orderId}>
      <ConnectedOrderPage />
    </OrderDetailsProvider>
  ) : null;
};
