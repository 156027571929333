import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Make } from "data";

interface ShowForDevicesWeCanInspectProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
}

export const canInspect = ({
  device,
}: {
  device?: DeviceDataFragment | null;
}): boolean => {
  if (!device) {
    return false;
  }

  if (
    device?.attributes?.type === Enum_Device_Type.HashBoard ||
    device?.attributes?.type === Enum_Device_Type.Miner
  ) {
    return true;
  }

  if (
    device?.attributes?.type === Enum_Device_Type.Psu &&
    [Enum_Device_Make.Antminer, Enum_Device_Make.Whatsminer].indexOf(
      device.attributes.make
    ) !== -1
  ) {
    return true;
  }

  return false;
};

// Devices we suport when it comes to inspection/diagnosis
// - miners (get inspected)
// - hashboards
// - Bitmain and Whatsminer PSUs
export const ShowForDevicesWeCanInspect = (
  props: ShowForDevicesWeCanInspectProps
) => {
  const { device, children } = props;
  return canInspect({ device }) ? children : null;
};
