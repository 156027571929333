import { useSearchParams } from "react-router-dom";

import { LoginForm } from "features/accounts/components/LoginForm";
import { LayoutShell } from "features/accounts/components/LayoutShell";
import { RedirectIfLoggedIn } from "features/accounts/containers/RedirectIfLoggedIn";

const emailConfirmedSearchParam = "email-confirmed";
const redirectSearchParam = "redirect";

export const LoginPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <RedirectIfLoggedIn>
      <LayoutShell
        title="Log in to your account"
        subtitle={
          searchParams.get(emailConfirmedSearchParam) !== null
            ? "Email confirmed! Please log in to finalize your registration"
            : "Don't have an account? Please contact ACS team"
        }
      >
        <LoginForm redirect={searchParams.get(redirectSearchParam)} />
      </LayoutShell>
    </RedirectIfLoggedIn>
  );
};
