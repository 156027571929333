import { Input, InputProps } from "@chakra-ui/react";
import { extractIPAddress } from "lib";

interface IPAddressInputProps extends InputProps {}

export const IPAddressInput = (props: IPAddressInputProps) => {
  return (
    <Input
      {...props}
      onChange={(e) => {
        const value = e.currentTarget.value;
        const ipAddress = extractIPAddress(value);

        // clean up http://root:root@192.2.15.78 -> 192.2.15.78

        if (props.onChange) {
          props.onChange(
            Object.assign(
              {},
              e,
              ipAddress
                ? {
                    currentTarget: Object.assign({}, e.currentTarget, {
                      value: ipAddress,
                    }),
                  }
                : {}
            )
          );
        }
      }}
    />
  );
};
