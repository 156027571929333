import { useState, useEffect } from "react";
import { ZohoSalesOrderDataFragment, LocationDataFragment } from "data";
import { ZohoSalesOrdersSelectorContainer } from "features/orders/containers/ZohoSalesOrdersSelector";
import { LocationSelectorContainer } from "features/locations/containers/LocationSelector";
import { ZohoSalesOrderLabel } from "features/orders/components/ZohoSalesOrderLabel";
import { LocationLabel } from "features/locations/components/LocationLabel";
import { Stack } from "@chakra-ui/react";

export interface ReceiveOrderPayload {
  zohoSalesOrder: ZohoSalesOrderDataFragment | null;
  location: LocationDataFragment | null;
}

interface ReceiveOrderContainerProps {
  onChangeSelection: (selection: ReceiveOrderPayload) => void;
}

export const ReceiveOrderContainer = (props: ReceiveOrderContainerProps) => {
  const { onChangeSelection } = props;
  const [selection, setSelection] = useState<ReceiveOrderPayload>({
    zohoSalesOrder: null,
    location: null,
  });

  useEffect(() => {
    onChangeSelection(selection);
  }, [selection, onChangeSelection]);

  return (
    <>
      <Stack>
        {selection.zohoSalesOrder ? (
          <ZohoSalesOrderLabel zohoSalesOrder={selection.zohoSalesOrder} />
        ) : null}
        {selection.location ? (
          <LocationLabel location={selection.location} />
        ) : null}
      </Stack>

      {!selection.zohoSalesOrder ? (
        <ZohoSalesOrdersSelectorContainer
          onChangeSelection={(zohoSalesOrder) => {
            setSelection((s) => Object.assign({}, s, { zohoSalesOrder }));
          }}
        />
      ) : null}
      {selection.zohoSalesOrder && selection.location === null ? (
        <LocationSelectorContainer
          onLocationSelected={(location) => {
            setSelection((s) => Object.assign({}, s, { location }));
          }}
        />
      ) : null}
    </>
  );
};
