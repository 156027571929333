import { useSearchParams } from "react-router-dom";
import { Text, Button } from "@chakra-ui/react";
import { useNotifications } from "context/Notifications";
import { LayoutShell } from "features/accounts/components/LayoutShell";
import { useAuthentication } from "features/accounts/context/Authentication";

export const ConfirmEmailPage = () => {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const { resendConfirmationEmail } = useAuthentication();

  return (
    <LayoutShell title="Confirm your email address" subtitle="">
      <Text textAlign="center">Please check your inbox at {email}</Text>
      {email ? (
        <Button
          variant="outline"
          onClick={async () => {
            try {
              await resendConfirmationEmail(email);
              showSuccessMessage("Email confirmation resent!");
            } catch (err) {
              showErrorMessage("Something went wrong. Please try again later");
            }
          }}
        >
          Resend confirmation email
        </Button>
      ) : null}
    </LayoutShell>
  );
};
