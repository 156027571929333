import { useParams } from "react-router-dom";
import { Page } from "components/layout/Page";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "components/utils/Tabs";
import { OrderItemsContainer as OrderItems } from "features/order-items/containers/OrderItems";
import { Loader } from "components/Loader";
import { OrderSummaryContainer as OrderSummary } from "features/orders/containers/OrderSummary";
import { OrderActivitiesContainer as OrderActivities } from "features/orders/containers/OrderActivities";
import {
  OrderDetailsProvider,
  useOrderDetails,
} from "features/orders/context/OrderDetails";
import { OrderItemsForOrderProvider } from "features/order-items/context/OrderItemsForOrder";
import { OrderLayoutProvider } from "features/orders/context/OrderLayout";
import { LabelPrinterProvider } from "context/LabelPrinter";
import { QAActionPanelProvider } from "features/qa/context/QAActionPanel";
import { OrderPageSubtitle } from "features/orders/components/OrderPageSubtitle";
import { OrderPageTitle } from "features/orders/components/OrderPageTitle";
import { OrderPageActions } from "features/orders/components/OrderPageActions";
import { NotesForOrder } from "features/notes/components/NotesForOrder";
import { OrderSettings } from "features/orders/components/OrderSettings";

const ConnectedOrderPage = () => {
  const { isLoadingOrderDetails, order } = useOrderDetails();

  if (isLoadingOrderDetails) {
    return <Loader />;
  }
  return order ? (
    <OrderItemsForOrderProvider orderId={order.id!}>
      <Page
        loading={isLoadingOrderDetails}
        title={order ? <OrderPageTitle order={order} /> : "Loading Order"}
        subTitle={order ? <OrderPageSubtitle order={order} /> : "Loading Order"}
        actionButton={<OrderPageActions />}
      >
        <Tabs isLazy>
          <TabList>
            <Tab data-cy="order-devices-tab">Devices</Tab>
            <Tab data-cy="order-summary-tab">Summary</Tab>
            <Tab>Activity</Tab>
            <Tab>Notes</Tab>
            <Tab>Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <LabelPrinterProvider>
                <OrderItems />
              </LabelPrinterProvider>
            </TabPanel>
            <TabPanel>
              {order?.id ? (
                <OrderLayoutProvider orderId={order.id}>
                  <OrderSummary />
                </OrderLayoutProvider>
              ) : null}
            </TabPanel>
            <TabPanel>
              {order ? <OrderActivities order={order} /> : null}
            </TabPanel>
            <TabPanel>
              {order ? <NotesForOrder showComposer order={order} /> : null}
            </TabPanel>
            <TabPanel>
              {order ? <OrderSettings order={order} /> : null}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Page>
    </OrderItemsForOrderProvider>
  ) : null;
};

export const OrderPage = () => {
  const { orderId } = useParams();
  return orderId ? (
    <OrderDetailsProvider orderId={orderId}>
      <QAActionPanelProvider>
        <ConnectedOrderPage />
      </QAActionPanelProvider>
    </OrderDetailsProvider>
  ) : null;
};
