import { Code } from "@chakra-ui/react";
import { NoteDataFragment } from "data";
import { ActionCard } from "./Base";

interface NoteActionCardProps {
  note?: NoteDataFragment | null;
}

export const NoteActionCard = ({ note }: NoteActionCardProps) => {
  return note ? (
    <ActionCard>
      <Code>{note.attributes?.content}</Code>
    </ActionCard>
  ) : null;
};
