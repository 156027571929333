import { ReactElement } from "react";
import {
  useDevicesLazyQuery,
  useOrdersLazyQuery,
  useFindDeviceLazyQuery,
  useCreateCustomerDeviceMutation,
  useCreateOrderItemMutation,
  useCreateOrderItemSubcomponentMutation,
  useCreateInspectionMutation,
  useCreateInspectionRackMutation,
  Enum_Orderitem_Status,
  OrderItemDataFragment,
  Enum_Device_Type,
} from "data";
import { deviceTemplate } from "features/devices/data";

interface TestHelpersProps {
  children: ReactElement;
}

const WrappedTestHelpers = (props: TestHelpersProps) => {
  const [getOrders] = useOrdersLazyQuery();
  const [findDevice] = useFindDeviceLazyQuery();
  const [getAllDevices] = useDevicesLazyQuery();
  const [createCustomerDevice] = useCreateCustomerDeviceMutation();
  const [createOrderItem] = useCreateOrderItemMutation();
  const [createOrderItemSubcomponent] =
    useCreateOrderItemSubcomponentMutation();
  const [createInspection] = useCreateInspectionMutation();
  const [createInspectionRack] = useCreateInspectionRackMutation();

  window.testHelpers = {
    getOrderByZohoSalesOrderId(zohoSalesOrderId) {
      return getOrders().then(({ data }) => {
        return (
          data?.orders?.data.find(
            (o) =>
              o.attributes?.zoho_sales_order?.data?.attributes?.zohoId ===
              zohoSalesOrderId
          ) || null
        );
      });
    },
    getDevice({ type, make, model }) {
      return findDevice({
        variables: {
          type,
          make,
          model,
        },
      }).then(({ data }) => {
        return data?.devices?.data.length !== 0
          ? data?.devices?.data[0] || null
          : null;
      });
    },
    createCustomerDevice(input) {
      return createCustomerDevice({
        variables: {
          customer: input.customer || "",
          device: input.device || "",
          serialNumber: input.serialNumber,
          shortId: input.shortId,
        },
      }).then(({ data }) => {
        const customerDevice = data?.createCustomerDevice?.data;

        if (!customerDevice) {
          throw new Error("Failed to create customer device");
        }

        return customerDevice;
      });
    },
    createOrderItem(input) {
      return createOrderItem({
        variables: {
          data: input,
        },
      }).then(({ data }) => {
        const oi = data?.createOrderItem?.data;

        if (!oi) {
          throw new Error("Failed to create order item");
        }

        return oi;
      });
    },
    createOrderItemFromSpec(spec) {
      const { device, order, parent, status, serialNumber } = spec;

      const createOi = (
        customer_device?: string
      ): Promise<OrderItemDataFragment> => {
        return createOrderItem({
          variables: {
            data: {
              order: order.id,
              device: device.id,
              customer_device,
              status,
              parent,
            },
          },
        }).then(({ data }) => {
          if (!data?.createOrderItem?.data) {
            throw new Error("Failed to create order item");
          }

          const orderItem = data.createOrderItem.data;

          if (
            device.attributes?.type === Enum_Device_Type.Miner &&
            status !== Enum_Orderitem_Status.Receiving
          ) {
            // populate subcomponents
            return getAllDevices()
              .then(({ data }) => {
                if (!data?.devices?.data) {
                  throw new Error("Failed to load all devices");
                }

                const template = deviceTemplate(device, data.devices.data);

                return Promise.all(
                  template.map((t) => {
                    return createOrderItemSubcomponent({
                      variables: {
                        data: {
                          parent: orderItem.id,
                          device: t.device.id,
                        },
                      },
                    });
                  })
                );
              })
              .then(() => {
                return orderItem;
              });
          }

          return orderItem;
        });
      };

      return status !== Enum_Orderitem_Status.Receiving
        ? createCustomerDevice({
            variables: {
              customer: order.attributes?.customer?.data?.id || "",
              device: device.id || "",
              serialNumber,
            },
          }).then(({ data }) => {
            if (!data?.createCustomerDevice?.data) {
              throw new Error("Failed to create customer device");
            }
            return createOi(data.createCustomerDevice.data.id || undefined);
          })
        : createOi();
    },
    createInspection({
      customer,
      name,
      numberOfRacks,
      configuration,
    }: {
      customer: string;
      name: string;
      numberOfRacks: number;
      configuration: object;
    }) {
      return createInspection({
        variables: {
          data: {
            name,
            customer,
            configuration,
          },
        },
      }).then((d) => {
        const inspection = d.data?.createInspection?.data;

        if (!inspection) {
          throw new Error("Failed to create inspection");
        }

        return Promise.all(
          Array(numberOfRacks)
            .fill(null)
            .map((_, i) =>
              createInspectionRack({
                variables: {
                  data: {
                    inspection: inspection.id,
                    name: `Rack ${i + 1}`,
                    startIp: `10.33.10.${i * 100}`,
                  },
                },
              })
            )
        ).then(() => {
          return inspection;
        });
      });
    },
  };
  return (
    <>
      <div data-cy="test-helpers" />
      {props.children}
    </>
  );
};

export const TestHelpers = (props: TestHelpersProps) => {
  const { children } = props;
  // @ts-ignore
  return typeof window.Cypress !== "undefined" ? (
    <WrappedTestHelpers>{children}</WrappedTestHelpers>
  ) : (
    children
  );
};
