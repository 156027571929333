import React from "react";
import {
  Box,
  Stack,
  Grid,
  GridItem,
  GridProps,
  BoxProps,
  Checkbox,
  StackProps,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxGroupProps,
  UseCheckboxProps,
  useId,
  useStyleConfig,
} from "@chakra-ui/react";

interface CheckboxCardProps extends BoxProps {
  value: string;
  checkboxProps?: UseCheckboxProps;
  gridMode?: boolean;
}

export const CheckboxCard = (props: CheckboxCardProps) => {
  const { gridMode, checkboxProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useCheckbox(checkboxProps);
  const id = useId(undefined, "checkbox");

  const styles = useStyleConfig("CheckboxCard", props);
  const inputProps = getInputProps();
  const labelProps = getLabelProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Box
        rounded="md"
        paddingTop={2}
        borderWidth="1px"
        {...getCheckboxProps()}
        sx={styles}
        {...rest}
        height="100%"
        boxShadow={state.isChecked ? "outline" : "xs"}
      >
        <Stack direction="row">
          <Box flex="1">{children}</Box>
        </Stack>
      </Box>
    </Box>
  );
};

interface CheckboxGridCardGroupProps<T> extends Omit<GridProps, "onChange"> {
  name?: string;
  value?: T;
  defaultValue?: string[];
  onChange?: (value: T) => void;
}

export const CheckboxGridCardGroup = <T extends string[]>(
  props: CheckboxGridCardGroupProps<T>
) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getCheckboxProps } = useCheckboxGroup({
    // name,
    defaultValue,
    value,
    onChange,
  });
  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<CheckboxCardProps>>(React.isValidElement)
        .map((card) => {
          return (
            <GridItem key={card.props.value} w="100%" h="20">
              {React.cloneElement(card, {
                checkboxProps: getCheckboxProps({
                  value: card.props.value,
                }),
              })}
            </GridItem>
          );
        }),
    [children, getCheckboxProps]
  );

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} {...rest}>
      {cards}
    </Grid>
  );
};

type CheckboxCardGroupProps = StackProps & UseCheckboxGroupProps;

export const CheckboxCardGroup = (props: CheckboxCardGroupProps) => {
  const { children, defaultValue, value, onChange, ...rest } = props;
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue,
    value,
    onChange,
  });

  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<RadioCardProps>>(React.isValidElement)
        .map((card) => {
          return React.cloneElement(card, {
            checkboxProps: getCheckboxProps({
              value: card.props.value,
            }),
          });
        }),
    [children, getCheckboxProps]
  );

  return <Stack {...rest}>{cards}</Stack>;
};

interface RadioCardProps extends BoxProps {
  value: string;
  checkboxProps?: UseCheckboxProps;
}

export const CheckboxListCard = (props: RadioCardProps) => {
  const { checkboxProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useCheckbox(checkboxProps);
  const id = useId(undefined, "checkbox-card");
  const styles = useStyleConfig("RadioCard", props);

  return (
    <Box
      as="label"
      cursor="pointer"
      {...getLabelProps()}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <Box sx={styles} {...getCheckboxProps()} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          <Checkbox
            pointerEvents="none"
            isFocusable={false}
            isChecked={state.isChecked}
            alignSelf="start"
          />
        </Stack>
      </Box>
    </Box>
  );
};
