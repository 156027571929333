import { MutableRefObject } from "react";
import { CustomerDeviceDataFragment } from "data";
import { QRCode } from "components/QRCode";
import { CustomerDeviceCompleteNameLabel } from "features/customer-devices/components/CustomerDeviceCompleteNameLabel";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { PrintableLabel } from "features/customer-devices/components/PrintableLabel";
import { HStack, Box, BoxProps, Stack, Text } from "@chakra-ui/react";

interface CustomerDeviceLabelProps extends BoxProps {
  device: CustomerDeviceDataFragment;
  printableRef: MutableRefObject<null>;
}

export const CustomerDeviceLabel = (props: CustomerDeviceLabelProps) => {
  const { device, printableRef, ...rest } = props;
  return (
    <>
      <Stack alignItems="center">
        <Box mb="10" {...rest}>
          <QRCode
            size={300}
            relativeURL={`/devices/${device.attributes?.shortId}`}
          />
        </Box>

        <HStack>
          <CustomerDeviceCompleteNameLabel
            showMakeIcon
            customerDevice={device}
            fontWeight="bold"
            fontSize="lg"
          />
          <CustomerDeviceShortIdLabel customerDevice={device} />
        </HStack>
        {device.attributes?.serialNumber ? (
          <Text color="gray.500" fontSize="sm">
            {device.attributes?.serialNumber}
          </Text>
        ) : null}
        <Text fontWeight="bold" pt="4">
          {device.attributes?.customer?.data?.attributes?.accountName}
        </Text>
      </Stack>
      <PrintableLabel device={device} ref={printableRef} />
    </>
  );
};
