import { FormControl, Box, Checkbox } from "@chakra-ui/react";
import { IPAddressInput } from "components/forms/IPAddressInput";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { MinerDiagnosticsReport } from "features/diagnostics/data";

export const MinerControlBoardDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;
  return (
    <>
      <FormControl>
        <IPAddressInput
          placeholder="IP Address of the Miner"
          type="text"
          value={minerDiagnosticsReport?.ipAddress || ""}
          onChange={(e) => {
            const ipAddress = e.currentTarget.value;
            updateData(
              Object.assign({}, minerDiagnosticsReport, { ipAddress })
            );
          }}
        />
      </FormControl>
      <Box my="4">
        <Checkbox
          isChecked={minerDiagnosticsReport.noIPAddress || false}
          onChange={(e) => {
            updateData(
              Object.assign({}, minerDiagnosticsReport, {
                noIPAddress: e.currentTarget.checked,
              })
            );
          }}
        >
          No IP found
        </Checkbox>
      </Box>
    </>
  );
};
