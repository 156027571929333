import { createContext, ReactNode, useContext } from "react";
import { OrderDataFragment, useOrderDetailsQuery } from "data";

interface OrderDetailsContextData {
  isLoadingOrderDetails: boolean;
  order: OrderDataFragment | null | undefined;
  refetch: () => Promise<void>;
}

interface OrderDetailsProviderProps {
  orderId: string;
  children: ReactNode;
}

const OrderDetailsContext = createContext<OrderDetailsContextData>(
  {} as OrderDetailsContextData
);

export const useOrderDetails = () => {
  return useContext(OrderDetailsContext);
};

export const OrderDetailsProvider = (props: OrderDetailsProviderProps) => {
  const { orderId } = props;
  const {
    loading: isLoadingOrderDetails,
    data,
    refetch,
  } = useOrderDetailsQuery({
    variables: {
      orderId,
    },
  });

  return (
    <OrderDetailsContext.Provider
      value={{
        isLoadingOrderDetails,
        order: data?.orders?.data.length !== 0 ? data?.orders?.data[0] : null,
        refetch: async () => {
          refetch();
        },
      }}
    >
      {props.children}
    </OrderDetailsContext.Provider>
  );
};
