import { useState } from "react";
import { Button, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { validateEmail } from "data/helpers";

interface ForgotPasswordFormProps {
  onForgotPassword: (data: ForgotPasswordFormData) => Promise<void>;
}

interface ForgotPasswordFormData {
  email: string;
}

interface ForgotPasswordFormValidation {
  isEmailInvalid: boolean;
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { onForgotPassword } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: "",
  });
  const [validationData, setValidationData] =
    useState<ForgotPasswordFormValidation>({
      isEmailInvalid: false,
    });

  const onSubmit = async () => {
    const isEmailInvalid = !validateEmail(formData.email);
    setValidationData({
      isEmailInvalid,
    });

    if (isEmailInvalid) {
      return;
    }

    setIsLoading(true);

    try {
      await onForgotPassword(formData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <Stack spacing="5">
        <FormControl isInvalid={validationData.isEmailInvalid}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            onChange={(e) => {
              setFormData((fd) =>
                Object.assign({}, fd, { email: e.target.value })
              );
            }}
            id="email"
            type="email"
            data-cy="email"
            value={formData.email}
            errorBorderColor="crimson"
            required
          />
        </FormControl>
      </Stack>
      <Stack py="6" spacing="6">
        <Button
          data-cy="request-password-reset"
          isLoading={isLoading}
          type="submit"
          variant="primary"
        >
          Request password reset
        </Button>
      </Stack>
    </form>
  );
};
