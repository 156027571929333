import { Wizard } from "components/wizard/Wizard";
import { InspectionStep, InspectionStepType } from "features/diagnostics/data";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { DiagnosticsWizardNav } from "features/diagnostics/components/DiagnosticsWizardNav";
import { PowerDiagnostics } from "features/diagnostics/components/miners/PowerDiagnostics";
import { MinerControlBoardDiagnostics } from "features/diagnostics/components/miners/MinerControlBoardDiagnostics";
import { MinerFansDiagnostics } from "features/diagnostics/components/miners/MinerFansDiagnostics";
import { PSUFansDiagnostics } from "features/diagnostics/components/miners/PSUFansDiagnostics";
import { HashboardsDiagnostics } from "features/diagnostics/components/miners/HashboardsDiagnostics";
import { ErrorCodesDiagnostics } from "features/diagnostics/components/miners/ErrorCodesDiagnostics";
import { OtherInputsDiagnostics } from "features/diagnostics/components/miners/OtherInputsDiagnostics";
import { TemperatureSensorsDiagnostics } from "features/diagnostics/components/hashboards/TemperatureSensorsDiagnostics";
import { OpenTracesDiagnostics } from "features/diagnostics/components/hashboards/OpenTracesDiagnostics";
import { ASICsDiagnostics } from "features/diagnostics/components/hashboards/ASICsDiagnostics";
import { OtherComponentsDiagnostics } from "features/diagnostics/components/hashboards/OtherComponentsDiagnostics";
import { BoostCircuitDiagnostics } from "features/diagnostics/components/hashboards/BoostCircuitDiagnostics";
import { BasicPSUInputs } from "features/diagnostics/components/psus/BasicInputs";
import { OrderItemDataFragment } from "data";

interface RenderStepContentProps {
  step: InspectionStep;
  orderItem: OrderItemDataFragment;
  onExit: () => void;
  onGoNext: () => void;
}

const StepContent = (props: RenderStepContentProps) => {
  const { step, orderItem } = props;
  switch (step.type) {
    case InspectionStepType.Power:
      return <PowerDiagnostics />;
    case InspectionStepType.ControlBoard:
      return <MinerControlBoardDiagnostics />;
    case InspectionStepType.MinerFans:
      return <MinerFansDiagnostics />;
    case InspectionStepType.PSUFans:
      return <PSUFansDiagnostics />;
    case InspectionStepType.Hashboards:
      return <HashboardsDiagnostics orderItem={orderItem} />;
    case InspectionStepType.Errors:
      return <ErrorCodesDiagnostics orderItem={orderItem} />;
    case InspectionStepType.OtherInputs:
      return <OtherInputsDiagnostics orderItem={orderItem} />;
    case InspectionStepType.TemperatureSensors:
      return <TemperatureSensorsDiagnostics />;
    case InspectionStepType.OpenTraces:
      return <OpenTracesDiagnostics />;
    case InspectionStepType.ASICs:
      return <ASICsDiagnostics />;
    case InspectionStepType.OtherComponentsNeeded:
      return <OtherComponentsDiagnostics />;
    case InspectionStepType.BoostCircuit:
      return <BoostCircuitDiagnostics />;
    case InspectionStepType.BasicPSUInputs:
      return <BasicPSUInputs />;
    default:
      return null;
  }
};

interface DiagnosticsWizardProps {
  onExit: () => void;
  orderItem: OrderItemDataFragment;
}

export const DiagnosticsWizard = (props: DiagnosticsWizardProps) => {
  const { onExit, orderItem } = props;
  const { steps, currentStep, goToNextStep } = useDiagnosticsWizard();

  return (
    <Wizard
      steps={steps}
      currentStep={currentStep}
      renderCurrentStep={(currentStep) => {
        return currentStep ? (
          <StepContent
            orderItem={orderItem}
            step={currentStep as InspectionStep}
            onExit={() => {
              onExit();
            }}
            onGoNext={() => {
              goToNextStep();
            }}
          />
        ) : null;
      }}
      renderNav={() => (
        <DiagnosticsWizardNav
          orderItem={orderItem}
          onExit={() => {
            onExit();
          }}
        />
      )}
    />
  );
};
