import { OrderItemDataFragment, Enum_Orderitemaction_Action } from "data";
import { CleanIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useRegisterOrderItemAction } from "features/order-items/data/hooks/order-item-actions";

interface CleanActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const CleanAction = (props: CleanActionProps) => {
  const { orderItem, variant } = props;
  const { registerAction } = useRegisterOrderItemAction();
  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await registerAction({
            orderItem,
            action: {
              action: Enum_Orderitemaction_Action.Clean,
            },
          });
        }
      }}
      variant={variant}
      actionIcon={<CleanIcon />}
      actionLabel="Clean"
      data-cy="action-clean"
    />
  );
};

CleanAction.defaultProps = {
  variant: "menu-item",
};
