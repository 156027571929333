import { useContext, ReactNode, useState } from "react";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";
import { Loader } from "components";
import { IntakeForm } from "features/customers/components/IntakeForm";
import { IntakeFormSuccessMessage } from "../components/IntakeSuccessMessage";
import { useSubmitCustomerIntakeForm } from "features/customers/data/hooks/intakeform";
import { useCurrentCustomerProfile } from "features/customers/data/hooks/customers";
import { useNotifications } from "context/Notifications";
import { CustomerIntakeFormDataFragment } from "data";

interface CustomerIntakFormPanelData {}

const customerIntakeFormPanel =
  generateContextAndProvider<CustomerIntakFormPanelData>();

export const useCustomerInakeFormActionPanel =
  (): ActionPanelContextData<CustomerIntakFormPanelData> => {
    const context = useContext(customerIntakeFormPanel.Context);

    if (!context) {
      throw new Error(
        "Calling useCustomerInakeFormActionPanel outside of CustomerIntakeFormPanelProvider"
      );
    }

    return context;
  };

export const WrappedReceiveDevicePanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { submitIntakeForm } = useSubmitCustomerIntakeForm();
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { loading, customer } = useCurrentCustomerProfile();
  const [customerIntakeData, setCustomerIntakeData] =
    useState<CustomerIntakeFormDataFragment | null>(null);

  return (
    <customerIntakeFormPanel.Provider
      onClose={() => {}}
      onAction={async () => {}}
      renderHeader={() => null}
      renderBody={() => {
        return loading || !customer ? (
          <Loader />
        ) : (
          <>
            {!customerIntakeData ? (
              <IntakeForm
                customer={customer}
                onSubmit={async ({ data, manifestFile }) => {
                  try {
                    const d = await submitIntakeForm({ data, manifestFile });
                    setCustomerIntakeData(d);

                    showSuccessMessage(
                      "We got your request and will be in touch!"
                    );
                  } catch {
                    showErrorMessage(
                      "Cannot process your request at this time"
                    );
                  }
                }}
              />
            ) : (
              <IntakeFormSuccessMessage
                customerIntakeData={customerIntakeData}
              />
            )}
          </>
        );
      }}
      renderFooter={() => null}
    >
      {children}
    </customerIntakeFormPanel.Provider>
  );
};

export const CustomerIntakeFormPanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <WrappedReceiveDevicePanelProvider>
      {children}
    </WrappedReceiveDevicePanelProvider>
  );
};
