import { Box, Button, Divider, Stack } from "@chakra-ui/react";
import { CheckboxGridCardGroup, CheckboxCard } from "components/forms/CheckboxCardGroup";
import { Select } from "components/forms/Select";
import { DeviceDataFragment } from "data";
import { DeviceTypeIcon, DeviceCompleteNameLabel } from "features/devices/components";

export interface DeviceLayoutItem {
  id: string;
  device: DeviceDataFragment;
  isSelected: boolean;
}

interface MinerDetailsProps {
  device: DeviceDataFragment;
  devices: DeviceLayoutItem[];
  supportedHashrates?: string[];
  hashrate?: string;
  onChangeDevices: (selectedDevices: DeviceLayoutItem[]) => void;
  onChangeHashrate: (hashrate: string) => void;
  onNext: () => void;
  isComplete: boolean;
}

export const MinerDetails = (props: MinerDetailsProps) => {
  const {
    device,
    devices,
    onChangeDevices,
    onChangeHashrate,
    isComplete,
    onNext,
    supportedHashrates,
    hashrate,
  } = props;

  return (
    <Box p={4}>
      <Stack spacing={10}>
        {supportedHashrates ? (
          <Select
            onChange={(e) => {
              onChangeHashrate(e.currentTarget.value);
            }}
            value={hashrate}
          >
            {(device.attributes?.supportedHashrates as string[]).map((hashrate) => (
              <option value={hashrate}>{hashrate}</option>
            ))}
          </Select>
        ) : null}
        <CheckboxGridCardGroup
          onChange={(value) => {
            onChangeDevices(
              devices.map((d) => {
                d.isSelected = value.indexOf(d.id) !== -1;
                return d;
              })
            );
          }}
          value={devices.filter((d) => d.isSelected).map((d) => d.id)}
        >
          {devices.map((d) => {
            return (
              <CheckboxCard key={d.id} value={d.id}>
                <Stack alignItems={"center"}>
                  <DeviceTypeIcon deviceType={d.device.attributes?.type} />
                  <DeviceCompleteNameLabel device={d.device} />
                </Stack>
              </CheckboxCard>
            );
          })}
        </CheckboxGridCardGroup>
      </Stack>
      <Divider my={4} />
      <Box py={2}>
        <Button
          onClick={() => {
            onNext();
          }}
          width='100%'
          size='sm'
        >
          Proceed with {isComplete ? "complete" : "incomplete"} miner
        </Button>
      </Box>
    </Box>
  );
};
