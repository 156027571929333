import {
  Center,
  HStack,
  Stack,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "components/Icons";
import { AppVersion } from "components/utils/AppVersion";
import { ShowForAllTechs } from "features/accounts";

export const FooterLinks = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const links = (
    <>
      <ShowForAllTechs>
        <>
          <Link
            fontSize="smaller"
            href="https://acs-metabase.herokuapp.com/dashboard/1-acs-control-center?date_filter=past7days&team_member=Aaron"
            isExternal
          >
            Stats <ExternalLinkIcon mx="2px" />
          </Link>
          <Link fontSize="smaller" href="/changelogs" isExternal>
            Changelogs <ExternalLinkIcon mx="2px" />
          </Link>
        </>
      </ShowForAllTechs>
      <AppVersion />
    </>
  );

  return isDesktop ? (
    <Center mt="4">
      <HStack spacing="8">{links}</HStack>
    </Center>
  ) : (
    <Stack ml="4">{links}</Stack>
  );
};
