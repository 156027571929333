import groupBy from "lodash.groupby";
import { Stack } from "@chakra-ui/react";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "components/utils/Table";
import {
  DeviceDataFragment,
  OrderLayoutItemDataFragment,
  OrderDataFragment,
} from "data";
import {
  DeviceTypeLabel,
  DeviceCompleteNameLabel,
} from "features/devices/components";
import { OrderStatsForCustomer } from "features/orders/components/OrderStatsForCustomer";

interface OrderSummaryProps {
  order: OrderDataFragment;
  orderLayoutItems: OrderLayoutItemDataFragment[];
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const { order, orderLayoutItems } = props;
  const groupedLayoutItems = Object.values(
    groupBy(orderLayoutItems, (oli) => oli.attributes?.device?.data?.id)
  );

  return (
    <Stack spacing="10">
      <OrderStatsForCustomer order={order} />
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Device</Th>
              <Th>Type</Th>
              <Th>Quantity</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupedLayoutItems.map((items) => {
              const orderLayoutItem = items[0];
              const device = orderLayoutItem.attributes?.device
                ?.data as DeviceDataFragment;
              return (
                <Tr
                  data-order-summary-device={`${device?.attributes?.type}-${device?.attributes?.make}-${device?.attributes?.model}`}
                  data-cy="order-summary-table-entry"
                  key={orderLayoutItem.id}
                >
                  <Td>
                    <DeviceCompleteNameLabel showMakeIcon device={device} />
                  </Td>
                  <Td>
                    <DeviceTypeLabel
                      showTypeIcon
                      deviceType={
                        orderLayoutItem.attributes?.device?.data?.attributes
                          ?.type
                      }
                    />
                  </Td>
                  <Td data-cy="order-summary-table-entry-quantity">
                    {items.reduce(
                      (total, it) => total + (it.attributes?.quantity || 0),
                      0
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
