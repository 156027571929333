import { OrderItemDataFragment, Enum_Orderitemaction_Action } from "data";
import { TemperatureIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useRegisterOrderItemAction } from "features/order-items/data/hooks/order-item-actions";

interface ApplyThermalPasteActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const ApplyThermalPasteAction = (
  props: ApplyThermalPasteActionProps
) => {
  const { orderItem, variant } = props;
  const { registerAction } = useRegisterOrderItemAction();
  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await registerAction({
            orderItem,
            action: {
              action: Enum_Orderitemaction_Action.ApplyThermalPaste,
            },
          });
        }
      }}
      variant={variant}
      actionIcon={<TemperatureIcon />}
      actionLabel="Apply thermal paste"
      data-cy="apply-thermal-paste"
    />
  );
};

ApplyThermalPasteAction.defaultProps = {
  variant: "menu-item",
};
