import * as Yup from "yup";
import { Button, Stack, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { OrderItemDataFragment, PsuRepairInput } from "data";

import { YesNoButtons, SelectTime } from "components/forms/fields";
import { PSURepairReportPreview } from "features/repairs/components/psu/PSURepairReportPreview";

interface PSURepairSummaryFormProps {
  orderItem: OrderItemDataFragment;
  report: PsuRepairInput;
  onSubmit: (report: PsuRepairInput) => Promise<void>;
  onPrevious: () => void;
}

export const PSURepairSummaryForm = (props: PSURepairSummaryFormProps) => {
  const { orderItem, report, onSubmit, onPrevious } = props;
  return (
    <Formik
      initialValues={{
        isSuccessful: true,
        repairTimeMinutes: 15,
      }}
      validationSchema={Yup.object().shape({
        isSuccessful: Yup.boolean().required(),
        repairTimeMinutes: Yup.number().required(),
      })}
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(
          Object.assign({}, values, {
            repairTimeMinutes: parseInt(`${values.repairTimeMinutes}`),
          })
        );
      }}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <Form>
          <Stack>
            <PSURepairReportPreview orderItem={orderItem} report={report} />
            <Stack mt={4} px={4}>
              <YesNoButtons
                label="Was repair successful?"
                name="isSuccessful"
              />
              <SelectTime
                name="repairTimeMinutes"
                label="How much time did repair take?"
                placeholder="Select time"
                start={15}
                end={150}
                step={15}
              />
              <strong>{isValid}</strong>
              <HStack mt={8}>
                <Button
                  isDisabled={isSubmitting}
                  onClick={() => {
                    onPrevious();
                  }}
                  variant="outline"
                >
                  Previous
                </Button>
                <Button
                  isDisabled={isSubmitting}
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
