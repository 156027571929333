import { HStack, Show } from "@chakra-ui/react";
import { OrderDataFragment } from "data";
import { PageHeader } from "components/layout/Page";
import { OrderStatusBadge } from "features/orders/components/OrderStatusBadge";
import { OrderStatsBadge } from "features/orders/components/OrderStats";
import { completeOrderName } from "features/orders/data";

interface OrderPageTitleProps {
  order: OrderDataFragment;
}

export const OrderPageTitle = (props: OrderPageTitleProps) => {
  const { order } = props;
  return (
    <>
      <HStack>
        <Show above="lg">
          <OrderStatsBadge order={order} />
        </Show>
        <PageHeader>
          {completeOrderName(order)} <OrderStatusBadge orderData={order} />
        </PageHeader>
      </HStack>
    </>
  );
};
