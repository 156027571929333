import {
  CustomerDeviceDataFragment,
  DeviceDataFragment,
  OrderDataFragment,
  useCreateCustomerDeviceMutation,
  useCustomerDeviceByIdQuery,
} from "data";

interface NewCustomerDeviceInput {
  serialNumber?: string;
  order: OrderDataFragment;
  device: DeviceDataFragment;
}

interface UserRegisterNewCustomerDeviceHookData {
  registerNewCustomerDevice: (
    input: NewCustomerDeviceInput
  ) => Promise<CustomerDeviceDataFragment | null>;
}

export const useRegisterNewCustomerDevice =
  (): UserRegisterNewCustomerDeviceHookData => {
    const [createCustomerDevice] = useCreateCustomerDeviceMutation();
    return {
      registerNewCustomerDevice: async (input: NewCustomerDeviceInput) => {
        const { serialNumber, order, device } = input;
        const customer = order.attributes?.customer?.data?.id;

        if (!customer) {
          return null;
        }

        const result = await createCustomerDevice({
          variables: {
            serialNumber,
            device: device.id || "",
            customer,
          },
        });

        return result?.data?.createCustomerDevice?.data || null;
      },
    };
  };

interface UseCustomerDeviceByIdHookData {
  loading: boolean;
  customerDevice?: CustomerDeviceDataFragment | null;
  activeOrderItemId?: string | null;
}

interface UseCustomerDeviceByIdProps {
  shortId: string;
}

export const useCustomerDeviceById = (
  props: UseCustomerDeviceByIdProps
): UseCustomerDeviceByIdHookData => {
  const { shortId } = props;
  const { loading, data } = useCustomerDeviceByIdQuery({
    variables: {
      shortId,
    },
  });
  const orderItems = data?.orderItems?.data || [];

  return {
    loading,
    customerDevice: (data?.customerDevices?.data || []).find(
      (cd) => cd.attributes?.shortId === shortId
    ),
    activeOrderItemId: orderItems.length !== 0 ? orderItems[0].id : null,
  };
};
