import {
  ControlBoardQaReportInput,
  HashboardQaReportInput,
  MinerQaReportInput,
  PsuQaReportInput,
  OrderItemDataFragment,
  Enum_Device_Type,
} from "data";

export type QAReportInput =
  | ControlBoardQaReportInput
  | HashboardQaReportInput
  | MinerQaReportInput
  | PsuQaReportInput;

export const getInitialQAReport = (
  orderItem?: OrderItemDataFragment | null
): QAReportInput => {
  if (
    orderItem?.attributes?.device?.data?.attributes?.type !==
    Enum_Device_Type.Psu
  ) {
    return {
      pass: true,
      notes: "",
    };
  }

  return {
    pass: true,
    notes: "",
    fanPower: true,
    railPower: true,
  };
};

export const validateQAReport = (
  report: QAReportInput,
  orderItem?: OrderItemDataFragment | null
): boolean => {
  return true;
};
