import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export interface CreateOrderPackageFormData {
  name: string;
}

interface CreateOrderPackageFormProps {
  formData: CreateOrderPackageFormData;
  onChange: (formData: CreateOrderPackageFormData) => void;
}

export const CreateOrderPackageForm = (props: CreateOrderPackageFormProps) => {
  const { formData, onChange } = props;
  return (
    <FormControl>
      <FormLabel>Package Name</FormLabel>
      <Input
        data-cy="order-package-name"
        autoFocus
        onChange={(e) => {
          onChange(
            Object.assign({}, formData, { name: e.currentTarget.value })
          );
        }}
        value={formData.name}
        type="text"
      />
    </FormControl>
  );
};
