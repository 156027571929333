import { ReactElement } from "react";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Make } from "data";

interface ShowForMinerProps {
  children: ReactElement;
  device?: DeviceDataFragment | null;
  makes?: Enum_Device_Make[];
}

export const ShowForMiner = (props: ShowForMinerProps) => {
  const { device, makes, children } = props;

  if (device?.attributes?.type !== Enum_Device_Type.Miner) {
    return null;
  }

  if (makes && makes.indexOf(device.attributes.make) === -1) {
    return null;
  }

  return children;
};
