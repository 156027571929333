import { HStack } from "@chakra-ui/react";
import { ShipIcon } from "components/Icons";
import { DaysLeftText } from "components/utils/DaysLeftText";
import { Enum_Order_Status, OrderDataFragment } from "data";

interface ExpectedShipmentDateLabelProps {
  order: OrderDataFragment;
}

export const ExpectedShipmentDateLabel = (
  props: ExpectedShipmentDateLabelProps
) => {
  const { order } = props;
  return order.attributes?.status !== Enum_Order_Status.Complete &&
    order.attributes?.expectedShipmentDate ? (
    <HStack>
      <ShipIcon />
      <DaysLeftText
        value={order.attributes?.expectedShipmentDate}
        suffix="left to ship"
        overdueSuffix="overdue"
        zeroSuffix="Due today"
      />
    </HStack>
  ) : null;
};
