import { Text } from "@chakra-ui/react";
import { Enum_Device_Make, OrderItemDataFragment } from "data";
import { MinerDiagnosticsReport } from "features/diagnostics/data";
import {
  CheckboxListCard,
  CheckboxCardGroup,
} from "components/forms/CheckboxCardGroup";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import antminerErrorCodes from "features/diagnostics/data/antminer-error-codes.json";
import whatsminerErrorCodes from "features/diagnostics/data/whatsminer-error-codes.json";

interface ErrorCode {
  code: string;
  reason: string;
}

const getAvailableErrorCodes = (
  orderItem: OrderItemDataFragment
): ErrorCode[] => {
  const make = orderItem.attributes?.device?.data?.attributes?.make;

  if (make === Enum_Device_Make.Antminer) {
    return antminerErrorCodes;
  }

  if (make === Enum_Device_Make.Whatsminer) {
    return whatsminerErrorCodes;
  }

  return [];
};

interface ErrorCodesDiagnosticsProps {
  orderItem: OrderItemDataFragment;
}

export const ErrorCodesDiagnostics = (props: ErrorCodesDiagnosticsProps) => {
  const { orderItem } = props;
  const { data, updateData } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;
  const avaiableErrorCodes = orderItem ? getAvailableErrorCodes(orderItem) : [];

  return orderItem?.attributes?.device?.data?.attributes?.make ===
    Enum_Device_Make.Avalon ? null : (
    <CheckboxCardGroup
      onChange={(value) => {
        updateData(
          Object.assign({}, minerDiagnosticsReport, {
            errorCodes: value,
          })
        );
      }}
      value={minerDiagnosticsReport?.errorCodes}
    >
      {avaiableErrorCodes.map((errorCode) => {
        return (
          <CheckboxListCard key={errorCode.code} value={errorCode.code}>
            <Text color="emphasized" fontWeight="medium" fontSize="sm">
              {errorCode.code}
            </Text>
            <Text color="muted" fontSize="sm">
              {errorCode.reason}
            </Text>
          </CheckboxListCard>
        );
      })}
    </CheckboxCardGroup>
  );
};
