import { Button, HStack, Spacer } from "@chakra-ui/react";
import { useField, FieldAttributes } from "formik";
import { Label } from "./Label";
// import { ErrorMessage } from "./ErrorMessage";

export const YesNoButtons = ({
  label,
  type,
  ...props
}: FieldAttributes<any>) => {
  const [field, , helpers] = useField(props);
  return (
    <HStack>
      <Label>{label}</Label>
      <Spacer />
      <HStack spacing={4}>
        <Button
          data-cy={`${field.name}_yes`}
          minWidth={90}
          textTransform="uppercase"
          onClick={() => {
            helpers.setValue(true);
          }}
          size="sm"
          variant="outline"
          colorScheme={field.value === true ? "blue" : "gray"}
          {...(field.value === true ? { backgroundColor: "blue.50" } : {})}
        >
          Yes
        </Button>
        <Button
          data-cy={`${field.name}_no`}
          minWidth={90}
          textTransform="uppercase"
          onClick={() => {
            helpers.setValue(false);
          }}
          size="sm"
          variant="outline"
          colorScheme={field.value === false ? "blue" : "gray"}
          {...(field.value === false ? { backgroundColor: "blue.50" } : {})}
        >
          No
        </Button>
      </HStack>

      {/* {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null} */}
    </HStack>
  );
};
