import { useState } from "react";
import {
  Badge,
  BadgeProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  Enum_Orderitem_Status,
  Enum_Device_Type,
} from "data";
import {
  orderItemStatusToString,
  _orderItemStatusToString,
} from "features/order-items/data";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";
import { Loader } from "components/Loader";

interface OrderItemStatusBadgeProps extends BadgeProps {
  orderItem: OrderItemDataFragment;
  editable?: boolean;
}

const statusListForOrderItem = (
  orderItem: OrderItemDataFragment
): Enum_Orderitem_Status[] => {
  const basics = [
    Enum_Orderitem_Status.DiagnosisPending,
    Enum_Orderitem_Status.QaFail,
    Enum_Orderitem_Status.QaPass,
  ];

  if (
    orderItem.attributes?.device?.data?.attributes?.type ===
    Enum_Device_Type.Miner
  ) {
    // we want to support a few additional statuses for miners
    // to enable marking them as "Repaired" or "Unrepairable"
    // these manual settings are needed since they are largely
    // based on the miner's subcomponents
    return [
      ...basics,
      Enum_Orderitem_Status.RepairedNeedsQa,
      Enum_Orderitem_Status.DiagnosedUnrepairable,
    ];
  }

  return basics;
};

export const OrderItemStatusBadge = (props: OrderItemStatusBadgeProps) => {
  const { orderItem, editable, ...rest } = props;
  const { updateOrderItemStatus } = useUpdateOrderItemStatus();
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return <Loader />;
  }

  return !editable ? (
    <Badge
      data-order-item-id={orderItem.id}
      data-cy="order-item-status-badge"
      data-order-item-status={orderItem.attributes?.status}
      {...rest}
    >
      {orderItemStatusToString(orderItem)}
    </Badge>
  ) : (
    <Menu>
      <MenuButton data-cy="edit-order-item-status-badge">
        <Badge
          data-order-item-id={orderItem.id}
          data-cy="order-item-status-badge"
          data-order-item-status={orderItem.attributes?.status}
          {...rest}
        >
          {orderItemStatusToString(orderItem)}
        </Badge>
      </MenuButton>
      <MenuList>
        {statusListForOrderItem(orderItem)
          .filter((s) => s !== orderItem.attributes?.status)
          .map((s) => (
            <MenuItem
              key={s}
              onClick={async () => {
                if (orderItem.id) {
                  try {
                    setLoading(true);
                    await updateOrderItemStatus(orderItem, s);
                  } finally {
                    setLoading(false);
                  }
                }
              }}
            >
              {_orderItemStatusToString(
                s,
                orderItem.attributes?.device?.data?.attributes?.type
              )}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};
