import { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import { Loader } from "components";
import { useInspectionRacks } from "features/inspections/context/InspectionRacks";
import { InspectionRackProvider } from "features/inspections/context/InspectionRack";
import { InspectionRack } from "features/inspections/components/InspectionRack";
import { useMinerScanner } from "../data/hooks";

export const InspectionRacks = () => {
  const { healthCheck } = useMinerScanner();
  const { loading, racks } = useInspectionRacks();

  useEffect(() => {
    const runScannerHealthcheck = async () => {
      const r = await healthCheck();
      if (!r) {
        alert("Looks like miner scanner is down. Please check the server");
      }
    };
    runScannerHealthcheck();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Tabs isLazy>
      <TabList>
        {racks.map((r) => (
          <Tab key={r.id}>{r.attributes?.name}</Tab>
        ))}
      </TabList>

      <TabPanels>
        {racks.map((r) => (
          <TabPanel key={r.id}>
            <InspectionRackProvider rack={r}>
              <InspectionRack rack={r} />
            </InspectionRackProvider>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
