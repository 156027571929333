import { useSearchParams } from "react-router-dom";

interface UseDataSourceSearchParamsReturns<T> {
  fromURLSearchParams: () => T;
  toURLSearchParams: (params: T) => void;
}

interface UseDataSourceSearchParamsProps<T> {
  parseFilters: (searchParams: Array<[string, string?]>) => T;
  serializeFilters: (filters: T) => Array<[string, string?]>;
}

export function useDataSourceSearchParams<T>(
  props: UseDataSourceSearchParamsProps<T>
): UseDataSourceSearchParamsReturns<T> {
  const { parseFilters, serializeFilters } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    fromURLSearchParams() {
      const data: Array<[string, string]> = [];
      searchParams.forEach((value, key) => {
        data.push([key, value]);
      });
      return parseFilters(data);
    },
    toURLSearchParams(params) {
      serializeFilters(params).forEach(([k, v]) => {
        if (typeof v !== "undefined") {
          searchParams.set(k, v);
        } else {
          searchParams.delete(k);
        }

        setSearchParams(searchParams);
      });
    },
  };
}
