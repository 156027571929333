import { OrderItemDataFragment } from "data";
import { QAIcon } from "components/Icons";
import { ActionComponent, ActionComponentVariant } from "components/ActionComponent";
import { useQAActionPanel } from "features/qa/context/QAActionPanel";

interface QAActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const QAAction = (props: QAActionProps) => {
  const { orderItem, variant } = props;
  const { showActionPanel } = useQAActionPanel();
  return (
    <ActionComponent
      action={async () => {
        showActionPanel({ orderItem });
      }}
      variant={variant}
      actionIcon={<QAIcon />}
      actionLabel='Register QA'
      data-cy='qa-action-button'
    />
  );
};

QAAction.defaultProps = {
  variant: "button",
};
