import * as Yup from "yup";
import { Button, Stack, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import {
  OrderItemDataFragment,
  PsuInspectionReportInput,
  Enum_Device_Make,
} from "data";
import { YesNoButtons } from "components/forms/fields";
import { ShowForMake } from "features/devices/components/ShowForMake";

interface PSUDiagnosticsFormProps {
  report: PsuInspectionReportInput | null;
  orderItem: OrderItemDataFragment;
  onSubmit: (report: PsuInspectionReportInput) => void;
}

const getSchema = (orderItem: OrderItemDataFragment) => {
  if (
    orderItem.attributes?.device?.data?.attributes?.make ===
    Enum_Device_Make.Whatsminer
  ) {
    return Yup.object().shape({
      inputFusesOk: Yup.boolean().required(),
      inrushCurrentLimitersOk: Yup.boolean().required(),
      mosfetsOk: Yup.boolean().required(),
      bridgesOk: Yup.boolean().required(),
      relaysOk: Yup.boolean().required(),
      capacitorsOk: Yup.boolean().required(),
      icsOk: Yup.boolean().required(),
    });
  }

  return Yup.object().shape({
    inputFusesOk: Yup.boolean().required(),
    inrushCurrentLimitersOk: Yup.boolean().required(),
    mosfetsOk: Yup.boolean().required(),
    powerDiodesOk: Yup.boolean().required(),
    u25Ok: Yup.boolean().required(),
  });
};

const getInitialValues = (orderItem: OrderItemDataFragment) => {
  if (
    orderItem.attributes?.device?.data?.attributes?.make ===
    Enum_Device_Make.Whatsminer
  ) {
    return {
      inputFusesOk: false,
      inrushCurrentLimitersOk: false,
      mosfetsOk: false,
      bridgesOk: false,
      relaysOk: false,
      capacitorsOk: false,
      icsOk: false,
    };
  }

  return {
    inputFusesOk: false,
    inrushCurrentLimitersOk: false,
    mosfetsOk: false,
    powerDiodesOk: false,
    u25Ok: false,
  };
};

export const PSUDiagnosticsForm = (props: PSUDiagnosticsFormProps) => {
  const { orderItem, onSubmit, report } = props;
  return (
    <Formik
      initialValues={report || getInitialValues(orderItem)}
      validationSchema={getSchema(orderItem)}
      validateOnBlur
      onSubmit={async (values) => {
        onSubmit(values);
      }}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <Form>
          <Stack px={4}>
            <ShowForMake
              device={orderItem.attributes?.device?.data}
              make={Enum_Device_Make.Antminer}
            >
              <>
                <YesNoButtons
                  label="Are both input fuses OK?"
                  name="inputFusesOk"
                />
                <YesNoButtons
                  label="Are 4 inrush current limiters OK?"
                  name="inrushCurrentLimitersOk"
                />
                <YesNoButtons label="Are 6 MOSFETS OK?" name="mosfetsOk" />
                <YesNoButtons
                  label="Are 4 power diodes OK?"
                  name="powerDiodesOk"
                />
                <YesNoButtons label="Is U25 OK?" name="u25Ok" />
              </>
            </ShowForMake>
            <ShowForMake
              device={orderItem.attributes?.device?.data}
              make={Enum_Device_Make.Whatsminer}
            >
              <>
                <YesNoButtons
                  label="Are both input fuses OK?"
                  name="inputFusesOk"
                />
                <YesNoButtons
                  label="Are both inrush current limiters OK?"
                  name="inrushCurrentLimitersOk"
                />
                <YesNoButtons label="Are all 8 MOSFETS OK?" name="mosfetsOk" />
                <YesNoButtons label="Are both bridges OK?" name="bridgesOk" />
                <YesNoButtons label="Is relay OK?" name="relaysOk" />
                <YesNoButtons
                  label="Are all 8 capacitors OK?"
                  name="capacitorsOk"
                />
                <YesNoButtons label="Are all 4 ICs OK?" name="icsOk" />
              </>
            </ShowForMake>
            <HStack mt={8}>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </HStack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

// "inputFusesOk": {
//     "type": "boolean"
// },
// "inrushCurrentLimitersOk": {
//     "type": "boolean"
// },
// "mosfetsOk": {
//     "type": "boolean"
// },
// "powerDiodesOk": {
//     "type": "boolean"
// },
// "bridgesOk": {
//     "type": "boolean"
// },
// "relaysOk": {
//     "type": "boolean"
// },
// "capacitorsOk": {
//     "type": "boolean"
// },
// "icsOk": {
//     "type": "boolean"
// },
// "d25Ok": {
//     "type": "boolean"
// },
// "u25Ok": {
//     "type": "boolean"
// },
// "order_item": {
//     "type": "relation",
//     "relation": "oneToOne",
//     "target": "api::order-item.order-item"
// },
// "isOk": {
//     "type": "boolean"
// },
// "timeOfDiagnosisMinutes": {
//     "type": "integer"
// }
