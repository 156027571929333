import { BiBriefcase } from "react-icons/bi";
import { Text, HStack } from "@chakra-ui/react";
import { ZohoSalesOrderDataFragment } from "data";

interface ZohoSalesOrderLabelProps {
  zohoSalesOrder: ZohoSalesOrderDataFragment;
}

export const ZohoSalesOrderLabel = (props: ZohoSalesOrderLabelProps) => {
  const { zohoSalesOrder } = props;
  return (
    <HStack>
      <BiBriefcase />
      <Text>
        {zohoSalesOrder.attributes?.accountName} -{" "}
        {zohoSalesOrder.attributes?.salesOrderNumber}
      </Text>
    </HStack>
  );
};
