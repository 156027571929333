import { Text, TextProps } from "@chakra-ui/react";
import moment from "moment";

interface DaysLeftTextProps extends TextProps {
  value: string;
  suffix: string;
  overdueSuffix: string;
  zeroSuffix: string;
}

export const DaysLeftText = (props: DaysLeftTextProps) => {
  const { value, suffix, overdueSuffix, zeroSuffix, ...rest } = props;
  const diff = moment(value).diff(moment(), "days");

  if (diff === 0) {
    return <Text {...rest}>{zeroSuffix}</Text>;
  }

  const daysTxt = Math.abs(diff) > 1 ? "days" : "day";

  return (
    <Text {...rest}>
      {diff > 0
        ? `${diff} ${daysTxt} ${suffix}`
        : `${Math.abs(diff)} ${daysTxt} ${overdueSuffix}`}
    </Text>
  );
};
