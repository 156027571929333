import {
  Enum_Orderitem_Status,
  useCreateMinerInspectionReportMutation,
  useUpdateMinerInspectionReportMutation,
  useCreateHashboardInspectionReportMutation,
  useUpdateHashboardInspectionReportMutation,
  useCreatePsuInspectionReportMutation,
  useUpdatePsuInspectionReportMutation,
  useMinerInspectionReportsForOrderItemQuery,
  useLatestHashboardInspectionReportsForOrderItemQuery,
  OrderItemDataFragment,
  MinerInspectionReportDataFragment,
  MinerInspectionReportInput,
  HashboardInspectionReportDataFragment,
  HashboardInspectionReportInput,
  HashboardAdvancedInspectionReportInput,
  MinerInspectionReportsForOrderItemDocument,
  PsuInspectionReportsForOrderItemDocument,
  PsuInspectionReportInput,
  HashboardInspectionReportsForOrderItemDocument,
  useHashboardInspectionReportsForOrderItemQuery,
  usePsuInspectionReportsForOrderItemQuery,
  useCreateHashboardAdvancedInspectionReportMutation,
  PsuInspectionReportDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
} from "data";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";
import {
  MinerDiagnosticsReport,
  HashboardDiagnosticsReport,
  PSUDiagnosticsReport,
  DiagnosticsReport,
  InspectionReportWithActionContext,
  InspectionReportData,
} from "features/diagnostics/data";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";

interface UseCreateInspectionReportHookData<T> {
  createReport: ({
    report,
    orderItem,
    isOk,
  }: {
    report: T;
    orderItem: OrderItemDataFragment;
    isOk: boolean;
  }) => Promise<void>;
}

interface UseUpdateInspectionReportHookData<T> {
  updateReport: ({
    report,
    orderItem,
    isOk,
  }: {
    report: T;
    orderItem: OrderItemDataFragment;
    isOk: boolean;
  }) => Promise<void>;
}

const prepareMinerInspectionReport = (
  orderItem: OrderItemDataFragment,
  report: MinerDiagnosticsReport,
  isOk: boolean
): MinerInspectionReportInput => {
  return {
    isOk,
    order_item: orderItem.id,
    power: report.power,
    ipAddress: report.ipAddress,
    minerFanCount: report.minerFans.filter((mf) => mf.isOn).length,
    psuFanCount: report.psuFans.filter((pf) => pf.isOn).length,
    errorCodes: report.errorCodes,
    finalHashrate: report.finalHashrate,
    timeHashing: report.timeHashing,
    controlBoardTemperature: report.controlBoardTemperature,
    notes: report.notes,
    hashboards: report.hashboards.map((h) => ({
      hashrate: h.hashrate,
      isGood: h.isGood,
      numberOfASICs: h.numberOfASICs,
      temperature: h.temperature,
    })),
    suggestedProblems: report.suggestedProblems,
    attachments: report.attachments
      .map((a) => a.id)
      .filter((id) => id) as string[],
  };
};

const prepareHashboardInspectionReport = (
  orderItem: OrderItemDataFragment,
  report: HashboardDiagnosticsReport,
  isOk: boolean
): HashboardInspectionReportInput => {
  return {
    order_item: orderItem.id,
    numberOfTemperatureSensors: (report.temperatureSensors || []).filter(
      (ts) => ts.isOn
    ).length,
    openTraces: report.openTraces,
    initialASICsFound: report.initialASICsFound,
    asicsRepairsNeeded: report.asicsRepairsNeeded,
    boostCircuitRepairsNeeded: report.boostCircuitRepairsNeeded,
    otherComponentsNeeded: report.otherComponentsNeeded,
    notes: report.notes,
    isOk,
  };
};

const preparePsuInspectionReport = (
  orderItem: OrderItemDataFragment,
  report: PSUDiagnosticsReport,
  isOk: boolean
): PsuInspectionReportInput => {
  return {
    order_item: orderItem.id,
    inputFusesOk: report.inputFusesOk,
    inrushCurrentLimitersOk: report.inrushCurrentLimitersOk,
    mosfetsOk: report.mosfetsOk,
    powerDiodesOk: report.powerDiodesOk,
    u25Ok: report.u25Ok,
    isOk,
  };
};

export const useUpdateMinerInspectionReport =
  (): UseUpdateInspectionReportHookData<MinerDiagnosticsReport> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const [updateMinerInspection] = useUpdateMinerInspectionReportMutation();

    return {
      async updateReport({ report, orderItem, isOk }) {
        try {
          if (!report.id) {
            throw new Error("Missing ID on the existing report");
          }

          await updateMinerInspection({
            variables: {
              id: report.id,
              data: prepareMinerInspectionReport(orderItem, report, isOk),
            },
          });

          showSuccessMessage("Inspection report updated!");
        } catch (err) {
          showErrorMessage("Cannot update inspection report");
        }
      },
    };
  };

export const useCreateHashboardInspectionReport =
  (): UseCreateInspectionReportHookData<HashboardDiagnosticsReport> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { createAction } = useCreateAction();
    const [createHashboardInspectionReport] =
      useCreateHashboardInspectionReportMutation();
    return {
      async createReport({ report, orderItem, isOk }) {
        try {
          const { data } = await createHashboardInspectionReport({
            variables: {
              data: prepareHashboardInspectionReport(orderItem, report, isOk),
            },
            refetchQueries: [
              {
                query: HashboardInspectionReportsForOrderItemDocument,
                variables: {
                  orderItemId: orderItem.id || "",
                },
              },
            ],
          });

          await createAction({
            type: Enum_Action_Type.DiagnoseOrderItem,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              hashboard_inspection_report:
                data?.createHashboardInspectionReport?.data?.id,
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
            },
          });

          showSuccessMessage("Diagnosis report submitted!");
        } catch (err) {
          showErrorMessage("Cannot submit diagnosis report");
        }
      },
    };
  };

export const useCreateAdvancedHashboardInspectionReport =
  (): UseCreateInspectionReportHookData<HashboardAdvancedInspectionReportInput> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { createAction } = useCreateAction();
    const { updateOrderItemStatus } = useUpdateOrderItemStatus();
    const [createHashboardInspectionReport] =
      useCreateHashboardAdvancedInspectionReportMutation();
    return {
      async createReport({ report, orderItem, isOk }) {
        try {
          await updateOrderItemStatus(
            orderItem,
            isOk
              ? Enum_Orderitem_Status.DiagnosedIsOk
              : Enum_Orderitem_Status.DiagnosedNeedsRepair,
            false
          );

          const { data } = await createHashboardInspectionReport({
            variables: {
              data: Object.assign({}, report, {
                order_item: orderItem.id,
                asicNumberASICsDetected:
                  typeof report.asicNumberASICsDetected !== "undefined"
                    ? parseInt(`${report.asicNumberASICsDetected}`)
                    : undefined,
                timeOfDiagnosisMinutes:
                  typeof report.timeOfDiagnosisMinutes !== "undefined"
                    ? parseInt(`${report.timeOfDiagnosisMinutes}`)
                    : undefined,
              }),
            },
            refetchQueries: [
              {
                query: HashboardInspectionReportsForOrderItemDocument,
                variables: {
                  orderItemId: orderItem.id || "",
                },
              },
            ],
          });

          await createAction({
            type: Enum_Action_Type.DiagnoseOrderItem,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              hashboard_advanced_inspection_report:
                data?.createHashboardAdvancedInspectionReport?.data?.id,
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
              hashboardAdvancedInspectionReport:
                data?.createHashboardAdvancedInspectionReport?.data,
            },
          });

          showSuccessMessage("Diagnosis report submitted!");
        } catch (err) {
          showErrorMessage("Cannot submit diagnosis report");
        }
      },
    };
  };

export const useUpdateHashboardInspectionReport =
  (): UseUpdateInspectionReportHookData<HashboardDiagnosticsReport> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const [updateHashboardInspectionReport] =
      useUpdateHashboardInspectionReportMutation();
    return {
      async updateReport({ report, orderItem, isOk }) {
        try {
          if (!report.id) {
            throw new Error("Missing ID on the existing report");
          }

          await updateHashboardInspectionReport({
            variables: {
              id: report.id,
              data: prepareHashboardInspectionReport(orderItem, report, isOk),
            },
          });
          showSuccessMessage("Diagnosis report updated!");
        } catch (err) {
          showErrorMessage("Cannot update diagnosis report");
        }
      },
    };
  };

export const useCreatePSUInspectionReport =
  (): UseCreateInspectionReportHookData<PsuInspectionReportInput> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { createAction } = useCreateAction();
    const { updateOrderItemStatus } = useUpdateOrderItemStatus();
    const [createPSUInspectionReport] = useCreatePsuInspectionReportMutation();
    return {
      async createReport({ report, orderItem }) {
        try {
          const { data } = await createPSUInspectionReport({
            variables: {
              data: Object.assign({}, report, {
                order_item: orderItem.id,
              }),
            },
            refetchQueries: [
              {
                query: PsuInspectionReportsForOrderItemDocument,
                variables: {
                  orderItemId: orderItem.id || "",
                },
              },
            ],
          });

          await updateOrderItemStatus(
            orderItem,
            report.isOk
              ? Enum_Orderitem_Status.DiagnosedIsOk
              : Enum_Orderitem_Status.DiagnosedNeedsRepair,
            false
          );

          await createAction({
            type: Enum_Action_Type.DiagnoseOrderItem,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              psu_inspection_report: data?.createPsuInspectionReport?.data?.id,
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
              psuInspectionReport: data?.createPsuInspectionReport?.data,
            },
          });

          showSuccessMessage("Diagnosis report submitted!");
        } catch (err) {
          showErrorMessage("Cannot submit diagnosis report");
        }
      },
    };
  };

export const useUpdatePSUInspectionReport =
  (): UseUpdateInspectionReportHookData<PSUDiagnosticsReport> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const [updatePsuInspection] = useUpdatePsuInspectionReportMutation();

    return {
      async updateReport({ report, orderItem, isOk }) {
        try {
          if (!report.id) {
            throw new Error("Missing ID on the existing report");
          }

          await updatePsuInspection({
            variables: {
              id: report.id,
              data: preparePsuInspectionReport(orderItem, report, isOk),
            },
          });

          showSuccessMessage("Inspection report updated!");
        } catch (err) {
          showErrorMessage("Cannot update inspection report");
        }
      },
    };
  };

export const useCreateMinerInspectionReport =
  (): UseCreateInspectionReportHookData<MinerDiagnosticsReport> => {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { createAction } = useCreateAction();
    const [createMinerInspectionReport] =
      useCreateMinerInspectionReportMutation();
    return {
      async createReport({ report, orderItem, isOk }) {
        try {
          const { data } = await createMinerInspectionReport({
            variables: {
              data: prepareMinerInspectionReport(orderItem, report, isOk),
            },
            refetchQueries: [
              {
                query: MinerInspectionReportsForOrderItemDocument,
                variables: {
                  orderItemId: orderItem.id || "",
                },
              },
            ],
          });

          await createAction({
            type: Enum_Action_Type.DiagnoseOrderItem,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              miner_inspection_report:
                data?.createMinerInspectionReport?.data?.id,
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device: orderItem.attributes?.device?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
            },
          });

          showSuccessMessage("Inspection report submitted!");
        } catch (err) {
          showErrorMessage("Cannot submit inspection report");
        }
      },
    };
  };

interface UseCreateOrUpdateDiagnosticsReportHookData {
  createOrUpdateDiagnosticsReport: ({
    report,
    orderItem,
    isDeviceOk,
  }: {
    report: DiagnosticsReport;
    orderItem: OrderItemDataFragment;
    isDeviceOk: boolean;
  }) => Promise<void>;
}

export const useCreateOrUpdateDiagnosticsReport =
  (): UseCreateOrUpdateDiagnosticsReportHookData => {
    const { createReport: createMinerReport } =
      useCreateMinerInspectionReport();
    const { updateReport: updateMinerReport } =
      useUpdateMinerInspectionReport();
    const { createReport: createHashboarReport } =
      useCreateHashboardInspectionReport();
    const { updateReport: updateHashboardReport } =
      useUpdateHashboardInspectionReport();
    const { createReport: createPSUReport } = useCreatePSUInspectionReport();
    const { updateReport: updatePSUReport } = useUpdatePSUInspectionReport();
    const { updateOrderItemStatus } = useUpdateOrderItemStatus();

    return {
      async createOrUpdateDiagnosticsReport({ report, orderItem, isDeviceOk }) {
        if (orderItem.id) {
          await updateOrderItemStatus(
            orderItem,
            isDeviceOk
              ? Enum_Orderitem_Status.DiagnosedIsOk
              : Enum_Orderitem_Status.DiagnosedNeedsRepair,
            false
          );
        }

        if (report.reportType === "psu") {
          const psuReport = report as PSUDiagnosticsReport;

          if (!psuReport.id) {
            await createPSUReport({
              report: psuReport,
              orderItem,
              isOk: isDeviceOk,
            });
          } else {
            await updatePSUReport({
              report: psuReport,
              orderItem,
              isOk: isDeviceOk,
            });
          }
        }

        if (report.reportType === "hashboard") {
          const hashboardReport = report as HashboardDiagnosticsReport;

          if (!hashboardReport.id) {
            await createHashboarReport({
              report: hashboardReport,
              orderItem,
              isOk: isDeviceOk,
            });
          } else {
            await updateHashboardReport({
              report: hashboardReport,
              orderItem,
              isOk: isDeviceOk,
            });
          }
        }

        if (report.reportType === "miner") {
          const minerReport = report as MinerDiagnosticsReport;

          if (isDeviceOk) {
            // clean up suggested problems if device is OK
            minerReport.suggestedProblems = undefined;
          }

          if (!minerReport.id) {
            await createMinerReport({
              report: minerReport,
              orderItem,
              isOk: isDeviceOk,
            });
          } else {
            await updateMinerReport({
              report: minerReport,
              orderItem,
              isOk: isDeviceOk,
            });
          }
        }
      },
    };
  };

interface UseInspectionReportsHookData<T extends InspectionReportData> {
  loading: boolean;
  reports: InspectionReportWithActionContext<T>[];
}

export const useMinerInspectionReports = (
  orderItem: OrderItemDataFragment
): UseInspectionReportsHookData<MinerInspectionReportDataFragment> => {
  const { loading, data } = useMinerInspectionReportsForOrderItemQuery({
    variables: {
      orderItemId: orderItem.id || "",
    },
  });
  return {
    loading,
    reports: (data?.minerInspectionReports?.data || []).map((report) => ({
      report,
      action: data?.actions?.data.find(
        (a) => a.attributes?.miner_inspection_report?.data?.id === report.id
      ),
    })),
  };
};

export const useHashboardInspectionReports = (
  orderItem: OrderItemDataFragment
): UseInspectionReportsHookData<
  | HashboardInspectionReportDataFragment
  | HashboardAdvancedInspectionReportDataFragment
> => {
  const { loading, data } = useHashboardInspectionReportsForOrderItemQuery({
    variables: {
      orderItemId: orderItem.id || "",
    },
  });

  return {
    loading,
    reports: [
      ...(data?.hashboardInspectionReports?.data || []),
      ...(data?.hashboardAdvancedInspectionReports?.data || []),
    ].map((report) => ({
      report,
      action: data?.actions?.data.find((a) => {
        return (
          a.attributes?.hashboard_inspection_report?.data?.id === report.id ||
          a.attributes?.hashboard_advanced_inspection_report?.data?.id ===
            report.id
        );
      }),
    })),
  };
};

export const usePSUInspectionReports = (
  orderItem: OrderItemDataFragment
): UseInspectionReportsHookData<PsuInspectionReportDataFragment> => {
  const { loading, data } = usePsuInspectionReportsForOrderItemQuery({
    variables: {
      orderItemId: orderItem.id || "",
    },
  });
  return {
    loading,
    reports: (data?.psuInspectionReports?.data || []).map((report) => ({
      report,
      action: data?.actions?.data.find(
        (a) => a.attributes?.psu_inspection_report?.data?.id === report.id
      ),
    })),
  };
};

interface UseLatestHashboardInspectionHookData {
  loading: boolean;
  report?: HashboardAdvancedInspectionReportDataFragment | null;
}

export const useLatestHashboardInspection = ({
  orderItemId,
}: {
  orderItemId: string;
}): UseLatestHashboardInspectionHookData => {
  const { loading, data } =
    useLatestHashboardInspectionReportsForOrderItemQuery({
      variables: {
        orderItemId,
      },
    });

  return {
    loading,
    report:
      data?.hashboardAdvancedInspectionReports?.data.length !== 0
        ? data?.hashboardAdvancedInspectionReports?.data[0]
        : null,
  };
};
