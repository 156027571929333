import { ReactNode } from "react";
import {
  Box,
  Center,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "components/Logo";

interface LayoutShellProps {
  children: ReactNode;
  title: string;
  subtitle: string;
}

export const LayoutShell = (props: LayoutShellProps) => {
  const { children, title, subtitle } = props;
  return (
    <>
      <Stack spacing="6">
        <Box>
          <Center>
            <Logo />
          </Center>
        </Box>
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            {title}
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">{subtitle}</Text>
          </HStack>
        </Stack>
      </Stack>
      <Box
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
        boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="6">{children}</Stack>
      </Box>
    </>
  );
};
