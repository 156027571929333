import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  Text,
  HStack,
  Stack,
  Center,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik } from "formik";
import { TextInput } from "components/forms/fields";
import { Loader } from "components/Loader";
import { ErrorIcon, DoneIcon } from "components/Icons";
import {
  CompleteCustomerProfileProvider,
  useCompleteCustomerProfile,
} from "features/accounts/context/CompleteCustomerProfile";
import { useAuthentication } from "features/accounts/context/Authentication";
import { LayoutShell } from "features/accounts/components/LayoutShell";

const generalInfoSchema = Yup.object({
  firstName: Yup.string().required("required"),
  lastName: Yup.string().required("required"),
  companyName: Yup.string(),
  phone: Yup.string().required("required"),
});

const addressSchema = Yup.object({
  addressLine1: Yup.string().required("required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("required"),
  zipCode: Yup.string().required("required"),
  state: Yup.string().required("required"),
  country: Yup.string().required("required"),
});

const AddressForm = () => (
  <>
    <TextInput label="Address Line 1" name="addressLine1" />
    <TextInput label="Address Line 2" name="addressLine2" />
    <TextInput label="City" name="city" />
    <TextInput label="Zip Code" name="zipCode" />
    <TextInput label="State" name="state" />
    <TextInput label="Country" name="country" />
  </>
);

const WrappedSetupCustomerAccountPage = () => {
  const navigate = useNavigate();
  const { isCustomerProfileComplete } = useAuthentication();
  const {
    loading,
    error,
    success,
    submit,
    step,
    data,
    next,
    prev,
    updateData,
    billingAddressSameAsShipping,
    toggleBillingAddressSameAsShipping,
  } = useCompleteCustomerProfile();

  useEffect(() => {
    if (isCustomerProfileComplete) {
      navigate("/orders");
    }
  });

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Stack textAlign="center">
        <Center>
          <ErrorIcon size={40} />
        </Center>
        <Text mt="10">{error}</Text>
      </Stack>
    );
  }

  if (success) {
    return (
      <Stack>
        <Center>
          <DoneIcon size={40} />
        </Center>
        <Text textAlign="center" mt="10">
          Your account setup is complete
        </Text>
        <Button
          mt="10"
          onClick={() => {
            window.location.reload();
          }}
        >
          Go to my orders
        </Button>
      </Stack>
    );
  }

  return (
    <>
      {step === "General Info" ? (
        <Formik
          validationSchema={generalInfoSchema}
          onSubmit={(values) => {
            updateData(
              Object.assign({}, values, {
                companyName: values.companyName || null,
              })
            );
            next();
          }}
          initialValues={{
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            companyName: data.companyName || "",
            phone: data.phone || "",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <TextInput label="First Name" name="firstName" />
              <TextInput label="Last Name" name="lastName" />
              <TextInput label="Company Name" name="companyName" />
              <TextInput label="Phone" name="phone" />
              <Divider />
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Next
              </Button>
            </>
          )}
        </Formik>
      ) : null}
      {step === "Shipping Address" ? (
        <Formik
          validationSchema={addressSchema}
          onSubmit={async (values) => {
            const shippingAddress = Object.assign({}, values, {
              addressLine2: values.addressLine2 || null,
            });

            const newData = Object.assign(
              data,
              {
                shippingAddress,
              },
              billingAddressSameAsShipping
                ? {
                    billingAddress: shippingAddress,
                  }
                : {}
            );

            updateData(newData);

            if (!billingAddressSameAsShipping) {
              next();
            } else {
              await submit(newData);
            }
          }}
          initialValues={{
            addressLine1: data.shippingAddress?.addressLine1 || "",
            addressLine2: data.shippingAddress?.addressLine2 || "",
            city: data.shippingAddress?.city || "",
            zipCode: data.shippingAddress?.zipCode || "",
            state: data.shippingAddress?.state || "",
            country: data.shippingAddress?.country || "US",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Text fontWeight="bold" fontSize="lg">
                Shipping address
              </Text>
              <Divider />
              <AddressForm />
              <Checkbox
                isChecked={billingAddressSameAsShipping}
                onChange={() => {
                  toggleBillingAddressSameAsShipping();
                }}
              >
                Billing is same as shipping
              </Checkbox>
              <Divider />
              <HStack>
                <Button
                  variant="outline"
                  onClick={() => {
                    prev();
                  }}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Next
                </Button>
              </HStack>
            </>
          )}
        </Formik>
      ) : null}
      {step === "Billing Address" ? (
        <Formik
          validationSchema={addressSchema}
          onSubmit={async (values) => {
            const newData = Object.assign({}, data, {
              billingAddress: Object.assign(values, {
                addressLine2: values.addressLine2 || null,
              }),
            });

            updateData(newData);

            await submit(newData);
          }}
          initialValues={{
            addressLine1: data.billingAddress?.addressLine1 || "",
            addressLine2: data.billingAddress?.addressLine2 || "",
            city: data.billingAddress?.city || "",
            zipCode: data.billingAddress?.zipCode || "",
            state: data.billingAddress?.state || "",
            country: data.billingAddress?.country || "US",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Text fontWeight="bold" fontSize="lg">
                Billing address
              </Text>
              <Divider />
              <AddressForm />
              <Divider />
              <HStack>
                <Button
                  variant="outline"
                  onClick={() => {
                    prev();
                  }}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Next
                </Button>
              </HStack>
            </>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export const SetupCustomerAccountPage = () => (
  <LayoutShell title="Complete your profile" subtitle="">
    <CompleteCustomerProfileProvider>
      <WrappedSetupCustomerAccountPage />
    </CompleteCustomerProfileProvider>
  </LayoutShell>
);
