import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { OrderPackageDataFragment } from "data";

interface LinkToOrderPackageProps {
  orderPackage?: OrderPackageDataFragment | null;
  children: ReactNode;
}

export const LinkToOrderPackage = (props: LinkToOrderPackageProps) => {
  const { orderPackage, children } = props;
  return orderPackage ? (
    <Link to={`/packages/${orderPackage.id}`}>{children}</Link>
  ) : null;
};
