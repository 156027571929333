import { useEffect, useState } from "react";
import { Text, Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Loader } from "components/Loader";

const getChangelogId = (s: string): number => {
  const m = /([0-9]+)(\.md)$/gi.exec(s);
  return !m || m.length < 2 ? 0 : parseInt(m[1]);
};

export const ChangeLogsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [changelogs, setChangelogs] = useState<string[]>([]);

  useEffect(() => {
    const runner = async () => {
      const r = await fetch("/data/changelogs/index.json");
      const content = await r.json();
      (content as string[]).sort(
        (a, b) => getChangelogId(b) - getChangelogId(a)
      );
      setChangelogs(content);
      setLoading(false);
    };
    runner();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <>
      <Text fontSize="lg" fontWeight="bold">
        ⚡ Changelogs
      </Text>
      <Divider />
      <ul>
        {changelogs.map((changelog) => {
          const changelogId = changelog.replace(/\.md$/gi, "");
          return (
            <li>
              <Link to={`/changelogs/${changelogId}`}>
                {changelogId.replace(/-[0-9]+$/gi, "").replaceAll("-", " ")}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};
