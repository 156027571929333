import { OrderItemDataFragment } from "data";
import { RepairIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { ShowForDevicesWeCanRepair } from "features/devices/components/ShowForDevicesWeCanRepair";
import { useRepairsContextMenu } from "features/repairs/context/RepairsContextMenu";

interface RepairActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const RepairAction = (props: RepairActionProps) => {
  const { orderItem, variant } = props;
  const { openRepairsMenu } = useRepairsContextMenu();
  return (
    <ShowForDevicesWeCanRepair device={orderItem.attributes?.device?.data}>
      <ActionComponent
        action={async () => {
          openRepairsMenu(orderItem);
        }}
        variant={variant}
        actionIcon={<RepairIcon />}
        actionLabel="Register Repair"
        data-cy="repair-action-button"
        data-order-item-id={orderItem.id}
      />
    </ShowForDevicesWeCanRepair>
  );
};

RepairAction.defaultProps = {
  variant: "button",
};
