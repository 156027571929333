import { RadioGroup, Radio, HStack } from "@chakra-ui/react";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { HashboardDiagnosticsReport } from "features/diagnostics/data";

export const BoostCircuitDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const hashboardDiagnosticsReport = data as HashboardDiagnosticsReport;
  return (
    <RadioGroup
      onChange={(value) => {
        updateData(
          Object.assign({}, hashboardDiagnosticsReport, {
            boostCircuitRepairsNeeded: value === "1" ? true : false,
          })
        );
      }}
      value={hashboardDiagnosticsReport.boostCircuitRepairsNeeded ? "1" : "0"}
    >
      <HStack spacing="8">
        <Radio value="1">Boost circuit repair needed</Radio>
        <Radio value="0">No</Radio>
      </HStack>
    </RadioGroup>
  );
};
