import { useState } from "react";
import { CustomerDataFragment } from "data";
import { useNotifications } from "context/Notifications";
import { useOrderPackage } from "features/order-packages/context/OrderPackage";
import { OrderItemPicker } from "features/order-packages/components/OrderItemPicker";
import { useOrderItemsReadyForPackaging } from "features/order-items/data/hooks/order-items";
import { Loader } from "components/Loader";

interface OrderItemPickerContainerProps {
  customer: CustomerDataFragment;
}

export const OrderItemPickerContainer = (
  props: OrderItemPickerContainerProps
) => {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { addOrderItem } = useOrderPackage();
  const { customer } = props;
  const { loading, orderItems, refetch } = useOrderItemsReadyForPackaging({
    customer,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  return loading ? (
    <Loader />
  ) : (
    <OrderItemPicker
      onPackage={async (oi) => {
        try {
          await addOrderItem(oi);
          await refetch();
          showSuccessMessage("Added to package");
        } catch (err) {
          showErrorMessage("Cannot package this item");
        }
      }}
      orderItems={
        searchTerm === ""
          ? orderItems
          : orderItems.filter((oi) => {
              const rx = new RegExp(searchTerm, "ig");
              return (
                oi.attributes?.customer_device?.data?.attributes?.shortId &&
                rx.exec(
                  oi.attributes?.customer_device?.data?.attributes?.shortId
                )
              );
            })
      }
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
    />
  );
};
