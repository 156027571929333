import { ReactElement } from "react";
import { Text, HStack, Button, Stack, Box, Divider } from "@chakra-ui/react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { DeviceDataFragment, Enum_Device_Type, Enum_Device_Series } from "data";
import { RadioCard, RadioCardGroupGrid } from "components/forms/RadioCardGroup";
import {
  DeviceTypeLabel,
  DeviceSeriesLabel,
  DeviceModelLabel,
  DeviceTypeIcon,
  DeviceMakeIcon,
  DeviceCompleteNameLabel,
} from "features/devices/components";
import { DeviceMakeLabel } from "features/devices/components/DeviceMakeLabel";

export type DeviceSelectorStage = "device_type" | "make" | "series" | "device";

interface DeviceSelectorProps {
  stage: DeviceSelectorStage;
  selectedDevice: DeviceDataFragment | null;
  devices: DeviceDataFragment[];
  deviceTypes: Enum_Device_Type[];
  makes: string[];
  deviceSeries: Enum_Device_Series[];
  onSelectDeviceType: (deviceType: Enum_Device_Type) => void;
  onSelectDeviceMake: (make: string) => void;
  onSelectDeviceSeries: (deviceSeries: Enum_Device_Series) => void;
  onSelectDevice: (device: DeviceDataFragment | null) => void;
  selectedDeviceType: Enum_Device_Type | null;
  selectedDeviceMake: string | null;
  selectedDeviceSeries: Enum_Device_Series | null;
  goBack: () => void;
}

interface SelectorUIProps<T> {
  items: T[];
  getValue: (el: T) => string;
  findByValue: (val: string) => T | undefined;
  onSelect: (el: T) => void;
  content: (el: T) => ReactElement;
}

function SelectorUI<T>(props: SelectorUIProps<T>): ReactElement {
  const { content, items, getValue, findByValue, onSelect } = props;
  return (
    <RadioCardGroupGrid
      onChange={(value) => {
        const el = findByValue(value);
        if (el) {
          onSelect(el);
        }
      }}
    >
      {items.map((it) => (
        <RadioCard gridMode key={getValue(it)} value={getValue(it)}>
          {content(it)}
        </RadioCard>
      ))}
    </RadioCardGroupGrid>
  );
}

const DeviceTypes = (props: DeviceSelectorProps) => {
  const { deviceTypes, onSelectDeviceType } = props;
  return (
    <SelectorUI
      items={deviceTypes}
      getValue={(dt) => dt as string}
      onSelect={(dt) => {
        onSelectDeviceType(dt);
      }}
      findByValue={(value) => {
        return deviceTypes.find((dt) => dt === value);
      }}
      content={(dt) => {
        return (
          <Stack alignItems={"center"}>
            <DeviceTypeIcon deviceType={dt} />
            <DeviceTypeLabel
              data-cy={`device-selector-type-${dt}`}
              deviceType={dt}
              color="emphasized"
              fontWeight="medium"
              fontSize="sm"
            />
          </Stack>
        );
      }}
    />
  );
};

const Makes = (props: DeviceSelectorProps) => {
  const { makes, onSelectDeviceMake } = props;
  return (
    <SelectorUI
      items={makes}
      getValue={(m) => m}
      onSelect={(m) => {
        onSelectDeviceMake(m);
      }}
      findByValue={(value) => value}
      content={(make) => {
        return (
          <Stack alignItems={"center"}>
            <DeviceMakeIcon deviceOrMake={make} />
            <Text
              data-cy={`device-selector-make-${make.replaceAll(" ", "")}`}
              color="emphasized"
              fontWeight="medium"
              fontSize="sm"
            >
              {make}
            </Text>
          </Stack>
        );
      }}
    />
  );
};

const Devices = (props: DeviceSelectorProps) => {
  const { devices, onSelectDevice } = props;
  return (
    <SelectorUI
      items={devices}
      getValue={(d) => d.id || ""}
      onSelect={(d) => {
        onSelectDevice(d);
      }}
      findByValue={(value) => devices.find((d) => d.id === value)}
      content={(d) => {
        return (
          <Stack alignItems={"center"}>
            <DeviceTypeIcon deviceType={d.attributes?.type} />
            <DeviceModelLabel
              data-cy={`device-selector-model-${d.attributes?.model}`}
              deviceModel={d.attributes?.model}
              color="emphasized"
              fontWeight="medium"
              fontSize="sm"
            />
          </Stack>
        );
      }}
    />
  );
};

const DeviceSeries = (props: DeviceSelectorProps) => {
  const { deviceSeries, onSelectDeviceSeries, selectedDeviceMake } = props;
  return (
    <SelectorUI
      items={deviceSeries}
      getValue={(ds) => ds as string}
      onSelect={(ds) => {
        onSelectDeviceSeries(ds);
      }}
      findByValue={(value) => deviceSeries.find((ds) => ds === value)}
      content={(ds) => (
        <Stack alignItems={"center"}>
          {selectedDeviceMake ? (
            <DeviceMakeIcon deviceOrMake={selectedDeviceMake} />
          ) : null}
          <DeviceSeriesLabel
            data-cy={`device-selector-series-${ds}`}
            deviceSeries={ds}
            color="emphasized"
            fontWeight="medium"
            fontSize="sm"
          />
        </Stack>
      )}
    />
  );
};

const Breadcrumbs = (props: DeviceSelectorProps) => {
  const {
    selectedDeviceType,
    selectedDeviceMake,
    selectedDeviceSeries,
    stage,
    goBack,
  } = props;
  return (
    <Box my={4}>
      <HStack>
        {stage !== "device_type" ? (
          <Button
            mr={8}
            variant="outline"
            size="xs"
            onClick={() => {
              goBack();
            }}
            leftIcon={<FaRegArrowAltCircleLeft />}
          >
            Back
          </Button>
        ) : null}
        <HStack>
          {selectedDeviceType ? (
            <DeviceTypeLabel showTypeIcon deviceType={selectedDeviceType} />
          ) : null}
          {selectedDeviceMake ? (
            <>
              <Text>{">"}</Text>
              <DeviceMakeLabel showMakeIcon deviceOrMake={selectedDeviceMake} />
            </>
          ) : null}
          {selectedDeviceSeries ? (
            <>
              <Text>{">"}</Text>
              <DeviceSeriesLabel
                includeSeriesSuffix
                deviceSeries={selectedDeviceSeries}
              />
            </>
          ) : null}
        </HStack>
      </HStack>
      <Divider my={4} />
    </Box>
  );
};

export const DeviceSelector = (props: DeviceSelectorProps) => {
  const { stage, selectedDevice } = props;

  if (selectedDevice) {
    return (
      <>
        <Breadcrumbs {...props} />
        <DeviceCompleteNameLabel fontSize="2xl" device={selectedDevice} />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs {...props} />
      {stage === "device_type" ? <DeviceTypes {...props} /> : null}
      {stage === "make" ? <Makes {...props} /> : null}
      {stage === "series" ? <DeviceSeries {...props} /> : null}
      {stage === "device" ? <Devices {...props} /> : null}
    </>
  );
};
