import {
  InspectionStepType,
  MinerDiagnosticsReport,
} from "features/diagnostics/data";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { Enum_Device_Type, OrderItemDataFragment } from "data";
import { WizardNav } from "components/wizard/WizardNav";

interface DiagnosticsWizardNavProps {
  orderItem: OrderItemDataFragment;
  onExit: () => void;
}

export const DiagnosticsWizardNav = (props: DiagnosticsWizardNavProps) => {
  const { onExit, orderItem } = props;
  const {
    currentStep,
    canGoToNextStep,
    canGoToPrevStep,
    isLastStep,
    goToNextStep,
    goToPrevStep,
    data,
    isNavEnabled,
  } = useDiagnosticsWizard();
  return (
    <WizardNav
      canGoToPrevStep={canGoToPrevStep}
      canGoToNextStep={canGoToNextStep}
      isNavEnabled={isNavEnabled}
      next={() => {
        if (
          orderItem.attributes?.device?.data?.attributes?.type ===
          Enum_Device_Type.Miner
        ) {
          const minerDiagnosticsReport = data as MinerDiagnosticsReport;
          // if no power, go straight to the preview
          if (currentStep?.type === InspectionStepType.Power) {
            if (!minerDiagnosticsReport.power) {
              onExit();
              return;
            }
          }

          // if no IP address, got straight to preview
          if (currentStep?.type === InspectionStepType.ControlBoard) {
            if (minerDiagnosticsReport.noIPAddress) {
              onExit();
              return;
            }
          }
        }

        if (isLastStep) {
          onExit();
        } else {
          goToNextStep();
        }
      }}
      previous={() => {
        goToPrevStep();
      }}
    />
  );
};
