import * as Yup from "yup";
import { Stack, Button, Spacer, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import {
  HashboardBasicRepairInput,
  HashboardAdvancedInspectionReportDataFragment,
} from "data";
import { SelectTime, TextArea, YesNoButtons } from "components/forms/fields";
import { HashboardDiagnosisLabel } from "features/diagnostics/components/hashboards/DiagnosisLabel";

const HashboardBasicRepairSchema = Yup.object().shape({
  traceRepair: Yup.boolean().required(),
  corrosionCleaning: Yup.boolean().required(),
  repairTimeMinutes: Yup.number().required(),
  isAdvancedRepair: Yup.boolean().required(),
  gotFurtherIssues: Yup.boolean().required(),
  notes: Yup.string(),
});

interface HashboardBasicRepairProps {
  latestReport?: HashboardAdvancedInspectionReportDataFragment | null;
  onSubmit: (repair: HashboardBasicRepairInput) => void;
}

export const HashboardBasicRepair = ({
  latestReport,
  onSubmit,
}: HashboardBasicRepairProps) => {
  return (
    <Formik
      initialValues={{
        traceRepair: false,
        corrosionCleaning: false,
        repairTimeMinutes: 15,
        isAdvancedRepair: false,
        gotFurtherIssues: false,
        notes: "",
      }}
      validationSchema={HashboardBasicRepairSchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <Form>
          <Stack spacing={6}>
            {latestReport ? (
              <HashboardDiagnosisLabel
                fontWeight="bold"
                fontSize="larger"
                diagnosis={latestReport.attributes?.diagnosis}
              />
            ) : null}
            <YesNoButtons label="Trace repair" name="traceRepair" />
            <YesNoButtons label="Corrosion cleaning" name="corrosionCleaning" />

            <SelectTime
              name="repairTimeMinutes"
              label="How much time did the repair take?"
              placeholder="Select time"
              start={15}
              end={150}
              step={15}
            />

            <YesNoButtons
              label="Was this an advanced repair?"
              name="isAdvancedRepair"
            />
            <YesNoButtons
              label="Are there further issues?"
              name="gotFurtherIssues"
            />

            <TextArea label="Notes (Optional)" name="notes" />
            <HStack mt="8">
              <Spacer />
              <Button
                isDisabled={!isValid}
                onClick={() => {
                  onSubmit(
                    Object.assign(
                      {},
                      values,
                      {
                        repairTimeMinutes: parseInt(
                          `${values.repairTimeMinutes}`
                        ),
                      },
                      !values.notes ? { notes: null } : { notes: values.notes }
                    )
                  );
                }}
              >
                Register repair
              </Button>
            </HStack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
