import { forwardRef, LegacyRef } from "react";
import moment from "moment";
import { CustomerDeviceDataFragment } from "data";
import { QRCode } from "components/QRCode";

interface LabelToPrintProps {
  device: CustomerDeviceDataFragment;
}

export const PrintableLabel = forwardRef((props: LabelToPrintProps, ref) => {
  const { device } = props;

  let truncatedAccountName =
    device.attributes?.customer?.data?.attributes?.accountName;

  if (truncatedAccountName && truncatedAccountName.length > 12) {
    truncatedAccountName = truncatedAccountName.substring(0, 12);
  }

  const serial =
    device.attributes?.serialNumber &&
    device.attributes.serialNumber.substring(
      device.attributes.serialNumber.length - 5
    );

  return (
    <div
      className="print-only"
      ref={ref as LegacyRef<HTMLDivElement>}
      style={{
        position: "relative",
        width: "19mm",
        height: "27mm",
        // border: "solid 1px #000000",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "18mm",
          height: "15mm",
        }}
      >
        <QRCode relativeURL={`/devices/${device.attributes?.shortId}`} />
      </div>
      <div style={{ textAlign: "center", paddingTop: "15mm" }}>
        <div style={{ fontSize: "11px", fontWeight: "bold" }}>
          {device.attributes?.shortId}
        </div>
        {serial ? (
          <div
            style={{
              fontSize: "9px",
              marginTop: "-2px",
              marginBottom: "-2px",
              whiteSpace: "nowrap",
            }}
          >
            {serial}
          </div>
        ) : null}
        <div style={{ fontSize: "9px", whiteSpace: "nowrap" }}>
          {truncatedAccountName}
        </div>
        <div style={{ fontSize: "8px" }}>
          {moment(device.attributes?.createdAt).format("MMM D YYYY")}
        </div>
      </div>
    </div>
  );
});
