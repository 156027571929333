import { useParams } from "react-router-dom";
import {
  Stack,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
} from "@chakra-ui/react";
import {
  OrderDetailsProvider,
  useOrderDetails,
} from "features/orders/context/OrderDetails";
import { OrderLayoutProvider } from "features/orders/context/OrderLayout";
import { OrderSummaryContainer as OrderSummary } from "features/orders/containers/OrderSummary";
import { DateTimeText } from "components/utils/DateTimeText";

export const ConnectedPrintOrderPage = () => {
  const { isLoadingOrderDetails, order } = useOrderDetails();

  if (isLoadingOrderDetails) {
    return null;
  }

  return (
    <Stack spacing={5}>
      <div className="no-print">
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Button>
      </div>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td fontWeight="bold">Sales Order</Td>
              <Td>
                {
                  order?.attributes?.zoho_sales_order?.data?.attributes
                    ?.salesOrderNumber
                }
              </Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Customer</Td>
              <Td>
                {
                  order?.attributes?.zoho_sales_order?.data?.attributes
                    ?.customer?.data?.attributes?.accountName
                }
              </Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Received</Td>
              <Td>
                <DateTimeText
                  format="MMM D, Y"
                  value={order?.attributes?.createdAt}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {order?.id ? (
        <OrderLayoutProvider orderId={order.id}>
          <OrderSummary />
        </OrderLayoutProvider>
      ) : null}
    </Stack>
  );
};

export const PrintOrderPage = () => {
  const { orderId } = useParams();
  return orderId ? (
    <OrderDetailsProvider orderId={orderId}>
      <ConnectedPrintOrderPage />
    </OrderDetailsProvider>
  ) : null;
};
