import * as Yup from "yup";
import { Text, HStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { TextArea, YesNoButtons, SelectTime } from "components/forms/fields";
import { useAdvancedHashboardDiagnosticsWizard } from "features/diagnostics/context/AdvancedHashboardDiagnosticsWizard";
import { FormWrapper } from "./Wrapper";
import { HashboardDiagnosisLabel } from "features/diagnostics/components/hashboards/DiagnosisLabel";

const SummarySchema = Yup.object().shape({
  notes: Yup.string(),
  timeOfDiagnosisMinutes: Yup.number().required(),
  furtherIssuesSuspected: Yup.boolean().required(),
});

export const SummaryForm = () => {
  const { report } = useAdvancedHashboardDiagnosticsWizard();
  return (
    <Formik
      initialValues={{
        notes: report.notes,
        timeOfDiagnosisMinutes: report.timeOfDiagnosisMinutes,
        furtherIssuesSuspected: report.furtherIssuesSuspected,
      }}
      validationSchema={SummarySchema}
      validateOnBlur
      onSubmit={async (values) => {}}
    >
      {({ errors, touched, isSubmitting, isValid, values }) => (
        <FormWrapper
          isValid={isValid}
          canGoToNextStep={
            typeof values.timeOfDiagnosisMinutes !== "undefined" && isValid
          }
          canGoToPrevStep
          title="Summary"
          values={values}
        >
          <HStack>
            <Text>Diagnosis: </Text>
            <HashboardDiagnosisLabel
              fontWeight="bold"
              diagnosis={report.diagnosis}
            />
          </HStack>

          <SelectTime
            name="timeOfDiagnosisMinutes"
            label="How much time did the diagnosis take?"
            placeholder="Select time"
            start={15}
            end={150}
            step={15}
          />

          <YesNoButtons
            label="Do you suspect further issues?"
            name="furtherIssuesSuspected"
          />

          <TextArea label="Notes (Optional)" name="thermalsNotes" />
        </FormWrapper>
      )}
    </Formik>
  );
};
