import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { AbsoluteCenter, Box } from "@chakra-ui/react";
import { useApolloClient } from "@apollo/client";
import {
  MeDataFragment,
  CustomerDataFragment,
  UserProfileDataFragment,
  useUserProfileLazyQuery,
  useMeLazyQuery,
} from "data";
import { Logo } from "components/Logo";
import {
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";
import { useNotifications } from "context/Notifications";
import {
  login,
  logout,
  register,
  resendConfirmationEmail,
  forgotPassword,
  resetPassword,
  EmailNotConfirmed,
} from "features/accounts/data/auth";

export {
  UserAlreadyExistsError,
  EmailNotConfirmed,
} from "features/accounts/data/auth";

interface LoginData {
  profile?: UserProfileDataFragment | null;
  customers: CustomerDataFragment[];
  isCustomer: boolean;
  role?: string | null;
  user?: MeDataFragment | null;
}

interface RegistrationInput {
  email: string;
  password: string;
  role: "customer";
}

interface PasswordResetInput {
  password: string;
  passwordConfirmation: string;
  code: string;
}

interface AuthenticationContextData {
  loading: boolean;
  login: ({
    email,
    password,
    redirect,
  }: {
    email: string;
    password: string;
    redirect?: string | null;
  }) => Promise<void>;
  isLoggedIn: () => Promise<boolean>;
  logout: () => Promise<void>;
  isCustomerProfileComplete: boolean;
  userProfile: UserProfileDataFragment | null;
  loginData: LoginData | null;
  register: (input: RegistrationInput) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (input: PasswordResetInput) => Promise<void>;
  resendConfirmationEmail: (email: string) => Promise<void>;
}

const AuthenticationContext = createContext<AuthenticationContextData>(
  {} as AuthenticationContextData
);

interface AuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { createAction } = useCreateAction();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { showErrorMessage } = useNotifications();
  const [getUserProfile] = useUserProfileLazyQuery();
  const [loading, setLoading] = useState<boolean>(true);
  const [doGetMeData] = useMeLazyQuery();
  const [loginData, setLoginData] = useState<LoginData | null>(null);

  const getLoginData = async (): Promise<LoginData | null> => {
    try {
      const { data: meData } = await doGetMeData();

      if (!meData?.me) {
        return null;
      }

      const { data: userProfileData } = await getUserProfile({
        variables: {
          userId: meData.me.id,
        },
      });
      const profile =
        (userProfileData?.userProfiles?.data || []).length !== 0
          ? userProfileData?.userProfiles?.data[0]
          : null;

      return {
        role: meData.me.role?.name,
        profile,
        customers: profile?.attributes?.customers?.data || [],
        isCustomer: meData.me.role?.name.match(/customer/gi) !== null,
        user: meData.me,
      };
    } catch {
      return null;
    }
  };

  const doLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<LoginData | null> => {
    await login({ email, password });
    return getLoginData();
  };

  useEffect(() => {
    const runner = async () => {
      const ld = await getLoginData();
      setLoading(false);
      setLoginData(ld);
    };

    runner();
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        loading,
        async logout() {
          await logout();

          setLoginData(null);
          navigate("/");

          try {
            await client.resetStore();
          } catch {}
        },
        async isLoggedIn() {
          const ld = await getLoginData();
          return ld !== null;
        },
        async login({ email, password, redirect }) {
          try {
            setLoginData(null);

            const ld = await doLogin({ email, password });

            setLoginData(ld);

            if (ld) {
              if (ld.isCustomer) {
                await createAction({
                  type: Enum_Action_Type.Login,
                  scope: Enum_Action_Scope.Customer,
                  data: {},
                  context: {
                    userProfile: ld.profile,
                  },
                });
              }
            }

            if (!redirect) {
              navigate("/orders");
            } else {
              window.location.href = redirect;
            }
          } catch (loginError) {
            // @ts-ignore
            if (loginError.message === EmailNotConfirmed) {
              navigate(
                `/account/confirm-email?email=${encodeURIComponent(email)}`
              );
            } else {
              showErrorMessage(
                "Invalid email + password combo. Did you forget your password?"
              );
            }
          }
        },
        loginData,
        userProfile: loginData?.profile || null,
        isCustomerProfileComplete: loginData?.customers.length !== 0,
        async register({ email, password }) {
          await register({ email, password });
        },
        async forgotPassword(email) {
          await forgotPassword({
            email,
          });
        },
        async resetPassword(input) {
          await resetPassword({
            password: input.password,
            passwordConfirmation: input.passwordConfirmation,
            code: input.code,
          });
        },
        async resendConfirmationEmail(email) {
          await resendConfirmationEmail(email);
        },
      }}
    >
      {loading ? (
        <Box position="relative" minHeight="100%" height="100%">
          <AbsoluteCenter pt="40" axis="both">
            <Logo />
          </AbsoluteCenter>
        </Box>
      ) : (
        props.children
      )}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () =>
  useContext<AuthenticationContextData>(AuthenticationContext);
