import { useState } from "react";
import { ZohoSalesOrderDataFragment } from "data";
import { Loader } from "components";
import { useZohoSalesOrders } from "features/orders/data/hooks/zoho-sales-orders";
import { ZohoSalesOrdersSelector } from "features/orders/components/ZohoSalesOrdersSelector";

interface ZohoSalesOrdersSelectorContainerProps {
  onChangeSelection: (order: ZohoSalesOrderDataFragment) => void;
}

export const ZohoSalesOrdersSelectorContainer = (
  props: ZohoSalesOrdersSelectorContainerProps
) => {
  const { onChangeSelection } = props;
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<string>("");
  const { loading, zohoSalesOrders } = useZohoSalesOrders();

  const filterIfNeeded = (
    data: ZohoSalesOrderDataFragment[],
    search: string
  ) => {
    if (search.length === 0) {
      return data;
    }
    return data.filter(
      (d) =>
        d.attributes?.accountName
          ?.toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
    );
  };
  return loading ? (
    <Loader />
  ) : (
    <ZohoSalesOrdersSelector
      selectedSalesOrderId={selectedSalesOrderId}
      onUpdateSelectedSalesOrderId={(orderId) => {
        setSelectedSalesOrderId(orderId);
        const selectedOrder = zohoSalesOrders.find(
          (d) => d.attributes?.zohoId === orderId
        );
        if (selectedOrder) {
          onChangeSelection(selectedOrder);
        }
      }}
      onUpdateSearchTerm={(value) => {
        setCurrentSearchTerm(value);
      }}
      currentSearchTerm={currentSearchTerm}
      orders={filterIfNeeded(zohoSalesOrders, currentSearchTerm)}
    />
  );
};
