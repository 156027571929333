import { Text, TextProps } from "@chakra-ui/react";
import { OrderDataFragment } from "data";

interface OrderNameLabelProps extends TextProps {
  orderData?: OrderDataFragment | null;
}

export const OrderNameLabel = (props: OrderNameLabelProps) => {
  const { orderData, ...rest } = props;
  return orderData ? (
    <Text {...rest}>
      {orderData.attributes?.zoho_sales_order?.data?.attributes?.accountName}{" "}
      {
        orderData.attributes?.zoho_sales_order?.data?.attributes
          ?.salesOrderNumber
      }
    </Text>
  ) : null;
};
