import { Select } from "components/forms/fields";

const timeLabel = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes} minutes`;
  }

  const hours = Math.floor(minutes / 60);
  const leftOvers = minutes % 60;

  return `${hours} hour${hours > 1 ? "s" : ""} ${
    leftOvers > 0 ? `${leftOvers} minutes` : ""
  }`;
};

interface SelectTimeProps {
  name: string;
  label: string;
  placeholder: string;
  start: number;
  end: number;
  step: number;
}

const generateRange = ({
  start,
  end,
  step,
}: {
  start: number;
  end: number;
  step: number;
}) => {
  const result = [];
  let current = start;

  while (current <= end) {
    result.push(current);
    current += step;
  }

  return result;
};

export const SelectTime = ({
  name,
  label,
  placeholder,
  start,
  end,
  step,
}: SelectTimeProps) => {
  const range = generateRange({ start, end, step });
  return (
    <Select name={name} label={label} placeholder={placeholder}>
      {range.map((v) => (
        <option key={v} value={v}>
          {timeLabel(v)}
        </option>
      ))}
    </Select>
  );
};
