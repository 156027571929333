import { HStack } from "@chakra-ui/react";
import { useOrderPackage } from "features/order-packages/context/OrderPackage";
import { LinkToCustomer } from "features/customers/components/LinkToCustomer";
import { CustomerNameLabel } from "features/customers/components/CustomerNameLabel";
import { DownloadReportButton } from "features/reports/components/DownloadReportButton";

export const OrderPackagePageSubtitle = () => {
  const { orderPackage } = useOrderPackage();
  return !orderPackage ? null : (
    <HStack>
      <LinkToCustomer customer={orderPackage.attributes?.customer?.data}>
        <CustomerNameLabel customer={orderPackage.attributes?.customer?.data} />
      </LinkToCustomer>
      <DownloadReportButton
        size="xs"
        label="Download CSV"
        reportName="package content data report"
        parameters={{ packageId: orderPackage.id }}
      />
    </HStack>
  );
};
