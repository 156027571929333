import { OrderItemDataFragment, Enum_Orderitemaction_Action, Enum_Device_Type } from "data";
import { FanIcon } from "components/Icons";
import { ActionComponent, ActionComponentVariant } from "components/ActionComponent";
import { useRegisterOrderItemAction } from "features/order-items/data/hooks/order-item-actions";

interface ReplaceFanActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const ReplaceFanAction = (props: ReplaceFanActionProps) => {
  const { orderItem, variant } = props;
  const { registerAction } = useRegisterOrderItemAction();
  const isMiner = orderItem.attributes?.device?.data?.attributes?.type === Enum_Device_Type.Miner;

  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await registerAction({
            orderItem,
            action: {
              action: isMiner
                ? Enum_Orderitemaction_Action.ReplaceMinerFan
                : Enum_Orderitemaction_Action.ReplacePsuFan,
            },
          });
        }
      }}
      variant={variant}
      actionIcon={<FanIcon />}
      actionLabel='Replace Miner Fan'
      data-cy='action-replace-miner-fan'
    />
  );
};

ReplaceFanAction.defaultProps = {
  variant: "menu-item",
};
