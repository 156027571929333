import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PasswordField } from "components/forms/PasswordField";
import { validateEmail } from "data/helpers";
import { useAuthentication } from "features/accounts/context/Authentication";

interface LoginFormData {
  email: string;
  password: string;
}

interface LoginFormValidation {
  isEmailInvalid: boolean;
  isPasswordInvalid: boolean;
}

interface LoginProps {
  redirect?: string | null;
}

export const LoginForm = (props: LoginProps) => {
  const { login } = useAuthentication();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [validationData, setValidationData] = useState<LoginFormValidation>({
    isEmailInvalid: false,
    isPasswordInvalid: false,
  });

  const onSubmit = async () => {
    const isEmailInvalid = !validateEmail(formData.email);
    const isPasswordInvalid = formData.password === "";
    setValidationData({
      isEmailInvalid,
      isPasswordInvalid,
    });

    if (isEmailInvalid || isPasswordInvalid) {
      return;
    }

    setIsLoading(true);

    try {
      await login({
        email: formData.email,
        password: formData.password,
        redirect: props.redirect,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <Stack spacing="5">
        <FormControl isInvalid={validationData.isEmailInvalid}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            onChange={(e) => {
              setFormData((fd) =>
                Object.assign({}, fd, { email: e.target.value })
              );
            }}
            id="email"
            name="email"
            type="email"
            data-cy="email"
            value={formData.email}
            errorBorderColor="crimson"
            required
          />
        </FormControl>
        <PasswordField
          data-cy="password"
          isInvalid={validationData.isPasswordInvalid}
          onChange={(e) => {
            setFormData((fd) =>
              Object.assign({}, fd, { password: e.target.value })
            );
          }}
          value={formData.password}
          errorBorderColor="crimson"
          name="password"
        />
      </Stack>
      <HStack py="6" justify="space-between">
        <Link to={"/account/forgot-password"}>
          <Button
            data-cy="forgot-password"
            variant="link"
            colorScheme="blue"
            size="sm"
          >
            Forgot password?
          </Button>
        </Link>
      </HStack>
      <Stack spacing="6">
        <Button
          data-cy="login"
          isLoading={isLoading}
          type="submit"
          variant="primary"
        >
          Sign in
        </Button>
      </Stack>
    </form>
  );
};
