import { useState } from "react";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "components/Icons";
import { AutocompleteSearchResults } from "features/search/components/AutocompleteSearchResults";

interface AutocompleteSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AutocompleteSearchModal = (
  props: AutocompleteSearchModalProps
) => {
  const { isOpen, onClose } = props;
  const [query, setQuery] = useState<string>("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack>
            <InputGroup mt="4">
              <InputLeftElement pointerEvents="none">
                <Icon as={SearchIcon} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input
                autoFocus
                value={query}
                onChange={(e) => {
                  setQuery(e.currentTarget.value);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                placeholder="Search"
              />
            </InputGroup>
            {query !== "" ? (
              <AutocompleteSearchResults
                onClickResult={() => {
                  onClose();
                }}
                query={query}
              />
            ) : (
              <Stack padding={8} textAlign="center">
                <Text color="gray.500">
                  Search for customers and their devices
                </Text>
              </Stack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
