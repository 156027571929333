import { HStack, Text, TextProps } from "@chakra-ui/react";
import { CustomerDataFragment } from "data";

interface CustomerNameLabelProps extends TextProps {
  customer?: CustomerDataFragment | null;
}

export const CustomerNameLabel = (props: CustomerNameLabelProps) => {
  const { customer, ...rest } = props;
  return customer ? (
    <HStack>
      <Text {...rest}>{customer.attributes?.accountName}</Text>
    </HStack>
  ) : null;
};
