import { ReactNode, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "features/accounts/context/Authentication";

interface AuthRequiredContainerProps {
  children: ReactNode;
}

export const AuthRequiredContainer = (
  props: AuthRequiredContainerProps
): ReactElement => {
  const { loginData } = useAuthentication();

  if (loginData?.isCustomer && loginData?.customers.length === 0) {
    // customer did not complete profile setup
    return <Navigate to="/account/setup-customer-account" replace />;
  }

  if (
    loginData &&
    !loginData.profile?.attributes?.user?.data?.attributes?.confirmed
  ) {
    // email not confirmed
    return <Navigate to="/account/confirm-email" replace />;
  }

  if (loginData) {
    return <>{props.children}</>;
  }

  return <Navigate to="/account/login" replace />;
};
