import { useParams } from "react-router-dom";
import {
  InspectionDataFragment,
  Enum_Device_Type,
  Enum_Device_Make,
  Enum_Device_Model,
} from "data";
import { LabelPrinterProvider } from "context/LabelPrinter";
import { useDevicesData } from "features/devices/data/hooks/devices";
import { useInspectionDetails } from "features/inspections/data/hooks";
import { Page } from "components/layout/Page";
import { InspectionRacksProvider } from "features/inspections/context/InspectionRacks";
import { InspectionSettingsProvider } from "features/inspections/context/InspectionSettings";
import { InspectionRacks } from "features/inspections/components/InspectionRacks";
import { InspectionStats } from "features/inspections/components/InspectionStats";

const ConnectedInspectionPage = ({
  inspection,
}: {
  inspection: InspectionDataFragment;
}) => {
  const { devices } = useDevicesData();
  const device = devices.find((d) => {
    return (
      d.attributes?.type === Enum_Device_Type.Miner &&
      d.attributes.make === Enum_Device_Make.Antminer &&
      d.attributes.model === Enum_Device_Model.S17
    );
  });
  return device ? (
    <Page
      title={inspection.attributes?.name}
      subTitle={<InspectionStats inspection={inspection} />}
    >
      <InspectionSettingsProvider inspection={inspection}>
        <InspectionRacksProvider inspection={inspection}>
          <InspectionRacks />
        </InspectionRacksProvider>
      </InspectionSettingsProvider>
    </Page>
  ) : null;
};

export const InspectionPage = () => {
  const { inspectionId } = useParams();
  const { inspection, loading } = useInspectionDetails({
    inspectionId: inspectionId || "",
  });

  return loading || !inspection ? (
    <strong>Loading...</strong>
  ) : (
    <LabelPrinterProvider>
      <ConnectedInspectionPage inspection={inspection} />
    </LabelPrinterProvider>
  );
};
