import {
  MinerInspectionReportDataFragment,
  HashboardInspectionReportDataFragment,
  HashboardAdvancedInspectionReportDataFragment,
  PsuInspectionReportDataFragment,
  PsuInspectionReport,
  OrderItemDataFragment,
} from "data";
import { ActionCard } from "./Base";
import { DiagnosticsReport } from "features/diagnostics/components/DiagnosticsReport";
import { AdvancedDiagnosticReport } from "features/diagnostics/components/AdvancedDiagnosticReport";
import { PSUInspectionReportPreview } from "features/diagnostics/components/psus/PSUInspectionReportPreview";

interface InspectionActionCardProps {
  orderItem: OrderItemDataFragment;
  minerInspection?: MinerInspectionReportDataFragment | null;
  hashboardInspection?: HashboardInspectionReportDataFragment | null;
  advancedHashboardInspection?: HashboardAdvancedInspectionReportDataFragment | null;
  psuInspection?: PsuInspectionReportDataFragment | null;
}

export const InspectionActionCard = ({
  minerInspection,
  hashboardInspection,
  advancedHashboardInspection,
  psuInspection,
  orderItem,
}: InspectionActionCardProps) => (
  <ActionCard>
    {minerInspection && <DiagnosticsReport report={minerInspection} />}
    {hashboardInspection && <DiagnosticsReport report={hashboardInspection} />}
    {psuInspection?.attributes && (
      <PSUInspectionReportPreview
        orderItem={orderItem}
        report={psuInspection.attributes as PsuInspectionReport}
      />
    )}
    {advancedHashboardInspection && (
      <AdvancedDiagnosticReport report={advancedHashboardInspection} />
    )}
  </ActionCard>
);
