import { AwesomeQRCode } from "@awesomeqr/react";

interface QRCodeProps {
  relativeURL: string;
  size?: number;
}

export const QRCode = (props: QRCodeProps) => {
  return (
    <AwesomeQRCode
      options={{
        margin: 0,
        size: props.size || 300,
        text: `${window.location.origin}${props.relativeURL}`,
        dotScale: 1,
      }}
    />
  );
};
