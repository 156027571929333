import {
  OrderItemSummaryQuery,
  OrderItemSummaryQueryVariables,
  ReceiveDeviceRequestInput,
  useCreateReceiveDeviceRequestMutation,
  useUpdateCustomerDeviceMutation,
  OrderDataFragment,
  OrderItemSummaryDocument,
  DeviceDataFragment,
  ComponentHashboardHashboardMetadataInput,
  ORDER_ITEM_TAGS,
} from "data";
import {
  useCreateAction,
  expandActionDataPiece,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";
import { useWrapWithNotifications } from "context/Notifications";
import { useOrderItemsForOrder } from "features/order-items/context/OrderItemsForOrder";
import { useUpdateOrderItem } from "features/order-items/data/hooks/order-items";
import {
  QuickInspectReport,
  NumberOfASICsDetected,
} from "features/diagnostics/data";

interface ReceiveDeviceProps {
  order: OrderDataFragment;
  device: DeviceDataFragment;
  receiveDeviceRequest: ReceiveDeviceRequestInput;
  hashrate?: string;
  hashboardMetadata?: ComponentHashboardHashboardMetadataInput;
  quickInspectionReport?: QuickInspectReport | null;
}

interface UseReceiveDeviceHookData {
  receiveDevice(props: ReceiveDeviceProps): Promise<void | null>;
}

export const useReceiveDevice = (): UseReceiveDeviceHookData => {
  const orderItemsContext = useOrderItemsForOrder();
  const [createReceiveDeviceRequest] = useCreateReceiveDeviceRequestMutation();
  const [updateCustomerDevice] = useUpdateCustomerDeviceMutation();
  const { updateOrderItem } = useUpdateOrderItem();
  const { createAction } = useCreateAction();

  const receiveDevice = useWrapWithNotifications<ReceiveDeviceProps, void>({
    successMessage: "Device received",
    runner: async (props) => {
      const {
        device,
        receiveDeviceRequest,
        order,
        hashrate,
        hashboardMetadata,
        quickInspectionReport,
      } = props;

      if (hashrate && receiveDeviceRequest.customer_device) {
        await updateCustomerDevice({
          variables: {
            id: receiveDeviceRequest.customer_device,
            data: {
              hashrate,
            },
          },
        });
      }

      if (hashboardMetadata && receiveDeviceRequest.customer_device) {
        await updateCustomerDevice({
          variables: {
            id: receiveDeviceRequest.customer_device,
            data: {
              hashboardMetadata,
            },
          },
        });
      }

      const { data } = await createReceiveDeviceRequest({
        variables: {
          data: receiveDeviceRequest,
        },
        async update(cache, { data }) {
          const newOrderItem =
            data?.createReceiveDeviceRequest?.data?.attributes?.order_item;

          if (!newOrderItem?.data?.attributes?.parent?.data?.id) {
            // cache updates are only required when dealing with subcomponents
            return;
          }

          // if we are dealing with a subcomponent,
          // update order item summary cache data

          const orderItemSummaryQuerySpec = {
            query: OrderItemSummaryDocument,
            variables: {
              id: newOrderItem?.data?.attributes?.parent?.data?.id || "",
            },
          };

          const orderItemSummary = cache.readQuery<
            OrderItemSummaryQuery,
            OrderItemSummaryQueryVariables
          >(orderItemSummaryQuerySpec);

          if (orderItemSummary?.subcomponentOrderItems) {
            cache.writeQuery<
              OrderItemSummaryQuery,
              OrderItemSummaryQueryVariables
            >(
              Object.assign({}, orderItemSummaryQuerySpec, {
                data: Object.assign({}, orderItemSummary, {
                  subcomponentOrderItems: Object.assign(
                    {},
                    orderItemSummary.subcomponentOrderItems,
                    {
                      data: [
                        ...(orderItemSummary.subcomponentOrderItems?.data ||
                          []),
                        newOrderItem?.data,
                      ],
                    }
                  ),
                }),
              })
            );
          }
        },
      });
      await createAction({
        type: Enum_Action_Type.ReceiveOrderItem,
        scope: Enum_Action_Scope.Order,
        data: {
          ...expandActionDataPiece(
            data?.createReceiveDeviceRequest?.data?.attributes?.order_item?.data
          ),
        },
        context: {
          order: order,
          device,
          customerDevice:
            data?.createReceiveDeviceRequest?.data?.attributes?.customer_device
              ?.data,
        },
      });

      // if quick inspect is available, tag order item accordingly
      if (
        quickInspectionReport &&
        data?.createReceiveDeviceRequest?.data?.attributes?.order_item?.data?.id
      ) {
        const asicsDetectedToTag = (
          asicsDetected: NumberOfASICsDetected
        ): string => {
          switch (asicsDetected) {
            case NumberOfASICsDetected.AllASICs:
              return ORDER_ITEM_TAGS.QuickInspectAllASICs;
            case NumberOfASICsDetected.NoASICs:
              return ORDER_ITEM_TAGS.QuickInspectNoASICs;
            case NumberOfASICsDetected.SomeASICs:
              return ORDER_ITEM_TAGS.QuickInspectSomeASICs;
          }
        };

        const orderItemId =
          data?.createReceiveDeviceRequest?.data?.attributes?.order_item?.data
            ?.id;

        await updateOrderItem(orderItemId, {
          tags: [asicsDetectedToTag(quickInspectionReport.asicsDetected)],
        });
      }

      if (orderItemsContext?.refetch) {
        await orderItemsContext.refetch();
      }
    },
  });

  return {
    receiveDevice,
  };
};
