import { createContext, ReactNode, useContext } from "react";
import { OrderPackageDataFragment, OrderItemDataFragment } from "data";
import {
  useOrderPackageById,
  useAddOrderItemToOrderPackage,
  useRemoveOrderItemFromPackage,
} from "features/order-packages/data/hooks/order-packages";

interface OrderPackageContextData {
  loading: boolean;
  orderPackage?: OrderPackageDataFragment | null;
  orderItems: OrderItemDataFragment[];
  addOrderItem: (orderItem: OrderItemDataFragment) => Promise<void>;
  removeOrderItemFromPackage: (
    orderItem: OrderItemDataFragment
  ) => Promise<void>;
}

interface OrderPackageProviderProps {
  orderPackageId: string;
  children: ReactNode;
}

const OrderPackageContext = createContext<OrderPackageContextData>(
  {} as OrderPackageContextData
);

export const useOrderPackage = () => {
  return useContext(OrderPackageContext);
};

export const OrderPackageProvider = (props: OrderPackageProviderProps) => {
  const { loading, orderPackage, orderItems } = useOrderPackageById({
    orderPackageId: props.orderPackageId,
  });
  const { addOrderItemToOrderPackage } = useAddOrderItemToOrderPackage();
  const { removeOrderItemFromPackage } = useRemoveOrderItemFromPackage();

  return (
    <OrderPackageContext.Provider
      value={{
        loading,
        orderPackage,
        orderItems,
        async addOrderItem(orderItem) {
          if (orderPackage) {
            await addOrderItemToOrderPackage({
              orderPackage,
              orderItem,
            });
          }
        },
        async removeOrderItemFromPackage(orderItem) {
          await removeOrderItemFromPackage(orderItem);
        },
      }}
    >
      {props.children}
    </OrderPackageContext.Provider>
  );
};
