import { Text, Stack, Textarea, Divider } from "@chakra-ui/react";
import { HashboardDiagnosticsReport } from "features/diagnostics/data";
import {
  CheckboxListCard,
  CheckboxCardGroup,
} from "components/forms/CheckboxCardGroup";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";

export const OtherComponentsDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const hashboardDiagnosticsReport = data as HashboardDiagnosticsReport;
  return (
    <Stack spacing="4">
      <CheckboxCardGroup
        onChange={(value) => {
          updateData(
            Object.assign({}, hashboardDiagnosticsReport, {
              otherComponentsNeeded: value,
            })
          );
        }}
        value={hashboardDiagnosticsReport.otherComponentsNeeded}
      >
        {["LDOs", "EEPROM", "PIC", "Other"].map((component) => {
          return (
            <CheckboxListCard key={component} value={component}>
              <Text color="emphasized" fontWeight="medium" fontSize="sm">
                {component}
              </Text>
            </CheckboxListCard>
          );
        })}
      </CheckboxCardGroup>
      <Divider />
      <Textarea
        onChange={(e) => {
          updateData(
            Object.assign({}, hashboardDiagnosticsReport, {
              notes: e.currentTarget.value,
            })
          );
        }}
        value={hashboardDiagnosticsReport.notes}
        placeholder="Notes"
      />
    </Stack>
  );
};
