import { Outlet } from "react-router-dom";
import {
  Container,
  Stack,
  Box,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

export const ChangeLogLayout = () => (
  <Container
    maxW="3xl"
    py={{ base: "12", md: "24" }}
    px={{ base: "0", sm: "8" }}
  >
    <Stack spacing="8">
      <Box
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
        boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="6">
          <Outlet />
        </Stack>
      </Box>
    </Stack>
  </Container>
);
