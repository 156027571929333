import React from "react";
import {
  Button,
  Circle,
  Divider,
  Stack,
  Text,
  HStack,
  Box,
  Flex,
  keyframes,
} from "@chakra-ui/react";
import {
  ActionFiltersInput,
  ActionDataFragment,
  Enum_Action_Type,
  OrderItemDataFragment,
} from "data";
import { Loader } from "components";
import { deviceTypeToString } from "features/devices/data";
import { DateTimeText } from "components/utils/DateTimeText";
import { UserProfileLabel } from "features/accounts/components/UserProfileLabel";
import { completeDeviceName } from "features/devices/data";
import { LinkToOrderItem } from "features/order-items/components/LinkToOrderItem";
import {
  useActions,
  useRealtimeActions,
} from "features/actions/data/hooks/actions";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { QAActionCard } from "features/actions/components/cards/QA";
import { InspectionActionCard } from "features/actions/components/cards/Inspection";
import { RepairActionCard } from "features/actions/components/cards/Repair";
import { NoteActionCard } from "features/actions/components/cards/Note";
import { ShowForCustomers, ShowForAllTechs } from "features/accounts/";
import { getActionDescription } from "features/actions/data";

type ActionTimeLineLevel = "order" | "order-item";

interface ActionsTimelineProps {
  filters: ActionFiltersInput;
  level: ActionTimeLineLevel;
}

interface TimelineElementProps {
  action: ActionDataFragment;
  level: ActionTimeLineLevel;
}

interface ActionElementProps {
  action: ActionDataFragment;
  level: ActionTimeLineLevel;
}

export const TimelineCircle = () => {
  return (
    <Circle
      size="4"
      bg={"inherit"}
      borderWidth={"2px"}
      borderColor={"inherit"}
      //   {...props}
    >
      <Circle bg={"border"} size="3" />
    </Circle>
  );
};

const ActionDescriptionTextWrapper = (props: { children: React.ReactNode }) => {
  return (
    <Text ml={2} color="fg.emphasized" fontWeight="medium">
      {props.children}
    </Text>
  );
};

const VerbAndOrderItem = ({
  level,
  verb,
  orderItem,
}: {
  level: ActionTimeLineLevel;
  verb: string;
  orderItem?: OrderItemDataFragment | null;
}) => (
  <>
    <ActionDescriptionTextWrapper>{verb}</ActionDescriptionTextWrapper>
    {level === "order" ? (
      <Box ml={1} fontWeight="bold">
        <ShowForAllTechs>
          <LinkToOrderItem orderItem={orderItem}>
            {completeDeviceName(
              orderItem?.attributes?.device?.data,
              orderItem?.attributes?.customer_device?.data?.attributes
                ?.hashrate || undefined
            )}
            {` ${deviceTypeToString(
              orderItem?.attributes?.device?.data?.attributes?.type
            )}`}
            <CustomerDeviceShortIdLabel
              ml={2}
              fontSize="xs"
              customerDevice={orderItem?.attributes?.customer_device?.data}
            />
          </LinkToOrderItem>
        </ShowForAllTechs>
        <ShowForCustomers>
          <>
            {completeDeviceName(
              orderItem?.attributes?.device?.data,
              orderItem?.attributes?.customer_device?.data?.attributes
                ?.hashrate || undefined
            )}
            {` ${deviceTypeToString(
              orderItem?.attributes?.device?.data?.attributes?.type
            )}`}
            <CustomerDeviceShortIdLabel
              ml={2}
              fontSize="xs"
              customerDevice={orderItem?.attributes?.customer_device?.data}
            />
          </>
        </ShowForCustomers>
      </Box>
    ) : null}
  </>
);

const ActionElement = (props: ActionElementProps) => {
  const { action, level } = props;
  const actionDescription = ((a: ActionDataFragment) => {
    switch (a.attributes?.type) {
      case Enum_Action_Type.ReceiveOrder:
      case Enum_Action_Type.ChangeOrderStatus:
      case Enum_Action_Type.AddOrderLayoutItem:
      case Enum_Action_Type.RemoveOrderItem:
      case Enum_Action_Type.RegisterOrderItemAction:
        return (
          <ActionDescriptionTextWrapper>
            {getActionDescription(a)}
          </ActionDescriptionTextWrapper>
        );
      case Enum_Action_Type.ChangeOrderItemStatus:
      case Enum_Action_Type.ReceiveOrderItem:
      case Enum_Action_Type.DiagnoseOrderItem:
      case Enum_Action_Type.RepairOrderItem:
      case Enum_Action_Type.QaOrderItem:
      case Enum_Action_Type.PackageOrderItem:
      case Enum_Action_Type.RemoveOrderItemFromPackage:
      case Enum_Action_Type.AddNote:
      case Enum_Action_Type.ScrapForParts:
      case Enum_Action_Type.ReplaceOrderItem:
        return (
          <VerbAndOrderItem
            level={level}
            verb={getActionDescription(a)}
            orderItem={a.attributes.order_item?.data}
          />
        );
      case Enum_Action_Type.AddTagToOrderItem:
        return (
          <VerbAndOrderItem
            level={level}
            verb={getActionDescription(a)}
            orderItem={a.attributes.order_item?.data}
          />
        );
      case Enum_Action_Type.RemoveTagFromOrderItem:
        return (
          <VerbAndOrderItem
            level={level}
            verb={getActionDescription(a)}
            orderItem={a.attributes.order_item?.data}
          />
        );
      default:
        return (
          <ActionDescriptionTextWrapper>{`${a.attributes?.type}`}</ActionDescriptionTextWrapper>
        );
    }
  })(action);

  const actionCard = ((a: ActionDataFragment) => {
    switch (a.attributes?.type) {
      case Enum_Action_Type.DiagnoseOrderItem:
        return (
          a.attributes.order_item?.data && (
            <InspectionActionCard
              orderItem={a.attributes.order_item?.data}
              minerInspection={a.attributes.miner_inspection_report?.data}
              hashboardInspection={
                a.attributes.hashboard_inspection_report?.data
              }
              advancedHashboardInspection={
                a.attributes.hashboard_advanced_inspection_report?.data
              }
              psuInspection={a.attributes.psu_inspection_report?.data}
            />
          )
        );
      case Enum_Action_Type.QaOrderItem:
        return (
          <QAActionCard
            controlBoardReport={a.attributes?.control_board_qa_report?.data}
            minerReport={a.attributes?.miner_qa_report?.data}
            hashboardReport={a.attributes.hashboard_qa_report?.data}
            psuReport={a.attributes.psu_qa_report?.data}
          />
        );
      case Enum_Action_Type.RepairOrderItem:
        return (
          a.attributes.order_item?.data && (
            <RepairActionCard
              orderItem={a.attributes.order_item?.data}
              hashboardRepair={a.attributes?.hashboard_repair?.data}
              psuRepair={a.attributes?.psu_repair?.data}
              basicHashboardRepair={a.attributes.hashboard_basic_repair?.data}
            />
          )
        );
      case Enum_Action_Type.AddNote:
        return <NoteActionCard note={a.attributes?.note?.data} />;
      default:
        return null;
    }
  })(action);

  return (
    <Stack spacing="4">
      <Flex flexWrap="wrap">
        <UserProfileLabel userProfile={action.attributes?.user_profile?.data} />
        {actionDescription}
      </Flex>
      {actionCard}
    </Stack>
  );
};

const appear = keyframes`  
  from { opacity: 0; }   
  to { opacity: 1; } 
`;

const TimelineElement = (props: TimelineElementProps) => {
  const { action, level } = props;
  return (
    <Stack spacing="4" direction="row">
      <Stack spacing="0" align="center">
        <TimelineCircle />
        <Divider
          orientation="vertical"
          borderWidth="1px"
          borderColor={"inherit"}
        />
      </Stack>
      <Stack animation={`${appear} 1s linear`} spacing="0.5" p="4">
        <Stack mt={-5}>
          <DateTimeText
            color="fg.muted"
            fontSize="small"
            value={action.attributes?.createdAt}
            format="MMMM Do YYYY, h:mm a"
          />
          <ActionElement action={action} level={level} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const ActionsTimeline = (props: ActionsTimelineProps) => {
  const { loading, actions, hasNext, hasPrevious, next, previous } = useActions(
    props.filters
  );
  return loading ? (
    <Loader />
  ) : (
    <>
      <Stack spacing="0">
        {actions.map((a) => (
          <TimelineElement action={a} level={props.level} />
        ))}
      </Stack>
      <HStack mt={4}>
        {hasPrevious && !loading ? (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              previous();
            }}
          >
            Previous
          </Button>
        ) : null}
        {hasNext && !loading ? (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              next();
            }}
          >
            Next
          </Button>
        ) : null}
      </HStack>
    </>
  );
};

export const RealtimeActionsTimeline = () => {
  const { actions, loading } = useRealtimeActions({
    refreshTimeoutInMs: 5000,
  });
  return loading ? (
    <Loader />
  ) : (
    <Stack spacing="0">
      {actions.map((a) => (
        <TimelineElement action={a} level="order" />
      ))}
    </Stack>
  );
};
