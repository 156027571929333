import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { ReturnToCustomerIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";

interface ReturnToCustomerActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

export const ReturnToCustomerAction = (props: ReturnToCustomerActionProps) => {
  const { orderItem, variant } = props;
  const { updateOrderItemStatus } = useUpdateOrderItemStatus();
  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await updateOrderItemStatus(
            orderItem,
            Enum_Orderitem_Status.QaFailReturnToCustomer,
            true
          );
        }
      }}
      variant={variant}
      actionIcon={<ReturnToCustomerIcon />}
      actionLabel="Return to Customer"
      data-cy="action-return-to-customer"
    />
  );
};

ReturnToCustomerAction.defaultProps = {
  variant: "button",
};
