import { Enum_Hashboardadvancedinspectionreport_Diagnosis } from "data";

interface ParsedDiagnosis {
  category: string;
  diagnosis: string;
}

export const parseHashboardDiagnosis = (
  d?: Enum_Hashboardadvancedinspectionreport_Diagnosis | null
): ParsedDiagnosis => {
  switch (d) {
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.Unrepairable:
      return { category: "General", diagnosis: "Unrepairable" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.PowerMosfetCircuit:
      return { category: "Power Circuit", diagnosis: "MOSFET Circuit" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.PowerBoostCircuit:
      return { category: "Power Circuit", diagnosis: "Boost Circuit" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.CommunicationsEeprom:
      return { category: "Communications Circuit", diagnosis: "EEPROM" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.CommunicationsPic:
      return { category: "Communications Circuit", diagnosis: "PIC" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicPowerLdo:
      return { category: "ASIC Power", diagnosis: "LDO" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalRx:
      return { category: "ASIC Signal", diagnosis: "RX" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalTx:
      return { category: "ASIC Signal", diagnosis: "TX" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalRst:
      return { category: "ASIC Signal", diagnosis: "RST" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalBi:
      return { category: "ASIC Signal", diagnosis: "BI" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalClk:
      return { category: "ASIC Signal", diagnosis: "CLK" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicSignalSignalGeneration:
      return { category: "ASIC Signal", diagnosis: "Signal Generation" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AsicCircuitOther:
      return { category: "ASIC Circuit", diagnosis: "Other" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.TempSensorTempSensorCircuit:
      return { category: "Temp Sensor", diagnosis: "Temp Sensor Circuit" };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.ThermalsThermalCompoundReplacement:
      return {
        category: "Thermals",
        diagnosis: "Thermal Compound Replacement",
      };
    case Enum_Hashboardadvancedinspectionreport_Diagnosis.AdvancedDiagnosisIntermittentIssues:
      return {
        category: "Advanced Diagnosis",
        diagnosis: "Intermitten Issues",
      };
    default:
      return { category: "Unknown", diagnosis: "Unknown" };
  }
};
