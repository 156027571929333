import { ReactNode } from "react";
import { Card, CardBody } from "@chakra-ui/react";

interface ActionCardProps {
  children: ReactNode;
}

export const ActionCard = (props: ActionCardProps) => (
  <Card mb="6" maxW="lg">
    <CardBody>{props.children}</CardBody>
  </Card>
);
