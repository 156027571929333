import {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
} from "react";
import {
  useApplicationConfigurationQuery,
  ApplicationConfigurationDataFragment,
} from "data";
import { TestModeBanner } from "components/utils/TestModeBanner";

interface ApplicationConfigurationContextData {
  applicationConfiguration: ApplicationConfigurationDataFragment | null;
}

const ApplicationConfigurationContext =
  createContext<ApplicationConfigurationContextData>(
    {} as ApplicationConfigurationContextData
  );

interface ApplicationConfigurationProviderProps {
  children: ReactNode;
}

export const ApplicationConfigurationProvider = (
  props: ApplicationConfigurationProviderProps
) => {
  const [applicationConfiguration, setApplicationConfiguration] =
    useState<ApplicationConfigurationDataFragment | null>(null);
  const { data } = useApplicationConfigurationQuery();

  useEffect(() => {
    if (data && data.applicationConfiguration?.data) {
      setApplicationConfiguration(data.applicationConfiguration?.data);
    }
  }, [data]);

  return (
    <ApplicationConfigurationContext.Provider
      value={{ applicationConfiguration }}
    >
      {applicationConfiguration?.attributes?.isTestMode ? (
        <TestModeBanner />
      ) : null}
      {props.children}
    </ApplicationConfigurationContext.Provider>
  );
};

export const useApplicationConfiguration = () =>
  useContext<ApplicationConfigurationContextData>(
    ApplicationConfigurationContext
  );
