import { Text, TextProps } from "@chakra-ui/react";

export const YesLabel = () => (
  <Text fontWeight="bold" color="green">
    Yes
  </Text>
);

export const NoLabel = () => (
  <Text fontWeight="bold" color="red">
    No
  </Text>
);

export const PassLabel = () => (
  <Text fontWeight="bold" color="green">
    Pass
  </Text>
);

export const FailLabel = () => (
  <Text fontWeight="bold" color="red">
    Fail
  </Text>
);

export const MultilineText = (props: TextProps) => {
  const { children, ...rest } = props;
  return (
    <Text
      {...rest}
      dangerouslySetInnerHTML={{
        __html: ((children && (children as string)) || "").replace(
          /\n/g,
          "<br />"
        ),
      }}
    />
  );
};
