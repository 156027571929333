import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  CustomerDeviceDataFragment,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
  DeviceDataFragment,
  Enum_Device_Type,
  OrderDataFragment,
  ReceiveDeviceRequestInput,
  ComponentHashboardHashboardMetadataInput,
} from "data";
import { useReceiveDevice } from "features/customer-devices/data/hooks/receive-device";
import { QuickInspectReport } from "features/diagnostics/data";

type ReceiveDeviceStage =
  | "find"
  | "miner-details"
  | "hashboard-details"
  | "display";

interface ReceiveDeviceProviderProps {
  children: ReactNode;
  order: OrderDataFragment;
}

type ReceiveDeviceTarget =
  | OrderItemDataFragment
  | OrderItemSubcomponentDataFragment
  | null;

interface ReceiveDeviceData {
  target: ReceiveDeviceTarget;
  getDevice: () => DeviceDataFragment | null;
  setTarget: (
    target: OrderItemDataFragment | OrderItemSubcomponentDataFragment
  ) => void;
  order: OrderDataFragment;
  selectedDevice: CustomerDeviceDataFragment | null;
  setSelectedDevice: (device: CustomerDeviceDataFragment) => void;
  receiveDeviceStage: ReceiveDeviceStage;
  subcomponentDevices: DeviceDataFragment[];
  setMinerDetails: ({
    subcomponentDevices,
    hashrate,
  }: {
    subcomponentDevices: DeviceDataFragment[];
    hashrate?: string;
  }) => void;
  setHashoardDetails: ({
    hashboardMetadata,
  }: {
    hashboardMetadata: ComponentHashboardHashboardMetadataInput;
  }) => void;
  receiveDevice(): Promise<void>;
  canReceiveDevice: boolean;
  clear: () => void;
  quickInspectionReport?: QuickInspectReport | null;
  setQuickInspectionReport: (report: QuickInspectReport) => void;
}

const ReceiveDeviceContext = createContext<ReceiveDeviceData>(
  {} as ReceiveDeviceData
);

export const useReceiveDeviceContext = () => {
  return useContext(ReceiveDeviceContext);
};

const getDeviceForTarget = (
  target: ReceiveDeviceTarget
): DeviceDataFragment | null => {
  if (!target) {
    return null;
  }

  if (target.__typename === "OrderItemEntity") {
    return (target as OrderItemDataFragment).attributes?.device?.data || null;
  }

  if (target.__typename === "OrderItemSubcomponentEntity") {
    return (
      (target as OrderItemSubcomponentDataFragment).attributes?.device?.data ||
      null
    );
  }

  return null;
};

export const ReceiveDeviceProvider = (props: ReceiveDeviceProviderProps) => {
  const { receiveDevice } = useReceiveDevice();
  const [receiveDeviceRequest, setReceiveDeviceRequest] =
    useState<ReceiveDeviceRequestInput>({});
  const [target, setTarget] = useState<ReceiveDeviceTarget>(null);
  const [selectedDevice, setSelectedDevice] =
    useState<CustomerDeviceDataFragment | null>(null);
  const [receiveDeviceStage, setReceiveDeviceStage] =
    useState<ReceiveDeviceStage>("find");
  const [subcomponentDevices, setSubcomponentDevices] = useState<
    DeviceDataFragment[]
  >([]);
  const [canReceiveDevice, setCanReceiveDevice] = useState<boolean>(false);
  const [hashrate, setHashrate] = useState<string | undefined>();
  const [hashboardMetadata, setHashboardMetadata] = useState<
    ComponentHashboardHashboardMetadataInput | undefined
  >();
  const [quickInspectionReport, setQuickInspectionReport] = useState<
    QuickInspectReport | null | undefined
  >(null);

  const clear = () => {
    setTarget(null);
    setReceiveDeviceStage("find");
    setSelectedDevice(null);
    setSubcomponentDevices([]);
  };

  useEffect(() => {
    setCanReceiveDevice(
      receiveDeviceStage === "display" && selectedDevice !== null
    );
  }, [selectedDevice, target, receiveDeviceStage]);

  return (
    <ReceiveDeviceContext.Provider
      value={{
        clear,
        order: props.order,
        target,
        getDevice() {
          return getDeviceForTarget(target);
        },
        setTarget(target) {
          setTarget(target);

          if (target.__typename === "OrderItemEntity") {
            setReceiveDeviceRequest(
              Object.assign(receiveDeviceRequest, {
                order_item: target.id,
              })
            );
          } else {
            setReceiveDeviceRequest(
              Object.assign(receiveDeviceRequest, {
                order_item_subcomponent: target.id,
                parent_order_item: (target as OrderItemSubcomponentDataFragment)
                  .attributes?.parent?.data?.id,
              })
            );
          }
        },
        selectedDevice,
        receiveDeviceStage,
        setSelectedDevice(device) {
          setSelectedDevice(device);

          setReceiveDeviceRequest(
            Object.assign(receiveDeviceRequest, {
              customer_device: device.id,
            })
          );

          if (
            device.attributes?.device?.data?.attributes?.type ===
            Enum_Device_Type.Miner
          ) {
            setReceiveDeviceStage("miner-details");
          } else if (
            device.attributes?.device?.data?.attributes?.type ===
            Enum_Device_Type.HashBoard
          ) {
            setReceiveDeviceStage("hashboard-details");
          } else {
            setReceiveDeviceStage("display");
          }
        },
        subcomponentDevices,
        setMinerDetails({ subcomponentDevices, hashrate }) {
          setReceiveDeviceRequest(
            Object.assign(receiveDeviceRequest, {
              hashboards: subcomponentDevices.filter(
                (d) => d.attributes?.type === Enum_Device_Type.HashBoard
              ).length,
              controlBoard: subcomponentDevices.filter(
                (d) => d.attributes?.type === Enum_Device_Type.ControlBoard
              ).length,
              psu: subcomponentDevices.filter(
                (d) => d.attributes?.type === Enum_Device_Type.Psu
              ).length,
            })
          );

          setSubcomponentDevices(subcomponentDevices);
          setHashrate(hashrate);
          setReceiveDeviceStage("display");
        },
        setHashoardDetails({ hashboardMetadata }) {
          setHashboardMetadata(hashboardMetadata);
          setReceiveDeviceStage("display");
        },
        canReceiveDevice,
        receiveDevice: async () => {
          if (selectedDevice?.attributes?.device?.data) {
            await receiveDevice({
              order: props.order,
              receiveDeviceRequest,
              device: selectedDevice?.attributes?.device?.data,
              hashrate,
              hashboardMetadata,
              quickInspectionReport,
            });
          }
        },
        quickInspectionReport,
        setQuickInspectionReport(report) {
          setQuickInspectionReport(report);
        },
      }}
    >
      {props.children}
    </ReceiveDeviceContext.Provider>
  );
};
