import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { DeleteIcon } from "components/Icons";
import { ActionComponent, ActionComponentVariant } from "components/ActionComponent";
import { ShowForOrderItemStatus } from "features/order-items/components/ShowForOrderItemStatus";
import { useScrapForParts } from "features/order-items/data/hooks/scrap-for-parts";

// Define props for the ScrapForPartsAction component
interface ScrapForPartsActionProps {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}

// ScrapForPartsAction component
export const ScrapForPartsAction = (props: ScrapForPartsActionProps) => {
  const { orderItem, variant } = props;
  // Use the custom hook to get the scrapeForParts function
  const { scrapeForParts } = useScrapForParts({ orderItem });

  return (
    // Conditionally render the action based on the order item status
    <ShowForOrderItemStatus
      orderItem={orderItem}
      status={[Enum_Orderitem_Status.ExcessiveDamageScrapForParts]}
      inverse
    >
      {/* Render the action component */}
      <ActionComponent
        action={async () => {
          await scrapeForParts();
        }}
        variant={variant}
        actionIcon={<DeleteIcon />}
        actionLabel='Scrap for Parts'
        data-cy='action-scrap-for-parts'
      />
    </ShowForOrderItemStatus>
  );
};

// Set default props for the component
ScrapForPartsAction.defaultProps = {
  variant: "button",
};
