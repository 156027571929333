import { Badge, BadgeProps } from "@chakra-ui/react";
import { OrderPackageDataFragment } from "data";
import { orderPackageStatusToString } from "./OrderPackageStatusLabel";

interface OrderPackageStatusBadgeProps extends BadgeProps {
  orderPackage?: OrderPackageDataFragment | null;
}
export const OrderPackageStatusBadge = (
  props: OrderPackageStatusBadgeProps
) => {
  const { orderPackage, ...rest } = props;
  return orderPackage ? (
    <Badge {...rest}>{orderPackageStatusToString(orderPackage)}</Badge>
  ) : null;
};
