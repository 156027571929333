import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Loader } from "components/Loader";

export const ChangeLogPage = () => {
  const { changelogId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    const runner = async () => {
      const r = await fetch(`/data/changelogs/${changelogId}.md`);
      const content = await r.text();
      setContent(content);
      setLoading(false);
    };
    runner();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div className="markdown-body">
      <ReactMarkdown>{content || ""}</ReactMarkdown>
    </div>
  );
};
