import {
  OrderItemDataFragment,
  useCreateOrderItemActionMutation,
  OrderItemActionInput,
} from "data";
import { useNotifications } from "context/Notifications";
import {
  expandActionDataPiece,
  useCreateAction,
  Enum_Action_Scope,
  Enum_Action_Type,
} from "features/actions";

interface UseRegisterOrderItemActionHookData {
  registerAction: ({
    action,
    orderItem,
  }: {
    action: OrderItemActionInput;
    orderItem: OrderItemDataFragment;
  }) => Promise<void>;
}

export const useRegisterOrderItemAction =
  (): UseRegisterOrderItemActionHookData => {
    const { createAction } = useCreateAction();
    const [createOrderItemAction] = useCreateOrderItemActionMutation();
    const { showSuccessMessage, showErrorMessage } = useNotifications();

    return {
      async registerAction({ action, orderItem }) {
        try {
          const { data } = await createOrderItemAction({
            variables: {
              data: Object.assign({}, action, {
                order_item: orderItem.id,
              }),
            },
          });

          await createAction({
            type: Enum_Action_Type.RegisterOrderItemAction,
            scope: Enum_Action_Scope.OrderItem,
            data: {
              ...expandActionDataPiece(orderItem),
              order_item_action: data?.createOrderItemAction?.data?.id,
            },
            context: {
              order: orderItem.attributes?.order?.data,
              device:
                orderItem.attributes?.customer_device?.data?.attributes?.device
                  ?.data,
              customerDevice: orderItem.attributes?.customer_device?.data,
              orderItemAction: data?.createOrderItemAction?.data,
            },
          });

          showSuccessMessage("Action registered!");
        } catch (err) {
          showErrorMessage("Something went wrong");
        }
      },
    };
  };
