import { HStack, Stack, StackDivider, Text } from "@chakra-ui/react";
import { NoteFiltersInput } from "data";
import { Loader } from "components/Loader";
import { DateTimeTextFromNow } from "components/utils/DateTimeText";
import { useNotes } from "features/notes/data/hooks/notes";
import { UserProfileLabel } from "features/accounts/components/UserProfileLabel";
import { ListUploads } from "features/uploads/components/ListUploads";

interface NotesListProps {
  filters: NoteFiltersInput;
}

export const NotesList = (props: NotesListProps) => {
  const { filters } = props;
  const { loading, notes } = useNotes(filters);

  return loading ? (
    <Loader />
  ) : (
    <Stack divider={<StackDivider />} spacing="4">
      {notes.map((note) => (
        <Stack
          data-cy="note-item"
          key={note.id}
          fontSize="sm"
          px="4"
          spacing="4"
        >
          <HStack>
            <UserProfileLabel userProfile={note.attributes?.author?.data} />
            <Text flex="1"></Text>
            <DateTimeTextFromNow value={note.attributes?.createdAt} />
          </HStack>

          {note.attributes?.content ? (
            <Text color="muted">{note.attributes?.content}</Text>
          ) : null}
          {note.attributes?.attachments?.data.length !== 0 ? (
            <ListUploads uploads={note.attributes?.attachments?.data || []} />
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};
