import { useContext, useState, ReactNode } from "react";
import {
  Enum_Orderitemreplacement_Status,
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
} from "data";
import {
  generateContextAndProvider,
  ActionPanelContextData,
} from "context/ActionPanel";
import { useLabelPrinter } from "context/LabelPrinter";
import { useReceiveDeviceContext } from "features/customer-devices/context/ReceiveDevice";
import { FindOrAddCustomerDevice } from "features/customer-devices/containers/FindOrAddCustomerDevice";
import { ReplaceSubcomponentForm } from "features/order-items/components/ReplaceSubcomponentForm";
import { useReplaceOrderItem } from "features/order-items/data/hooks/replacements";
import { CustomerDeviceLabel } from "features/customer-devices/components/CustomerDeviceLabel";
import { VisuallyHidden } from "@chakra-ui/react";

interface ReplaceSubcomponentPanelData {
  orderItem: OrderItemDataFragment;
  orderItemSubcomponent: OrderItemSubcomponentDataFragment;
}

const ReplaceSubcomponentPanel =
  generateContextAndProvider<ReplaceSubcomponentPanelData>();

export const useReplaceSubcomponentPanel =
  (): ActionPanelContextData<ReplaceSubcomponentPanelData> => {
    const context = useContext(ReplaceSubcomponentPanel.Context);

    if (!context) {
      throw new Error(
        "Calling useReplaceSubcomponentPanel outside of ReplaceSubcomponentPanelProvider"
      );
    }

    return context;
  };

export const ReplaceSubcomponentPanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { printableRef, print } = useLabelPrinter();
  const { clear, selectedDevice } = useReceiveDeviceContext();
  const { replaceOrderItem } = useReplaceOrderItem();
  const [replacementStatus, setReplacementStatus] =
    useState<Enum_Orderitemreplacement_Status>(
      Enum_Orderitemreplacement_Status.ScrapForParts
    );

  return (
    <ReplaceSubcomponentPanel.Provider
      onClose={() => {
        clear();
      }}
      enablePrimaryAction={selectedDevice !== null}
      primaryButtonText="Replace Device"
      onAction={async ({ data }) => {
        if (
          !data?.orderItem ||
          !data.orderItemSubcomponent ||
          !selectedDevice
        ) {
          return;
        }

        await replaceOrderItem({
          originalOrderItem: data?.orderItem,
          orderItemSubcomponent: data.orderItemSubcomponent,
          replacementCustomerDevice: selectedDevice,
          replacementStatus,
        });

        // XX: currently no way to test printing correctly
        // due to how react-to-print uses an iframe
        // @ts-ignore
        if (typeof window.Cypress === "undefined") {
          print();
        }
      }}
      renderHeader={({ data }) => null}
      renderBody={({ data }) => {
        if (!data?.orderItem || !data.orderItemSubcomponent) {
          return null;
        }

        if (!selectedDevice) {
          return <FindOrAddCustomerDevice />;
        }

        return (
          <>
            <VisuallyHidden>
              {/* Need this to print device label */}
              <CustomerDeviceLabel
                printableRef={printableRef}
                device={selectedDevice}
                w="40"
                h="40"
              />
            </VisuallyHidden>
            <ReplaceSubcomponentForm
              replacementStatus={replacementStatus}
              onChangeReplacementStatus={(status) => {
                setReplacementStatus(status);
              }}
              replacementCustomerDevice={selectedDevice}
              originalOrderItem={data?.orderItem}
              orderItemSubcomponent={data.orderItemSubcomponent}
            />
          </>
        );
      }}
    >
      {children}
    </ReplaceSubcomponentPanel.Provider>
  );
};
