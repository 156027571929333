import { useState, useEffect } from "react";
import moment from "moment";
import { CustomerDataFragment } from "data";
import { CreateOrderPackageForm } from "features/order-packages/components/CreateOrderPackageForm";
import type { CreateOrderPackageFormData } from "features/order-packages/components/CreateOrderPackageForm";
export type { CreateOrderPackageFormData } from "features/order-packages/components/CreateOrderPackageForm";

interface CreateOrderPackageFormContainerProps {
  customer: CustomerDataFragment;
  onChange: (formData: CreateOrderPackageFormData | null) => void;
}

export const CreateOrderPackageFormContainer = (
  props: CreateOrderPackageFormContainerProps
) => {
  const { customer, onChange } = props;
  const [formData, setFormData] = useState<CreateOrderPackageFormData>({
    name: `${customer.attributes?.accountName} ${moment().format("MMM D")}`,
  });

  useEffect(() => {
    onChange(formData.name ? formData : null);
  }, [formData, onChange]);

  return <CreateOrderPackageForm formData={formData} onChange={setFormData} />;
};
