import { Text, TextProps, HStack } from "@chakra-ui/react";
import { DeviceDataFragment } from "data";
import { deviceMakeToString } from "features/devices/data";
import { DeviceMakeIcon } from "./DeviceMakeIcon";

interface DeviceMakeLabelProps extends TextProps {
  deviceOrMake: DeviceDataFragment | string;
  showMakeIcon?: boolean;
}

export const DeviceMakeLabel = (props: DeviceMakeLabelProps) => {
  const { deviceOrMake, showMakeIcon, ...rest } = props;
  return (
    <HStack>
      {showMakeIcon ? (
        <DeviceMakeIcon deviceOrMake={deviceOrMake} w={4} h={4} />
      ) : null}
      <Text {...rest}>
        {typeof deviceOrMake === "string"
          ? deviceOrMake
          : deviceMakeToString(
              (deviceOrMake as DeviceDataFragment).attributes?.make
            )}
      </Text>
    </HStack>
  );
};
