import { Text, TextProps } from "@chakra-ui/react";
import { Enum_Device_Model } from "data";
import { deviceModelToString } from "features/devices/data";

interface DeviceModelLabelProps extends TextProps {
  deviceModel?: Enum_Device_Model;
}

export const DeviceModelLabel = (props: DeviceModelLabelProps) => {
  const { deviceModel, ...rest } = props;
  return <Text {...rest}>{deviceModelToString(deviceModel)}</Text>;
};
