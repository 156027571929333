import { Enum_Device_Type, Enum_Orderitem_Status } from "data";
import { shallowDiff } from "lib";
import { useOrderDetails } from "features/orders/context/OrderDetails";
import { useOrderItemsForOrder } from "features/order-items/context/OrderItemsForOrder";
import { Item } from "features/order-items/components/OrderItems";
import { ReceiveDevicePanelProvider } from "features/customer-devices/context/ReceiveDevicePanel";
import { OrderItemContextMenuProvider } from "features/order-items/context/OrderItemContextMenu";
import { OrderItemsFilters } from "features/order-items/components/OrderItemsFilters";
import { DiagnosticsContextMenuProvider } from "features/diagnostics/context/DiagnosticsContextMenu";
import { RepairsContextMenuProvider } from "features/repairs/context/RepairsContextMenu";
import { DataListWithFiltersAndPagination } from "components/DataList";

export const OrderItemsContainer = () => {
  const { order } = useOrderDetails();
  const orderItemsContext = useOrderItemsForOrder();

  return !order || !orderItemsContext ? null : (
    <ReceiveDevicePanelProvider order={order}>
      <OrderItemContextMenuProvider>
        <DiagnosticsContextMenuProvider>
          <RepairsContextMenuProvider>
            <DataListWithFiltersAndPagination
              loading={orderItemsContext.loading}
              data={orderItemsContext.data?.orderItems?.data || []}
              pagination={orderItemsContext.data?.orderItems?.meta.pagination}
              onPageChange={(page) => {
                orderItemsContext.setParams(
                  Object.assign({}, orderItemsContext.params, { page })
                );
              }}
              renderFilters={() => (
                <OrderItemsFilters
                  order={order}
                  selectedStatus={
                    orderItemsContext.params.status &&
                    orderItemsContext.params.status.length > 0
                      ? (orderItemsContext.params
                          .status as Enum_Orderitem_Status[])
                      : null
                  }
                  selectedDeviceType={
                    orderItemsContext.params.deviceTypes?.length === 1
                      ? (orderItemsContext.params
                          .deviceTypes[0] as Enum_Device_Type)
                      : null
                  }
                  onChange={({ selectedDeviceType, selectedStatus }) => {
                    const newParams = Object.assign(
                      {},
                      orderItemsContext.params,
                      {
                        status: selectedStatus ? [selectedStatus] : null,
                        deviceTypes: selectedDeviceType
                          ? [selectedDeviceType]
                          : [],
                      }
                    );

                    const diff = shallowDiff(
                      orderItemsContext.params,
                      newParams
                    );
                    orderItemsContext.setParams(
                      Object.keys(diff).filter((k) => k !== "page").length !== 0
                        ? Object.assign(newParams, { page: 1 })
                        : newParams
                    );
                  }}
                />
              )}
              renderItem={({ item: orderItem }) => (
                <Item orderItem={orderItem} />
              )}
            />
          </RepairsContextMenuProvider>
        </DiagnosticsContextMenuProvider>
      </OrderItemContextMenuProvider>
    </ReceiveDevicePanelProvider>
  );
};
