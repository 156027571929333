import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CustomerDeviceDataFragment } from "data";

interface LinkToCustomerDeviceProps {
  customerDevice?: CustomerDeviceDataFragment | null;
  onClick?: () => void;
  children: ReactNode;
}

export const LinkToCustomerDevice = (props: LinkToCustomerDeviceProps) => {
  const { customerDevice, onClick, children } = props;
  return customerDevice?.attributes?.shortId ? (
    <Link
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      to={`/devices/${customerDevice.attributes?.shortId}`}
    >
      {children}
    </Link>
  ) : null;
};
