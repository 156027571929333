import { useContext } from "react";
import { generateContextAndProvider, WizardContextData } from "context/Wizard";
import { InspectionStep, DiagnosticsReport } from "features/diagnostics/data";

const diagnosticsWizard =
  generateContextAndProvider<InspectionStep, DiagnosticsReport>();

export const useDiagnosticsWizard = (): WizardContextData<
  InspectionStep,
  DiagnosticsReport
> => {
  const context = useContext(diagnosticsWizard.Context);

  if (!context) {
    throw new Error(
      "Calling useDiagnosticsWizard outside of DiagnosticsWizardProvider"
    );
  }

  return context;
};

export const DiagnosticsWizardProvider = diagnosticsWizard.Provider;
