import { createContext, ReactNode, useContext } from "react";
import { OrderLayoutItemDataFragment, useOrderLayoutItemsQuery } from "data";

interface OrderLayoutContextData {
  loading: boolean;
  orderLayoutItems: OrderLayoutItemDataFragment[];
  refetch: () => Promise<void>;
}

interface OrderLayoutProviderProps {
  orderId: string;
  children: ReactNode;
}

const OrderLayoutContext = createContext<OrderLayoutContextData>(
  {} as OrderLayoutContextData
);

export const useOrderLayout = () => {
  return useContext(OrderLayoutContext);
};

export const OrderLayoutProvider = (props: OrderLayoutProviderProps) => {
  const { orderId } = props;
  const { loading, data, refetch } = useOrderLayoutItemsQuery({
    variables: {
      orderId,
    },
  });

  return (
    <OrderLayoutContext.Provider
      value={{
        refetch: async () => {
          await refetch();
        },
        loading,
        orderLayoutItems: data?.orderLayoutItems?.data || [],
      }}
    >
      {props.children}
    </OrderLayoutContext.Provider>
  );
};
