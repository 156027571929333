import { createContext, ReactNode, useContext, useState } from "react";
import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Spinner,
  Center,
  HStack,
  Card,
  CardBody,
  Text,
  Stack,
} from "@chakra-ui/react";
import { PowerOffIcon, PowerOnIcon } from "components/Icons";
import {
  InspectionRackDataFragment,
  useInspectedDevicesForRackQuery,
  useUpdateInspectedDeviceMutation,
  useUpdateInspectionRackMutation,
  InspectedDeviceDataFragment,
  Enum_Inspecteddevice_Hashingstatus,
} from "data";
import { useUpload } from "features/uploads/data/hooks/upload";
import {
  useBulkCheckin,
  useMinerScanner,
  useResetRack,
  DeviceWithMinerScanData,
  ScannerResponse,
  isInspectedDeviceValid,
  newStatusForDevice,
} from "features/inspections/data/hooks";
import { useInspectionSettings } from "features/inspections/context/InspectionSettings";
import { getIpsRange } from "./lib";

interface InspectionRackProviderProps {
  rack: InspectionRackDataFragment;
  children: ReactNode;
}

interface InspectionRackData {
  loading: boolean;
  devices: DeviceWithMinerScanData[];
  checkInDevices: (serialNumbers: string[]) => Promise<void>;
  scan: () => Promise<void>;
  done: () => Promise<void>;
  reset: () => Promise<void>;
  updateDeviceStatus: ({
    device,
  }: {
    device: InspectedDeviceDataFragment;
  }) => Promise<void>;
  canSubmit: boolean;
}

const InspectionRackContext = createContext<InspectionRackData>(
  {} as InspectionRackData
);

export const useInspectionRack = () => {
  return useContext(InspectionRackContext);
};

const zipDevicesWithMinerData = ({
  devices,
  ips,
  minerData,
}: {
  ips: string[];
  devices: InspectedDeviceDataFragment[];
  minerData: { [key: string]: ScannerResponse };
}): DeviceWithMinerScanData[] => {
  return devices.map((device, i) => {
    const ip = ips[i];
    return {
      device,
      ip,
      minerData: minerData[ip]?.miner_data,
      kernelLogs: minerData[ip]?.kernel_log,
    };
  });
};

export const InspectionRackProvider = (props: InspectionRackProviderProps) => {
  const { rack } = props;
  const {
    isOpen: isProgressModalOpen,
    onOpen: onProgressModalOpen,
    onClose: onProgressModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeviceStatusUpdateModalOpen,
    onOpen: onDeviceStatusUpdateModalOpen,
    onClose: onDeviceStatusUpdateModalClose,
  } = useDisclosure();
  const { layout, mode, poolSettings, expectedHashratePercentageCutoff } =
    useInspectionSettings();
  const { resetRack } = useResetRack();
  const [updateInspectedDevice] = useUpdateInspectedDeviceMutation();
  const [updateRack] = useUpdateInspectionRackMutation();
  const { bulkCheckin } = useBulkCheckin();
  const { loading, data, refetch } = useInspectedDevicesForRackQuery({
    variables: {
      inspectionRackId: props.rack.id!,
    },
  });
  const { scan, ping, configureMiningPool } = useMinerScanner();
  const { uploadTextFromURL } = useUpload();

  const [targetDevice, setTargetDevice] =
    useState<InspectedDeviceDataFragment | null>(null);
  const [minerData, setMinerData] = useState<{
    [key: string]: ScannerResponse;
  }>({});

  return (
    <InspectionRackContext.Provider
      value={{
        loading,
        canSubmit:
          (data?.inspectedDevices?.data || []).length !== 0 &&
          (data?.inspectedDevices?.data || [])
            .map((id) => isInspectedDeviceValid(id))
            .reduce((acc, v) => acc && v, true),
        devices: zipDevicesWithMinerData({
          devices: data?.inspectedDevices?.data || [],
          minerData,
          ips:
            rack.attributes?.ips ||
            getIpsRange({
              startIp: rack.attributes?.startIp!,
              start: layout.start,
              count: (data?.inspectedDevices?.data || []).length,
              stepCol: layout.stepCol,
              stepRow: layout.stepRow,
              devicesPerRow: layout.cols,
            }),
        }),
        async checkInDevices(serialNumbers) {
          await bulkCheckin({
            serialNumbers,
            inspection: rack.attributes?.inspection?.data!,
            rack,
          });
          const d = await refetch();

          if (
            poolSettings &&
            rack.attributes?.ips &&
            d.data.inspectedDevices?.data.length
          ) {
            onProgressModalOpen();

            for (let i = 0; i < d.data.inspectedDevices?.data.length; i++) {
              const ip = rack.attributes?.ips[i];
              const device = d.data.inspectedDevices.data[i];

              await configureMiningPool({
                minerIp: ip,
                groupName: poolSettings.groupName,
                url: poolSettings.url,
                username: `${poolSettings.username}.${device.attributes?.customer_device?.data?.attributes?.shortId}`,
                password: poolSettings.password,
              });
            }

            onProgressModalClose();
          }
        },
        async scan() {
          if (!rack.attributes?.startIp || !data?.inspectedDevices?.data) {
            return;
          }

          onProgressModalOpen();

          const ipsToScan =
            rack.attributes.ips ||
            getIpsRange({
              startIp: rack.attributes.startIp,
              start: layout.start,
              count: data.inspectedDevices.data.length,
              stepCol: layout.stepCol,
              stepRow: layout.stepRow,
              devicesPerRow: layout.cols,
            });

          if (mode === "scan") {
            // full scan

            const d = await scan({
              ips: ipsToScan,
            });

            const devices = zipDevicesWithMinerData({
              minerData: d,
              devices: data?.inspectedDevices?.data,
              ips: ipsToScan,
            });

            for (let i = 0; i < devices.length; i++) {
              const { device, minerData, kernelLogs, ip } = devices[i];

              const kernelLogsFile =
                kernelLogs &&
                (await uploadTextFromURL({
                  url: kernelLogs,
                  fileName: "kernel-logs.txt",
                }));

              await updateInspectedDevice({
                variables: {
                  id: device.id!,
                  data: Object.assign(
                    {
                      ip,
                      report: {
                        reportDate: new Date().toISOString(),
                        data: minerData,
                      },
                    },
                    newStatusForDevice(
                      devices[i],
                      expectedHashratePercentageCutoff
                    ),
                    kernelLogsFile
                      ? {
                          kernelLogs: kernelLogsFile.id,
                        }
                      : {}
                  ),
                },
              });
            }

            setMinerData(d);
          } else if (mode === "ping") {
            // ping only
            const pingableIps = await ping({ ips: ipsToScan });

            for (let i = 0; i < ipsToScan.length; i++) {
              const ip = ipsToScan[i];
              const device = data?.inspectedDevices?.data[i];

              await updateInspectedDevice({
                variables: {
                  id: device.id!,
                  data: Object.assign(
                    {
                      ip,
                    },
                    pingableIps.indexOf(ip) !== -1
                      ? {
                          // device can be pinged
                          gotResponse: true,
                          gotPower: true,
                        }
                      : {
                          // device is not pingable
                          gotResponse: false,
                        }
                  ),
                },
              });
            }
          }

          onProgressModalClose();
        },
        async done() {
          await updateRack({
            variables: {
              id: rack.id!,
              data: {
                devices: [],
              },
            },
          });
          await refetch();
        },
        async reset() {
          try {
            onProgressModalOpen();

            await resetRack({
              rack,
              devices: data?.inspectedDevices?.data || [],
              refetchDevices: async () => {
                await refetch();
              },
            });

            await refetch();
          } finally {
            onProgressModalClose();
          }
        },
        async updateDeviceStatus({ device }) {
          setTargetDevice(device);
          onDeviceStatusUpdateModalOpen();
        },
      }}
    >
      {/* Progress modal */}
      <Modal
        data-cy="bulk-scan-progress-modal"
        closeOnOverlayClick={false}
        isOpen={isProgressModalOpen}
        onClose={onProgressModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={6}>
            <Center>
              <Spinner size="xl" />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Device update modal */}
      <Modal
        size="lg"
        isOpen={isDeviceStatusUpdateModalOpen}
        onClose={onDeviceStatusUpdateModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={6}>
            <Stack>
              <HStack>
                <Text fontWeight="bold">
                  {
                    targetDevice?.attributes?.customer_device?.data?.attributes
                      ?.shortId
                  }
                </Text>
                <Text fontSize="large">Does this miner have power?</Text>
              </HStack>
              <HStack spacing={10}>
                <Card
                  cursor="pointer"
                  onClick={async () => {
                    if (targetDevice) {
                      await updateInspectedDevice({
                        variables: {
                          id: targetDevice.id!,
                          data: {
                            gotPower: false,
                            hashingStatus:
                              Enum_Inspecteddevice_Hashingstatus.NoHashing,
                          },
                        },
                      });
                    }
                    onDeviceStatusUpdateModalClose();
                  }}
                >
                  <CardBody>
                    <HStack>
                      <PowerOffIcon size="20" />
                      <Text data-cy="specify-power-off">No Power</Text>
                    </HStack>
                  </CardBody>
                </Card>
                <Card
                  cursor="pointer"
                  onClick={async () => {
                    if (targetDevice) {
                      await updateInspectedDevice({
                        variables: {
                          id: targetDevice.id!,
                          data: {
                            gotPower: true,
                            hashingStatus:
                              Enum_Inspecteddevice_Hashingstatus.NoHashing,
                          },
                        },
                      });
                      onDeviceStatusUpdateModalClose();
                    }
                  }}
                >
                  <CardBody>
                    <HStack>
                      <PowerOnIcon size="20" />
                      <Text data-cy="specify-power-on">Got Power</Text>
                    </HStack>
                  </CardBody>
                </Card>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {props.children}
    </InspectionRackContext.Provider>
  );
};
