import { Textarea } from "@chakra-ui/react";
import { useField, FieldAttributes } from "formik";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export const TextArea = ({ label, ...props }: FieldAttributes<any>) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Label>{label}</Label>

      <Textarea
        data-cy={`field-${field.name}`}
        errorBorderColor="crimson"
        isInvalid={meta.touched && meta.error ? true : false}
        {...field}
      />

      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </>
  );
};
