import { Box, HStack, Text, ButtonGroup, Button } from "@chakra-ui/react";
import { PaginationDataFragment } from "data";

interface PaginationProps {
  pagination?: PaginationDataFragment;
  onPageChange: (page: number) => void;
}

export const Pagination = (props: PaginationProps) => {
  const { onPageChange, pagination } = props;

  if (!pagination || pagination.total < pagination.pageSize) {
    return (
      <span
        data-order-items-max-page={pagination?.pageCount}
        data-cy="order-items-pagination"
      />
    );
  }

  return (
    <Box
      data-order-items-max-page={pagination.pageCount}
      data-cy="order-items-pagination"
      px={{ base: "4", md: "6" }}
      py="5"
    >
      <HStack spacing="3" justify="space-between">
        {pagination.total > pagination.pageSize ? (
          <Text color="muted" fontSize="sm">
            Showing {(pagination.page - 1) * pagination.pageSize + 1} to{" "}
            {pagination.page * pagination.pageSize} of {pagination.total}{" "}
            results
          </Text>
        ) : null}
        <ButtonGroup
          spacing="3"
          justifyContent="space-between"
          width={{ base: "full", md: "auto" }}
          variant="secondary"
        >
          {pagination.page > 1 ? (
            <Button
              data-cy="pagination-button-previous"
              size="sm"
              onClick={() => {
                onPageChange(pagination.page - 1);
              }}
            >
              Previous
            </Button>
          ) : null}
          {pagination.page < pagination.pageCount ? (
            <Button
              data-cy="pagination-button-next"
              size="sm"
              onClick={() => {
                onPageChange(pagination.page + 1);
              }}
            >
              Next
            </Button>
          ) : null}
        </ButtonGroup>
      </HStack>
    </Box>
  );
};
