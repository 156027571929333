import { useState } from "react";
import { LocationDataFragment } from "data";
import { Loader } from "components/Loader";
import { LocationSelector } from "features/locations/components/LocationSelector";
import { useLocations } from "features/locations/data/hooks/locations";

interface LocationSelectorContainerProps {
  onLocationSelected: (location?: LocationDataFragment) => void;
}

export const LocationSelectorContainer = (
  props: LocationSelectorContainerProps
) => {
  const { onLocationSelected } = props;
  const [selectedLocation, setSelectedLocation] =
    useState<LocationDataFragment | null>(null);
  const { locations, loading } = useLocations();

  return loading ? (
    <Loader />
  ) : (
    <LocationSelector
      locations={locations}
      selectedLocationId={selectedLocation ? selectedLocation.id : undefined}
      onUpdateSelectedLocationId={(selectedLocationId) => {
        const location = locations.find((l) => l.id === selectedLocationId);
        setSelectedLocation(location || null);
        onLocationSelected(location);
      }}
    />
  );
};
