import { useParams } from "react-router-dom";
import { Enum_Device_Type } from "data";
import {
  OrderItemProvider,
  useOrderItem,
} from "features/order-items/context/OrderItem";
import { OrderItemSummaryContainer as OrderItemSummary } from "features/order-items/containers/OrderItemSummary";
import { Tabs, TabList, TabPanels, TabPanel, Tab } from "components/utils/Tabs";
import { Page } from "components/layout/Page";
import { OrderItemPageSubtitle } from "features/order-items/components/OrderItemPageSubtitle";
import { OrderItemPageTitle } from "features/order-items/components/OrderItemPageTitle";
import { DiagnosticsContextMenuProvider } from "features/diagnostics/context/DiagnosticsContextMenu";
import { RepairsContextMenuProvider } from "features/repairs/context/RepairsContextMenu";
import { QAActionPanelProvider } from "features/qa/context/QAActionPanel";
import { OrderItemPageActions } from "features/order-items/components/OrderItemPageActions";
import { ReceiveDevicePanelProvider } from "features/customer-devices/context/ReceiveDevicePanel";
import { ReplaceSubcomponentPanelProvider } from "features/order-items/context/ReplaceSubcomponentPanel";
import { ActionsTimeline } from "features/actions/components/ActionsTimeline";
import { CustomerDeviceHistory } from "features/customer-devices/components/CustomerDeviceHistory";
import { LabelPrinterProvider } from "context/LabelPrinter";
import { OrderItemTaggerProvider } from "features/order-item-tags/context/OrderItemTagger";
import { NotesForOrderItem } from "features/notes/components/NotesForOrderItem";
import { OrderItemSettings } from "features/order-items/components/Settings";

export const ConnectedOrderItemPage = () => {
  const {
    loading,
    orderItem,
    subcomponentOrderItems,
    replacements,
    isComplete,
  } = useOrderItem();

  if (!loading && !orderItem) {
    return <strong>Nothing found</strong>;
  }

  return (
    <Page
      loading={loading}
      title={
        orderItem ? (
          <OrderItemPageTitle
            replacements={replacements}
            isComplete={isComplete()}
            orderItem={orderItem}
          />
        ) : null
      }
      subTitle={
        orderItem ? <OrderItemPageSubtitle orderItem={orderItem} /> : null
      }
      actionButton={
        orderItem ? <OrderItemPageActions orderItem={orderItem} /> : null
      }
    >
      {orderItem ? (
        <Tabs isLazy mt={8}>
          <TabList>
            {orderItem.attributes?.device?.data?.attributes?.type ===
            Enum_Device_Type.Miner ? (
              <Tab>Summary</Tab>
            ) : null}
            <Tab>Activity</Tab>
            <Tab>History</Tab>
            <Tab>Notes</Tab>
            <Tab>Settings</Tab>
          </TabList>
          <TabPanels>
            {orderItem.attributes?.device?.data?.attributes?.type ===
            Enum_Device_Type.Miner ? (
              <TabPanel>
                {orderItem.attributes?.order?.data ? (
                  <ReceiveDevicePanelProvider
                    order={orderItem.attributes?.order?.data}
                  >
                    <ReplaceSubcomponentPanelProvider>
                      <OrderItemSummary
                        order={orderItem.attributes?.order?.data}
                        subcomponentOrderItems={subcomponentOrderItems}
                        orderItem={orderItem}
                      />
                    </ReplaceSubcomponentPanelProvider>
                  </ReceiveDevicePanelProvider>
                ) : null}
              </TabPanel>
            ) : null}
            <TabPanel>
              <ActionsTimeline
                level="order-item"
                filters={{
                  order_item: {
                    id: {
                      eq: orderItem.id,
                    },
                  },
                }}
              />
            </TabPanel>
            <TabPanel>
              {orderItem.attributes?.customer_device?.data ? (
                <CustomerDeviceHistory
                  customerDevice={orderItem.attributes?.customer_device?.data}
                  currentOrder={orderItem.attributes?.order?.data}
                />
              ) : null}
            </TabPanel>
            <TabPanel>
              <NotesForOrderItem showComposer orderItem={orderItem} />
            </TabPanel>
            <TabPanel>
              <OrderItemSettings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : null}
    </Page>
  );
};

export const OrderItemPage = () => {
  const { orderItemId } = useParams();
  return orderItemId ? (
    <OrderItemProvider orderItemId={orderItemId}>
      <DiagnosticsContextMenuProvider>
        <RepairsContextMenuProvider>
          <QAActionPanelProvider>
            <LabelPrinterProvider>
              <OrderItemTaggerProvider>
                <ConnectedOrderItemPage />
              </OrderItemTaggerProvider>
            </LabelPrinterProvider>
          </QAActionPanelProvider>
        </RepairsContextMenuProvider>
      </DiagnosticsContextMenuProvider>
    </OrderItemProvider>
  ) : null;
};
