import { forwardRef, LegacyRef } from "react";
import { CustomerDeviceDataFragment } from "data";
import { QRCode } from "components/QRCode";

interface CustomerDeviceWitMeta {
  device: CustomerDeviceDataFragment;
  meta: string;
}

interface MultiDevicesPrintableLabelProps {
  devices: CustomerDeviceWitMeta[];
}

const LabelWithMeta = (props: { device: CustomerDeviceWitMeta }) => {
  const { device, meta } = props.device;

  let truncatedAccountName =
    device.attributes?.customer?.data?.attributes?.accountName;

  if (truncatedAccountName && truncatedAccountName.length > 12) {
    truncatedAccountName = truncatedAccountName.substring(0, 12);
  }
  return (
    <div
      style={{
        breakAfter: "page",
        position: "relative",
        width: "19mm",
        height: "27mm",
        paddingTop: "2mm",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "18mm",
          height: "15mm",
        }}
      >
        <QRCode relativeURL={`/devices/${device.attributes?.shortId}`} />
      </div>
      <div style={{ textAlign: "center", paddingTop: "15mm" }}>
        <div style={{ fontSize: "11px", fontWeight: "bold" }}>
          {device.attributes?.shortId}
        </div>
        <div style={{ fontSize: "9px", whiteSpace: "nowrap" }}>
          {truncatedAccountName}
        </div>
        <div style={{ fontSize: "8px" }}>{meta}</div>
      </div>
    </div>
  );
};

export const MultiDevicesPrintableLabel = forwardRef(
  (props: MultiDevicesPrintableLabelProps, ref) => {
    const { devices } = props;

    return (
      <div className="print-only" ref={ref as LegacyRef<HTMLDivElement>}>
        {devices.map((device) => (
          <LabelWithMeta device={device} />
        ))}
      </div>
    );
  }
);
