import { OrderItemDataFragment, Enum_Orderitem_Status, Enum_Orderitemaction_Action } from "data";
import { PurchaseIcon } from "components/Icons";
import { ActionComponent, ActionComponentVariant } from "components/ActionComponent";
import { useRegisterOrderItemAction } from "features/order-items/data/hooks/order-item-actions";
import { useUpdateOrderItemStatus } from "features/order-items/data/hooks/order-items";

interface PurchaseAction {
  orderItem: OrderItemDataFragment;
  variant: ActionComponentVariant;
}
// Used to indicate that the order item has been purchased by ACS from the customer
export const PurchaseAction = (props: PurchaseAction) => {
  const { orderItem, variant } = props;
  const { registerAction } = useRegisterOrderItemAction();
  const { updateOrderItemStatus } = useUpdateOrderItemStatus();

  return (
    <ActionComponent
      action={async () => {
        if (orderItem.id) {
          await registerAction({
            orderItem,
            action: {
              action: Enum_Orderitemaction_Action.Purchase,
            },
          });
          await updateOrderItemStatus(orderItem, Enum_Orderitem_Status.Purchased);
        }
      }}
      variant={variant}
      actionIcon={<PurchaseIcon />}
      actionLabel='Purchase from Customer'
      data-cy='action-purchase-from-customer'
    />
  );
};

PurchaseAction.defaultProps = {
  variant: "menu-item",
};
