import {
  InspectedDeviceDataFragment,
  Enum_Inspecteddevice_Hashingstatus,
} from "data";
import { ScannerMinerData } from "./miner-scanner";

export { useBulkCheckin, MISSING_SERIAL_PLACEHOLDER } from "./bulk-checkin";
export { useInspectionDetails } from "./inspection-details";
export { useMinerScanner } from "./miner-scanner";
export { useInspectionStats } from "./inspection-stats";
export type { ScannerMinerData, ScannerResponse } from "./miner-scanner";
export type { InspectionStats } from "./inspection-stats";
export { useResetRack } from "./reset-rack";

export interface DeviceWithMinerScanData {
  device: InspectedDeviceDataFragment;
  ip: string;
  kernelLogs?: string | null;
  minerData?: ScannerMinerData | null;
}

export const isInspectedDeviceValid = (
  device: InspectedDeviceDataFragment
): boolean => {
  // device is valid (i.e. ready for submission)
  // if it has gotPower explicitly set when gotResponse is false
  // and it's status is not unknown
  if (device.attributes?.gotResponse === false) {
    return device.attributes.gotPower !== null;
  }

  if (
    device.attributes?.gotPower === null &&
    device.attributes?.gotResponse === null &&
    device.attributes?.hashingStatus === null
  ) {
    return false;
  }

  return true;
};

const getPercentExpectedHashrate = (minerData: ScannerMinerData): number => {
  const { percent_expected_hashrate, summary, expected_hashrate } = minerData;

  if (!summary?.SUMMARY) {
    return percent_expected_hashrate || 0;
  }

  if ((summary.SUMMARY || []).length !== 0) {
    if (summary.SUMMARY[0]["GHS av"]) {
      // GHS -> THS
      return (summary.SUMMARY[0]["GHS av"] / 1000 / expected_hashrate) * 100;
    }
  }

  return percent_expected_hashrate || 0;
};

export const newStatusForDevice = (
  device: DeviceWithMinerScanData,
  expectedHashratePercentageCutoff: number
): {
  gotResponse: boolean;
  gotPower?: boolean;
  hashingStatus: Enum_Inspecteddevice_Hashingstatus;
} => {
  const percentExpectedHashrate = device.minerData
    ? getPercentExpectedHashrate(device.minerData)
    : 0;

  const hashingStatusForMinerData = (
    minerData: ScannerMinerData
  ): Enum_Inspecteddevice_Hashingstatus => {
    if (percentExpectedHashrate === 0) {
      return Enum_Inspecteddevice_Hashingstatus.NoHashing;
    }

    // check to see if summary + status data is available

    // XX: miner is partially hashing if
    // - one or more hashboards are missing
    // - miner is hashing below expectedHashratePercentageCutoff % expected rate
    if (
      minerData.hashboards.filter((h) => h.missing).length !== 0 ||
      percentExpectedHashrate < expectedHashratePercentageCutoff
    ) {
      return Enum_Inspecteddevice_Hashingstatus.PartiallyHashing;
    }

    return Enum_Inspecteddevice_Hashingstatus.Hashing;
  };

  if (!device.minerData && !device.kernelLogs) {
    return {
      gotResponse: false,
      hashingStatus: Enum_Inspecteddevice_Hashingstatus.NoHashing,
    };
  }

  if (device.minerData) {
    return {
      gotResponse: true,
      gotPower: true,
      hashingStatus: hashingStatusForMinerData(device.minerData),
    };
  }

  return {
    gotResponse: true,
    hashingStatus: Enum_Inspecteddevice_Hashingstatus.NoHashing,
  };
};
