import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { OrderDataFragment } from "data";

interface LinkToOrderProps {
  order?: OrderDataFragment | null;
  onClick?: () => void;
  children: ReactNode;
}

export const LinkToOrder = (props: LinkToOrderProps) => {
  const { order, onClick, children } = props;
  return order ? (
    <Link
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      data-cy="order-link"
      to={`/orders/${order.attributes?.zoho_sales_order?.data?.attributes?.salesOrderNumber}`}
    >
      {children}
    </Link>
  ) : null;
};
