import { FindCustomerDeviceContainer as FindCustomerDevice } from "features/customer-devices/containers/FindCustomerDevice";
import { AddCustomerDevice } from "features/customer-devices/components/AddCustomerDevice";
import { useRegisterNewCustomerDevice } from "features/customer-devices/data/hooks/customer-devices";
import { useReceiveDeviceContext } from "features/customer-devices/context/ReceiveDevice";

export const FindOrAddCustomerDevice = () => {
  const { setSelectedDevice, order, getDevice } = useReceiveDeviceContext();
  const { registerNewCustomerDevice } = useRegisterNewCustomerDevice();

  return (
    <FindCustomerDevice
      order={order}
      device={getDevice()}
      onFoundDevice={(device) => {
        if (!device) {
          return;
        }
        setSelectedDevice(device);
      }}
      nothingFoundTemplate={(serialNumber, onDeviceAdded) => (
        <AddCustomerDevice
          serialNumber={serialNumber}
          onAddDevice={async (serialNumber?: string) => {
            const device = getDevice();

            if (!device) {
              return;
            }

            const newDevice = await registerNewCustomerDevice({
              serialNumber,
              order,
              device,
            });

            if (newDevice) {
              onDeviceAdded(newDevice);
              setSelectedDevice(newDevice);
            }
          }}
        />
      )}
    />
  );
};
