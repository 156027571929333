import { Stack, HStack, Center, Text, Divider } from "@chakra-ui/react";
import {
  OrderItemDataFragment,
  OrderItemSubcomponentDataFragment,
  CustomerDeviceDataFragment,
  Enum_Orderitemreplacement_Status,
} from "data";
import { ReplaceIcon } from "components/Icons";
import { RadioCard, RadioCardGroup } from "components/forms/RadioCardGroup";
import { CustomerDeviceShortIdLabel } from "features/customer-devices/components/CustomerDeviceShortIdLabel";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";
import { ReplacementStatusLabel } from "features/order-items/components/ReplacementStatusLabel";

interface ReplaceSubcomponentFormProps {
  originalOrderItem: OrderItemDataFragment;
  orderItemSubcomponent: OrderItemSubcomponentDataFragment;
  replacementCustomerDevice: CustomerDeviceDataFragment;
  replacementStatus: Enum_Orderitemreplacement_Status;
  onChangeReplacementStatus: (
    replacementStatus: Enum_Orderitemreplacement_Status
  ) => void;
}

// originalOrderItem,
// orderItemSubcomponent,
// replacementCustomerDevice,
// replacementStatus,

export const ReplaceSubcomponentForm = (
  props: ReplaceSubcomponentFormProps
) => {
  const {
    originalOrderItem,
    replacementCustomerDevice,
    replacementStatus,
    onChangeReplacementStatus,
  } = props;

  return (
    <Stack>
      <Center>
        <HStack>
          <DeviceCompleteNameLabel
            fontSize="md"
            device={originalOrderItem.attributes?.device?.data}
            showMakeIcon
          />
          <CustomerDeviceShortIdLabel
            customerDevice={originalOrderItem.attributes?.customer_device?.data}
          />
        </HStack>
      </Center>
      <Center>
        <ReplaceIcon size="30" />
      </Center>
      <Center>
        <HStack>
          <DeviceCompleteNameLabel
            fontSize="md"
            device={replacementCustomerDevice.attributes?.device?.data}
            showMakeIcon
          />
          <CustomerDeviceShortIdLabel
            customerDevice={replacementCustomerDevice}
          />
        </HStack>
      </Center>
      <Divider pt="4" />
      <Stack pt="4" spacing="6">
        <Center>
          <HStack>
            <Text>What do you want to do with the original device? </Text>
            <Text fontWeight="bold" fontSize="small">
              {
                originalOrderItem.attributes?.customer_device?.data?.attributes
                  ?.shortId
              }
            </Text>
          </HStack>
        </Center>
        <RadioCardGroup
          onChange={(status) => {
            onChangeReplacementStatus(status);
          }}
          value={replacementStatus}
          spacing="3"
        >
          {[
            Enum_Orderitemreplacement_Status.ScrapForParts,
            Enum_Orderitemreplacement_Status.Refurbish,
            Enum_Orderitemreplacement_Status.ReturnToCustomer,
          ].map((replacementStatus) => (
            <RadioCard key={replacementStatus} value={replacementStatus}>
              <ReplacementStatusLabel
                data-cy="replacement-status"
                color="emphasized"
                fontWeight="medium"
                fontSize="sm"
                status={replacementStatus}
              />
            </RadioCard>
          ))}
        </RadioCardGroup>
      </Stack>
    </Stack>
  );
};
