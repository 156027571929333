import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { OrderItemDataFragment } from "data";

interface LinkToOrderItemProps {
  orderItem?: OrderItemDataFragment | null;
  children: ReactNode;
}

export const LinkToOrderItem = (props: LinkToOrderItemProps) => {
  const { orderItem, children } = props;
  return orderItem ? (
    <Link data-cy="order-item-link" to={`/orders/items/${orderItem.id}`}>
      {children}
    </Link>
  ) : null;
};
