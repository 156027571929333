import { useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthentication } from "features/accounts/context/Authentication";

export const RedirectIfLoggedIn = ({
  children,
}: {
  children: ReactElement;
}) => {
  const navigate = useNavigate();
  const { isLoggedIn, loginData, isCustomerProfileComplete } =
    useAuthentication();

  useEffect(() => {
    const runner = async () => {
      const loggedIn = await isLoggedIn();
      if (
        loggedIn &&
        (loginData?.isCustomer ? isCustomerProfileComplete : true)
      ) {
        navigate("/orders");
      }
    };
    runner();
  }, []);

  return children;
};
