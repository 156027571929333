import {
  Stack,
  Code,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { CustomerDeviceDataFragment, OrderDataFragment } from "data";
import { Loader } from "components/Loader";
import { LinkToOrderItem } from "features/order-items/components/LinkToOrderItem";
import { useCustomerDeviceHistory } from "features/customer-devices/data/hooks/history";

interface CustomerDeviceHistoryProps {
  customerDevice: CustomerDeviceDataFragment;
  currentOrder?: OrderDataFragment | null;
}

export const CustomerDeviceHistory = (props: CustomerDeviceHistoryProps) => {
  const { customerDevice, currentOrder } = props;
  const { loading, history } = useCustomerDeviceHistory({
    customerDevice,
  });

  return loading ? (
    <Loader />
  ) : history ? (
    <TableContainer>
      <Table variant="simple">
        <Tbody>
          {history.orderItems.map((oi) => {
            const isCurrent =
              currentOrder &&
              oi.attributes?.order?.data?.id === currentOrder.id;

            return (
              <Tr fontWeight={isCurrent ? "bold" : "normal"} key={oi.id}>
                <Td>
                  <LinkToOrderItem orderItem={oi}>
                    {
                      oi.attributes?.order?.data?.attributes?.zoho_sales_order
                        ?.data?.attributes?.salesOrderNumber
                    }
                  </LinkToOrderItem>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <strong>N/a</strong>
  );
};
