import {
  OrderItemDataFragment,
  Enum_Device_Type,
  OrderItemSubcomponentDataFragment,
} from "data";
import { ReplaceIcon } from "components/Icons";
import {
  ActionComponent,
  ActionComponentVariant,
} from "components/ActionComponent";
import { useReplaceSubcomponentPanel } from "features/order-items/context/ReplaceSubcomponentPanel";
import { useReceiveDeviceContext } from "features/customer-devices/context/ReceiveDevice";

interface ReplaceSubcomponentActionProps {
  orderItem?: OrderItemDataFragment | null;
  orderItemSubcomponent: OrderItemSubcomponentDataFragment;
  variant: ActionComponentVariant;
}

export const ReplaceSubcomponentAction = (
  props: ReplaceSubcomponentActionProps
) => {
  const { orderItem, orderItemSubcomponent, variant } = props;
  const { setTarget } = useReceiveDeviceContext();
  const { showActionPanel } = useReplaceSubcomponentPanel();
  const deviceType =
    orderItemSubcomponent.attributes?.device?.data?.attributes?.type;

  if (
    !orderItem ||
    !orderItemSubcomponent.attributes?.order_item?.data ||
    !deviceType ||
    [Enum_Device_Type.ControlBoard, Enum_Device_Type.Psu].indexOf(
      deviceType
    ) === -1
  ) {
    return null;
  }

  return (
    <ActionComponent
      action={async () => {
        setTarget(orderItem);
        showActionPanel({
          orderItem,
          orderItemSubcomponent,
        });
      }}
      variant={variant}
      actionIcon={<ReplaceIcon />}
      actionLabel="Replace"
      data-cy="action-replace-subcomponent"
    />
  );
};

ReplaceSubcomponentAction.defaultProps = {
  variant: "button",
};
