import { FaFan } from "react-icons/fa";
import { useDiagnosticsWizard } from "features/diagnostics/context/DiagnosticsWizard";
import { FansDiagnostics } from "features/diagnostics/components/miners/FansDiagnostics";
import { MinerDiagnosticsReport } from "features/diagnostics/data";

export const PSUFansDiagnostics = () => {
  const { data, updateData } = useDiagnosticsWizard();
  const minerDiagnosticsReport = data as MinerDiagnosticsReport;
  return (
    <FansDiagnostics
      icon={FaFan}
      prefix="PSU Fan"
      fans={minerDiagnosticsReport?.psuFans || []}
      onChange={(fans) => {
        updateData(
          Object.assign({}, minerDiagnosticsReport, {
            psuFans: fans,
          })
        );
      }}
    />
  );
};
