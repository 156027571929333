import {
  Badge,
  BadgeProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { OrderDataFragment, Enum_Order_Status } from "data";
import { orderStatusToString } from "features/orders/data";
import { ShowForCustomers, ShowForAllTechs } from "features/accounts";
import { useUpdateOrderStatus } from "features/orders/data/hooks/update-order";

interface OrderStatusBadgeProps extends BadgeProps {
  orderData: OrderDataFragment;
}

const statusList = [
  Enum_Order_Status.Receiving,
  Enum_Order_Status.Wip,
  Enum_Order_Status.Complete,
];

const colorSchemeForStatus = (status?: Enum_Order_Status) => {
  switch (status) {
    case Enum_Order_Status.Complete:
      return "green";
    case Enum_Order_Status.Wip:
      return "blue";
    default:
      return undefined;
  }
};

export const OrderStatusBadge = (props: OrderStatusBadgeProps) => {
  const { orderData, ...rest } = props;
  const { updateOrderStatus } = useUpdateOrderStatus();

  return (
    <>
      <ShowForCustomers>
        <Badge
          colorScheme={colorSchemeForStatus(orderData.attributes?.status)}
          data-order-status={orderData.attributes?.status}
          {...rest}
        >
          {orderStatusToString(orderData.attributes?.status)}
        </Badge>
      </ShowForCustomers>
      <ShowForAllTechs>
        <Menu>
          <MenuButton
            data-cy="editable-order-badge"
            data-order-status={orderData.attributes?.status}
          >
            <Badge {...rest}>
              {orderStatusToString(orderData.attributes?.status)}
            </Badge>
          </MenuButton>
          <MenuList fontSize="initial">
            {statusList
              .filter((s) => s !== orderData.attributes?.status)
              .map((s) => (
                <MenuItem
                  data-cy={`set-order-status-${s}`}
                  key={s}
                  onClick={async () => {
                    await updateOrderStatus({
                      order: orderData,
                      status: s,
                    });
                  }}
                >
                  {orderStatusToString(s)}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </ShowForAllTechs>
    </>
  );
};
