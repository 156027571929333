import { Text } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { LayoutShell } from "features/accounts/components/LayoutShell";

export const PasswordResetRequestedPage = () => {
  const [searchParams] = useSearchParams();
  return (
    <LayoutShell
      title="Password reset requested"
      subtitle="Check your email address for the instructions"
    >
      <Text>
        We emailed reset instructions to{" "}
        <strong>{searchParams.get("email")}</strong>
      </Text>
    </LayoutShell>
  );
};
